
.exhibit-cards-container  {

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  
  .card__exhibit-media {
    flex: 0 1 100%;
    margin-bottom: 12px;
    background-color: #222;
    transition: 0.2s;

    a {
      text-decoration: none;
      color: white;
    }

    &:hover {
      background-color: black;
      transform: scale(1.01);
      box-shadow: 2px 2px 2px rgba(#666, 0.5);

      a {
        color: white;
      }
    }

    
    .exhibit-media-title {
      padding: 0 10px 1rem 10px;
      @include font-size(2);
      font-family: 'Roboto Condensed';
      color: white;
    }

    .exhibit-media-summary,
    .exhibit-media-description {
      padding: 0 10px 10px 10px;
      @include font-size(1.4);
      color: white;

      p {
        margin: 0 0 0.5rem 0;
        @include font-size(1.4);
        color: white;
      }
    }

    .exhibit-media-more {
      margin: 10px;
      text-align: right;;

      a {
        @include simple-button($bg-color: $hawk-gold);
        @include font-size(1.4);
      }
    }
  }

  @include breakpoint($smallish-width) {

    .card__exhibit-media {
      flex: 0 1 calc(50% - 0.5rem);

      &.columns-2 {
        flex: 0 1 100%;
      }

      &.columns-3 {
        flex: 0 1 100%;
      }
    }
  }

  @include breakpoint($medium-width) {

    .card__exhibit-media {
      flex: 0 1 calc(33.3333% - 0.5rem);

      &.columns-2 {
        flex: 0 1 calc(66.6666% - 0.25rem);
      }

      &.columns-3 {
        flex: 0 1 100%;
      }
    }

    // // TODO:  TOTAL HACK. Moving to grid css should fix this.
    // div.wp-caption > img {
    //   // max-width: calc($medium-width * 0.66);
    //   max-width: 66% !important;
    //   width: 66%;
    // }
  }

  // @include breakpoint($fixed-width) {

  //   // TODO:  TOTAL HACK. Moving to grid css should fix this.
  //   div.wp-caption > img {
  //     max-width: calc($fixed-width*66%) !important;
  //   }
  // }
}

.single-exhibit {

  a.virtual-exhibit {
    @include simple-button-with-icon("laptop-house", $hawk-gold);
    @include font-size(2.8);
    // text-transform: uppercase;
  }

  h3.exhibit-dates {
    display: none;
  }
}

// Detail pages
.single-exhibit_media {

  .entry-title {

    .exhibit-type {
      display: block;
      font-size: 60%;
      font-weight: lighter;
      text-transform: uppercase;
    }
  }

  .exhibit-media-title {
    @include font-size(3);
    font-family: 'Roboto Condensed', sans-serif;
    // font-weight: lighter;
  }

  // TODO:  TOTAL HACK. Moving to grid css should fix this.
  div.wp-caption > img {
    max-width: 66%;
  }

  
}