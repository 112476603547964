// These styles override/augment any contrib styles associated with Slicknav mobile menu.

.slicknav_menu {
	padding: 4px 0 0 0;
	background-color: #222;
	text-align: center;

	a.slicknav_btn {
		margin: 0 auto;
		line-height: 36px;
		text-decoration: none;

		.slicknav_menutxt {
			margin-left: 2px;
			padding: 0;
			text-transform: uppercase;
			color: #fff;
			@include font-size(1.2);
		}

		.slicknav_icon {
			display: inline-block;
			width: 24px;
			height: 24px;
			background-image: url('/includes/icons/png/white/32/bars.png');
			background-size: cover;
			vertical-align: text-bottom;
			transition: 0.33s;
		}
		&.slicknav_open .slicknav_icon {
			background-image: url('/includes/icons/png/white/32/times-circle.png');
		}
	}

	ul.slicknav_nav {
		margin: 0;
		padding: 0.5em 1em;
		list-style-type: none;
		text-align: left;
		background-color: #666;

		ul {
			padding-left: 1em;
			list-style-type: none;
		}
		
		li {
			padding: 0.25em;
		
			a {
				text-decoration: none;
				color: #ccc;

				&:hover {
					background-color: #666;
				}
			}
		}
	}
}

// Hamburger overrides
// .c-hamburger--htx {
// 	background-color: #222;
// }

// .c-hamburger {
// 	display: inline-block;
// }