.hide-offscreen {
	@include hide-offscreen;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

// Default the svg from FA to be small. Otherwise they are HUGE.
.inline-icon svg {
	height: 18px;
	width: 18px;
}
