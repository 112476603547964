.pod-region-slide-outer {

	.pod-region-slide-inner {

		.slide-item {

			// ------ Title options -----------------------------------------------
			&.title-hide {

				.slide-title {
					@include hide-offscreen;
				}
			}

			&.darker-bg {

				.slide-title {
					background-color: rgba(black, 0.85);
				}
				.slide-description {
					background-color: rgba(black, 0.85);
				}
			}

			&.lighter-bg {

				.slide-title {
					background-color: rgba(black, 0.5);
				}
				.slide-description {
					background-color: rgba(black, 0.5);
				}
			}

			&.left-title,
			&.title-left {

				@include breakpoint($fixed-width) {

					.slide-title {
						left: 0;
						right: auto;
						padding: 8px 24px 8px 300px;
						width: auto;
					}
				}
			}

			&.title-right {

				@include breakpoint($fixed-width) {

					.slide-title {
						left: auto !important;
						right: 0;
						width: auto;
					}
				}
			}

			&.title-smaller {

				@include breakpoint($fixed-width) {

					.slide-title {
						@include font-size($default__slide-title-font-size * 0.666);
					}
				}
			}

			&.title-bigger {

				@include breakpoint($fixed-width) {

					.slide-title {
						@include font-size($default__slide-title-font-size * 1.25)
					}
				}
			}

			&.title-full-width {

				@include breakpoint($fixed-width) {

					.slide-title {
						left: 0;
						right: 0;
						text-align: center;
						width: auto;
					}
				}
			}

			&.title-lower {

				@include breakpoint($fixed-width) {

					.slide-title {
						top: auto !important;
						bottom: 18px;
						width: auto;
					}
				}
			}

			// ------ Description options ---------------------------------------------------




			// ------ Image description options ---------------------------------------------



			
			// ------ Covers ----------------------------------------------------------------
			&.cover-pattern-screendoor {

				.slide-cover {
					background-image: url('/includes/cover-pattern-screendoor.png');
				}
			}

			&.cover-pattern-gridpaper {

				.slide-cover {
					background-image: url('/includes/cover-pattern-gridpaper.png');
				}
			}

			&.cover-pattern-squares {

				.slide-cover {
					background-image: url('/includes/cover-pattern-tiles.png');
				}
			}

			&.cover-pattern-dark {

				.slide-cover {
					background-color: black;
					opacity: 0.333;
				}
			}

			&.cover-pattern-darker {

				.slide-cover {
					background-color: black;
					opacity: 0.666;
				}
			}

			&.cover-pattern-darkest {

				.slide-cover {
					background-color: black;
					opacity: 0.8;
				}
			}

			&.cover-pattern-light {

				.slide-cover {
					background-color: white;
					opacity: 0.333;
				}
			}

			&.cover-pattern-lighter {

				.slide-cover {
					background-color: white;
					opacity: 0.666;
				}
			}

			&.cover-pattern-lightest {

				.slide-cover {
					background-color: white;
					opacity: 0.8;
				}
			}

			// &.cover-blur {

			// 	.slide-cover {
			// 		@include filter(grayscale(50%));
			// 		// @include filter(blur(5px));
			// 	}
			// }
		}
	}
}