@import "buttons";
@import "typography";
@import "selects";

/* -- Gravity forms overrides -- */

// BEGIN _old-global.scss
// Dust off and nuke this from orbit.
input[type=text], input[type=password], textarea { background: none; }
.gform_wrapper textarea { font-size: 100%; }
// input[type=submit] { background: none; }
.gform_wrapper .gform_body .gform_fields .gfield input[type=text] { padding: 8px; margin: 0; }
.gform_wrapper .gform_body .gform_fields .gfield input[type=email] { padding: 8px; margin: 0; }
.gform_wrapper .gform_body .gform_fields .gfield input[type=tel] { padding: 8px; margin: 0; }
.gform_wrapper .gform_body .gform_fields .gfield select { padding: 2px; margin: 0; }
.gform_wrapper .left_label li.gfield_html_formatted, .uil_description p { font-weight: normal; font-size: 100%; padding: 0 0 7px 0; margin: 0; }
// .gform_wrapper .gform_body .gform_fields h3 { padding: 20px 0; }
.uil_description a { text-decoration: underline; }
///*added 4/30/12*/

.gform_wrapper select, .gform_wrapper li { font-size: 100%; }

///*added 5/25/12 - for the email line on the contact us forms*/
.contactforms { padding-top: 10px; }
///*added 5/29/12 for recall form*/
.indent { padding-left: 30px; }
//@include

.gform_wrapper .description, .gform_wrapper .gfield_description, .gform_wrapper .gsection_description, .gform_wrapper .instruction { font-size: 100%; }
.gform_wrapper .gfield_label { font-size: 100%; }
.gform_wrapper .left_label .gfield_label { width: 20%; font-size: 100%; }
.gform_wrapper .gform_description { font-size: 100%; }
.gform_wrapper input[type=text], .gform_wrapper input[type=url], .gform_wrapper input[type=email], .gform_wrapper input[type=tel], .gform_wrapper input[type=number], .gform_wrapper input[type=password] { font-size: 100%; }
.gform_wrapper .dontdisplay { font-size: 1.2em; }
.gform_wrapper .dontdisplay .gfield_label /*.gfield_label .dontdisplay */ { display: none; }
// END _old-global.scss

.gform_wrapper {
	margin-top: 0;
	padding-left: 1rem;
	padding-right: 1rem;

	// TEMP:  Possible fix for Siteimprove errors
	.ginput_complex {

		label {
			overflow: visible;
		}
	}

	.gform_heading {

		.gform_description {
			font-weight: bold;

			strong {
				color: #900;
			}
		}
	}

	.gform_body {
		margin-bottom: 1.5em;

		// .gfield_description {
		// 	padding-top: 0;
		// }

		h3 {
			padding: 0;
			margin: 0; 
			// font-size: 1.1em; 
		}
		.gform_fields .gfield {
			margin-top: 1em;
			margin-bottom: 1em;

			select, input[type=text], input[type=email], textarea {
				font-size: 1em;
				border: 1px solid #999;
			}

			.gfield_description {
				padding-top: 0;
			}
		}
		.gfield_required {
			color: #900;
		}

		ul.gfield_checkbox {

			li {

				br {  
					display: none;  // kills the inexplicable <br> that GF sticks after the checkbox
				}
			}
		}

		.field_sublabel_below {

			// label {
			// 	margin-bottom: 0;
			// }
		}

		// multi-page forms
		.gform_page_footer {
			
			border-top: none;

			// "Next" button in a multipage form
			input[type="button"].gform_next_button {
				@include simple-button-with-icon('arrow-circle-right');
				border: 0;
			}
			input[type="button"].gform_previous_button {
				@include simple-button-with-icon('arrow-circle-left');
				border: 0;
			}
		}
	}

	.gform_footer {
		padding: 0 !important;
	}

	// default buttons like "Submit"
	input[type="submit"].gform_button {
		@include simple-button;
		@include font-size(1.6);
		border: 0;
	}

	.gfield_html_formatted {

		 ul {
		 	padding-left: 2em !important;
		 	// background-color: #900;

		 	li {
				margin-bottom: 0 !important;
		 	}
		 }
	}

	.gfield_description {

		ol, ol li {
			list-style-type: decimal !important;
		}

		li {
			list-style-type: disc !important;
		}
	}

	.gform_validation_errors {
		// border-bottom: 0;
		// border-top: 0;
		// color: white !important;
		// text-align: left;
		// background-color: #900;
		// @include simple-icon('exclamation-triangle', #900);

		h2.gform_submission_error {
			text-align: left;
		
			&:before {
				background-image: none;
			}
		}
	}
	
	// validation error, missed required elements
	li.gfield.gfield_error.gfield_contains_required {
		padding-left: 6px;
		padding-right: 6px;
		border-top: 6px solid #900;
		border-bottom: 0;
		background-color: #eee;

		div.gfield_description.validation_message {
			margin-top: 6px;
			padding: 6px;
			color: white;
			background-color: #900;
		}
	}

	// ------ BEGIN breakpoints ----------------------------------------
	@include breakpoint($fixed-width) {
		padding-left: 0;
		padding-right: 0;
	}
	// ------ END breakpoints ------------------------------------------

	.gform_fileupload_multifile .gform_drop_area .gform_drop_instructions {
		color: #555;
	}

	// Checkboxes, radio buttons, quiz inputs and labels need to be inline. Duh.
	.gfield--type-choice {

		.gchoice {
			display: flex;
			align-items: flex-start;
			padding-top: 0.5rem;
			
			input {
				margin-top: 0.2em;
				margin-right: 0.75em;
			}
			label {

			}
		}
	}

	// Tweaks for HTML blocks.
	.gfield--type-html {
	
		h4 {
			margin-top: 0;
		}
	}
}