body.error404 {



	.page-region__content-primary {
		grid-column: col-start 1 / span 12 !important;
        grid-row: 2 / 5;

	}

	#wrapper {
		
		padding-top: 0;

	}

	#content .single-post {
		margin-top: 0;
	}

	.entry-content {
		margin: 0 auto;
		max-width: $fixed-width;
	}

	
	.error404-top {
		position: relative;

		img {
			width: 100%;
		}
		.entry-title {
			position: absolute;
			left: 0;
			bottom: 0;
			padding: 3rem;
			
			@include font-size(6);

			text-shadow: 0 1px 1px #000;
        	color: white;
		}
	}
}