// Overriding WP plugin, Zotpress
// Initially created for http://www.lib.uiowa.edu/biz/tippie-publications/ (20 May 2015 -Ken)
.zp-Zotpress {
	@include zebra-stripe;

	.zp-Entry {
		padding: 0.5em 0.75em;
		@include zebra-stripe;

		// &:nth-of-type(odd) {
		// 	background-color: $gray-lightest;
		// }

		.csl-entry {
			line-height: 1.75em;
		}
	}
}