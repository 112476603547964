.entry-content {

	ul, ol {
		margin: 0.5em 1em 1em 0.5em; 
		// overflow: hidden;

		ul, ol {
			margin-top: 0;
			margin-bottom: 1rem;
		}

		li {
			line-height: 1.5;
		}
	}

	ul {

		li ~ p {
			margin: 0;
		}
		li > a {
			line-height: 2.4rem;
		}
		&.space-between > li {
			margin-bottom: 1em;
		}
	}

	ol {
		// list-style-type: decimal;

		li {
			// list-style: decimal;
		}
		&.space-between > li {
			margin-bottom: 1em;
		}
	}

	// description lists
	dl {

		dt {
			font-weight: bold;
		}
		dd {
			margin: 0 0 1rem 1rem;
		}
	}
}

// Simple lists with a key (accessible styling)
.simple-list-with-key {

  .simple-list-key {
    margin: 0;
    padding: 1em;
    list-style-type: none;
    background-color: #ddd;

    li {
      font-weight: normal;
      font-style: italic;

      &:before {
        content: " = ";
      }
    }
  }

  .simple-list-items {

    dt, li {

      .item-message-outer {
				margin-left: 1rem;
  
        .item-message {
          @include hide-offscreen;
          font-weight: lighter;
          font-size: 85%;
        }
      }
    }
	}
	
	// classes and associated icons in the key
	.simple-list-key li,
	.simple-list-items .item-message-outer {

		&.restricted-resource {
			@include simple-icon('lock');
		}
		&.recommended-resource {
			@include simple-icon('star');
		}
	}
}