@media print {

	#printheader {
		display: block;
		.printheader-inner {
			margin: 0 auto;
			max-width: $fixed-width;
		}
	}

	.entry-title-outer {
		background-color: white !important;
		font-family: 'Zilla Slab' !important;
		font-weight: 700 !important;
	}

	.all-pages-alert {
		display: none;
	}

	.home.site-staff {
		
		.moreinfo {
			display: none;
		}
	}
}