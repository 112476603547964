// @import '../../assets/scss/variables';
/////// Accordions
.accordion {
  $md: 1.25rem;
  $sm: 0;

  .accordion__heading {
    margin: 0;
    margin-top: 1rem;
    font-size: 1.9rem;
    font-weight: 700;
    padding-bottom: 0;

    &:not(:first-child) {
      margin-top: 1rem;
    }

    button {
      all: inherit;
      width: calc(100% - 2.5rem);
      background-color: #edeceb;
      padding: $md;
      font-family: $font-stack-body;

      display: flex;
      justify-content: space-between;
      transition: all 0.2s ease-in-out;
      transition-property: background-color;
      cursor: pointer;

      &:focus {
        outline: 5px auto -webkit-focus-ring-color;
      }

      i,
      img {
        color: currentColor;
        transform: rotate(-180deg) translate(0px, -0.1em);
        transition: all 0.3s ease-in-out;
        transition-property: transform, background-color;
        background-color: transparent;
        width: 30px !important;
        height: 30px !important;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -0.3rem 0;
        border-radius: 50%;
        opacity: 0.8;
      }

      &:hover,
      &:focus {
        // background-color: darken(#edeceb, 4%);

        img {
          transform: rotate(-180deg) translate(0px, -0.2em);
        }

        &[aria-expanded="true"] i,
        &[aria-expanded="true"] img {
          transform: rotate(0deg) translate(0px, 0em);
        }
      }

      &[aria-expanded="true"] i,
      &[aria-expanded="true"] img {
        transform: rotate(0deg) translate(0px, 0.1em);
        background-color: #fafafa;
      }
    }
  }

  .accordion__content {
    padding: $sm $md;
    padding-bottom: 0;
    border: 1px solid #edeceb;

    p {
      font-size: inherit;
    }
  }
}
