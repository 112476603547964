//
// Defines a subset of variants of UIDS buttons for the Libraries

.uids-button,
.wp-block-button a {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: .7em 3rem .55em 3rem;
    background-color: $hawk-gold;
    color: black !important; // needed to override link colors
    font-family: "Antonio", "Antonio-fallback", sans-serif;
    // font-family: 'Roboto Condensed', sans-serif;
    font-variation-settings: "wght" 500;
    outline: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 2rem;
    transition: 0.1s;
    outline-offset: -2px;
    border: 0;
  
    // For the button underline that appears on hover
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 100%;
      height: 4px;
      background-color: black;
      transform-origin: center;
      transform: translate(-50%, 0) scaleX(0);
      transition: transform 0.3s ease-in-out;
    }
  
    &:hover {
      text-decoration: none;
      background-color: $hawk-gold;
      color: black;
    
      &:after {
        transform: translate(-50%, 0) scaleX(1);
      }
    }
  
    i {
      margin: 0 0 0 0.5em; // Default: icon follows link text
      color: black;
      vertical-align: middle;
  
      &.icon-first {
        margin: 0 0.5em 0 0;
      }
  
      // The :before psuedo-element is used by FontAwesome code regardless of icon placement
      &:before {
        display: inline-block;
        margin-bottom: 4px;
      }
    }
  
    &:focus {
      text-decoration: none;
      outline: 2px solid $link-standard;
    }
  
    &--align-right {
      float: right;
    }
  
    &--small-font {
      font-size: 1.8rem;
    }

    &--smaller-font {
      font-size: 1.6rem;
    }
  
    &--large-font {
      font-size: 2.4rem;
    }
  
    &--larger-font {
      font-size: 3.2rem;
    }
  
    &--norm-case {
      text-transform: none;
    }
  
    &--full-width {
      width: calc(100% - 6rem);
    }
  
    &--body-font {
      font-family: $font-workhorse-condensed;
    }

    &--simple {
      font-family: $font-workhorse;
      text-transform: none;
      @include font-size(1.6);
    }
  
  }
  
  .uids-button--primary {
    @extend .uids-button;
  }
  
  .uids-button--secondary {
    @extend .uids-button;
    background-color: black;
    color: white !important;
  
    &:after {
      background-color: $hawk-gold !important;
    }
  
    &:hover {
      background-color: black !important;
      color: white;
    }
    i {
      color: $hawk-gold;
    }
  }
  
  .uids-button--tertiary {
    @extend .uids-button;
    background-color: $secondary-grey-lighter;
    color: black !important;
    border: 1px solid black !important;
  
    &:after {
      background-color: $hawk-gold !important;
    }
  
    &:hover {
      background-color: $secondary-grey-lighter !important;
      color: black;
    }
    i {
      color: $hawk-gold;
    }
  }
  
  .uids-button--card-cta {
    @extend .uids-button;
    background-color: transparent;
    color: black;
    border: 1px solid black;
  
    // --- TEMP -------------------------------------------------
    // display: inline-block;
    font-family: "Roboto", sans-serif;
    @include font-size(1.4);
    line-height: 20px;
    border-color: $hawk-gold !important;
    // ----------------------------------------------------------
  
    &:after {
      background-color: $hawk-gold !important;
    }
  
    &:hover {
      background-color: transparent;
      color: black;
    }
    i {
      color: $hawk-gold;
    }
  }
  
  
  
  