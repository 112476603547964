.big-feature-item {
  position: relative;
  display: block;
  min-height: 240px;
  background-size: cover;
  background-repeat: no-repeat;
  flex: 1.68;

  a.big-feature-link {
    display: block;
    margin: 0;
    padding: 0;
    height: 100%;
  }

  .big-feature-title-area {
    position: absolute;
    margin: 0;
    padding: 0.5rem;
    bottom: 18px;
    left: 0;
    background-color: rgba(black, 0.75);
    color: white;

    .big-feature-title {
      margin: 0;
      padding: 0;
      font-family: $font-stack-headers-2;
      @include font-size(3);
      line-height: 3rem;
      font-weight: lighter;
      text-transform: uppercase;

      span {
        display: block;
        @include font-size(2);
        line-height: 2rem;
      }
    }

    .big-feature-subtitle {
      @include font-size(1.2);
      font-weight: lighter;
      text-align: center;

      em {
        font-style: normal;
        font-family: $font-stack-cursive;
        color: $hawk-gold;
      }
    }
    
    .libcal-todays-hours {
      
      ul {
        margin: 8px 0 0 0;
        padding: 0;
        list-style-type: none;

        li {
          margin: 0;
          padding: 0;
          @include font-size(1.2);
          font-weight: lighter;
          opacity: 0.666;
          transition: 0.2s;

          &:hover {
            opacity: 1;
          }
        }

        a {
          color: white;
          text-decoration: none !important;
        }
      }
    }
  }
  
  .big-feature-image-description {
    position: absolute;
    margin: 0;
    padding: 4px 8px;
    bottom: 0;
    right: 0;
    background-color: rgba(black, 0.8);
    color: rgba(white, 0.66);
    @include font-size(1);
    opacity: 0;
  }
}

// -- BEGIN special classes -----------------------
.big-feature-item {

  &.librarian {

    .big-feature-title-area {
      top: 36px;
      bottom: auto;
    }

    .big-feature-image-description {
      transition: 0.25s;
      transition-delay: 1s;
      visibility: visible;
    }

    &.virus-changes {

      .big-feature-title-area {
        top: 0;
        bottom: auto;
        background-color: rgba($hawk-gold, 0.85);

        .big-feature-title {
          color: black;
          @include font-size(3.6);
          font-family: $font-stack-body;
          font-weight: 700;
          text-transform: none;
          line-height: 3.6rem;
        }

        .big-feature-subtitle {
          padding-bottom: 0;

          em {
            @include font-size(1.6);
            color: black;
            font-family: $font-stack-body;
            font-weight: 500;
            font-style: italic;
          }
        }
      }

      &-bottom {

        .big-feature-title-area {
          top: auto;
          bottom: 2rem;
        }
      }
    }

    &:hover {

      .big-feature-image-description {
        opacity: 1;
      }
    }
  }

  &.title-top {

    .big-feature-title-area {
      top: 32px;
      bottom: auto;
    }
  }

  &.title-right {

    .big-feature-title-area {
      right: 0;
      left: auto;
    }
  }

  &.title-left {

    .big-feature-title-area {
      top: 48px;
      right: auto;
      left: auto;
    }
  }

  &.show-image-description {

    .big-feature-title-area {
      bottom: 36px;
    }

    .big-feature-image-description {
      visibility: visible;
    }
  }

  &.longer-title-cta {
    border-left: 0px solid #000 !important;

    .big-feature-title-area {
      background-color: $hawk-gold;
      opacity: 0.91;

      .big-feature-title {
        color: black;
        @include font-size(3.6);
        font-family: $font-stack-headers;
        text-transform: none;
        font-weight: 400;;
      }
      .big-feature-subtitle {
        color: black;
        // text-align: left;
      }
    }

    .big-feature-image-description {
      transition: 0.25s;
      transition-delay: 1s;
      visibility: visible;
    }
  }
}
// --- END special classes ------------------------

// --- BEGIN breakpoints ---------------------------------
@include breakpoint($smallish-width) {

  .big-feature-item {
    flex: 1.33;
    min-height: 300px;

    .big-feature-title-area {

      .big-feature-title {
        @include font-size(4);
        line-height: 4rem;
  
        span {
          @include font-size(3);
          line-height: 3rem;
        }
      }

      .big-feature-subtitle {
        @include font-size(1.6);
      }
    }
  }
}

@include breakpoint($fixed-width) {
  
  .big-feature-item {
    flex: 1.68;
    min-height: 360px;

    .big-feature-title-area {
      padding: 1rem;

      .big-feature-title {
        @include font-size(5);
        line-height: 5rem;

        span {
          @include font-size(4);
          line-height: 4rem;
        }
      }

      .big-feature-subtitle {
        @include font-size(2);
      }
    }
  }
}
// --- END breakpoints -----------------------------------