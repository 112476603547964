.simple-button {
	@include simple-button;

	&--big {
		@include font-size(2.2);
	}
	&--small {
		@include font-size(1.2);
	}

	&--pdf {  // this links to a PDF
		@include simple-button-with-icon('file-pdf');
	}
	&--virtual {  // view this remotely
		@include simple-button-with-icon('laptop-house');
	}
	&--media {  // go to this media interface
		@include simple-button-with-icon('play-circle');
	}
	&--signup {  // register for this workshop
		@include simple-button-with-icon('user-plus');
	}
	&--schedule {  // schedule an appointment
		@include simple-button-with-icon('custom_clock-calendar');
	}
}

.simple-button-with-icon {
	@include simple-button-with-icon("circle");  // DEFAULT

	&.simple-icon-register {
		@include bg-icon("user-plus");
	}
}