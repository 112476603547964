@charset "UTF-8";
/* ----- BEGIN old global.css ----- */
html,
body {
  margin: 0;
  padding: 0;
}

/* ----- Links ----- */
a:link {
  color: #00558c;
}

a:visited {
  color: #00558c;
}

a:hover {
  color: #003659;
  text-decoration: underline;
}

a:active {
  color: #00080d;
  text-decoration: underline;
}

a:focus {
  color: #00080d;
  text-decoration: underline;
}

h3 + p {
  margin-top: 0 !important;
}

html {
  font-size: 62.5%;
}

body, p, ul {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
}
body .small-type, body.small-type, p .small-type, p.small-type, ul .small-type, ul.small-type {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
}

.entry-content p {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  line-height: 1.4em;
}

.entry-content li {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
}

h1, h2, h3, h4 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

h1 {
  margin: 0;
  padding-bottom: 0.5em;
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 1.5;
}

.header-wrapper-inner h1 {
  position: absolute;
  left: -10000px;
  top: auto;
  height: 1px;
  overflow: hidden;
  width: 1px;
}

h2 {
  margin: 0;
  padding-bottom: 0.5em;
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.5;
}
h2.site-name {
  font-weight: lighter;
}
h2.entry-title {
  margin: 0.75em 0;
  padding: 0;
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 1.5;
  font-weight: 300;
}

h3 {
  margin: 0;
  padding-bottom: 0.5em;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
}

h4 {
  margin: 1.33em 0 0 0;
  padding-bottom: 0.25em;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
}

h5 {
  margin: 1.33em 0 0 0;
  padding-bottom: 0.25em;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
}
h5 + p {
  margin-top: 0.25em;
}

code {
  font-size: 100%;
}

img {
  border: none;
  height: auto;
  max-width: 100%;
}
img.aligncenter {
  display: block;
  margin: 0 auto !important;
}
img.alignright {
  float: right;
  margin: 0 0 1em 1.5em;
}
img.alignleft {
  float: left;
  margin: 0 1.5em 1em 0;
}
img.floatleft {
  float: left;
}
img.floatright {
  float: right;
}
img.textcenter {
  text-align: center;
}
img.textright {
  text-align: right;
}
img.textleft {
  text-align: left;
}
img.top-banner {
  width: 100%;
}
img.border--dark {
  border: 1px solid #333;
}
img.border--light {
  border: 1px solid #bbb;
}

.wp-caption.aligncenter {
  display: block;
  margin: 0 auto !important;
}
.wp-caption.alignright {
  float: right;
  margin: 0 0 1em 1.5em;
}
.wp-caption.alignleft {
  float: left;
  margin: 0 1.5em 1em 0;
}
.wp-caption img {
  margin: 0;
  padding: 0;
}
.wp-caption .wp-caption-text {
  margin-top: 0.25em;
  text-align: left;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
}

.entry-content ul, .entry-content ol {
  margin: 0.5em 1em 1em 0.5em;
}
.entry-content ul ul, .entry-content ul ol, .entry-content ol ul, .entry-content ol ol {
  margin-top: 0;
  margin-bottom: 1rem;
}
.entry-content ul li, .entry-content ol li {
  line-height: 1.5;
}
.entry-content ul li ~ p {
  margin: 0;
}
.entry-content ul li > a {
  line-height: 2.4rem;
}
.entry-content ul.space-between > li {
  margin-bottom: 1em;
}
.entry-content ol.space-between > li {
  margin-bottom: 1em;
}
.entry-content dl dt {
  font-weight: bold;
}
.entry-content dl dd {
  margin: 0 0 1rem 1rem;
}

.simple-list-with-key .simple-list-key {
  margin: 0;
  padding: 1em;
  list-style-type: none;
  background-color: #ddd;
}
.simple-list-with-key .simple-list-key li {
  font-weight: normal;
  font-style: italic;
}
.simple-list-with-key .simple-list-key li:before {
  content: " = ";
}
.simple-list-with-key .simple-list-items dt .item-message-outer, .simple-list-with-key .simple-list-items li .item-message-outer {
  margin-left: 1rem;
}
.simple-list-with-key .simple-list-items dt .item-message-outer .item-message, .simple-list-with-key .simple-list-items li .item-message-outer .item-message {
  position: absolute;
  left: -10000px;
  top: auto;
  height: 1px;
  overflow: hidden;
  width: 1px;
  font-weight: lighter;
  font-size: 85%;
}
.simple-list-with-key .simple-list-key li.restricted-resource,
.simple-list-with-key .simple-list-items .item-message-outer.restricted-resource {
  text-align: left;
  padding-left: calc(1em * 1.333 + 0em);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  line-height: 1.5em;
  color: black;
  background-image: url(/includes/icons/svg/black/lock.svg);
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: left 0em top 0.35em;
}
.simple-list-with-key .simple-list-key li.recommended-resource,
.simple-list-with-key .simple-list-items .item-message-outer.recommended-resource {
  text-align: left;
  padding-left: calc(1em * 1.333 + 0em);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  line-height: 1.5em;
  color: black;
  background-image: url(/includes/icons/svg/black/star.svg);
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: left 0em top 0.35em;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

body #content .entry-content {
  padding: 0 8px;
}
@media (min-width: 1150px) {
  body #content .entry-content {
    padding: 0;
  }
}

#main {
  margin: 0 auto;
  min-height: 400px;
  width: 100%;
}

table.simple-table {
  margin-bottom: 3em;
  width: 100%;
}
table.simple-table > tbody > tr:nth-of-type(odd) {
  background-color: #e8e8e9;
}
table.simple-table > tbody > tr:nth-of-type(even) {
  background-color: #f2f3f3;
}
table.simple-table > tbody > tr > td {
  padding: 1em;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
}
table.simple-table > tbody > tr > th {
  padding: 1em;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  background-color: #333;
  color: white;
  font-weight: lighter;
}
table.simple-table > thead th {
  padding: 1em;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  background-color: #333;
  color: white;
  font-weight: bolder;
}
table.zebra-stripe tr:nth-of-type(odd) {
  background-color: #e8e8e9;
}
table.zebra-stripe tr:nth-of-type(even) {
  background-color: #f2f3f3;
}
table.zebra-stripe tr td {
  padding: 1rem;
}

.hide-offscreen {
  position: absolute;
  left: -10000px;
  top: auto;
  height: 1px;
  overflow: hidden;
  width: 1px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.inline-icon svg {
  height: 18px;
  width: 18px;
}

.show-for-logged-in-only {
  display: none;
}
body.logged-in .show-for-logged-in-only {
  display: block;
}

body.libchat-ONLINE .chat-online {
  display: block;
  padding: 2rem 0 3rem 0;
}
body.libchat-ONLINE .chat-offline {
  display: none;
}

body.libchat-OFFLINE .chat-online {
  display: none;
}
body.libchat-OFFLINE .chat-offline {
  display: block;
  padding: 1rem 0 3rem 0;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
  font-weight: bold;
}

body.user-clinkenb .all-homes-alert {
  display: block !important;
}

.widgetcontainer {
  padding: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  border: 1px solid #ccc;
}
.widgetcontainer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widgetcontainer ul li {
  margin: 0;
}
.widgetcontainer ul li a {
  display: inline-block;
  margin: 0.25em 0 0.25em 1em;
  text-indent: -1em;
  line-height: 2.4rem;
}
.widgetcontainer .excerpt {
  font-size: 11px;
  margin: 5px 0;
}
.widgetcontainer input[type=text],
.widgetcontainer input[type=password] {
  width: 80%;
}
.widgetcontainer .widgettitle {
  color: #333;
  margin: -10px -10px 10px;
  padding: 5px 10px;
  background-color: #ccc;
  border-bottom: 1px solid #ccc;
}
.widgetcontainer .widgettitle a {
  color: #333;
}

.uilibraries-branding .ui-branding,
.uilibraries-branding .uilibraries-wordmark {
  transition: 0.25s;
  stroke: transparent;
  stroke-width: 12;
}
.uilibraries-branding .ui-branding {
  fill: #ffcd00;
}
.uilibraries-branding .ui-branding:hover {
  fill: #ffdc4d;
  cursor: pointer;
}
.uilibraries-branding .uilibraries-wordmark {
  fill: #ccc;
}
.uilibraries-branding .uilibraries-wordmark:hover {
  fill: white;
  cursor: pointer;
}

.uilib-branding {
  fill: #ccc;
}
.uilib-branding.uilib-iowa .block-letter {
  fill: #ffcd00;
  transition: 0.1s;
}
.uilib-branding.uilib-iowa .block-letter.block-letter-i {
  transition-delay: 0s;
}
.uilib-branding.uilib-iowa .block-letter.block-letter-o {
  transition-delay: 0.03s;
}
.uilib-branding.uilib-iowa .block-letter.block-letter-w {
  transition-delay: 0.06s;
}
.uilib-branding.uilib-iowa .block-letter.block-letter-a {
  transition-delay: 0.09s;
}
.uilib-branding.uilib-iowa:hover .block-letter.block-letter-i {
  fill: #ffe680;
}
.uilib-branding.uilib-iowa:hover .block-letter.block-letter-o {
  fill: #ffe680;
}
.uilib-branding.uilib-iowa:hover .block-letter.block-letter-w {
  fill: #ffe680;
}
.uilib-branding.uilib-iowa:hover .block-letter.block-letter-a {
  fill: #ffe680;
}
.uilib-branding.uilib-libraries {
  transition: 0.2s;
}
.uilib-branding.uilib-libraries:hover {
  fill: #eee;
}

.header-wrapper .uilib-branding {
  margin-bottom: 10px;
}
.header-wrapper .uilib-branding svg {
  max-width: 480px !important;
}

#global-footer .uilib-branding {
  margin-bottom: 10px;
  max-width: 480px;
}

.liblock {
  display: block;
  clear: both;
  margin-bottom: 2rem;
}
.liblock:after {
  content: "";
  display: table;
  clear: both;
}

.liblock__hours {
  margin: 0 auto 0.5rem auto;
  max-width: 1150px;
}
.liblock__hours--title {
  margin-top: 2rem;
  padding: 0;
}
.liblock__hours--contact {
  margin-bottom: 4px;
  padding: 0;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  color: #000;
}
.liblock__hours--contact .contact-options {
  display: inline-block;
  margin: 0;
  padding: 8px;
  list-style-type: none;
}
.liblock__hours--contact .contact-options li {
  display: inline-block;
}
.liblock__hours--contact .contact-options li a {
  display: inline;
}
.liblock__hours--contact .contact-options li:nth-last-child(2):after {
  content: "";
}
.liblock__hours--contact .contact-options li.virtual-hours {
  display: block;
}
.liblock__hours--contact .contact-options li.virtual-hours:after {
  content: "";
}
.liblock__hours--contact .contact-options li.virtual-hours .temp-alert {
  font-weight: bold;
  font-style: normal;
}
.liblock__hours--contact .contact-options li.option-more .options-below {
  display: none;
}
.liblock__hours--contact .contact-options.post-virus-normal li {
  display: block;
}
.liblock__hours--contact .contact-options.post-virus-normal li:nth-child(2):before {
  content: none;
}
.liblock__hours--contact .contact-options .contact-message {
  padding: 8px;
}
.liblock__hours--list {
  margin: 0 !important;
  padding: 0 !important;
  list-style-type: none;
}
.liblock__hours--list-item {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: baseline;
  padding: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
}
.liblock__hours--list-item:nth-of-type(odd) {
  background-color: #e8e8e9;
}
.liblock__hours--list-item:nth-of-type(even) {
  background-color: #f2f3f3;
}
.liblock__hours--list-item:first-of-type:before {
  display: none;
}
.liblock__hours--list-item:has(> button) {
  padding: 0;
}
.liblock__hours--list-item > span {
  padding: 2px;
}
.liblock__hours--list-item .date {
  min-width: 7em;
  font-weight: bold;
  color: black;
}
.liblock__hours--list-item .status {
  display: block;
  padding: 0 2rem 0 0;
  color: black;
}
.liblock__hours--list-item .note {
  display: block;
  padding: 2px 0;
  padding-right: 2rem;
}
.liblock__hours--list-item.status-closed {
  color: #333;
}
.liblock__hours--list-item.status-closed .liblock__hours--list-toggle {
  background-color: #bbb;
}
.liblock__hours--list-item.status-closed .liblock__hours--list-toggle:hover {
  background-color: #ccc;
}
.liblock__hours--list-item.status-closed .date, .liblock__hours--list-item.status-closed .status, .liblock__hours--list-item.status-closed .added-note, .liblock__hours--list-item.status-closed .note {
  color: #333;
}
.liblock__hours--list-item.tense-past {
  color: #666;
}
.liblock__hours--list-item.tense-today {
  padding: 0;
}
.liblock__hours--list-item.spring-break:nth-child(odd) {
  background-color: rgba(189, 71, 42, 0.3);
}
.liblock__hours--list-item.spring-break:nth-child(even) {
  background-color: rgba(189, 71, 42, 0.2);
}
.liblock__hours--list-item.final-exams:nth-child(odd) {
  background-color: rgba(255, 205, 0, 0.3);
}
.liblock__hours--list-item.final-exams:nth-child(even) {
  background-color: rgba(255, 205, 0, 0.2);
}
.liblock__hours--list-item .added-note {
  display: block;
  font-style: italic;
}
.liblock__hours--list-item .added-note a {
  text-decoration: none;
}
.liblock__hours--list-item .note {
  font-style: italic;
  color: #444;
}
.liblock__hours--list-toggle {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: baseline;
  margin: 0;
  padding: 1rem 2em 1rem 1rem;
  width: 100%;
  text-align: left;
  background-color: #ffcd00;
  border: 0;
  font-size: 1.6rem;
  cursor: pointer;
  transition: 0.2s;
  background-image: url(/includes/icons/svg/black/angle-down.svg);
  background-repeat: no-repeat;
  background-position: right 18px center;
  background-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
}
.liblock__hours--list-toggle:hover {
  background-color: #ffdc4d;
}
.liblock__hours--list-toggle:not(.collapsed) {
  background-image: url(/includes/icons/svg/black/angle-up.svg);
  background-repeat: no-repeat;
}
.liblock__hours--more-link {
  display: block;
  padding: 0;
  text-align: right;
  background-color: transparent !important;
  text-align: right;
  font-size: 85%;
}
.liblock__hours--more-link a {
  display: block;
  padding: 8px 12px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  background-color: #ffcd00;
  color: black;
  transition: 0.2s;
  text-decoration: none;
}
.liblock__hours--more-link a:hover {
  background-color: #ffdc4d;
}
.liblock__hours--footer p {
  margin: 0;
  padding: 4px 8px;
  font-size: 85%;
}
.liblock__hours.hide-title .liblock__hours--title {
  display: none;
}
.liblock__hours.contact-info-only .liblock__hours--list {
  display: none;
}
.liblock__hours.hide-contact-info .liblock__hours--contact {
  display: none;
}
.liblock__hours.hide-more-link .liblock__hours--more-link {
  position: absolute;
  left: -10000px;
  top: auto;
  height: 1px;
  overflow: hidden;
  width: 1px;
}
.liblock__hours.show-all .liblock__hours--more-link {
  display: none !important;
}
.liblock__hours.hide-footnote .liblock__hours--footer {
  position: absolute;
  left: -10000px;
  top: auto;
  height: 1px;
  overflow: hidden;
  width: 1px;
}
.liblock__hours .notabene {
  color: #900;
  font-weight: bold;
}
@media (min-width: 855px) {
  .liblock__hours--contact p strong {
    display: inline-block;
  }
  .liblock__hours--contact p strong:after {
    content: " – ";
  }
  .liblock__hours--contact p .virtual-hours {
    display: inline-block;
  }
  .liblock__hours--contact p .virtual-hours:after {
    content: " – " !important;
  }
  .liblock__hours--contact p .contact-options {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .liblock__hours--contact p .contact-options li {
    display: inline-block;
  }
  .liblock__hours--contact p .contact-options li:after {
    content: ", ";
  }
  .liblock__hours--contact p .contact-options li:last-of-type:after {
    content: ".";
  }
  .liblock__hours--contact p .contact-options li.virtual-hours:after {
    content: " – " !important;
  }
  .liblock__hours--contact p .contact-options li.option-more .options-below {
    display: none;
  }
  .liblock__hours a {
    display: inline-block;
  }
}

.liblock__location {
  background-color: #e8e8e9;
}
.liblock__location p {
  margin: 0;
  padding: 1rem;
}
.liblock__location span.label {
  display: block;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
  color: #666;
  line-height: 1.2rem;
}
.liblock__location--title {
  clear: both;
  display: block;
}
.liblock__location--mailing-address span {
  display: block;
}
.liblock__location--email-link span.label {
  position: absolute;
  left: -10000px;
  top: auto;
  height: 1px;
  overflow: hidden;
  width: 1px;
}
.liblock__location--phone span.label {
  position: absolute;
  left: -10000px;
  top: auto;
  height: 1px;
  overflow: hidden;
  width: 1px;
}
.liblock__location--hours span.label {
  position: absolute;
  left: -10000px;
  top: auto;
  height: 1px;
  overflow: hidden;
  width: 1px;
}
.liblock__location--home-link, .liblock__location--contact-link, .liblock__location--hours-link, .liblock__location--people-link {
  display: none;
}
.liblock__location.hide-title .liblock__location--title {
  position: absolute;
  left: -10000px;
  top: auto;
  height: 1px;
  overflow: hidden;
  width: 1px;
}
@media (min-width: 1000px) {
  .liblock__location--image {
    margin-right: 1rem;
  }
}

.liblock__gform {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #eee;
}
.liblock__gform .gform_wrapper .gform_body .gform_fields .gfield {
  margin-top: 1em;
}
.liblock__gform .gform_wrapper .gform_body .gform_fields .gfield_label {
  font-size: 85%;
  font-weight: lighter;
}
.liblock__gform .gform_wrapper .gform_footer input[type=submit] {
  display: inline-block;
  padding: 0.7em 2em 0.55em;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none !important;
  font-family: inherit;
  transition: 0.1s;
  background-color: #ffcd00;
  color: black !important;
  border: none;
  line-height: 1.5em;
}
.liblock__gform .gform_wrapper .gform_footer input[type=submit]:hover {
  text-decoration: none;
  background-color: #ffd733;
  color: black;
}
.liblock__gform .gform_wrapper .gform_footer input[type=submit]:active {
  position: relative;
  top: 1px;
  background-color: #ffcd00;
  color: black;
}
.liblock__gform .gform_wrapper .gform_footer input[type=submit]:focus {
  outline: 2px solid #00558c;
}
.liblock__gform .gform_wrapper .gform_footer input[type=submit] i {
  margin-right: 0.333em;
}

.liblock__gallery {
  display: flex;
  flex-flow: row wrap;
}
.liblock__gallery > div {
  width: 100% !important;
  padding: 1rem;
}
.liblock__gallery > div img {
  width: 100% !important;
}
@media (min-width: 1150px) {
  .liblock__gallery > div {
    width: 19.5% !important;
  }
}

.table-of-contents {
  float: right;
  margin: 0 0 8px 12px;
  padding: 4px 8px;
  background-color: #ddd;
  width: 30%;
  font-size: small;
  border-radius: 4px;
}

.table-of-contents ul {
  padding: 0;
}

.table-of-contents li {
  line-height: 1.25em;
}

.table-of-contents a {
  text-decoration: none !important;
}

.table-of-contents a:hover {
  color: #666;
}

.table-of-contents > h2 {
  border-bottom: none;
}

.simple-button {
  display: inline-block;
  padding: 0.7em 2em 0.55em;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none !important;
  font-family: inherit;
  transition: 0.1s;
  background-color: #ffcd00;
  color: black !important;
  border: none;
  line-height: 1.5em;
}
.simple-button:hover {
  text-decoration: none;
  background-color: #ffd733;
  color: black;
}
.simple-button:active {
  position: relative;
  top: 1px;
  background-color: #ffcd00;
  color: black;
}
.simple-button:focus {
  outline: 2px solid #00558c;
}
.simple-button i {
  margin-right: 0.333em;
}
.simple-button--big {
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.5;
}
.simple-button--small {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
}
.simple-button--pdf {
  display: inline-block;
  padding: 0.7em 2em 0.55em;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none !important;
  font-family: inherit;
  transition: 0.1s;
  background-color: #ffcd00;
  color: black !important;
  border: none;
  line-height: 1.5em;
  text-align: left;
  padding-left: 2.5em;
  background-image: url(/includes/icons/svg/black/file-pdf.svg);
  background-repeat: no-repeat;
  background-size: 1.25em;
  background-position: 0.75em center;
  padding-right: 1.25em;
}
.simple-button--pdf:hover {
  text-decoration: none;
  background-color: #ffd733;
  color: black;
}
.simple-button--pdf:active {
  position: relative;
  top: 1px;
  background-color: #ffcd00;
  color: black;
}
.simple-button--pdf:focus {
  outline: 2px solid #00558c;
}
.simple-button--pdf i {
  margin-right: 0.333em;
}
.simple-button--virtual {
  display: inline-block;
  padding: 0.7em 2em 0.55em;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none !important;
  font-family: inherit;
  transition: 0.1s;
  background-color: #ffcd00;
  color: black !important;
  border: none;
  line-height: 1.5em;
  text-align: left;
  padding-left: 2.5em;
  background-image: url(/includes/icons/svg/black/laptop-house.svg);
  background-repeat: no-repeat;
  background-size: 1.25em;
  background-position: 0.75em center;
  padding-right: 1.25em;
}
.simple-button--virtual:hover {
  text-decoration: none;
  background-color: #ffd733;
  color: black;
}
.simple-button--virtual:active {
  position: relative;
  top: 1px;
  background-color: #ffcd00;
  color: black;
}
.simple-button--virtual:focus {
  outline: 2px solid #00558c;
}
.simple-button--virtual i {
  margin-right: 0.333em;
}
.simple-button--media {
  display: inline-block;
  padding: 0.7em 2em 0.55em;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none !important;
  font-family: inherit;
  transition: 0.1s;
  background-color: #ffcd00;
  color: black !important;
  border: none;
  line-height: 1.5em;
  text-align: left;
  padding-left: 2.5em;
  background-image: url(/includes/icons/svg/black/play-circle.svg);
  background-repeat: no-repeat;
  background-size: 1.25em;
  background-position: 0.75em center;
  padding-right: 1.25em;
}
.simple-button--media:hover {
  text-decoration: none;
  background-color: #ffd733;
  color: black;
}
.simple-button--media:active {
  position: relative;
  top: 1px;
  background-color: #ffcd00;
  color: black;
}
.simple-button--media:focus {
  outline: 2px solid #00558c;
}
.simple-button--media i {
  margin-right: 0.333em;
}
.simple-button--signup {
  display: inline-block;
  padding: 0.7em 2em 0.55em;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none !important;
  font-family: inherit;
  transition: 0.1s;
  background-color: #ffcd00;
  color: black !important;
  border: none;
  line-height: 1.5em;
  text-align: left;
  padding-left: 2.5em;
  background-image: url(/includes/icons/svg/black/user-plus.svg);
  background-repeat: no-repeat;
  background-size: 1.25em;
  background-position: 0.75em center;
  padding-right: 1.25em;
}
.simple-button--signup:hover {
  text-decoration: none;
  background-color: #ffd733;
  color: black;
}
.simple-button--signup:active {
  position: relative;
  top: 1px;
  background-color: #ffcd00;
  color: black;
}
.simple-button--signup:focus {
  outline: 2px solid #00558c;
}
.simple-button--signup i {
  margin-right: 0.333em;
}
.simple-button--schedule {
  display: inline-block;
  padding: 0.7em 2em 0.55em;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none !important;
  font-family: inherit;
  transition: 0.1s;
  background-color: #ffcd00;
  color: black !important;
  border: none;
  line-height: 1.5em;
  text-align: left;
  padding-left: 2.5em;
  background-image: url(/includes/icons/custom/svg/black/clock-calendar.svg);
  background-repeat: no-repeat;
  background-size: 1.25em;
  background-position: 0.75em center;
  padding-right: 1.25em;
}
.simple-button--schedule:hover {
  text-decoration: none;
  background-color: #ffd733;
  color: black;
}
.simple-button--schedule:active {
  position: relative;
  top: 1px;
  background-color: #ffcd00;
  color: black;
}
.simple-button--schedule:focus {
  outline: 2px solid #00558c;
}
.simple-button--schedule i {
  margin-right: 0.333em;
}

.simple-button-with-icon {
  display: inline-block;
  padding: 0.7em 2em 0.55em;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none !important;
  font-family: inherit;
  transition: 0.1s;
  background-color: #ffcd00;
  color: black !important;
  border: none;
  line-height: 1.5em;
  text-align: left;
  padding-left: 2.5em;
  background-image: url(/includes/icons/svg/black/circle.svg);
  background-repeat: no-repeat;
  background-size: 1.25em;
  background-position: 0.75em center;
  padding-right: 1.25em;
}
.simple-button-with-icon:hover {
  text-decoration: none;
  background-color: #ffd733;
  color: black;
}
.simple-button-with-icon:active {
  position: relative;
  top: 1px;
  background-color: #ffcd00;
  color: black;
}
.simple-button-with-icon:focus {
  outline: 2px solid #00558c;
}
.simple-button-with-icon i {
  margin-right: 0.333em;
}
.simple-button-with-icon.simple-icon-register {
  background-image: url(/includes/icons/svg/black/user-plus.svg);
  background-repeat: no-repeat;
}

.simple-box {
  margin: 0 0 18px 0;
  padding: 8px;
  border: 1px solid #ccc;
}
.simple-box .box-title {
  display: block;
  margin: -8px -8px 8px -8px;
  padding: 4px 8px;
  background-color: #ccc;
  color: black;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
}
.simple-box .box-title a:link {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
}
.simple-box .box-title em {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
}
.simple-box ul {
  padding-bottom: 0.5em;
}

aside.callout {
  margin-bottom: 3rem;
  padding: 1.5rem 2rem;
  background-image: url("/includes/icons/svg/white/info-circle.svg"), linear-gradient(rgba(0, 85, 140, 0.35), rgba(0, 85, 140, 0.2));
  background-size: 100px, auto;
  background-repeat: no-repeat, repeat;
  background-position: top -15px right -15px, top left;
}
aside.callout .callout-inner {
  margin: 0 auto;
  max-width: 1150px;
}
aside.callout h2 {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
}
aside.callout p {
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}
aside.callout ul {
  margin: 0.5rem 0;
}
aside.callout:before {
  float: right;
  display: block;
  margin: 0 1rem 0 1rem;
  content: "";
  width: 85px;
  height: 85px;
}
aside.callout.callout-alert {
  background-color: rgba(189, 71, 42, 0.25);
  background-image: none;
  border-bottom: 2px solid #bd472a;
}
aside.callout.callout-alert:before {
  display: none;
}
aside.callout.callout-alert h2 {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
}
aside.callout.callout-alert h2 i {
  color: #bd472a;
}
aside.callout.callout-alert-info {
  background-color: rgba(0, 85, 140, 0.15);
  background-image: none;
  border-bottom: 2px solid #00558c;
}
aside.callout.callout-alert-info h2 {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
}
aside.callout.callout-alert-info h2 i {
  color: #00558c;
}
aside.callout.callout-alert-info i {
  color: #00558c;
}
aside.callout.callout-alert-red {
  background: url("/includes/icons/svg/white/exclamation-circle.svg"), linear-gradient(rgba(189, 71, 42, 0.45), rgba(189, 71, 42, 0.33));
}
aside.callout.callout-alert-hawkalert {
  background-color: rgba(189, 71, 42, 0.25);
  background-image: none;
  border-bottom: 2px solid #bd472a;
}
aside.callout.callout-alert-hawkalert:before {
  display: none;
}
aside.callout.callout-alert-hawkalert h2 {
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.5;
}
aside.callout.callout-alert-hawkalert h2 i {
  color: #bd472a;
}
aside.callout.callout-alert-hawkalert p.hawkalert-timestamp {
  margin-top: 0;
}
aside.callout.callout-alert-virus {
  background-color: rgba(0, 85, 140, 0.15);
  background-image: none;
  background-size: 80px;
  background-repeat: no-repeat;
  background-position: top 10px right 10px;
  border-bottom: 2px solid #00558c;
}
aside.callout.callout-alert-virus:before {
  display: none;
}
aside.callout.callout-alert-virus h2 {
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 1.5;
}
aside.callout.callout-alert-virus h2 i {
  color: #00558c;
}

.all-pages-alert aside.callout h2,
.all-homes-alert aside.callout h2 {
  padding-bottom: 0;
}

.svg-fallback {
  display: none;
}

.no-inlinesvg .svg-fallback {
  display: block;
}

.svg-fallback-test {
  display: inline-block;
  border: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #global-footer.footer-wrapper .uilibraries-branding svg {
    width: 40%;
  }
  body.theme-ranganathan .header-wrapper .uilibraries-branding svg {
    max-height: 48px;
  }
}
#top-menu ul#menu-top {
  display: inline-block;
  float: right;
}
#top-menu ul#menu-top li a {
  display: block;
  padding: 2px 8px;
}

body.theme-ranganathan #nav {
  display: none;
  padding-top: 12px;
  background: none;
  background-color: #111;
}
body.theme-ranganathan #nav #nav-content.new-format {
  display: block;
}
@media (min-width: 855px) {
  body.theme-ranganathan #nav {
    position: relative;
    display: block;
    z-index: 101;
  }
  body.theme-ranganathan #nav #nav-content.new-format {
    width: 855px;
  }
  body.theme-ranganathan #nav a {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.5;
  }
}
@media (min-width: 1150px) {
  body.theme-ranganathan #nav #nav-content.new-format {
    margin: 0 auto;
    width: 1150px;
  }
  body.theme-ranganathan #nav #nav-content.new-format ul#menu-main {
    display: flex;
    width: 100%;
  }
  body.theme-ranganathan #nav #nav-content.new-format ul#menu-main li a span {
    font-size: 80%;
  }
  body.theme-ranganathan #nav #nav-content.new-format ul#menu-main > li {
    flex: 1;
  }
  body.theme-ranganathan #nav #nav-content.new-format ul#menu-main > li > a {
    text-transform: uppercase;
    text-align: center;
  }
  body.theme-ranganathan #nav #nav-content.new-format ul#menu-main > li.sfHover:last-of-type > ul.sub-menu {
    left: auto;
    right: 0;
  }
  body.theme-ranganathan #nav #nav-content {
    margin: 0 auto;
    width: 1150px;
  }
}

body.theme-ranganathan #mobnav {
  display: block;
}
@media (min-width: 855px) {
  body.theme-ranganathan #mobnav {
    display: none;
  }
}

body.theme-ranganathan #main {
  background-color: white;
}
body.theme-ranganathan #menu-mobile {
  display: none;
}

body.theme-ranganathan .header-wrapper .header-wrapper-inner .site-name a {
  color: #ccc;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
  text-decoration: none;
  transition: 0.2s;
}
body.theme-ranganathan .header-wrapper .header-wrapper-inner .site-name a:hover {
  color: white;
}
@media (min-width: 480px) {
  body.theme-ranganathan .header-wrapper .header-wrapper-inner .site-name a {
    font-size: 30px;
    font-size: 3rem;
    line-height: 1.5;
  }
}
@media (min-width: 1150px) {
  body.theme-ranganathan .header-wrapper .header-wrapper-inner .site-name a {
    font-size: 42px;
    font-size: 4.2rem;
    line-height: 1.5;
  }
}
body.theme-ranganathan.site-studio .header-wrapper .header-wrapper-inner .site-name a, body.theme-ranganathan.site-hardin .header-wrapper .header-wrapper-inner .site-name a, body.theme-ranganathan.site-eng .header-wrapper .header-wrapper-inner .site-name a, body.theme-ranganathan.site-biz .header-wrapper .header-wrapper-inner .site-name a {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
}
@media (min-width: 480px) {
  body.theme-ranganathan.site-studio .header-wrapper .header-wrapper-inner .site-name a, body.theme-ranganathan.site-hardin .header-wrapper .header-wrapper-inner .site-name a, body.theme-ranganathan.site-eng .header-wrapper .header-wrapper-inner .site-name a, body.theme-ranganathan.site-biz .header-wrapper .header-wrapper-inner .site-name a {
    font-size: 22px;
    font-size: 2.2rem;
    line-height: 1.5;
  }
}
@media (min-width: 855px) {
  body.theme-ranganathan.site-studio .header-wrapper .header-wrapper-inner .site-name a, body.theme-ranganathan.site-hardin .header-wrapper .header-wrapper-inner .site-name a, body.theme-ranganathan.site-eng .header-wrapper .header-wrapper-inner .site-name a, body.theme-ranganathan.site-biz .header-wrapper .header-wrapper-inner .site-name a {
    font-size: 28px;
    font-size: 2.8rem;
    line-height: 1.5;
  }
}
@media (min-width: 1150px) {
  body.theme-ranganathan.site-studio .header-wrapper .header-wrapper-inner .site-name a, body.theme-ranganathan.site-hardin .header-wrapper .header-wrapper-inner .site-name a, body.theme-ranganathan.site-eng .header-wrapper .header-wrapper-inner .site-name a, body.theme-ranganathan.site-biz .header-wrapper .header-wrapper-inner .site-name a {
    font-size: 32px;
    font-size: 3.2rem;
    line-height: 1.5;
  }
}

a[href*="sharepoint.uiowa.edu"]:after {
  content: " (Authentication required) ";
}

a[href$=".pdf"].iconify {
  text-align: left;
  padding-left: calc(1em * 1.333 + 0em);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  line-height: 1.5em;
  color: black;
  background-image: url(/includes/icons/svg/black/file-pdf.svg);
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: left 0em top 0.35em;
}

a[href$=".mp3"].iconify {
  text-align: left;
  padding-left: calc(1em * 1.333 + 0em);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  line-height: 1.5em;
  color: black;
  background-image: url(/includes/icons/svg/black/file-audio.svg);
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: left 0em top 0.35em;
}

ul.menu.subnav-sidebar,
ul.menu.subnav-sidebar ul.sub-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: #ccc;
}
ul.menu.subnav-sidebar li,
ul.menu.subnav-sidebar ul.sub-menu li {
  margin: 0;
}
ul.menu.subnav-sidebar li.current-menu-item,
ul.menu.subnav-sidebar ul.sub-menu li.current-menu-item {
  background-color: #aaa;
}
ul.menu.subnav-sidebar li.current-menu-item:before,
ul.menu.subnav-sidebar ul.sub-menu li.current-menu-item:before {
  position: absolute;
  display: block;
  content: "";
  width: 10px;
  height: 29px;
  background-position: -1px center;
  background-size: contain;
  background-image: url("/includes/icons/svg/black/caret-right.svg");
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar a,
ul.menu.subnav-sidebar ul.sub-menu a {
  display: block;
  padding: 6px 6px 6px 12px;
  text-decoration: none;
  line-height: 1.25em;
  color: black;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  transition: 0.2s;
}
ul.menu.subnav-sidebar a:hover,
ul.menu.subnav-sidebar ul.sub-menu a:hover {
  background-color: #ffdc4d;
  color: black;
}
ul.menu.subnav-sidebar button:hover,
ul.menu.subnav-sidebar ul.sub-menu button:hover {
  background-color: #ffdc4d;
  cursor: pointer;
}

ul.menu.subnav-sidebar {
  position: relative;
  margin-bottom: 2rem;
  padding: 4px;
  background-color: white;
}
ul.menu.subnav-sidebar > li:first-of-type {
  position: relative;
  background-color: #ccc;
}
ul.menu.subnav-sidebar > li:first-of-type > a {
  text-align: left;
  padding-left: calc(1em * 1.333 + 0em);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  line-height: 1.5em;
  color: black;
  background-image: url(/includes/icons/svg/black/home.svg);
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: left 0em top 0.35em;
  padding-left: 2em;
  background-position-x: 0.666em;
  background-position-y: 0.5em;
  border-bottom: 2px solid white;
}
ul.menu.subnav-sidebar > li:first-of-type > a:hover + button {
  background-color: #ffdc4d;
}
ul.menu.subnav-sidebar > li:first-of-type > button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  border: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px;
  background-color: transparent;
  height: 29px;
  width: 32px;
  transition: 0.2s;
}
ul.menu.subnav-sidebar > li:first-of-type > button.menu-expanded {
  background-image: url("/includes/icons/svg/black/caret-up.svg");
}
ul.menu.subnav-sidebar > li:first-of-type > button:not(.menu-expanded) {
  background-image: url("/includes/icons/svg/black/caret-down.svg");
}
ul.menu.subnav-sidebar > li:first-of-type > button:hover {
  background-color: #ffdc4d;
}
ul.menu.subnav-sidebar > li:first-of-type.current-menu-item {
  background-color: #aaa;
}
ul.menu.subnav-sidebar > li.menu-icon > a {
  text-align: left;
  padding-left: calc(1em * 1.333 + 0em);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  line-height: 1.5em;
  color: black;
  background-image: url(/includes/icons/svg/black/circle.svg);
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: left 0em top 0.35em;
  padding-left: 2em !important;
  background-position-x: 0.666em;
  background-position-y: 0.5em;
}
ul.menu.subnav-sidebar > li.resources > a {
  background-image: url(/includes/icons/svg/black/link.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.services > a {
  background-image: url(/includes/icons/svg/black/link.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.events > a {
  background-image: url(/includes/icons/svg/black/calendar-alt.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.news > a {
  background-image: url(/includes/icons/svg/black/rss.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.contact > a {
  background-image: url(/includes/icons/svg/black/user-friends.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.contact-info > a {
  background-image: url(/includes/icons/svg/black/info-circle.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.about > a {
  background-image: url(/includes/icons/svg/black/info-circle.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.past > a {
  background-image: url(/includes/icons/custom/svg/black/calendar-rewind.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.recent > a {
  background-image: url(/includes/icons/svg/black/step-backward.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.next > a {
  background-image: url(/includes/icons/svg/black/step-forward.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.future > a {
  background-image: url(/includes/icons/custom/svg/black/calendar-forward.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.featured > a {
  background-image: url(/includes/icons/svg/black/star.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.hourz > a {
  background-image: url(/includes/icons/svg/black/clock.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.ui-presidents > a {
  background-image: url(/includes/icons/svg/black/user-graduate.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.add > a {
  background-image: url(/includes/icons/svg/black/plus-square.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.write > a {
  background-image: url(/includes/icons/svg/black/pen-alt.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.timeline > a {
  background-image: url(/includes/icons/svg/black/history.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.move-to-parent > a {
  background-image: url(/includes/icons/svg/black/arrow-circle-left.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.user > a {
  background-image: url(/includes/icons/svg/black/user.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.sitemap > a {
  background-image: url(/includes/icons/svg/black/sitemap.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.award > a {
  background-image: url(/includes/icons/svg/black/award.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.parking > a {
  background-image: url(/includes/icons/svg/black/car.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.current > a {
  background-image: url(/includes/icons/svg/black/star.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.upcoming > a {
  background-image: url(/includes/icons/svg/black/angle-double-right.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.training > a {
  background-image: url(/includes/icons/svg/black/chalkboard-teacher.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.facebook > a {
  background-image: url(/includes/icons/svg/black/facebook.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.youtube > a {
  background-image: url(/includes/icons/svg/black/youtube.svg);
  background-repeat: no-repeat;
}
ul.menu.subnav-sidebar > li.spacer {
  margin-top: 0.5rem;
}

ul.menu.subnav-sidebar > li:not(:first-of-type) {
  position: relative;
  background-color: #ccc;
  border-bottom: 2px solid white;
}
ul.menu.subnav-sidebar > li:not(:first-of-type) > a {
  padding-left: 12px;
}
ul.menu.subnav-sidebar > li:not(:first-of-type) > a:hover + button {
  background-color: #ffdc4d;
}
ul.menu.subnav-sidebar > li:not(:first-of-type) > button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  border: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px;
  background-color: transparent;
  height: 29px;
  width: 32px;
  transition: 0.2s;
}
ul.menu.subnav-sidebar > li:not(:first-of-type) > button:hover {
  background-color: #ffdc4d;
}
ul.menu.subnav-sidebar > li:not(:first-of-type) > button.menu-expanded {
  background-image: url("/includes/icons/svg/black/caret-up.svg");
}
ul.menu.subnav-sidebar > li:not(:first-of-type) > button:not(.menu-expanded) {
  background-image: url("/includes/icons/svg/black/caret-down.svg");
}
ul.menu.subnav-sidebar > li:not(:first-of-type).current-page-ancestor, ul.menu.subnav-sidebar > li:not(:first-of-type).current-menu-parent {
  background-color: #aaa;
}
ul.menu.subnav-sidebar > li:not(:first-of-type).current-page-ancestor ul.sub-menu, ul.menu.subnav-sidebar > li:not(:first-of-type).current-menu-parent ul.sub-menu {
  background-color: #ccc;
}
ul.menu.subnav-sidebar > li:not(:first-of-type).current-menu-item {
  background-color: #aaa;
}
ul.menu.subnav-sidebar > li:not(:first-of-type).current-menu-item ul.sub-menu {
  background-color: #ccc;
}
ul.menu.subnav-sidebar > li:not(:first-of-type):last-of-type {
  border-bottom: 1rem solid white;
}

ul.menu.subnav-sidebar > li > ul.sub-menu > li {
  position: relative;
}
ul.menu.subnav-sidebar > li > ul.sub-menu > li > a {
  padding-left: 20px;
}

ul.menu.subnav-sidebar > li > ul.sub-menu ul.sub-menu > li {
  position: relative;
}
ul.menu.subnav-sidebar > li > ul.sub-menu ul.sub-menu > li > a {
  padding-left: 28px;
  font-size: 80%;
}

body ul.menu.subnav-sidebar li.dev-hidden,
body ul.menu.subnav-sidebar li.dev-hidden-webcomm {
  display: none;
}
body.logged-in ul.menu.subnav-sidebar li.dev-hidden {
  display: block;
}
body.logged-in ul.menu.subnav-sidebar li.dev-hidden-webcomm {
  display: block;
}

#subnav-menu {
  margin: 0 0 1rem 0;
}

#subnav-menu-toggle {
  margin: 0;
  padding: 1rem;
  width: 100%;
  border: none;
  color: black;
  background-color: #aaa;
  text-align: left;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
  border-bottom: 2px solid white;
  font-family: "Roboto", sans-serif;
}
#subnav-menu-toggle .toggle-icon {
  width: 1.25em;
}
#subnav-menu-toggle[aria-expanded=true] i.icon-menu {
  display: none;
}
#subnav-menu-toggle[aria-expanded=true] i.icon-close {
  display: inline-block;
  opacity: 0.5;
}
#subnav-menu-toggle[aria-expanded=true] + #subnav-menu-container {
  display: block;
}
#subnav-menu-toggle[aria-expanded=false] i.icon-menu {
  display: inline-block;
}
#subnav-menu-toggle[aria-expanded=false] i.icon-close {
  display: none;
}
#subnav-menu-toggle[aria-expanded=false] + #subnav-menu-container {
  display: none;
}

#subnav-menu-container.hidden-when-narrow {
  display: none;
}
#subnav-menu-container .menu.subnav {
  margin: 0;
  padding: 0;
}

@media (min-width: 855px) {
  #subnav-menu-toggle {
    display: none;
  }
  #subnav-menu-toggle[aria-expanded=false] + #subnav-menu-container {
    display: block;
  }
}
body.error404 .page-region__content-primary {
  grid-column: col-start 1/span 12 !important;
  grid-row: 2/5;
}
body.error404 #wrapper {
  padding-top: 0;
}
body.error404 #content .single-post {
  margin-top: 0;
}
body.error404 .entry-content {
  margin: 0 auto;
  max-width: 1150px;
}
body.error404 .error404-top {
  position: relative;
}
body.error404 .error404-top img {
  width: 100%;
}
body.error404 .error404-top .entry-title {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 3rem;
  font-size: 60px;
  font-size: 6rem;
  line-height: 1.5;
  text-shadow: 0 1px 1px #000;
  color: white;
}

h1.widgettitle, h2.widgettitle, h3.widgettitle, h4.widgettitle {
  font-family: "Roboto", sans-serif;
}

ul.links-widget {
  margin: 0;
  padding: 0;
}
ul.links-widget ul {
  margin: 0;
  padding: 0;
}
ul.links-widget li a {
  text-decoration: none;
}

dl.def-list-classes {
  margin-bottom: 2em;
}
dl.def-list-classes dt {
  margin-bottom: 0.5em;
  font-weight: bold;
}
dl.def-list-classes dd {
  margin-bottom: 1em;
}

.subjects-listing .subject-item {
  margin-bottom: 1rem;
}
.subjects-listing .subject-item h3 {
  margin-bottom: 0;
  padding: 6px;
  background-color: #333;
  color: white;
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.5;
  font-weight: 300;
}
.subjects-listing .subject-item .subject-primary-link {
  padding: 6px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  background-color: #ccc;
}
.subjects-listing .subject-item .subject-primary-link a i:before {
  color: #333;
}
.subjects-listing .subject-item .subject-expert {
  padding: 6px;
  background-color: #e1e1e1;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
}

#content .single-post {
  margin: 1em 0;
  padding-bottom: 1em;
}
#content .single-post .entry-info {
  color: #333;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
}
#content .single-post .entry-info a {
  color: #333;
}
#content .single-post .entry-info abbr[title] {
  text-decoration: none;
}
#content .single-post .entry-content img {
  padding: 0;
  border: 0;
  background-color: transparent;
}
#content .single-post .entry-content img.alignright {
  float: right;
  margin: 1em 0 1em 1.5em;
}
#content .single-post .entry-content img.alignleft {
  float: left;
  margin: 1em 1.5em 1em 0;
}
#content .single-post .entry-content ul {
  overflow: hidden;
}
#content .single-post .entry-content ul li {
  margin-left: 0.5em;
}

.search-widget {
  display: block;
  margin: 0;
  padding: 1rem;
}
.search-widget .search-widget-inner {
  margin: 0;
  min-height: 18rem;
}
.search-widget .search-widget-inner h2 {
  margin: 0;
  text-align: center;
  color: white;
  font-family: "Zilla Slab", serif;
  font-size: 40px;
  font-size: 4rem;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: lighter;
}
.search-widget .ihp-branding {
  margin-top: 0;
}
.search-widget .ihp-branding svg {
  display: inline-block;
  fill: #bbb;
  height: 20px;
  width: 140px;
  transition: all 0.2s ease;
}
.search-widget .ihp-branding svg .plus {
  opacity: 1;
  transition: all 0.2s ease;
}
.search-widget .ihp-branding svg:hover, .search-widget .ihp-branding svg:hover .plus {
  cursor: pointer;
  fill: #eee;
  opacity: 1 !important;
}
.search-widget.toggled-ON .ihp-branding svg .plus {
  opacity: 0.333;
}
.search-widget form#simple2,
.search-widget form.form-search-standard {
  margin: 0;
  padding: 0;
  height: 36px;
}
.search-widget form#simple2 .core,
.search-widget form.form-search-standard .core {
  height: 36px;
}
.search-widget form#simple2 .core input#ihplus_search,
.search-widget form#simple2 .core input.input-primary,
.search-widget form.form-search-standard .core input#ihplus_search,
.search-widget form.form-search-standard .core input.input-primary {
  float: left;
  margin: 0;
  padding: 0 0.5em;
  width: 85%;
  height: 36px;
  background-color: white;
  border: 2px solid #ffcd00;
  border-right: 0;
  border-radius: 0;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
}
.search-widget form#simple2 .core input#ihplus_search::placeholder,
.search-widget form#simple2 .core input.input-primary::placeholder,
.search-widget form.form-search-standard .core input#ihplus_search::placeholder,
.search-widget form.form-search-standard .core input.input-primary::placeholder {
  color: #999;
  font-weight: lighter;
}
.search-widget form#simple2 .core input#submit,
.search-widget form#simple2 .core input.submit-primary,
.search-widget form.form-search-standard .core input#submit,
.search-widget form.form-search-standard .core input.submit-primary {
  float: left;
  margin: 0;
  padding: 0;
  height: 36px;
  width: 15%;
  background-color: #ffcd00;
  background-image: url(/includes/icons/svg/black/search.svg);
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  border: 0;
  transition: 0.25s;
  border-radius: 0;
  z-index: 50;
  color: transparent;
  font-size: 1px;
}
.search-widget form#simple2 .core input#submit:hover, .search-widget form#simple2 .core input#submit:focus,
.search-widget form#simple2 .core input.submit-primary:hover,
.search-widget form#simple2 .core input.submit-primary:focus,
.search-widget form.form-search-standard .core input#submit:hover,
.search-widget form.form-search-standard .core input#submit:focus,
.search-widget form.form-search-standard .core input.submit-primary:hover,
.search-widget form.form-search-standard .core input.submit-primary:focus {
  background-color: #ffdc4d;
  cursor: pointer;
}
.search-widget form#simple2 .search-options,
.search-widget form.form-search-standard .search-options {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.search-widget form#simple2 .search-options:after,
.search-widget form.form-search-standard .search-options:after {
  content: "";
  display: table;
  clear: both;
}
.search-widget form#simple2 .search-options > div span, .search-widget form#simple2 .search-options > div a,
.search-widget form.form-search-standard .search-options > div span,
.search-widget form.form-search-standard .search-options > div a {
  font-size: 14px !important;
}
.search-widget form#simple2 .search-options .focus-toggle,
.search-widget form.form-search-standard .search-options .focus-toggle {
  position: relative;
  width: 40%;
  text-align: left;
  margin: 0.6em 0 0 0;
  max-height: 24px;
  color: #bbb;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
}
.search-widget form#simple2 .search-options .focus-toggle .label-cbx,
.search-widget form.form-search-standard .search-options .focus-toggle .label-cbx {
  user-select: none;
  cursor: pointer;
}
.search-widget form#simple2 .search-options .focus-toggle .label-cbx input + .checkbox,
.search-widget form.form-search-standard .search-options .focus-toggle .label-cbx input + .checkbox {
  position: relative;
  float: left;
  width: 20px;
  height: 20px;
  transition: all 0.2s ease;
}
.search-widget form#simple2 .search-options .focus-toggle .label-cbx input + .checkbox svg,
.search-widget form.form-search-standard .search-options .focus-toggle .label-cbx input + .checkbox svg {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s ease;
  max-height: 20px;
  max-width: 20px;
}
.search-widget form#simple2 .search-options .focus-toggle .label-cbx input + .checkbox svg.checked-square,
.search-widget form.form-search-standard .search-options .focus-toggle .label-cbx input + .checkbox svg.checked-square {
  fill: #999;
}
.search-widget form#simple2 .search-options .focus-toggle .label-cbx input + .checkbox ~ span,
.search-widget form.form-search-standard .search-options .focus-toggle .label-cbx input + .checkbox ~ span {
  display: inline-block;
  margin-top: 0;
  transition: all 0.2s ease;
  min-width: 2em;
}
.search-widget form#simple2 .search-options .focus-toggle .label-cbx input + .checkbox + span,
.search-widget form.form-search-standard .search-options .focus-toggle .label-cbx input + .checkbox + span {
  padding-left: 4px;
}
.search-widget form#simple2 .search-options .focus-toggle .label-cbx input:not(:checked) + .checkbox svg.empty-square,
.search-widget form.form-search-standard .search-options .focus-toggle .label-cbx input:not(:checked) + .checkbox svg.empty-square {
  opacity: 1;
  fill: #bbb;
}
.search-widget form#simple2 .search-options .focus-toggle .label-cbx input:not(:checked) + .checkbox svg.checked-square,
.search-widget form.form-search-standard .search-options .focus-toggle .label-cbx input:not(:checked) + .checkbox svg.checked-square {
  opacity: 0;
}
.search-widget form#simple2 .search-options .focus-toggle .label-cbx input:not(:checked) + .checkbox ~ span.on,
.search-widget form.form-search-standard .search-options .focus-toggle .label-cbx input:not(:checked) + .checkbox ~ span.on {
  display: none;
}
.search-widget form#simple2 .search-options .focus-toggle .label-cbx input:not(:checked) + .checkbox ~ span.off,
.search-widget form.form-search-standard .search-options .focus-toggle .label-cbx input:not(:checked) + .checkbox ~ span.off {
  display: inline-block;
}
.search-widget form#simple2 .search-options .focus-toggle .label-cbx input:checked + .checkbox svg.empty-square,
.search-widget form.form-search-standard .search-options .focus-toggle .label-cbx input:checked + .checkbox svg.empty-square {
  opacity: 0;
}
.search-widget form#simple2 .search-options .focus-toggle .label-cbx input:checked + .checkbox svg.checked-square,
.search-widget form.form-search-standard .search-options .focus-toggle .label-cbx input:checked + .checkbox svg.checked-square {
  opacity: 1;
  fill: #ffcd00;
}
.search-widget form#simple2 .search-options .focus-toggle .label-cbx input:checked + .checkbox ~ span.on,
.search-widget form.form-search-standard .search-options .focus-toggle .label-cbx input:checked + .checkbox ~ span.on {
  display: inline-block;
}
.search-widget form#simple2 .search-options .focus-toggle .label-cbx input:checked + .checkbox ~ span.off,
.search-widget form.form-search-standard .search-options .focus-toggle .label-cbx input:checked + .checkbox ~ span.off {
  display: none;
}
.search-widget form#simple2 .search-options .focus-toggle .label-cbx:hover input:not(:checked) + .checkbox svg.empty-square,
.search-widget form.form-search-standard .search-options .focus-toggle .label-cbx:hover input:not(:checked) + .checkbox svg.empty-square {
  fill: #ffdc4d;
  opacity: 1;
}
.search-widget form#simple2 .search-options .focus-toggle .label-cbx:hover input:checked + .checkbox svg.checked-square,
.search-widget form.form-search-standard .search-options .focus-toggle .label-cbx:hover input:checked + .checkbox svg.checked-square {
  fill: #ffdc4d;
}
.search-widget form#simple2 .search-options .focus-toggle .label-cbx:hover input + .checkbox ~ span,
.search-widget form.form-search-standard .search-options .focus-toggle .label-cbx:hover input + .checkbox ~ span {
  color: #eee;
}
.search-widget form#simple2 .search-options .focus-toggle .focus-help,
.search-widget form.form-search-standard .search-options .focus-toggle .focus-help {
  display: inline-block;
}
.search-widget form#simple2 .search-options .focus-toggle .focus-help a,
.search-widget form.form-search-standard .search-options .focus-toggle .focus-help a {
  display: block;
  margin: 0;
  padding: 0 0 0 4px;
}
.search-widget form#simple2 .search-options .focus-toggle .focus-help a svg,
.search-widget form.form-search-standard .search-options .focus-toggle .focus-help a svg {
  margin-top: 2px;
  height: 16px;
  width: 16px;
  fill: #bbb;
  transition: all 0.2s ease;
}
.search-widget form#simple2 .search-options .focus-toggle .focus-help a:hover svg,
.search-widget form.form-search-standard .search-options .focus-toggle .focus-help a:hover svg {
  fill: #eee;
}
.search-widget form#simple2 .search-options .invisible,
.search-widget form.form-search-standard .search-options .invisible {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  opacity: 0;
}

@media (min-width: 600px) {
  .search-widget .search-widget-inner {
    margin-left: 2rem;
  }
  .search-widget .search-widget-inner h2 {
    margin: 5rem 0;
  }
}
@media (min-width: 1150px) {
  .search-widget {
    margin: 0;
  }
}
.events-region {
  margin-bottom: 2rem;
}

.events-list-contenthub {
  margin-right: 1rem;
}
.events-list-contenthub ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.events-list-contenthub ul .event {
  padding: 1rem 0;
}
.events-list-contenthub ul .event:after {
  content: "";
  display: table;
  clear: both;
}
.events-list-contenthub ul .event .event-image {
  float: left;
  margin-left: 3px;
  width: 57.1428571429px;
  height: 57.1428571429px;
  transition: 0.2s;
}
.events-list-contenthub ul .event .event-image a img {
  border: 1px solid #666;
  background-color: #fff;
  transition: 0.2s;
}
.events-list-contenthub ul .event:hover .event-image {
  transform: scale(1.05, 1.05);
}
.events-list-contenthub ul .event:hover .event-image a img {
  box-shadow: 2px 2px 2px rgba(102, 102, 102, 0.5);
}
.events-list-contenthub ul .event .event-info {
  padding-left: 8px;
  overflow: hidden;
}
.events-list-contenthub ul .event .event-info a {
  overflow-wrap: anywhere;
}
.events-list-contenthub ul .event .event-title {
  padding-bottom: 0.5rem;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
}
.events-list-contenthub ul .event .event-title a {
  text-decoration: none;
}
.events-list-contenthub ul .event .event-description {
  padding-bottom: 0.5rem;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
}
.events-list-contenthub ul .event .event-description .readmore a {
  text-decoration: none;
}
.events-list-contenthub ul .event .event-time {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  text-align: left;
  padding-left: calc(1em * 1.333 + 0em);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  line-height: 1.5em;
  color: black;
  background-image: url(/includes/icons/svg/black/calendar-alt-regular.svg);
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: left 0em top 0.35em;
}
.events-list-contenthub ul .event .event-time i {
  text-transform: uppercase;
  font-size: 85%;
  font-style: normal;
}
.events-list-contenthub ul .event .event-time em {
  display: block;
  font-size: 85%;
  line-height: 85%;
}
.events-list-contenthub ul .event .event-virtual-location {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  text-align: left;
  padding-left: calc(1em * 1.333 + 0em);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  line-height: 1.5em;
  color: black;
  background-image: url(/includes/icons/svg/black/globe.svg);
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: left 0em top 0.35em;
}
.events-list-contenthub ul .event .event-location {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  text-align: left;
  padding-left: calc(1em * 1.333 + 0em);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  line-height: 1.5em;
  color: black;
  background-image: url(/includes/icons/svg/black/map-marker-alt.svg);
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: left 0em top 0.35em;
  background-size: 0.9em;
}
.events-list-contenthub ul .event.EVENT-CANCELED {
  opacity: 0.8;
}
.events-list-contenthub ul .event.EVENT-CANCELED .event-time,
.events-list-contenthub ul .event.EVENT-CANCELED .event-location {
  text-decoration: line-through;
}
.events-list-contenthub ul .event.SCOPE-PRIVATE {
  display: none;
}
@media (min-width: 480px) {
  .events-list-contenthub ul li.event .event-image {
    width: 76.1904761905px;
    height: 76.1904761905px;
  }
}
@media (min-width: 1150px) {
  .events-list-contenthub ul li.event .event-image {
    width: 95.2380952381px;
    height: 95.2380952381px;
  }
}

.event-single-contenthub .event:after {
  content: "";
  display: table;
  clear: both;
}
.event-single-contenthub .event .event-image {
  margin-bottom: 1rem;
  max-width: 100%;
}
.event-single-contenthub .event .event-image a img {
  border: 1px solid #666;
  max-width: 100%;
}
.event-single-contenthub .event .event-info {
  padding-top: 2rem;
}
.event-single-contenthub .event .event-title {
  font-weight: 400;
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 1.5;
}
.event-single-contenthub .event .event-title a {
  text-decoration: none;
}
.event-single-contenthub .event .event-quick {
  padding-bottom: 1rem;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
}
.event-single-contenthub .event .event-quick-time {
  text-align: left;
  padding-left: calc(1em * 1.333 + 0em);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  line-height: 1.5em;
  color: black;
  background-image: url(/includes/icons/svg/black/calendar-alt-regular.svg);
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: left 0em top 0.35em;
}
.event-single-contenthub .event .event-quick-time i {
  text-transform: uppercase;
  font-size: 85%;
  font-style: normal;
}
.event-single-contenthub .event .event-quick-time em {
  display: block;
  font-size: 85%;
}
.event-single-contenthub .event .event-quick.event-virtual-location {
  text-align: left;
  padding-left: calc(1em * 1.333 + 0em);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  line-height: 1.5em;
  color: black;
  background-image: url(/includes/icons/svg/black/globe.svg);
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: left 0em top 0.35em;
  background-size: 0.9em;
}
.event-single-contenthub .event .event-quick-location {
  text-align: left;
  padding-left: calc(1em * 1.333 + 0em);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  line-height: 1.5em;
  color: black;
  background-image: url(/includes/icons/svg/black/map-marker-alt.svg);
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: left 0em top 0.35em;
  background-size: 0.9em;
}
.event-single-contenthub .event .event-quick-contact {
  text-align: left;
  padding-left: calc(1em * 1.333 + 0em);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  line-height: 1.5em;
  color: black;
  background-image: url(/includes/icons/svg/black/address-card.svg);
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: left 0em top 0.35em;
}
.event-single-contenthub .event .event-quick-url {
  text-align: left;
  padding-left: calc(1em * 1.333 + 0em);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  line-height: 1.5em;
  color: black;
  background-image: url(/includes/icons/svg/black/link.svg);
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: left 0em top 0.35em;
}
.event-single-contenthub .event .event-quick-signup-url {
  text-align: left;
  padding-left: calc(1em * 1.333 + 0em);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  line-height: 1.5em;
  color: black;
  background-image: url(/includes/icons/svg/black/user-plus.svg);
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: left 0em top 0.35em;
}
.event-single-contenthub .event .event-description {
  margin: 2rem 0;
  padding-bottom: 6rem;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
}
.event-single-contenthub .event .event-description .readmore a {
  text-decoration: none;
}
.event-single-contenthub .event .event-taxonomy {
  padding-bottom: 1rem;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  text-align: left;
  padding-left: calc(1em * 1.333 + 0em);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  line-height: 1.5em;
  color: black;
  background-image: url(/includes/icons/svg/black/tags.svg);
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: left 0em top 0.35em;
}
.event-single-contenthub .event .event-taxonomy strong {
  font-weight: 400;
}
.event-single-contenthub .event .event-taxonomy strong:after {
  content: ": ";
}
.event-single-contenthub .event .events-site-url {
  padding-bottom: 1rem;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  text-align: left;
  padding-left: calc(1em * 1.333 + 0em);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  line-height: 1.5em;
  color: black;
  background-image: url(/includes/icons/svg/black/external-link-alt.svg);
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: left 0em top 0.35em;
}
.event-single-contenthub .event .events-site-url strong {
  font-weight: 400;
}
.event-single-contenthub .event .accessibility-statement {
  margin-top: 2rem;
  padding: 2rem 0;
  border-top: 1px solid #ccc;
  font-style: italic;
  color: #666;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
}
@media (min-width: 855px) {
  .event-single-contenthub .event .event-image {
    float: right;
    margin-left: 2rem;
    max-width: 300px;
  }
}
@media (min-width: 1150px) {
  .event-single-contenthub .event {
    padding: 0;
  }
}

.banner-region {
  margin: 0;
  max-width: 100%;
  background-color: #191919;
  background-image: linear-gradient(#111, #191919);
}
.banner-region .banner-region-inner {
  display: block;
  margin: 0 auto;
  max-width: 1150px;
}
@media (min-width: 600px) {
  .banner-region .banner-region-inner {
    display: flex;
    flex-flow: row nowrap;
  }
  .banner-region.banner-region-flipped .banner-region-inner {
    flex-flow: row-reverse nowrap;
  }
}

.big-feature-item {
  position: relative;
  display: block;
  min-height: 240px;
  background-size: cover;
  background-repeat: no-repeat;
  flex: 1.68;
}
.big-feature-item a.big-feature-link {
  display: block;
  margin: 0;
  padding: 0;
  height: 100%;
}
.big-feature-item .big-feature-title-area {
  position: absolute;
  margin: 0;
  padding: 0.5rem;
  bottom: 18px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
}
.big-feature-item .big-feature-title-area .big-feature-title {
  margin: 0;
  padding: 0;
  font-family: "Zilla Slab", serif;
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.5;
  line-height: 3rem;
  font-weight: lighter;
  text-transform: uppercase;
}
.big-feature-item .big-feature-title-area .big-feature-title span {
  display: block;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
  line-height: 2rem;
}
.big-feature-item .big-feature-title-area .big-feature-subtitle {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: lighter;
  text-align: center;
}
.big-feature-item .big-feature-title-area .big-feature-subtitle em {
  font-style: normal;
  font-family: Lora, "Times New Roman", Times, serif;
  color: #ffcd00;
}
.big-feature-item .big-feature-title-area .libcal-todays-hours ul {
  margin: 8px 0 0 0;
  padding: 0;
  list-style-type: none;
}
.big-feature-item .big-feature-title-area .libcal-todays-hours ul li {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: lighter;
  opacity: 0.666;
  transition: 0.2s;
}
.big-feature-item .big-feature-title-area .libcal-todays-hours ul li:hover {
  opacity: 1;
}
.big-feature-item .big-feature-title-area .libcal-todays-hours ul a {
  color: white;
  text-decoration: none !important;
}
.big-feature-item .big-feature-image-description {
  position: absolute;
  margin: 0;
  padding: 4px 8px;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: rgba(255, 255, 255, 0.66);
  font-size: 10px;
  font-size: 1rem;
  line-height: 1.5;
  opacity: 0;
}

.big-feature-item.librarian .big-feature-title-area {
  top: 36px;
  bottom: auto;
}
.big-feature-item.librarian .big-feature-image-description {
  transition: 0.25s;
  transition-delay: 1s;
  visibility: visible;
}
.big-feature-item.librarian.virus-changes .big-feature-title-area {
  top: 0;
  bottom: auto;
  background-color: rgba(255, 205, 0, 0.85);
}
.big-feature-item.librarian.virus-changes .big-feature-title-area .big-feature-title {
  color: black;
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  text-transform: none;
  line-height: 3.6rem;
}
.big-feature-item.librarian.virus-changes .big-feature-title-area .big-feature-subtitle {
  padding-bottom: 0;
}
.big-feature-item.librarian.virus-changes .big-feature-title-area .big-feature-subtitle em {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  color: black;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}
.big-feature-item.librarian.virus-changes-bottom .big-feature-title-area {
  top: auto;
  bottom: 2rem;
}
.big-feature-item.librarian:hover .big-feature-image-description {
  opacity: 1;
}
.big-feature-item.title-top .big-feature-title-area {
  top: 32px;
  bottom: auto;
}
.big-feature-item.title-right .big-feature-title-area {
  right: 0;
  left: auto;
}
.big-feature-item.title-left .big-feature-title-area {
  top: 48px;
  right: auto;
  left: auto;
}
.big-feature-item.show-image-description .big-feature-title-area {
  bottom: 36px;
}
.big-feature-item.show-image-description .big-feature-image-description {
  visibility: visible;
}
.big-feature-item.longer-title-cta {
  border-left: 0px solid #000 !important;
}
.big-feature-item.longer-title-cta .big-feature-title-area {
  background-color: #ffcd00;
  opacity: 0.91;
}
.big-feature-item.longer-title-cta .big-feature-title-area .big-feature-title {
  color: black;
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  text-transform: none;
  font-weight: 400;
}
.big-feature-item.longer-title-cta .big-feature-title-area .big-feature-subtitle {
  color: black;
}
.big-feature-item.longer-title-cta .big-feature-image-description {
  transition: 0.25s;
  transition-delay: 1s;
  visibility: visible;
}

@media (min-width: 600px) {
  .big-feature-item {
    flex: 1.33;
    min-height: 300px;
  }
  .big-feature-item .big-feature-title-area .big-feature-title {
    font-size: 40px;
    font-size: 4rem;
    line-height: 1.5;
    line-height: 4rem;
  }
  .big-feature-item .big-feature-title-area .big-feature-title span {
    font-size: 30px;
    font-size: 3rem;
    line-height: 1.5;
    line-height: 3rem;
  }
  .big-feature-item .big-feature-title-area .big-feature-subtitle {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
@media (min-width: 1150px) {
  .big-feature-item {
    flex: 1.68;
    min-height: 360px;
  }
  .big-feature-item .big-feature-title-area {
    padding: 1rem;
  }
  .big-feature-item .big-feature-title-area .big-feature-title {
    font-size: 50px;
    font-size: 5rem;
    line-height: 1.5;
    line-height: 5rem;
  }
  .big-feature-item .big-feature-title-area .big-feature-title span {
    font-size: 40px;
    font-size: 4rem;
    line-height: 1.5;
    line-height: 4rem;
  }
  .big-feature-item .big-feature-title-area .big-feature-subtitle {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.5;
  }
}
.exhibit-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.exhibit-cards-container .card__exhibit-media {
  flex: 0 1 100%;
  margin-bottom: 12px;
  background-color: #222;
  transition: 0.2s;
}
.exhibit-cards-container .card__exhibit-media a {
  text-decoration: none;
  color: white;
}
.exhibit-cards-container .card__exhibit-media:hover {
  background-color: black;
  transform: scale(1.01);
  box-shadow: 2px 2px 2px rgba(102, 102, 102, 0.5);
}
.exhibit-cards-container .card__exhibit-media:hover a {
  color: white;
}
.exhibit-cards-container .card__exhibit-media .exhibit-media-title {
  padding: 0 10px 1rem 10px;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
  font-family: "Roboto Condensed";
  color: white;
}
.exhibit-cards-container .card__exhibit-media .exhibit-media-summary,
.exhibit-cards-container .card__exhibit-media .exhibit-media-description {
  padding: 0 10px 10px 10px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  color: white;
}
.exhibit-cards-container .card__exhibit-media .exhibit-media-summary p,
.exhibit-cards-container .card__exhibit-media .exhibit-media-description p {
  margin: 0 0 0.5rem 0;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  color: white;
}
.exhibit-cards-container .card__exhibit-media .exhibit-media-more {
  margin: 10px;
  text-align: right;
}
.exhibit-cards-container .card__exhibit-media .exhibit-media-more a {
  display: inline-block;
  padding: 0.7em 2em 0.55em;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none !important;
  font-family: inherit;
  transition: 0.1s;
  background-color: #ffcd00;
  color: black !important;
  border: none;
  line-height: 1.5em;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
}
.exhibit-cards-container .card__exhibit-media .exhibit-media-more a:hover {
  text-decoration: none;
  background-color: #ffd733;
  color: black;
}
.exhibit-cards-container .card__exhibit-media .exhibit-media-more a:active {
  position: relative;
  top: 1px;
  background-color: #ffcd00;
  color: black;
}
.exhibit-cards-container .card__exhibit-media .exhibit-media-more a:focus {
  outline: 2px solid #00558c;
}
.exhibit-cards-container .card__exhibit-media .exhibit-media-more a i {
  margin-right: 0.333em;
}
@media (min-width: 600px) {
  .exhibit-cards-container .card__exhibit-media {
    flex: 0 1 calc(50% - 0.5rem);
  }
  .exhibit-cards-container .card__exhibit-media.columns-2 {
    flex: 0 1 100%;
  }
  .exhibit-cards-container .card__exhibit-media.columns-3 {
    flex: 0 1 100%;
  }
}
@media (min-width: 855px) {
  .exhibit-cards-container .card__exhibit-media {
    flex: 0 1 calc(33.3333% - 0.5rem);
  }
  .exhibit-cards-container .card__exhibit-media.columns-2 {
    flex: 0 1 calc(66.6666% - 0.25rem);
  }
  .exhibit-cards-container .card__exhibit-media.columns-3 {
    flex: 0 1 100%;
  }
}

.single-exhibit a.virtual-exhibit {
  display: inline-block;
  padding: 0.7em 2em 0.55em;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none !important;
  font-family: inherit;
  transition: 0.1s;
  background-color: #ffcd00;
  color: black !important;
  border: none;
  line-height: 1.5em;
  text-align: left;
  padding-left: 2.5em;
  background-image: url(/includes/icons/svg/black/laptop-house.svg);
  background-repeat: no-repeat;
  background-size: 1.25em;
  background-position: 0.75em center;
  padding-right: 1.25em;
  font-size: 28px;
  font-size: 2.8rem;
  line-height: 1.5;
}
.single-exhibit a.virtual-exhibit:hover {
  text-decoration: none;
  background-color: #ffd733;
  color: black;
}
.single-exhibit a.virtual-exhibit:active {
  position: relative;
  top: 1px;
  background-color: #ffcd00;
  color: black;
}
.single-exhibit a.virtual-exhibit:focus {
  outline: 2px solid #00558c;
}
.single-exhibit a.virtual-exhibit i {
  margin-right: 0.333em;
}
.single-exhibit h3.exhibit-dates {
  display: none;
}

.single-exhibit_media .entry-title .exhibit-type {
  display: block;
  font-size: 60%;
  font-weight: lighter;
  text-transform: uppercase;
}
.single-exhibit_media .exhibit-media-title {
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.5;
  font-family: "Roboto Condensed", sans-serif;
}
.single-exhibit_media div.wp-caption > img {
  max-width: 66%;
}

a.badlink-scua {
  background-color: #E2B858;
  color: black;
}

a.badlink-scua-bai {
  background-color: #90dbff;
  color: black;
}

a.badlink-sdrc {
  background-color: #fca87b;
  color: black;
}

a.badlink-sdrc-dada {
  background-color: transparent;
  color: #00558c;
}

h2.entry-title {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
}

@media (min-width: 480px) {
  h2.entry-title {
    font-size: 26px;
    font-size: 2.6rem;
    line-height: 1.5;
  }
}
@media (min-width: 855px) {
  h2.entry-title {
    font-size: 26px;
    font-size: 2.6rem;
    line-height: 1.5;
  }
}
.gfield_required_asterisk {
  font-size: 1em !important;
  font-weight: bolder;
}

.ginput_container_textarea textarea {
  font-family: "Roboto", sans-serif;
}

.gravity-theme.ui-datepicker .ui-datepicker-header .ui-datepicker-title .ui-datepicker-month,
.gravity-theme.ui-datepicker .ui-datepicker-header .ui-datepicker-title .ui-datepicker-year {
  font-size: 1.6rem !important;
}
.gravity-theme.ui-datepicker .ui-datepicker-calendar th span, .gravity-theme.ui-datepicker .ui-datepicker-calendar td {
  font-size: 1.6rem !important;
}

.gfield--type-select .ginput_container_select a.chosen-default > span {
  color: #555;
}

.gform_wrapper.gravity-theme .chosen-container,
.gform_wrapper.gravity-theme .chosen-single {
  overflow-x: visible !important;
  overflow-y: visible !important;
  white-space: normal !important;
  word-wrap: break-word;
}

/* -- Gravity forms overrides -- */
input[type=text], input[type=password], textarea {
  background: none;
}

.gform_wrapper textarea {
  font-size: 100%;
}

.gform_wrapper .gform_body .gform_fields .gfield input[type=text] {
  padding: 8px;
  margin: 0;
}

.gform_wrapper .gform_body .gform_fields .gfield input[type=email] {
  padding: 8px;
  margin: 0;
}

.gform_wrapper .gform_body .gform_fields .gfield input[type=tel] {
  padding: 8px;
  margin: 0;
}

.gform_wrapper .gform_body .gform_fields .gfield select {
  padding: 2px;
  margin: 0;
}

.gform_wrapper .left_label li.gfield_html_formatted, .uil_description p {
  font-weight: normal;
  font-size: 100%;
  padding: 0 0 7px 0;
  margin: 0;
}

.uil_description a {
  text-decoration: underline;
}

.gform_wrapper select, .gform_wrapper li {
  font-size: 100%;
}

.contactforms {
  padding-top: 10px;
}

.indent {
  padding-left: 30px;
}

.gform_wrapper .description, .gform_wrapper .gfield_description, .gform_wrapper .gsection_description, .gform_wrapper .instruction {
  font-size: 100%;
}

.gform_wrapper .gfield_label {
  font-size: 100%;
}

.gform_wrapper .left_label .gfield_label {
  width: 20%;
  font-size: 100%;
}

.gform_wrapper .gform_description {
  font-size: 100%;
}

.gform_wrapper input[type=text], .gform_wrapper input[type=url], .gform_wrapper input[type=email], .gform_wrapper input[type=tel], .gform_wrapper input[type=number], .gform_wrapper input[type=password] {
  font-size: 100%;
}

.gform_wrapper .dontdisplay {
  font-size: 1.2em;
}

.gform_wrapper .dontdisplay .gfield_label {
  display: none;
}

.gform_wrapper {
  margin-top: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}
.gform_wrapper .ginput_complex label {
  overflow: visible;
}
.gform_wrapper .gform_heading .gform_description {
  font-weight: bold;
}
.gform_wrapper .gform_heading .gform_description strong {
  color: #900;
}
.gform_wrapper .gform_body {
  margin-bottom: 1.5em;
}
.gform_wrapper .gform_body h3 {
  padding: 0;
  margin: 0;
}
.gform_wrapper .gform_body .gform_fields .gfield {
  margin-top: 1em;
  margin-bottom: 1em;
}
.gform_wrapper .gform_body .gform_fields .gfield select, .gform_wrapper .gform_body .gform_fields .gfield input[type=text], .gform_wrapper .gform_body .gform_fields .gfield input[type=email], .gform_wrapper .gform_body .gform_fields .gfield textarea {
  font-size: 1em;
  border: 1px solid #999;
}
.gform_wrapper .gform_body .gform_fields .gfield .gfield_description {
  padding-top: 0;
}
.gform_wrapper .gform_body .gfield_required {
  color: #900;
}
.gform_wrapper .gform_body ul.gfield_checkbox li br {
  display: none;
}
.gform_wrapper .gform_body .gform_page_footer {
  border-top: none;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_next_button {
  display: inline-block;
  padding: 0.7em 2em 0.55em;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none !important;
  font-family: inherit;
  transition: 0.1s;
  background-color: #ffcd00;
  color: black !important;
  border: none;
  line-height: 1.5em;
  text-align: left;
  padding-left: 2.5em;
  background-image: url(/includes/icons/svg/black/arrow-circle-right.svg);
  background-repeat: no-repeat;
  background-size: 1.25em;
  background-position: 0.75em center;
  padding-right: 1.25em;
  border: 0;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_next_button:hover {
  text-decoration: none;
  background-color: #ffd733;
  color: black;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_next_button:active {
  position: relative;
  top: 1px;
  background-color: #ffcd00;
  color: black;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_next_button:focus {
  outline: 2px solid #00558c;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_next_button i {
  margin-right: 0.333em;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_previous_button {
  display: inline-block;
  padding: 0.7em 2em 0.55em;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none !important;
  font-family: inherit;
  transition: 0.1s;
  background-color: #ffcd00;
  color: black !important;
  border: none;
  line-height: 1.5em;
  text-align: left;
  padding-left: 2.5em;
  background-image: url(/includes/icons/svg/black/arrow-circle-left.svg);
  background-repeat: no-repeat;
  background-size: 1.25em;
  background-position: 0.75em center;
  padding-right: 1.25em;
  border: 0;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_previous_button:hover {
  text-decoration: none;
  background-color: #ffd733;
  color: black;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_previous_button:active {
  position: relative;
  top: 1px;
  background-color: #ffcd00;
  color: black;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_previous_button:focus {
  outline: 2px solid #00558c;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_previous_button i {
  margin-right: 0.333em;
}
.gform_wrapper .gform_footer {
  padding: 0 !important;
}
.gform_wrapper input[type=submit].gform_button {
  display: inline-block;
  padding: 0.7em 2em 0.55em;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none !important;
  font-family: inherit;
  transition: 0.1s;
  background-color: #ffcd00;
  color: black !important;
  border: none;
  line-height: 1.5em;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  border: 0;
}
.gform_wrapper input[type=submit].gform_button:hover {
  text-decoration: none;
  background-color: #ffd733;
  color: black;
}
.gform_wrapper input[type=submit].gform_button:active {
  position: relative;
  top: 1px;
  background-color: #ffcd00;
  color: black;
}
.gform_wrapper input[type=submit].gform_button:focus {
  outline: 2px solid #00558c;
}
.gform_wrapper input[type=submit].gform_button i {
  margin-right: 0.333em;
}
.gform_wrapper .gfield_html_formatted ul {
  padding-left: 2em !important;
}
.gform_wrapper .gfield_html_formatted ul li {
  margin-bottom: 0 !important;
}
.gform_wrapper .gfield_description ol, .gform_wrapper .gfield_description ol li {
  list-style-type: decimal !important;
}
.gform_wrapper .gfield_description li {
  list-style-type: disc !important;
}
.gform_wrapper .gform_validation_errors h2.gform_submission_error {
  text-align: left;
}
.gform_wrapper .gform_validation_errors h2.gform_submission_error:before {
  background-image: none;
}
.gform_wrapper li.gfield.gfield_error.gfield_contains_required {
  padding-left: 6px;
  padding-right: 6px;
  border-top: 6px solid #900;
  border-bottom: 0;
  background-color: #eee;
}
.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_description.validation_message {
  margin-top: 6px;
  padding: 6px;
  color: white;
  background-color: #900;
}
@media (min-width: 1150px) {
  .gform_wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
.gform_wrapper .gform_fileupload_multifile .gform_drop_area .gform_drop_instructions {
  color: #555;
}
.gform_wrapper .gfield--type-choice .gchoice {
  display: flex;
  align-items: flex-start;
  padding-top: 0.5rem;
}
.gform_wrapper .gfield--type-choice .gchoice input {
  margin-top: 0.2em;
  margin-right: 0.75em;
}
.gform_wrapper .gfield--type-html h4 {
  margin-top: 0;
}

/* -- Owl Carousel style overrides -- */
.owl-theme .owl-controls.clickable {
  margin-top: 0;
}

.owl-carousel-item-text {
  position: relative;
}

.owl-caption {
  position: absolute;
  bottom: 1px;
  left: 1px;
  width: 100%;
  text-align: center;
  padding: 6px;
  font-size: small;
  color: white;
  background: rgba(0, 0, 0, 0.6);
}

.owl-item img {
  padding: 0;
}

#nav .sf-menu {
  float: left;
  line-height: 1.2;
}
#nav .sf-menu a {
  display: block;
  margin: 0;
  padding: 8px 8px 12px 8px;
  text-decoration: none;
  color: #e1e1e1;
  transition: background 0.1s linear, color 0.1s linear;
}
#nav .sf-menu ul li {
  border-bottom: 1px solid #444;
  margin-top: 0;
  background-color: #373331;
}
#nav .sf-menu ul li:last-of-type {
  border-bottom: none;
}
#nav .sf-menu > li:hover > a {
  background-color: #ffcd00;
  color: #111;
}
#nav .sf-menu a:hover,
#nav .sf-menu li:hover,
#nav .sf-menu li.sfHover {
  background-color: #ffdc4d;
  color: #111;
}

#nav .sf-menu ul a:hover,
#nav .sf-menu ul li.sfHover,
#nav .sf-menu ul li:hover {
  color: #373331;
  background: #e1e1e1;
}

#nav .sf-menu ul li.sfHover a.sf-with-ul {
  color: #373331;
}

#nav .sf-menu li li a {
  padding: 0.75em;
  height: auto;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
}

#nav .sf-sub-indicator {
  background: url(/images/dropdown.png) no-repeat;
  width: 5px;
  height: 5px;
  text-indent: -9999px;
  display: inline-block;
  position: relative;
  left: 7px;
  top: 0;
  vertical-align: middle;
}

#nav .sf-menu ul .sf-sub-indicator {
  display: none;
}

#nav .quick-nav li {
  padding: 5px 0 0 8px;
}

.sf-menu,
.sf-menu * {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sf-menu {
  line-height: 1;
}

.sf-menu ul {
  position: absolute;
  left: -999em;
  width: 11em;
  z-index: 100;
}

.sf-menu ul li {
  width: 100%;
}

.sf-menu li:hover {
  visibility: inherit;
}

.sf-menu li {
  float: left;
  position: relative;
}

.sf-menu a {
  display: block;
  position: relative;
}

.sf-menu li:hover ul,
.sf-menu li.sfHover ul {
  left: 0;
  top: 2.2em;
  z-index: 99;
}

ul.sf-menu li:hover li ul,
ul.sf-menu li.sfHover li ul {
  top: -999em;
}

ul.sf-menu li li:hover ul,
ul.sf-menu li li.sfHover ul {
  left: 11em;
  top: 0;
}

ul.sf-menu li li:hover li ul,
ul.sf-menu li li.sfHover li ul {
  top: -999em;
}

ul.sf-menu li li li:hover ul,
ul.sf-menu li li li.sfHover ul {
  left: 11em;
  top: 0;
}

.sf-shadow ul {
  background: url(/images/shadow.png) no-repeat bottom right;
  padding: 0 8px 9px 0;
  border-top-right-radius: 17px;
  border-bottom-left-radius: 17px;
}

.sf-shadow ul.sf-shadow-off {
  background: transparent;
}

.quick-nav {
  float: right;
  list-style: none;
  margin: 0 10px 0;
  padding: 0;
}

.quick-nav li {
  float: left;
  display: inline;
}

#menu-mobile {
  display: none;
}

#mobileonly {
  display: none;
}

/* -- Columns style overrides -- */
.column-group {
  float: left;
  width: 100%;
  clear: both;
}

.column {
  float: left;
  margin-right: 5%;
  width: 100%;
}

.column-group > br, .column-group > p {
  display: none;
}

.column.last {
  margin-right: 0;
}

.columns-2 .column {
  width: 47.5%;
} /* ( 100 - $margin * ( $num_cols - 1 ) ) / $num_cols */
.columns-3 .column {
  width: 30%;
}

.columns-4 .column {
  width: 21.25%;
}

.columns-3 .column-span-2 {
  width: 65%;
} /* $width * $span + $margin * ( $span - 1 ) */
.columns-4 .column-span-2 {
  width: 47.5%;
}

.columns-4 .column-span-3 {
  width: 73.75%;
}

.column-group {
  margin: 10px 0;
}
.column-group h2, .column-group h3 {
  margin-bottom: 4px;
}

/* ----- Misc. overrides ----- */
.zp-Zotpress:nth-of-type(odd) {
  background-color: #e8e8e9;
}
.zp-Zotpress:nth-of-type(even) {
  background-color: #f2f3f3;
}
.zp-Zotpress .zp-Entry {
  padding: 0.5em 0.75em;
}
.zp-Zotpress .zp-Entry:nth-of-type(odd) {
  background-color: #e8e8e9;
}
.zp-Zotpress .zp-Entry:nth-of-type(even) {
  background-color: #f2f3f3;
}
.zp-Zotpress .zp-Entry .csl-entry {
  line-height: 1.75em;
}

.slicknav_menu {
  padding: 4px 0 0 0;
  background-color: #222;
  text-align: center;
}
.slicknav_menu a.slicknav_btn {
  margin: 0 auto;
  line-height: 36px;
  text-decoration: none;
}
.slicknav_menu a.slicknav_btn .slicknav_menutxt {
  margin-left: 2px;
  padding: 0;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
}
.slicknav_menu a.slicknav_btn .slicknav_icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("/includes/icons/png/white/32/bars.png");
  background-size: cover;
  vertical-align: text-bottom;
  transition: 0.33s;
}
.slicknav_menu a.slicknav_btn.slicknav_open .slicknav_icon {
  background-image: url("/includes/icons/png/white/32/times-circle.png");
}
.slicknav_menu ul.slicknav_nav {
  margin: 0;
  padding: 0.5em 1em;
  list-style-type: none;
  text-align: left;
  background-color: #666;
}
.slicknav_menu ul.slicknav_nav ul {
  padding-left: 1em;
  list-style-type: none;
}
.slicknav_menu ul.slicknav_nav li {
  padding: 0.25em;
}
.slicknav_menu ul.slicknav_nav li a {
  text-decoration: none;
  color: #ccc;
}
.slicknav_menu ul.slicknav_nav li a:hover {
  background-color: #666;
}

.feedzy-rss ul {
  margin: 0 !important;
  padding: 0;
}
.feedzy-rss ul li.rss_item {
  margin: 0 0 0 3px;
  padding: 1rem 1rem 1rem 0 !important;
  border-bottom: none;
}
.feedzy-rss ul li.rss_item .rss_image {
  margin-top: 0;
  margin-right: 1rem;
  transition: 0.2s;
  border: 1px solid #666;
}
.feedzy-rss ul li.rss_item .rss_image a span {
  transition: 0.2s;
}
.feedzy-rss ul li.rss_item .rss_image a span.default {
  display: none;
}
.feedzy-rss ul li.rss_item .rss_image + p {
  margin: 0;
}
.feedzy-rss ul li.rss_item .rss_image span.link-text {
  font-size: 2px;
  visibility: hidden;
}
.feedzy-rss ul li.rss_item .title {
  display: block;
  font-weight: bold;
}
.feedzy-rss ul li.rss_item .title a {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
  font-weight: bold;
  text-decoration: none;
}
.feedzy-rss ul li.rss_item .rss_content p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
}
.feedzy-rss ul li.rss_item:hover .rss_image {
  transform: scale(1.05, 1.05);
  box-shadow: 2px 2px 2px rgba(102, 102, 102, 0.5);
}
@media (min-width: 0) {
  .feedzy-rss ul li.rss_item .rss_image {
    width: 57.1428571429px !important;
    height: 57.1428571429px !important;
  }
  .feedzy-rss ul li.rss_item .rss_image a {
    width: 57.1428571429px !important;
    height: 57.1428571429px !important;
  }
  .feedzy-rss ul li.rss_item .rss_image span {
    height: 57.1428571429px !important;
    width: 57.1428571429px !important;
  }
  .feedzy-rss ul li.rss_item .title {
    padding-left: 65.1428571429px;
  }
  .feedzy-rss ul li.rss_item .rss_content {
    padding-left: 65.1428571429px;
  }
}
@media (min-width: 480px) {
  .feedzy-rss ul li.rss_item .rss_image {
    width: 76.1904761905px !important;
    height: 76.1904761905px !important;
  }
  .feedzy-rss ul li.rss_item .rss_image a {
    width: 76.1904761905px !important;
    height: 76.1904761905px !important;
  }
  .feedzy-rss ul li.rss_item .rss_image a span {
    height: 76.1904761905px !important;
    width: 76.1904761905px !important;
  }
  .feedzy-rss ul li.rss_item .title {
    padding-left: 84.1904761905px;
  }
  .feedzy-rss ul li.rss_item .rss_content {
    padding-left: 84.1904761905px;
  }
}
@media (min-width: 1150px) {
  .feedzy-rss ul li.rss_item .rss_image {
    width: 95.2380952381px !important;
    height: 95.2380952381px !important;
  }
  .feedzy-rss ul li.rss_item .rss_image a {
    width: 95.2380952381px !important;
    height: 95.2380952381px !important;
  }
  .feedzy-rss ul li.rss_item .rss_image a span {
    height: 95.2380952381px !important;
    width: 95.2380952381px !important;
  }
  .feedzy-rss ul li.rss_item .title {
    padding-left: 103.2380952381px;
    line-height: 1.5;
  }
  .feedzy-rss ul li.rss_item .rss_content {
    padding-left: 103.2380952381px;
  }
}

.feedzy-rss + .more-link {
  float: right;
}
.feedzy-rss + .more-link a {
  font-weight: bold;
  text-decoration: none;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
}

div.gallery {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  clear: both;
}
div.gallery br {
  display: none;
}
div.gallery dl.gallery-item {
  margin-right: 1.5rem;
  flex-basis: 150px;
}
div.gallery dl.gallery-item img {
  max-width: 150px;
}
div.gallery dl.gallery-item dd.wp-caption-text {
  margin: 0;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
}

#slb_viewer_wrap .slb_theme_slb_default .slb_data_content .slb_data_title {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 300;
}
#slb_viewer_wrap .slb_theme_slb_default .slb_data_content .slb_group_status {
  float: right;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 300;
}

.gfield_required_asterisk {
  font-size: 1em !important;
  font-weight: bolder;
}

.ginput_container_textarea textarea {
  font-family: "Roboto", sans-serif;
}

.gravity-theme.ui-datepicker .ui-datepicker-header .ui-datepicker-title .ui-datepicker-month,
.gravity-theme.ui-datepicker .ui-datepicker-header .ui-datepicker-title .ui-datepicker-year {
  font-size: 1.6rem !important;
}
.gravity-theme.ui-datepicker .ui-datepicker-calendar th span, .gravity-theme.ui-datepicker .ui-datepicker-calendar td {
  font-size: 1.6rem !important;
}

.gfield--type-select .ginput_container_select a.chosen-default > span {
  color: #555;
}

.gform_wrapper.gravity-theme .chosen-container,
.gform_wrapper.gravity-theme .chosen-single {
  overflow-x: visible !important;
  overflow-y: visible !important;
  white-space: normal !important;
  word-wrap: break-word;
}

/* -- Gravity forms overrides -- */
input[type=text], input[type=password], textarea {
  background: none;
}

.gform_wrapper textarea {
  font-size: 100%;
}

.gform_wrapper .gform_body .gform_fields .gfield input[type=text] {
  padding: 8px;
  margin: 0;
}

.gform_wrapper .gform_body .gform_fields .gfield input[type=email] {
  padding: 8px;
  margin: 0;
}

.gform_wrapper .gform_body .gform_fields .gfield input[type=tel] {
  padding: 8px;
  margin: 0;
}

.gform_wrapper .gform_body .gform_fields .gfield select {
  padding: 2px;
  margin: 0;
}

.gform_wrapper .left_label li.gfield_html_formatted, .uil_description p {
  font-weight: normal;
  font-size: 100%;
  padding: 0 0 7px 0;
  margin: 0;
}

.uil_description a {
  text-decoration: underline;
}

.gform_wrapper select, .gform_wrapper li {
  font-size: 100%;
}

.contactforms {
  padding-top: 10px;
}

.indent {
  padding-left: 30px;
}

.gform_wrapper .description, .gform_wrapper .gfield_description, .gform_wrapper .gsection_description, .gform_wrapper .instruction {
  font-size: 100%;
}

.gform_wrapper .gfield_label {
  font-size: 100%;
}

.gform_wrapper .left_label .gfield_label {
  width: 20%;
  font-size: 100%;
}

.gform_wrapper .gform_description {
  font-size: 100%;
}

.gform_wrapper input[type=text], .gform_wrapper input[type=url], .gform_wrapper input[type=email], .gform_wrapper input[type=tel], .gform_wrapper input[type=number], .gform_wrapper input[type=password] {
  font-size: 100%;
}

.gform_wrapper .dontdisplay {
  font-size: 1.2em;
}

.gform_wrapper .dontdisplay .gfield_label {
  display: none;
}

.gform_wrapper {
  margin-top: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}
.gform_wrapper .ginput_complex label {
  overflow: visible;
}
.gform_wrapper .gform_heading .gform_description {
  font-weight: bold;
}
.gform_wrapper .gform_heading .gform_description strong {
  color: #900;
}
.gform_wrapper .gform_body {
  margin-bottom: 1.5em;
}
.gform_wrapper .gform_body h3 {
  padding: 0;
  margin: 0;
}
.gform_wrapper .gform_body .gform_fields .gfield {
  margin-top: 1em;
  margin-bottom: 1em;
}
.gform_wrapper .gform_body .gform_fields .gfield select, .gform_wrapper .gform_body .gform_fields .gfield input[type=text], .gform_wrapper .gform_body .gform_fields .gfield input[type=email], .gform_wrapper .gform_body .gform_fields .gfield textarea {
  font-size: 1em;
  border: 1px solid #999;
}
.gform_wrapper .gform_body .gform_fields .gfield .gfield_description {
  padding-top: 0;
}
.gform_wrapper .gform_body .gfield_required {
  color: #900;
}
.gform_wrapper .gform_body ul.gfield_checkbox li br {
  display: none;
}
.gform_wrapper .gform_body .gform_page_footer {
  border-top: none;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_next_button {
  display: inline-block;
  padding: 0.7em 2em 0.55em;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none !important;
  font-family: inherit;
  transition: 0.1s;
  background-color: #ffcd00;
  color: black !important;
  border: none;
  line-height: 1.5em;
  text-align: left;
  padding-left: 2.5em;
  background-image: url(/includes/icons/svg/black/arrow-circle-right.svg);
  background-repeat: no-repeat;
  background-size: 1.25em;
  background-position: 0.75em center;
  padding-right: 1.25em;
  border: 0;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_next_button:hover {
  text-decoration: none;
  background-color: #ffd733;
  color: black;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_next_button:active {
  position: relative;
  top: 1px;
  background-color: #ffcd00;
  color: black;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_next_button:focus {
  outline: 2px solid #00558c;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_next_button i {
  margin-right: 0.333em;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_previous_button {
  display: inline-block;
  padding: 0.7em 2em 0.55em;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none !important;
  font-family: inherit;
  transition: 0.1s;
  background-color: #ffcd00;
  color: black !important;
  border: none;
  line-height: 1.5em;
  text-align: left;
  padding-left: 2.5em;
  background-image: url(/includes/icons/svg/black/arrow-circle-left.svg);
  background-repeat: no-repeat;
  background-size: 1.25em;
  background-position: 0.75em center;
  padding-right: 1.25em;
  border: 0;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_previous_button:hover {
  text-decoration: none;
  background-color: #ffd733;
  color: black;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_previous_button:active {
  position: relative;
  top: 1px;
  background-color: #ffcd00;
  color: black;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_previous_button:focus {
  outline: 2px solid #00558c;
}
.gform_wrapper .gform_body .gform_page_footer input[type=button].gform_previous_button i {
  margin-right: 0.333em;
}
.gform_wrapper .gform_footer {
  padding: 0 !important;
}
.gform_wrapper input[type=submit].gform_button {
  display: inline-block;
  padding: 0.7em 2em 0.55em;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none !important;
  font-family: inherit;
  transition: 0.1s;
  background-color: #ffcd00;
  color: black !important;
  border: none;
  line-height: 1.5em;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  border: 0;
}
.gform_wrapper input[type=submit].gform_button:hover {
  text-decoration: none;
  background-color: #ffd733;
  color: black;
}
.gform_wrapper input[type=submit].gform_button:active {
  position: relative;
  top: 1px;
  background-color: #ffcd00;
  color: black;
}
.gform_wrapper input[type=submit].gform_button:focus {
  outline: 2px solid #00558c;
}
.gform_wrapper input[type=submit].gform_button i {
  margin-right: 0.333em;
}
.gform_wrapper .gfield_html_formatted ul {
  padding-left: 2em !important;
}
.gform_wrapper .gfield_html_formatted ul li {
  margin-bottom: 0 !important;
}
.gform_wrapper .gfield_description ol, .gform_wrapper .gfield_description ol li {
  list-style-type: decimal !important;
}
.gform_wrapper .gfield_description li {
  list-style-type: disc !important;
}
.gform_wrapper .gform_validation_errors h2.gform_submission_error {
  text-align: left;
}
.gform_wrapper .gform_validation_errors h2.gform_submission_error:before {
  background-image: none;
}
.gform_wrapper li.gfield.gfield_error.gfield_contains_required {
  padding-left: 6px;
  padding-right: 6px;
  border-top: 6px solid #900;
  border-bottom: 0;
  background-color: #eee;
}
.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_description.validation_message {
  margin-top: 6px;
  padding: 6px;
  color: white;
  background-color: #900;
}
@media (min-width: 1150px) {
  .gform_wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
.gform_wrapper .gform_fileupload_multifile .gform_drop_area .gform_drop_instructions {
  color: #555;
}
.gform_wrapper .gfield--type-choice .gchoice {
  display: flex;
  align-items: flex-start;
  padding-top: 0.5rem;
}
.gform_wrapper .gfield--type-choice .gchoice input {
  margin-top: 0.2em;
  margin-right: 0.75em;
}
.gform_wrapper .gfield--type-html h4 {
  margin-top: 0;
}

.pod-region-slide-outer {
  margin: 0 0 1em 0;
}
.pod-region-slide-outer .pod-region-slide-inner {
  margin: 0 auto;
  max-width: 1200px;
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item {
  position: relative;
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item:after {
  content: "";
  display: table;
  clear: both;
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item > a {
  display: block;
  color: white;
  text-decoration: none;
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item > a:hover .slide-info {
  background-color: black;
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item .slide-image img {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item .slide-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item .slide-info .slide-title {
  clear: both;
  margin: 0 0 0.5em 0;
  padding: 0.5em;
  background-color: rgba(0, 0, 0, 0.666);
  color: white;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  max-width: 100%;
  font-weight: lighter;
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item .slide-info .slide-title em {
  display: block;
  margin-top: 8px;
  font-size: 80%;
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item .slide-info .slide-title-image {
  position: absolute;
  top: 20px;
  height: auto;
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item .slide-info .slide-description {
  position: absolute;
  bottom: 0;
  margin: 0;
  padding: 2px 4px;
  background-color: rgba(0, 0, 0, 0.8);
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item .slide-info .slide-description p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
  color: white;
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item .slide-info .slide-description a {
  color: white;
}
@media (min-width: 1150px) {
  .pod-region-slide-outer .pod-region-slide-inner .slide-item .slide-info .slide-title {
    position: absolute;
    top: 32px;
    left: 0;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.666);
    color: white;
    font-size: 36px;
    font-size: 3.6rem;
    line-height: 1.5;
    max-width: 90%;
  }
  .pod-region-slide-outer .pod-region-slide-inner .slide-item .slide-info .slide-title em {
    display: block;
    margin-top: 8px;
    font-size: 80%;
  }
}

.jumbotron .pod-region-slide-outer .pod-region-slide-inner {
  max-width: 100%;
}

.pod-region-slide-inner .owl-nav button {
  position: absolute;
  margin: 0;
  padding: 0;
  bottom: 40px;
  height: 48px;
  width: 48px;
  background-color: rgba(0, 85, 140, 0.8) !important;
  transition: 0.25s;
}
.pod-region-slide-inner .owl-nav button span {
  display: block;
  height: 100%;
  width: 100%;
  background: none;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  color: transparent;
}
.pod-region-slide-inner .owl-nav button.owl-next {
  right: 0;
}
.pod-region-slide-inner .owl-nav button.owl-next span {
  background-image: url("/includes/icons/png/white/128/caret-right.png");
}
.pod-region-slide-inner .owl-nav button.owl-prev {
  right: 52px;
}
.pod-region-slide-inner .owl-nav button.owl-prev span {
  background-image: url("/includes/icons/png/white/128/caret-left.png");
}
.pod-region-slide-inner .owl-nav button:hover {
  background-color: #00558c !important;
}
.pod-region-slide-inner .owl-nav .disabled {
  display: none;
}

.pod-region-slide-outer .pod-region-slide-inner .slide-item.title-hide .slide-title {
  position: absolute;
  left: -10000px;
  top: auto;
  height: 1px;
  overflow: hidden;
  width: 1px;
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item.darker-bg .slide-title {
  background-color: rgba(0, 0, 0, 0.85);
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item.darker-bg .slide-description {
  background-color: rgba(0, 0, 0, 0.85);
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item.lighter-bg .slide-title {
  background-color: rgba(0, 0, 0, 0.5);
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item.lighter-bg .slide-description {
  background-color: rgba(0, 0, 0, 0.5);
}
@media (min-width: 1150px) {
  .pod-region-slide-outer .pod-region-slide-inner .slide-item.left-title .slide-title, .pod-region-slide-outer .pod-region-slide-inner .slide-item.title-left .slide-title {
    left: 0;
    right: auto;
    padding: 8px 24px 8px 300px;
    width: auto;
  }
}
@media (min-width: 1150px) {
  .pod-region-slide-outer .pod-region-slide-inner .slide-item.title-right .slide-title {
    left: auto !important;
    right: 0;
    width: auto;
  }
}
@media (min-width: 1150px) {
  .pod-region-slide-outer .pod-region-slide-inner .slide-item.title-smaller .slide-title {
    font-size: 23.976px;
    font-size: 2.3976rem;
    line-height: 1.5;
  }
}
@media (min-width: 1150px) {
  .pod-region-slide-outer .pod-region-slide-inner .slide-item.title-bigger .slide-title {
    font-size: 45px;
    font-size: 4.5rem;
    line-height: 1.5;
  }
}
@media (min-width: 1150px) {
  .pod-region-slide-outer .pod-region-slide-inner .slide-item.title-full-width .slide-title {
    left: 0;
    right: 0;
    text-align: center;
    width: auto;
  }
}
@media (min-width: 1150px) {
  .pod-region-slide-outer .pod-region-slide-inner .slide-item.title-lower .slide-title {
    top: auto !important;
    bottom: 18px;
    width: auto;
  }
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item.cover-pattern-screendoor .slide-cover {
  background-image: url("/includes/cover-pattern-screendoor.png");
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item.cover-pattern-gridpaper .slide-cover {
  background-image: url("/includes/cover-pattern-gridpaper.png");
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item.cover-pattern-squares .slide-cover {
  background-image: url("/includes/cover-pattern-tiles.png");
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item.cover-pattern-dark .slide-cover {
  background-color: black;
  opacity: 0.333;
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item.cover-pattern-darker .slide-cover {
  background-color: black;
  opacity: 0.666;
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item.cover-pattern-darkest .slide-cover {
  background-color: black;
  opacity: 0.8;
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item.cover-pattern-light .slide-cover {
  background-color: white;
  opacity: 0.333;
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item.cover-pattern-lighter .slide-cover {
  background-color: white;
  opacity: 0.666;
}
.pod-region-slide-outer .pod-region-slide-inner .slide-item.cover-pattern-lightest .slide-cover {
  background-color: white;
  opacity: 0.8;
}

.featured-items.owl-carousel {
  margin-bottom: 0;
}
.featured-items.owl-carousel .owl-controls {
  display: none;
}

.featured-items.owl-carousel {
  margin-bottom: 24px;
}
.featured-items.owl-carousel .feature-image {
  display: none;
}
.featured-items.owl-carousel .owl-item {
  position: relative;
}
.featured-items.owl-carousel .owl-item .feature-item {
  transition: 0.2s;
}
.featured-items.owl-carousel .owl-item .feature-image {
  display: block;
}
.featured-items.owl-carousel .owl-item .feature-info {
  position: absolute;
  bottom: -1px;
  right: 0;
  padding: 0.5rem;
  width: calc(100% - 1rem);
  opacity: 1;
  color: white;
  text-shadow: none;
  background-color: rgba(0, 0, 0, 0.8);
  transition: 0.2s;
}
.featured-items.owl-carousel .owl-item .feature-info .feature-title {
  margin: 0;
  padding: 4px 0;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 200;
  text-align: center;
}
.featured-items.owl-carousel .owl-item .feature-info .feature-description {
  margin: 0;
  padding: 0 0 6px 0;
  font-weight: 200;
  text-align: center;
}
.featured-items.owl-carousel .owl-item .feature-info .feature-description p {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.5;
  line-height: 1.35rem;
}
.featured-items.owl-carousel .owl-item .feature-info a {
  color: white;
  text-decoration: none;
}
.featured-items.owl-carousel .owl-item:hover .feature-info {
  background-color: black;
}
.featured-items.owl-carousel .owl-item .feature-info.info-top {
  bottom: auto;
  top: 0;
}

.featured-items-outer .feature-item.title-uppercase .feature-title {
  text-transform: uppercase;
}

.org-unit {
  margin-bottom: 24px;
}

.dir-list {
  margin-bottom: 2rem;
}
.dir-list .dir-list-item {
  margin: 0;
  padding: 8px;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
}
.dir-list .dir-list-item:after {
  content: "";
  display: table;
  clear: both;
}
.dir-list .dir-list-item:nth-of-type(odd) {
  background-color: #e8e8e9;
}
.dir-list .dir-list-item:nth-of-type(even) {
  background-color: #f2f3f3;
}
.dir-list .dir-list-item .dir-list-item-inner:after {
  content: "";
  display: table;
  clear: both;
}
.dir-list .dir-list-item .dir-list-item-inner h3.heading {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
}
.dir-list .dir-list-item .dir-list-item-inner > div {
  display: inline-block;
  vertical-align: top;
}
.dir-list .dir-list-item .dir-list-item-inner > div.photo {
  margin-right: 1em;
  margin-left: -8px;
  margin-top: -8px;
  margin-bottom: -11px;
  width: 100px;
  min-height: 100px;
  background-image: url("//www.lib.uiowa.edu/people/files/2017/04/generic-person-square.jpg");
  background-size: contain;
  background-repeat: no-repeat;
}
.dir-list .dir-list-item .dir-list-item-inner > div.photo img {
  width: 100%;
  height: auto;
}
.dir-list .dir-list-item .dir-list-item-inner > div.person-info {
  width: calc(100% - 100px - 1em);
  columns: 2 300px;
}
.dir-list .dir-list-item .dir-list-item-inner > div.person-info .name-title-link {
  break-inside: avoid-column;
}
.dir-list .dir-list-item .dir-list-item-inner > div.person-info .name-title-link .name {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
}
.dir-list .dir-list-item .dir-list-item-inner > div.person-info .name-title-link .edit {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
}
.dir-list .dir-list-item .dir-list-item-inner > div.person-info .name-title-link .pronouns {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
  font-style: italic;
}
.dir-list .dir-list-item .dir-list-item-inner > div.person-info .subject-specialties {
  display: none;
  clear: both;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
}
.dir-list .dir-list-item .dir-list-item-inner > div.person-info .subject-specialties ul {
  display: inline;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.dir-list .dir-list-item .dir-list-item-inner > div.person-info .subject-specialties ul li {
  display: inline;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
}
.dir-list .dir-list-item .dir-list-item-inner > div.person-info .subject-specialties ul li:after {
  content: "; ";
}
.dir-list .dir-list-item .dir-list-item-inner > div.person-info .subject-specialties ul li:last-of-type:after {
  content: "";
}
@media (min-width: 480px) {
  .dir-list h3.heading {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.5;
  }
  .dir-list .dir-list-item {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.5;
  }
}

.org-unit h3, .org-unit h4,
.subject-area h3,
.subject-area h4 {
  position: relative;
  margin: 0 !important;
  padding: 8px !important;
  background-color: #333;
  color: white;
  font-weight: lighter !important;
}
.org-unit h3 .back-to-top, .org-unit h4 .back-to-top,
.subject-area h3 .back-to-top,
.subject-area h4 .back-to-top {
  position: absolute;
  top: 8px;
  right: 8px;
  transition: 0.25s;
  opacity: 0.666;
}
.org-unit h3 .back-to-top img, .org-unit h4 .back-to-top img,
.subject-area h3 .back-to-top img,
.subject-area h4 .back-to-top img {
  height: 1.5em;
  width: 1.5em;
}
.org-unit h3 .back-to-top:hover, .org-unit h4 .back-to-top:hover,
.subject-area h3 .back-to-top:hover,
.subject-area h4 .back-to-top:hover {
  opacity: 1;
}

.resources-list .resource-item a {
  display: block;
  padding: 1em;
  width: 100%;
  text-decoration: none;
  background-color: #666;
}
.resources-list .resource-item a:after {
  content: "";
  display: table;
  clear: both;
}
.resources-list .resource-item a .resource-region-1 {
  float: left;
  padding-right: 1em;
  width: 40%;
}
.resources-list .resource-item a .resource-region-1 .resource-area {
  padding-right: 1em;
  font-family: Lora, "Times New Roman", Times, serif;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
}
.resources-list .resource-item a .resource-region-1 .resource-person .person-image {
  float: left;
  padding: 0.5em;
  max-height: 120px;
  border-radius: 50%;
}
.resources-list .resource-item a .resource-region-1 .resource-person .person-title {
  clear: both;
  padding-top: 0px;
  font-size: 11px;
  font-size: 1.1rem;
  line-height: 1.5;
}
.resources-list .resource-item a .resource-region-2 {
  float: left;
  width: 59%;
}
.resources-list .resource-item a .resource-region-2 .resource-title {
  margin-top: 0;
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: lighter;
}
.resources-list .resource-item a .resource-region-2 .resource-description p {
  margin: 0;
  font-family: Lora, "Times New Roman", Times, serif !important;
  font-style: italic;
  font-weight: lighter;
}
@media (min-width: 855px) {
  .resources-list .resource-item a .resource-region-1 .resource-area {
    float: right;
    font-size: 30px;
    font-size: 3rem;
    line-height: 1.5;
  }
  .resources-list .resource-item a .resource-region-1 .resource-person .person-image {
    max-height: 120px;
  }
  .resources-list .resource-item a .resource-region-1 .resource-person .person-title {
    clear: none;
    padding-top: 90px;
  }
}

@media not print {
  .print-only {
    display: none;
  }
}
@media print {
  .print-only {
    display: block;
  }
  a.show-url-on-print:after,
  .show-url-on-print a:after {
    content: " [" attr(href) "] ";
  }
  .iowa-bar--full,
  .nav--horizontal,
  .breadcrumbs,
  .page-region__nav-secondary,
  .footer {
    display: none !important;
  }
  .print-header {
    margin: 1rem 0 2rem 0;
  }
  .print-header .print-header-inner {
    display: flex;
    margin-bottom: 2rem;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .print-header .print--block-iowa {
    flex: 0 0 auto;
    max-width: 160px;
  }
  .print-header .print--org-title {
    flex: 0 0 auto;
    margin: 0 0 0 2rem;
    font-size: 2rem;
  }
  .print-header .print--site-title {
    flex: 0 0 auto;
    margin: 0;
    font-size: 2rem;
  }
  .page-region__banner {
    margin-bottom: 0 !important;
  }
  .page-region__banner .entry-title {
    font-size: 3.6rem !important;
    color: black !important;
    text-shadow: none !important;
  }
  .page-region__banner.banner-image-exists {
    height: auto !important;
    min-height: 0 !important;
    background-color: white !important;
  }
  .page-region__banner.banner-image-exists .banner__image {
    display: none;
  }
  .print-footer {
    font-size: 1.2rem;
  }
  .print-footer .print--block-iowa {
    float: right;
    margin-top: 1.5rem;
    padding-left: 4rem;
    height: 4.5rem;
  }
  .print-footer .print--footer-content {
    margin-top: 2rem;
  }
}
@media print {
  #printheader {
    display: block;
  }
  #printheader .printheader-inner {
    margin: 0 auto;
    max-width: 1150px;
  }
  .entry-title-outer {
    background-color: white !important;
    font-family: "Zilla Slab" !important;
    font-weight: 700 !important;
  }
  .all-pages-alert {
    display: none;
  }
  .home.site-staff .moreinfo {
    display: none;
  }
}
.logged-in-eyes-only {
  display: none;
}

.logged-in .logged-in-eyes-only {
  display: block;
}

.page__container .page-region__content-primary h2 {
  margin-bottom: 1rem;
  padding: 0;
  font-family: "Zilla Slab", "Roboto", serif;
  font-weight: 600;
  font-size: 27.5px;
  font-size: 2.75rem;
  line-height: 1.5;
}
.page__container .page-region__content-primary h2 + p, .page__container .page-region__content-primary h2 + ul, .page__container .page-region__content-primary h2 + ol {
  margin-top: 0;
}
.page__container .page-region__content-primary h2 strong, .page__container .page-region__content-primary h2 b {
  font-weight: 600;
}
@media (min-width: 855px) {
  .page__container .page-region__content-primary h2 {
    font-size: 32.5px;
    font-size: 3.25rem;
    line-height: 1.5;
  }
}
@media (min-width: 1150px) {
  .page__container .page-region__content-primary h2 {
    font-size: 37.5px;
    font-size: 3.75rem;
    line-height: 1.5;
  }
}
.page__container h3 {
  margin-bottom: 1rem;
  padding: 0;
  font-family: "Zilla Slab", "Roboto", serif;
  font-weight: 600;
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 1.5;
}
.page__container h3 + p, .page__container h3 + ul, .page__container h3 + ol {
  margin-top: 0;
}
.page__container h3 strong, .page__container h3 b {
  font-weight: 600;
}
@media (min-width: 855px) {
  .page__container h3 {
    font-size: 27.5px;
    font-size: 2.75rem;
    line-height: 1.5;
  }
}
@media (min-width: 1150px) {
  .page__container h3 {
    font-size: 30px;
    font-size: 3rem;
    line-height: 1.5;
  }
}
.page__container h4 {
  margin-bottom: 1rem;
  padding: 0;
  font-family: "Roboto", "Roboto Condensed", sans-serif;
  font-weight: 600;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
}
.page__container h4 + p, .page__container h4 + ul, .page__container h4 + ol {
  margin-top: 0;
}
.page__container h4 strong, .page__container h4 b {
  font-weight: 600;
}
@media (min-width: 855px) {
  .page__container h4 {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.5;
  }
}
@media (min-width: 1150px) {
  .page__container h4 {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.5;
  }
}
.uids-button, ul.button-grid li a, ul.button-grid--3 li a,
ul.button-list--3 li a, ul.button-grid--2 li a,
ul.button-list--2 li a,
ul.button-list li a, .uids-button--card-cta, .uids-card .card-body .card-button, .uids-button--tertiary, .uids-button--secondary, .uids-button--primary, .gform_footer input[type=submit].gform_button.button,
.wp-block-button a {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0.7em 3rem 0.55em 3rem;
  background-color: #ffcd00;
  color: black !important;
  font-family: "Antonio", "Antonio-fallback", sans-serif;
  font-variation-settings: "wght" 500;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 2rem;
  transition: 0.1s;
  outline-offset: -2px;
  border: 0;
}
.uids-button:after, ul.button-grid li a:after, ul.button-grid--3 li a:after,
ul.button-list--3 li a:after, ul.button-grid--2 li a:after,
ul.button-list--2 li a:after,
ul.button-list li a:after, .uids-button--card-cta:after, .uids-card .card-body .card-button:after, .uids-button--tertiary:after, .uids-button--secondary:after, .uids-button--primary:after, .gform_footer input[type=submit].gform_button.button:after,
.wp-block-button a:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  height: 4px;
  background-color: black;
  transform-origin: center;
  transform: translate(-50%, 0) scaleX(0);
  transition: transform 0.3s ease-in-out;
}
.uids-button:hover, ul.button-grid li a:hover, ul.button-grid--3 li a:hover,
ul.button-list--3 li a:hover, ul.button-grid--2 li a:hover,
ul.button-list--2 li a:hover,
ul.button-list li a:hover, .uids-button--card-cta:hover, .uids-card .card-body .card-button:hover, .uids-button--tertiary:hover, .uids-button--secondary:hover, .uids-button--primary:hover, .gform_footer input[type=submit].gform_button.button:hover,
.wp-block-button a:hover {
  text-decoration: none;
  background-color: #ffcd00;
  color: black;
}
.uids-button:hover:after, ul.button-grid li a:hover:after, ul.button-grid--3 li a:hover:after,
ul.button-list--3 li a:hover:after, ul.button-grid--2 li a:hover:after,
ul.button-list--2 li a:hover:after,
ul.button-list li a:hover:after, .uids-button--card-cta:hover:after, .uids-card .card-body .card-button:hover:after, .uids-button--tertiary:hover:after, .uids-button--secondary:hover:after, .uids-button--primary:hover:after, .gform_footer input[type=submit].gform_button.button:hover:after,
.wp-block-button a:hover:after {
  transform: translate(-50%, 0) scaleX(1);
}
.uids-button i, ul.button-grid li a i, ul.button-grid--3 li a i,
ul.button-list--3 li a i, ul.button-grid--2 li a i,
ul.button-list--2 li a i,
ul.button-list li a i, .uids-button--card-cta i, .uids-card .card-body .card-button i, .uids-button--tertiary i, .uids-button--secondary i, .uids-button--primary i, .gform_footer input[type=submit].gform_button.button i,
.wp-block-button a i {
  margin: 0 0 0 0.5em;
  color: black;
  vertical-align: middle;
}
.uids-button i.icon-first, ul.button-grid li a i.icon-first, ul.button-grid--3 li a i.icon-first,
ul.button-list--3 li a i.icon-first, ul.button-grid--2 li a i.icon-first,
ul.button-list--2 li a i.icon-first,
ul.button-list li a i.icon-first, .uids-button--card-cta i.icon-first, .uids-card .card-body .card-button i.icon-first, .uids-button--tertiary i.icon-first, .uids-button--secondary i.icon-first, .uids-button--primary i.icon-first, .gform_footer input[type=submit].gform_button.button i.icon-first,
.wp-block-button a i.icon-first {
  margin: 0 0.5em 0 0;
}
.uids-button i:before, ul.button-grid li a i:before, ul.button-grid--3 li a i:before,
ul.button-list--3 li a i:before, ul.button-grid--2 li a i:before,
ul.button-list--2 li a i:before,
ul.button-list li a i:before, .uids-button--card-cta i:before, .uids-card .card-body .card-button i:before, .uids-button--tertiary i:before, .uids-button--secondary i:before, .uids-button--primary i:before, .gform_footer input[type=submit].gform_button.button i:before,
.wp-block-button a i:before {
  display: inline-block;
  margin-bottom: 4px;
}
.uids-button:focus, ul.button-grid li a:focus, ul.button-grid--3 li a:focus,
ul.button-list--3 li a:focus, ul.button-grid--2 li a:focus,
ul.button-list--2 li a:focus,
ul.button-list li a:focus, .uids-button--card-cta:focus, .uids-card .card-body .card-button:focus, .uids-button--tertiary:focus, .uids-button--secondary:focus, .uids-button--primary:focus, .gform_footer input[type=submit].gform_button.button:focus,
.wp-block-button a:focus {
  text-decoration: none;
  outline: 2px solid #00558c;
}
.uids-button--align-right,
.wp-block-button a--align-right {
  float: right;
}
.uids-button--small-font,
.wp-block-button a--small-font {
  font-size: 1.8rem;
}
.uids-button--smaller-font,
.wp-block-button a--smaller-font {
  font-size: 1.6rem;
}
.uids-button--large-font,
.wp-block-button a--large-font {
  font-size: 2.4rem;
}
.uids-button--larger-font,
.wp-block-button a--larger-font {
  font-size: 3.2rem;
}
.uids-button--norm-case,
.wp-block-button a--norm-case {
  text-transform: none;
}
.uids-button--full-width,
.wp-block-button a--full-width {
  width: calc(100% - 6rem);
}
.uids-button--body-font,
.wp-block-button a--body-font {
  font-family: "Roboto Condensed", sans-serif;
}
.uids-button--simple,
.wp-block-button a--simple {
  font-family: "Roboto", sans-serif;
  text-transform: none;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
}

.uids-button--secondary {
  background-color: black;
  color: white !important;
}
.uids-button--secondary:after {
  background-color: #ffcd00 !important;
}
.uids-button--secondary:hover {
  background-color: black !important;
  color: white;
}
.uids-button--secondary i {
  color: #ffcd00;
}

.uids-button--tertiary {
  background-color: #f2f3f3;
  color: black !important;
  border: 1px solid black !important;
}
.uids-button--tertiary:after {
  background-color: #ffcd00 !important;
}
.uids-button--tertiary:hover {
  background-color: #f2f3f3 !important;
  color: black;
}
.uids-button--tertiary i {
  color: #ffcd00;
}

.uids-button--card-cta, .uids-card .card-body .card-button {
  background-color: transparent;
  color: black;
  border: 1px solid black;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  line-height: 20px;
  border-color: #ffcd00 !important;
}
.uids-button--card-cta:after, .uids-card .card-body .card-button:after {
  background-color: #ffcd00 !important;
}
.uids-button--card-cta:hover, .uids-card .card-body .card-button:hover {
  background-color: transparent;
  color: black;
}
.uids-button--card-cta i, .uids-card .card-body .card-button i {
  color: #ffcd00;
}

ul.button-grid, ul.button-grid--3,
ul.button-list--3, ul.button-grid--2,
ul.button-list--2,
ul.button-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  gap: 1rem;
}
ul.button-grid li, ul.button-grid--3 li,
ul.button-list--3 li, ul.button-grid--2 li,
ul.button-list--2 li,
ul.button-list li {
  flex-basis: 100%;
}
ul.button-grid li a, ul.button-grid--3 li a,
ul.button-list--3 li a, ul.button-grid--2 li a,
ul.button-list--2 li a,
ul.button-list li a {
  display: block;
  font-family: "Antonio", sans-serif;
  font-variation-settings: "wght" 500;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
  text-transform: none;
  transition: 0.2s;
}
ul.button-grid li a i:before, ul.button-grid--3 li a i:before,
ul.button-list--3 li a i:before, ul.button-grid--2 li a i:before,
ul.button-list--2 li a i:before,
ul.button-list li a i:before {
  color: black;
}

@media (min-width: 855px) {
  ul.button-grid--2,
  ul.button-list--2 {
    flex-flow: row wrap;
  }
  ul.button-grid--2 li,
  ul.button-list--2 li {
    flex-basis: calc((100% - 1rem) / 2);
  }
}

@media (min-width: 855px) {
  ul.button-grid--3,
  ul.button-list--3 {
    flex-flow: row wrap;
  }
  ul.button-grid--3 li,
  ul.button-list--3 li {
    flex-basis: calc((100% - 1rem) / 2);
  }
}
@media (min-width: 1150px) {
  ul.button-grid--3 li,
  ul.button-list--3 li {
    flex-basis: calc((100% - 2rem) / 3);
  }
}

.wp-block-button > a {
  border-radius: 0;
  font-family: "Roboto", sans-serif;
  text-transform: none;
  font-weight: 500;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
}

.uids-card {
  --card-bg-color: white;
  --card-border-color: rgba(0, 0, 0, 0.125);
  --card-border-width: 1px;
  --card-padding: 0;
  --card-media-margin: 0 0 1rem 0;
  --card-media-width: 100%;
  --card-media-aspect: auto;
  --card-media-position: auto;
  --card-body-padding-top: 0;
  --card-body-padding-right: 1rem;
  --card-body-padding-bottom: 1rem;
  --card-body-padding-left: 1rem;
  --card-body-text-align: start;
  --card-body-text-color: black;
  --card-title-font: Roboto, sans-serif;
  --card-title-size: 2rem;
  --card-title-weight: bold;
  --card-title-color: black;
  --card-title-margin-top: 0;
  --card-title-margin-bottom: 0;
  --card-desc-link-color: #00558c;
  --card-desc-link-visited-color: #00558c;
  --card-desc-link-hover-color: #003659;
  --card-desc-link-active-color: #00080d;
  --card-desc-link-focus-color: #00080d;
  --card-desc-margin: 0 0 0 0;
  --card-button-margin-top: auto;
  --card-button-margin-right: auto;
  --card-button-margin-bottom: 0;
  --card-button-margin-left: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  flex-basis: 100%;
  margin: 0;
  padding: var(--card-padding);
  border: var(--card-border-width) solid var(--card-border-color) !important;
  box-sizing: border-box;
  background-color: var(--card-bg-color) !important;
}
.uids-card .card-media {
  order: 0;
  margin: var(--card-media-margin);
  padding: 0;
  width: var(--card-media-width);
}
.uids-card .card-media img {
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: var(--card-media-position);
  aspect-ratio: var(--card-media-aspect);
}
.uids-card .card-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  order: 1;
  padding: var(--card-body-padding-top) var(--card-body-padding-right) var(--card-body-padding-bottom) var(--card-body-padding-left);
  text-align: var(--card-body-text-align);
  color: var(--card-body-text-color);
}
.uids-card .card-body .card-title {
  margin-top: var(--card-title-margin-top);
  margin-bottom: var(--card-title-margin-bottom);
  font-family: var(--card-title-font);
  font-size: var(--card-title-size);
  font-weight: var(--card-title-weight);
}
.uids-card .card-body .card-title a {
  color: var(--card-title-color);
  text-decoration: none;
}
.uids-card .card-body .card-title a:hover {
  text-decoration: underline;
}
.uids-card .card-body .card-desc {
  margin: var(--card-desc-margin);
}
.uids-card .card-body .card-desc > p, .uids-card .card-body .card-desc > ul {
  margin-top: 0;
}
.uids-card .card-body .card-desc a {
  color: var(--card-desc-link-color);
}
.uids-card .card-body .card-desc a:visited {
  color: var(--card-desc-link-visited-color);
}
.uids-card .card-body .card-desc a:hover {
  color: var(--card-desc-link-hover-color);
}
.uids-card .card-body .card-desc a:active {
  color: var(--card-desc-link-active-color);
}
.uids-card .card-body .card-desc a:focus {
  color: var(--card-desc-link-focus-color);
}
.uids-card .card-body .card-button {
  display: inline-block;
  margin: var(--card-button-margin-top) var(--card-button-margin-right) var(--card-button-margin-bottom) var(--card-button-margin-left);
  padding: 1.5rem;
  background-color: transparent;
  transition: 0.1s;
}
.uids-card .card-body .card-button.no-link-text {
  display: inline-block;
  width: 2rem;
  border-radius: 4rem;
}
.uids-card .card-body .card-button.no-link-text i {
  margin-left: 0;
}
.uids-card .card-body .card-button.no-link-text i:before {
  color: black;
  transform: scale(1.1);
}
.uids-card .card-body .card-button.no-link-text:hover {
  outline: 4px solid #ffcd00;
}
.uids-card .card-body .card-button.no-link-text:hover:after {
  display: none;
}
.card-bg--gold .uids-card .card-body .card-button.no-link-text:hover {
  outline: 4px solid #fff;
}
.uids-card .card-body .card-button.no-link-url {
  display: none;
}
.uids-card .card-body a.card-button,
.uids-card .card-body a.card-button.no-link-text {
  display: none;
}

.card-list.card-media--square .uids-card, .card-media--square.card-list--6 .uids-card, .card-media--square.card-list--5 .uids-card, .card-media--square.card-list--4 .uids-card, .card-media--square.card-list--3 .uids-card, .card-media--square.card-list--2 .uids-card,
.uids-card.card-media--square {
  --card-media-aspect: 1 / 1;
}

.card-list.card-media--wide .uids-card, .card-media--wide.card-list--6 .uids-card, .card-media--wide.card-list--5 .uids-card, .card-media--wide.card-list--4 .uids-card, .card-media--wide.card-list--3 .uids-card, .card-media--wide.card-list--2 .uids-card,
.uids-card.card-media--wide {
  --card-media-aspect: 1.618 / 1;
}

.card-list.card-media--circle .uids-card, .card-media--circle.card-list--6 .uids-card, .card-media--circle.card-list--5 .uids-card, .card-media--circle.card-list--4 .uids-card, .card-media--circle.card-list--3 .uids-card, .card-media--circle.card-list--2 .uids-card,
.uids-card.card-media--circle {
  --card-media-aspect: 1 / 1;
}
.card-list.card-media--circle .uids-card .card-media img, .card-media--circle.card-list--6 .uids-card .card-media img, .card-media--circle.card-list--5 .uids-card .card-media img, .card-media--circle.card-list--4 .uids-card .card-media img, .card-media--circle.card-list--3 .uids-card .card-media img, .card-media--circle.card-list--2 .uids-card .card-media img,
.uids-card.card-media--circle .card-media img {
  border-radius: 50%;
}

.card-list.card-media--align-top .uids-card, .card-media--align-top.card-list--6 .uids-card, .card-media--align-top.card-list--5 .uids-card, .card-media--align-top.card-list--4 .uids-card, .card-media--align-top.card-list--3 .uids-card, .card-media--align-top.card-list--2 .uids-card,
.uids-card.card-media--align-top {
  --card-media-position: top;
}

.card-list.card-media--align-bottom .uids-card, .card-media--align-bottom.card-list--6 .uids-card, .card-media--align-bottom.card-list--5 .uids-card, .card-media--align-bottom.card-list--4 .uids-card, .card-media--align-bottom.card-list--3 .uids-card, .card-media--align-bottom.card-list--2 .uids-card,
.uids-card.card-media--align-bottom {
  --card-media-position: bottom;
}

.card-list.card-button--no-valign .uids-card, .card-button--no-valign.card-list--6 .uids-card, .card-button--no-valign.card-list--5 .uids-card, .card-button--no-valign.card-list--4 .uids-card, .card-button--no-valign.card-list--3 .uids-card, .card-button--no-valign.card-list--2 .uids-card,
.uids-card.card-button--no-valign {
  --card-button-margin-top: 0;
}

.card-list.card-button--full-width .uids-card, .card-button--full-width.card-list--6 .uids-card, .card-button--full-width.card-list--5 .uids-card, .card-button--full-width.card-list--4 .uids-card, .card-button--full-width.card-list--3 .uids-card, .card-button--full-width.card-list--2 .uids-card,
.uids-card.card-button--full-width {
  --card-button-margin-right: 0;
  --card-button-margin-left: 0;
}

.card-list.card-button--hide .uids-card .card-body .card-button, .card-button--hide.card-list--6 .uids-card .card-body .card-button, .card-button--hide.card-list--5 .uids-card .card-body .card-button, .card-button--hide.card-list--4 .uids-card .card-body .card-button, .card-button--hide.card-list--3 .uids-card .card-body .card-button, .card-button--hide.card-list--2 .uids-card .card-body .card-button,
.uids-card.card-button--hide .card-body .card-button {
  display: none;
}

.card-list.card-title--serif .uids-card, .card-title--serif.card-list--6 .uids-card, .card-title--serif.card-list--5 .uids-card, .card-title--serif.card-list--4 .uids-card, .card-title--serif.card-list--3 .uids-card, .card-title--serif.card-list--2 .uids-card,
.uids-card.card-title--serif {
  --card-title-font: Zilla Slab, serif;
  --card-title-size: 2.2rem;
  --card-title-weight: 700;
}

.card-list.card-title--hide .uids-card, .card-title--hide.card-list--6 .uids-card, .card-title--hide.card-list--5 .uids-card, .card-title--hide.card-list--4 .uids-card, .card-title--hide.card-list--3 .uids-card, .card-title--hide.card-list--2 .uids-card,
.uids-card.card-title--hide {
  --card-body-padding-top: 1rem;
}
.card-list.card-title--hide .uids-card .card-title, .card-title--hide.card-list--6 .uids-card .card-title, .card-title--hide.card-list--5 .uids-card .card-title, .card-title--hide.card-list--4 .uids-card .card-title, .card-title--hide.card-list--3 .uids-card .card-title, .card-title--hide.card-list--2 .uids-card .card-title,
.uids-card.card-title--hide .card-title {
  position: absolute;
  left: -10000px;
  top: auto;
  height: 1px;
  overflow: hidden;
  width: 1px;
}

.card-list.card-title--size-smaller .uids-card, .card-title--size-smaller.card-list--6 .uids-card, .card-title--size-smaller.card-list--5 .uids-card, .card-title--size-smaller.card-list--4 .uids-card, .card-title--size-smaller.card-list--3 .uids-card, .card-title--size-smaller.card-list--2 .uids-card,
.uids-card.card-title--size-smaller {
  --card-title-size: 1.8rem;
}

.card-list.card-title--size-smallest .uids-card, .card-title--size-smallest.card-list--6 .uids-card, .card-title--size-smallest.card-list--5 .uids-card, .card-title--size-smallest.card-list--4 .uids-card, .card-title--size-smallest.card-list--3 .uids-card, .card-title--size-smallest.card-list--2 .uids-card,
.uids-card.card-title--size-smallest {
  --card-title-size: 1.6rem;
}

.card-list.card-desc--none .uids-card .card-body .card-desc, .card-desc--none.card-list--6 .uids-card .card-body .card-desc, .card-desc--none.card-list--5 .uids-card .card-body .card-desc, .card-desc--none.card-list--4 .uids-card .card-body .card-desc, .card-desc--none.card-list--3 .uids-card .card-body .card-desc, .card-desc--none.card-list--2 .uids-card .card-body .card-desc,
.uids-card.card-desc--none .card-body .card-desc {
  display: none;
}

.card-list.card-border--none .uids-card, .card-border--none.card-list--6 .uids-card, .card-border--none.card-list--5 .uids-card, .card-border--none.card-list--4 .uids-card, .card-border--none.card-list--3 .uids-card, .card-border--none.card-list--2 .uids-card,
.uids-card.card-border--none,
.card-list.card-border--alt-odd .uids-card:nth-child(even),
.card-border--alt-odd.card-list--6 .uids-card:nth-child(even),
.card-border--alt-odd.card-list--5 .uids-card:nth-child(even),
.card-border--alt-odd.card-list--4 .uids-card:nth-child(even),
.card-border--alt-odd.card-list--3 .uids-card:nth-child(even),
.card-border--alt-odd.card-list--2 .uids-card:nth-child(even),
.card-list.card-border--alt-even .uids-card:nth-child(odd),
.card-border--alt-even.card-list--6 .uids-card:nth-child(odd),
.card-border--alt-even.card-list--5 .uids-card:nth-child(odd),
.card-border--alt-even.card-list--4 .uids-card:nth-child(odd),
.card-border--alt-even.card-list--3 .uids-card:nth-child(odd),
.card-border--alt-even.card-list--2 .uids-card:nth-child(odd) {
  --card-border-color: rgba(0, 0, 0, 0);
  --card-border-width: 0;
  --card-body-padding-right: 0;
  --card-body-padding-left: 0;
}

.card-list.card-bg--black .uids-card, .card-bg--black.card-list--6 .uids-card, .card-bg--black.card-list--5 .uids-card, .card-bg--black.card-list--4 .uids-card, .card-bg--black.card-list--3 .uids-card, .card-bg--black.card-list--2 .uids-card,
.uids-card.card-bg--black,
.card-list.card-bg--black-odd .uids-card:nth-child(odd),
.card-bg--black-odd.card-list--6 .uids-card:nth-child(odd),
.card-bg--black-odd.card-list--5 .uids-card:nth-child(odd),
.card-bg--black-odd.card-list--4 .uids-card:nth-child(odd),
.card-bg--black-odd.card-list--3 .uids-card:nth-child(odd),
.card-bg--black-odd.card-list--2 .uids-card:nth-child(odd),
.card-list.card-bg--black-even .uids-card:nth-child(even),
.card-bg--black-even.card-list--6 .uids-card:nth-child(even),
.card-bg--black-even.card-list--5 .uids-card:nth-child(even),
.card-bg--black-even.card-list--4 .uids-card:nth-child(even),
.card-bg--black-even.card-list--3 .uids-card:nth-child(even),
.card-bg--black-even.card-list--2 .uids-card:nth-child(even) {
  --card-bg-color: black;
  --card-border-color: black;
  --card-body-padding-right: 1rem;
  --card-body-padding-left: 1rem;
  --card-body-text-color: white;
  --card-title-color: white;
  --card-desc-link-color: white;
  --card-desc-link-visited-color: #white;
  --card-desc-link-hover-color: #ccc;
  --card-desc-link-active-color: white;
  --card-desc-link-focus-color: white;
}
.card-list.card-bg--black .uids-card .card-body .card-button, .card-bg--black.card-list--6 .uids-card .card-body .card-button, .card-bg--black.card-list--5 .uids-card .card-body .card-button, .card-bg--black.card-list--4 .uids-card .card-body .card-button, .card-bg--black.card-list--3 .uids-card .card-body .card-button, .card-bg--black.card-list--2 .uids-card .card-body .card-button,
.uids-card.card-bg--black .card-body .card-button,
.card-list.card-bg--black-odd .uids-card:nth-child(odd) .card-body .card-button,
.card-bg--black-odd.card-list--6 .uids-card:nth-child(odd) .card-body .card-button,
.card-bg--black-odd.card-list--5 .uids-card:nth-child(odd) .card-body .card-button,
.card-bg--black-odd.card-list--4 .uids-card:nth-child(odd) .card-body .card-button,
.card-bg--black-odd.card-list--3 .uids-card:nth-child(odd) .card-body .card-button,
.card-bg--black-odd.card-list--2 .uids-card:nth-child(odd) .card-body .card-button,
.card-list.card-bg--black-even .uids-card:nth-child(even) .card-body .card-button,
.card-bg--black-even.card-list--6 .uids-card:nth-child(even) .card-body .card-button,
.card-bg--black-even.card-list--5 .uids-card:nth-child(even) .card-body .card-button,
.card-bg--black-even.card-list--4 .uids-card:nth-child(even) .card-body .card-button,
.card-bg--black-even.card-list--3 .uids-card:nth-child(even) .card-body .card-button,
.card-bg--black-even.card-list--2 .uids-card:nth-child(even) .card-body .card-button {
  color: white !important;
  border-color: white !important;
}
.card-list.card-bg--black .uids-card .card-body .card-button i:before, .card-bg--black.card-list--6 .uids-card .card-body .card-button i:before, .card-bg--black.card-list--5 .uids-card .card-body .card-button i:before, .card-bg--black.card-list--4 .uids-card .card-body .card-button i:before, .card-bg--black.card-list--3 .uids-card .card-body .card-button i:before, .card-bg--black.card-list--2 .uids-card .card-body .card-button i:before,
.uids-card.card-bg--black .card-body .card-button i:before,
.card-list.card-bg--black-odd .uids-card:nth-child(odd) .card-body .card-button i:before,
.card-bg--black-odd.card-list--6 .uids-card:nth-child(odd) .card-body .card-button i:before,
.card-bg--black-odd.card-list--5 .uids-card:nth-child(odd) .card-body .card-button i:before,
.card-bg--black-odd.card-list--4 .uids-card:nth-child(odd) .card-body .card-button i:before,
.card-bg--black-odd.card-list--3 .uids-card:nth-child(odd) .card-body .card-button i:before,
.card-bg--black-odd.card-list--2 .uids-card:nth-child(odd) .card-body .card-button i:before,
.card-list.card-bg--black-even .uids-card:nth-child(even) .card-body .card-button i:before,
.card-bg--black-even.card-list--6 .uids-card:nth-child(even) .card-body .card-button i:before,
.card-bg--black-even.card-list--5 .uids-card:nth-child(even) .card-body .card-button i:before,
.card-bg--black-even.card-list--4 .uids-card:nth-child(even) .card-body .card-button i:before,
.card-bg--black-even.card-list--3 .uids-card:nth-child(even) .card-body .card-button i:before,
.card-bg--black-even.card-list--2 .uids-card:nth-child(even) .card-body .card-button i:before {
  color: #ffcd00;
}
.card-list.card-bg--black .uids-card .card-body .card-button.no-link-text i:before, .card-bg--black.card-list--6 .uids-card .card-body .card-button.no-link-text i:before, .card-bg--black.card-list--5 .uids-card .card-body .card-button.no-link-text i:before, .card-bg--black.card-list--4 .uids-card .card-body .card-button.no-link-text i:before, .card-bg--black.card-list--3 .uids-card .card-body .card-button.no-link-text i:before, .card-bg--black.card-list--2 .uids-card .card-body .card-button.no-link-text i:before,
.uids-card.card-bg--black .card-body .card-button.no-link-text i:before,
.card-list.card-bg--black-odd .uids-card:nth-child(odd) .card-body .card-button.no-link-text i:before,
.card-bg--black-odd.card-list--6 .uids-card:nth-child(odd) .card-body .card-button.no-link-text i:before,
.card-bg--black-odd.card-list--5 .uids-card:nth-child(odd) .card-body .card-button.no-link-text i:before,
.card-bg--black-odd.card-list--4 .uids-card:nth-child(odd) .card-body .card-button.no-link-text i:before,
.card-bg--black-odd.card-list--3 .uids-card:nth-child(odd) .card-body .card-button.no-link-text i:before,
.card-bg--black-odd.card-list--2 .uids-card:nth-child(odd) .card-body .card-button.no-link-text i:before,
.card-list.card-bg--black-even .uids-card:nth-child(even) .card-body .card-button.no-link-text i:before,
.card-bg--black-even.card-list--6 .uids-card:nth-child(even) .card-body .card-button.no-link-text i:before,
.card-bg--black-even.card-list--5 .uids-card:nth-child(even) .card-body .card-button.no-link-text i:before,
.card-bg--black-even.card-list--4 .uids-card:nth-child(even) .card-body .card-button.no-link-text i:before,
.card-bg--black-even.card-list--3 .uids-card:nth-child(even) .card-body .card-button.no-link-text i:before,
.card-bg--black-even.card-list--2 .uids-card:nth-child(even) .card-body .card-button.no-link-text i:before {
  color: white;
}

.card-list.card-bg--gold .uids-card, .card-bg--gold.card-list--6 .uids-card, .card-bg--gold.card-list--5 .uids-card, .card-bg--gold.card-list--4 .uids-card, .card-bg--gold.card-list--3 .uids-card, .card-bg--gold.card-list--2 .uids-card,
.uids-card.card-bg--gold,
.card-list.card-bg--gold-odd .uids-card:nth-child(odd),
.card-bg--gold-odd.card-list--6 .uids-card:nth-child(odd),
.card-bg--gold-odd.card-list--5 .uids-card:nth-child(odd),
.card-bg--gold-odd.card-list--4 .uids-card:nth-child(odd),
.card-bg--gold-odd.card-list--3 .uids-card:nth-child(odd),
.card-bg--gold-odd.card-list--2 .uids-card:nth-child(odd),
.card-list.card-bg--gold-even .uids-card:nth-child(even),
.card-bg--gold-even.card-list--6 .uids-card:nth-child(even),
.card-bg--gold-even.card-list--5 .uids-card:nth-child(even),
.card-bg--gold-even.card-list--4 .uids-card:nth-child(even),
.card-bg--gold-even.card-list--3 .uids-card:nth-child(even),
.card-bg--gold-even.card-list--2 .uids-card:nth-child(even) {
  --card-bg-color: #ffcd00;
  --card-body-padding-right: 1rem;
  --card-body-padding-left: 1rem;
  --card-body-text-color: black;
}
.card-list.card-bg--gold .uids-card .card-body .card-button, .card-bg--gold.card-list--6 .uids-card .card-body .card-button, .card-bg--gold.card-list--5 .uids-card .card-body .card-button, .card-bg--gold.card-list--4 .uids-card .card-body .card-button, .card-bg--gold.card-list--3 .uids-card .card-body .card-button, .card-bg--gold.card-list--2 .uids-card .card-body .card-button,
.uids-card.card-bg--gold .card-body .card-button,
.card-list.card-bg--gold-odd .uids-card:nth-child(odd) .card-body .card-button,
.card-bg--gold-odd.card-list--6 .uids-card:nth-child(odd) .card-body .card-button,
.card-bg--gold-odd.card-list--5 .uids-card:nth-child(odd) .card-body .card-button,
.card-bg--gold-odd.card-list--4 .uids-card:nth-child(odd) .card-body .card-button,
.card-bg--gold-odd.card-list--3 .uids-card:nth-child(odd) .card-body .card-button,
.card-bg--gold-odd.card-list--2 .uids-card:nth-child(odd) .card-body .card-button,
.card-list.card-bg--gold-even .uids-card:nth-child(even) .card-body .card-button,
.card-bg--gold-even.card-list--6 .uids-card:nth-child(even) .card-body .card-button,
.card-bg--gold-even.card-list--5 .uids-card:nth-child(even) .card-body .card-button,
.card-bg--gold-even.card-list--4 .uids-card:nth-child(even) .card-body .card-button,
.card-bg--gold-even.card-list--3 .uids-card:nth-child(even) .card-body .card-button,
.card-bg--gold-even.card-list--2 .uids-card:nth-child(even) .card-body .card-button {
  color: black !important;
  border: 1px solid black !important;
}
.card-list.card-bg--gold .uids-card .card-body .card-button:after, .card-bg--gold.card-list--6 .uids-card .card-body .card-button:after, .card-bg--gold.card-list--5 .uids-card .card-body .card-button:after, .card-bg--gold.card-list--4 .uids-card .card-body .card-button:after, .card-bg--gold.card-list--3 .uids-card .card-body .card-button:after, .card-bg--gold.card-list--2 .uids-card .card-body .card-button:after,
.uids-card.card-bg--gold .card-body .card-button:after,
.card-list.card-bg--gold-odd .uids-card:nth-child(odd) .card-body .card-button:after,
.card-bg--gold-odd.card-list--6 .uids-card:nth-child(odd) .card-body .card-button:after,
.card-bg--gold-odd.card-list--5 .uids-card:nth-child(odd) .card-body .card-button:after,
.card-bg--gold-odd.card-list--4 .uids-card:nth-child(odd) .card-body .card-button:after,
.card-bg--gold-odd.card-list--3 .uids-card:nth-child(odd) .card-body .card-button:after,
.card-bg--gold-odd.card-list--2 .uids-card:nth-child(odd) .card-body .card-button:after,
.card-list.card-bg--gold-even .uids-card:nth-child(even) .card-body .card-button:after,
.card-bg--gold-even.card-list--6 .uids-card:nth-child(even) .card-body .card-button:after,
.card-bg--gold-even.card-list--5 .uids-card:nth-child(even) .card-body .card-button:after,
.card-bg--gold-even.card-list--4 .uids-card:nth-child(even) .card-body .card-button:after,
.card-bg--gold-even.card-list--3 .uids-card:nth-child(even) .card-body .card-button:after,
.card-bg--gold-even.card-list--2 .uids-card:nth-child(even) .card-body .card-button:after {
  background-color: white !important;
}
.card-list.card-bg--gold .uids-card .card-body .card-button i:before, .card-bg--gold.card-list--6 .uids-card .card-body .card-button i:before, .card-bg--gold.card-list--5 .uids-card .card-body .card-button i:before, .card-bg--gold.card-list--4 .uids-card .card-body .card-button i:before, .card-bg--gold.card-list--3 .uids-card .card-body .card-button i:before, .card-bg--gold.card-list--2 .uids-card .card-body .card-button i:before,
.uids-card.card-bg--gold .card-body .card-button i:before,
.card-list.card-bg--gold-odd .uids-card:nth-child(odd) .card-body .card-button i:before,
.card-bg--gold-odd.card-list--6 .uids-card:nth-child(odd) .card-body .card-button i:before,
.card-bg--gold-odd.card-list--5 .uids-card:nth-child(odd) .card-body .card-button i:before,
.card-bg--gold-odd.card-list--4 .uids-card:nth-child(odd) .card-body .card-button i:before,
.card-bg--gold-odd.card-list--3 .uids-card:nth-child(odd) .card-body .card-button i:before,
.card-bg--gold-odd.card-list--2 .uids-card:nth-child(odd) .card-body .card-button i:before,
.card-list.card-bg--gold-even .uids-card:nth-child(even) .card-body .card-button i:before,
.card-bg--gold-even.card-list--6 .uids-card:nth-child(even) .card-body .card-button i:before,
.card-bg--gold-even.card-list--5 .uids-card:nth-child(even) .card-body .card-button i:before,
.card-bg--gold-even.card-list--4 .uids-card:nth-child(even) .card-body .card-button i:before,
.card-bg--gold-even.card-list--3 .uids-card:nth-child(even) .card-body .card-button i:before,
.card-bg--gold-even.card-list--2 .uids-card:nth-child(even) .card-body .card-button i:before {
  color: white;
}
.card-list.card-bg--gold .uids-card .card-body .card-button.no-link-text i:before, .card-bg--gold.card-list--6 .uids-card .card-body .card-button.no-link-text i:before, .card-bg--gold.card-list--5 .uids-card .card-body .card-button.no-link-text i:before, .card-bg--gold.card-list--4 .uids-card .card-body .card-button.no-link-text i:before, .card-bg--gold.card-list--3 .uids-card .card-body .card-button.no-link-text i:before, .card-bg--gold.card-list--2 .uids-card .card-body .card-button.no-link-text i:before,
.uids-card.card-bg--gold .card-body .card-button.no-link-text i:before,
.card-list.card-bg--gold-odd .uids-card:nth-child(odd) .card-body .card-button.no-link-text i:before,
.card-bg--gold-odd.card-list--6 .uids-card:nth-child(odd) .card-body .card-button.no-link-text i:before,
.card-bg--gold-odd.card-list--5 .uids-card:nth-child(odd) .card-body .card-button.no-link-text i:before,
.card-bg--gold-odd.card-list--4 .uids-card:nth-child(odd) .card-body .card-button.no-link-text i:before,
.card-bg--gold-odd.card-list--3 .uids-card:nth-child(odd) .card-body .card-button.no-link-text i:before,
.card-bg--gold-odd.card-list--2 .uids-card:nth-child(odd) .card-body .card-button.no-link-text i:before,
.card-list.card-bg--gold-even .uids-card:nth-child(even) .card-body .card-button.no-link-text i:before,
.card-bg--gold-even.card-list--6 .uids-card:nth-child(even) .card-body .card-button.no-link-text i:before,
.card-bg--gold-even.card-list--5 .uids-card:nth-child(even) .card-body .card-button.no-link-text i:before,
.card-bg--gold-even.card-list--4 .uids-card:nth-child(even) .card-body .card-button.no-link-text i:before,
.card-bg--gold-even.card-list--3 .uids-card:nth-child(even) .card-body .card-button.no-link-text i:before,
.card-bg--gold-even.card-list--2 .uids-card:nth-child(even) .card-body .card-button.no-link-text i:before {
  color: black;
}
.card-list.card-bg--gold .uids-card .card-body .card-button.no-link-text:hover, .card-bg--gold.card-list--6 .uids-card .card-body .card-button.no-link-text:hover, .card-bg--gold.card-list--5 .uids-card .card-body .card-button.no-link-text:hover, .card-bg--gold.card-list--4 .uids-card .card-body .card-button.no-link-text:hover, .card-bg--gold.card-list--3 .uids-card .card-body .card-button.no-link-text:hover, .card-bg--gold.card-list--2 .uids-card .card-body .card-button.no-link-text:hover,
.uids-card.card-bg--gold .card-body .card-button.no-link-text:hover,
.card-list.card-bg--gold-odd .uids-card:nth-child(odd) .card-body .card-button.no-link-text:hover,
.card-bg--gold-odd.card-list--6 .uids-card:nth-child(odd) .card-body .card-button.no-link-text:hover,
.card-bg--gold-odd.card-list--5 .uids-card:nth-child(odd) .card-body .card-button.no-link-text:hover,
.card-bg--gold-odd.card-list--4 .uids-card:nth-child(odd) .card-body .card-button.no-link-text:hover,
.card-bg--gold-odd.card-list--3 .uids-card:nth-child(odd) .card-body .card-button.no-link-text:hover,
.card-bg--gold-odd.card-list--2 .uids-card:nth-child(odd) .card-body .card-button.no-link-text:hover,
.card-list.card-bg--gold-even .uids-card:nth-child(even) .card-body .card-button.no-link-text:hover,
.card-bg--gold-even.card-list--6 .uids-card:nth-child(even) .card-body .card-button.no-link-text:hover,
.card-bg--gold-even.card-list--5 .uids-card:nth-child(even) .card-body .card-button.no-link-text:hover,
.card-bg--gold-even.card-list--4 .uids-card:nth-child(even) .card-body .card-button.no-link-text:hover,
.card-bg--gold-even.card-list--3 .uids-card:nth-child(even) .card-body .card-button.no-link-text:hover,
.card-bg--gold-even.card-list--2 .uids-card:nth-child(even) .card-body .card-button.no-link-text:hover {
  outline: 4px solid white;
}

.card-list.card-bg--grey .uids-card, .card-bg--grey.card-list--6 .uids-card, .card-bg--grey.card-list--5 .uids-card, .card-bg--grey.card-list--4 .uids-card, .card-bg--grey.card-list--3 .uids-card, .card-bg--grey.card-list--2 .uids-card,
.uids-card.card-bg--grey,
.card-list.card-bg--grey-odd .uids-card:nth-child(odd),
.card-bg--grey-odd.card-list--6 .uids-card:nth-child(odd),
.card-bg--grey-odd.card-list--5 .uids-card:nth-child(odd),
.card-bg--grey-odd.card-list--4 .uids-card:nth-child(odd),
.card-bg--grey-odd.card-list--3 .uids-card:nth-child(odd),
.card-bg--grey-odd.card-list--2 .uids-card:nth-child(odd),
.card-list.card-bg--grey-even .uids-card:nth-child(even),
.card-bg--grey-even.card-list--6 .uids-card:nth-child(even),
.card-bg--grey-even.card-list--5 .uids-card:nth-child(even),
.card-bg--grey-even.card-list--4 .uids-card:nth-child(even),
.card-bg--grey-even.card-list--3 .uids-card:nth-child(even),
.card-bg--grey-even.card-list--2 .uids-card:nth-child(even) {
  --card-bg-color: #f2f3f3;
  --card-body-padding-right: 1rem;
  --card-body-padding-left: 1rem;
}
.card-list.card-bg--grey .uids-card .card-body .card-button, .card-bg--grey.card-list--6 .uids-card .card-body .card-button, .card-bg--grey.card-list--5 .uids-card .card-body .card-button, .card-bg--grey.card-list--4 .uids-card .card-body .card-button, .card-bg--grey.card-list--3 .uids-card .card-body .card-button, .card-bg--grey.card-list--2 .uids-card .card-body .card-button,
.uids-card.card-bg--grey .card-body .card-button,
.card-list.card-bg--grey-odd .uids-card:nth-child(odd) .card-body .card-button,
.card-bg--grey-odd.card-list--6 .uids-card:nth-child(odd) .card-body .card-button,
.card-bg--grey-odd.card-list--5 .uids-card:nth-child(odd) .card-body .card-button,
.card-bg--grey-odd.card-list--4 .uids-card:nth-child(odd) .card-body .card-button,
.card-bg--grey-odd.card-list--3 .uids-card:nth-child(odd) .card-body .card-button,
.card-bg--grey-odd.card-list--2 .uids-card:nth-child(odd) .card-body .card-button,
.card-list.card-bg--grey-even .uids-card:nth-child(even) .card-body .card-button,
.card-bg--grey-even.card-list--6 .uids-card:nth-child(even) .card-body .card-button,
.card-bg--grey-even.card-list--5 .uids-card:nth-child(even) .card-body .card-button,
.card-bg--grey-even.card-list--4 .uids-card:nth-child(even) .card-body .card-button,
.card-bg--grey-even.card-list--3 .uids-card:nth-child(even) .card-body .card-button,
.card-bg--grey-even.card-list--2 .uids-card:nth-child(even) .card-body .card-button {
  color: black !important;
  border: 1px solid #ffcd00 !important;
}

.card-list.card-button--full-width .uids-card .card-body .card-button, .card-button--full-width.card-list--6 .uids-card .card-body .card-button, .card-button--full-width.card-list--5 .uids-card .card-body .card-button, .card-button--full-width.card-list--4 .uids-card .card-body .card-button, .card-button--full-width.card-list--3 .uids-card .card-body .card-button, .card-button--full-width.card-list--2 .uids-card .card-body .card-button,
.uids-card.card-button--full-width .card-body .card-button {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.card-list.card-body--centered .uids-card, .card-body--centered.card-list--6 .uids-card, .card-body--centered.card-list--5 .uids-card, .card-body--centered.card-list--4 .uids-card, .card-body--centered.card-list--3 .uids-card, .card-body--centered.card-list--2 .uids-card,
.uids-card.card-body--centered {
  --card-body-text-align: center;
}
.card-list.card-body--centered .uids-card .card-body .card-button, .card-body--centered.card-list--6 .uids-card .card-body .card-button, .card-body--centered.card-list--5 .uids-card .card-body .card-button, .card-body--centered.card-list--4 .uids-card .card-body .card-button, .card-body--centered.card-list--3 .uids-card .card-body .card-button, .card-body--centered.card-list--2 .uids-card .card-body .card-button,
.uids-card.card-body--centered .card-body .card-button {
  margin-left: auto;
  margin-right: auto;
}

.card-list.card-orientation--left .uids-card, .card-orientation--left.card-list--6 .uids-card, .card-orientation--left.card-list--5 .uids-card, .card-orientation--left.card-list--4 .uids-card, .card-orientation--left.card-list--3 .uids-card, .card-orientation--left.card-list--2 .uids-card,
.uids-card.card-orientation--left {
  --card-media-margin: 0 0 0 0;
  --card-body-padding-top: 1rem;
  --card-body-padding-right: 2rem;
  --card-body-padding-left: 2rem;
  --card-body-padding-bottom: 2rem;
  flex-direction: row;
  justify-content: flex-start;
  flex-basis: 100%;
}
.card-list.card-orientation--left .uids-card .card-media, .card-orientation--left.card-list--6 .uids-card .card-media, .card-orientation--left.card-list--5 .uids-card .card-media, .card-orientation--left.card-list--4 .uids-card .card-media, .card-orientation--left.card-list--3 .uids-card .card-media, .card-orientation--left.card-list--2 .uids-card .card-media,
.uids-card.card-orientation--left .card-media {
  min-width: 33%;
  width: 33%;
}
.card-list.card-orientation--left .uids-card .card-media img, .card-orientation--left.card-list--6 .uids-card .card-media img, .card-orientation--left.card-list--5 .uids-card .card-media img, .card-orientation--left.card-list--4 .uids-card .card-media img, .card-orientation--left.card-list--3 .uids-card .card-media img, .card-orientation--left.card-list--2 .uids-card .card-media img,
.uids-card.card-orientation--left .card-media img {
  height: 100%;
}

.card-list.card-orientation--right .uids-card, .card-orientation--right.card-list--6 .uids-card, .card-orientation--right.card-list--5 .uids-card, .card-orientation--right.card-list--4 .uids-card, .card-orientation--right.card-list--3 .uids-card, .card-orientation--right.card-list--2 .uids-card,
.uids-card.card-orientation--right {
  --card-media-margin: 0 0 0 0;
  --card-body-padding-top: 1rem;
  --card-body-padding-right: 2rem;
  --card-body-padding-left: 2rem;
  --card-body-padding-bottom: 2rem;
  flex-direction: row-reverse;
  justify-content: flex-end;
  flex-basis: 100%;
}
.card-list.card-orientation--right .uids-card .card-media, .card-orientation--right.card-list--6 .uids-card .card-media, .card-orientation--right.card-list--5 .uids-card .card-media, .card-orientation--right.card-list--4 .uids-card .card-media, .card-orientation--right.card-list--3 .uids-card .card-media, .card-orientation--right.card-list--2 .uids-card .card-media,
.uids-card.card-orientation--right .card-media {
  min-width: 33%;
  width: 33%;
}
.card-list.card-orientation--right .uids-card .card-media img, .card-orientation--right.card-list--6 .uids-card .card-media img, .card-orientation--right.card-list--5 .uids-card .card-media img, .card-orientation--right.card-list--4 .uids-card .card-media img, .card-orientation--right.card-list--3 .uids-card .card-media img, .card-orientation--right.card-list--2 .uids-card .card-media img,
.uids-card.card-orientation--right .card-media img {
  height: 100%;
}

.card-list.card-padding--some .uids-card, .card-padding--some.card-list--6 .uids-card, .card-padding--some.card-list--5 .uids-card, .card-padding--some.card-list--4 .uids-card, .card-padding--some.card-list--3 .uids-card, .card-padding--some.card-list--2 .uids-card,
.uids-card.card-padding--some {
  --card-padding: 1rem;
  --card-body-padding-right: 0 !important;
  --card-body-padding-bottom: 0 !important;
  --card-body-padding-left: 0 !important;
}

.card-list.card-padding--more .uids-card, .card-padding--more.card-list--6 .uids-card, .card-padding--more.card-list--5 .uids-card, .card-padding--more.card-list--4 .uids-card, .card-padding--more.card-list--3 .uids-card, .card-padding--more.card-list--2 .uids-card,
.uids-card.card-padding--more {
  --card-padding: 2rem;
  --card-body-padding-right: 0 !important;
  --card-body-padding-bottom: 0 !important;
  --card-body-padding-left: 0 !important;
}

.card-list.card-padding--most .uids-card, .card-padding--most.card-list--6 .uids-card, .card-padding--most.card-list--5 .uids-card, .card-padding--most.card-list--4 .uids-card, .card-padding--most.card-list--3 .uids-card, .card-padding--most.card-list--2 .uids-card,
.uids-card.card-padding--most {
  --card-padding: 3rem;
  --card-body-padding-right: 0 !important;
  --card-body-padding-bottom: 0 !important;
  --card-body-padding-left: 0 !important;
}

.card-list, .card-list--6, .card-list--5, .card-list--4, .card-list--3, .card-list--2 {
  --card-gap: 1rem;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  gap: var(--card-gap);
  margin: 0;
  padding: 0;
}
.card-list .uids-card, .card-list--6 .uids-card, .card-list--5 .uids-card, .card-list--4 .uids-card, .card-list--3 .uids-card, .card-list--2 .uids-card {
  flex-basis: 100%;
}

@media (min-width: 855px) {
  .card-list--2 {
    flex-flow: row wrap;
  }
  .card-list--2 .uids-card {
    flex-basis: calc((100% - var(--card-gap) * 1) / 2);
  }
}

@media (min-width: 855px) {
  .card-list--3 {
    flex-flow: row wrap;
  }
  .card-list--3 .uids-card {
    flex-basis: calc((100% - var(--card-gap) * 1) / 2);
  }
}
@media (min-width: 1150px) {
  .card-list--3 .uids-card {
    flex-basis: calc((100% - var(--card-gap) * 2) / 3);
  }
}

@media (min-width: 600px) {
  .card-list--4 {
    flex-flow: row wrap;
  }
  .card-list--4 .uids-card {
    flex-basis: calc((100% - var(--card-gap) * 1) / 2);
  }
}
@media (min-width: 855px) {
  .card-list--4 .uids-card {
    flex-basis: calc((100% - var(--card-gap) * 2) / 3);
  }
}
@media (min-width: 1150px) {
  .card-list--4 .uids-card {
    flex-basis: calc((100% - var(--card-gap) * 3) / 4);
  }
}

@media (min-width: 600px) {
  .card-list--5 {
    flex-flow: row wrap;
  }
  .card-list--5 .uids-card {
    flex-basis: calc((100% - var(--card-gap) * 1) / 2);
  }
}
@media (min-width: 855px) {
  .card-list--5 .uids-card {
    flex-basis: calc((100% - var(--card-gap) * 2) / 3);
  }
}
@media (min-width: 1150px) {
  .card-list--5 .uids-card {
    flex-basis: calc((100% - var(--card-gap) * 4) / 5);
  }
}

@media (min-width: 600px) {
  .card-list--6 {
    flex-flow: row wrap;
  }
  .card-list--6 .uids-card {
    flex-basis: calc((100% - var(--card-gap) * 1) / 2);
  }
}
@media (min-width: 855px) {
  .card-list--6 .uids-card {
    flex-basis: calc((100% - var(--card-gap) * 2) / 3);
  }
}
@media (min-width: 1150px) {
  .card-list--6 .uids-card {
    flex-basis: calc((100% - var(--card-gap) * 5) / 6);
  }
}

.card-gap--more {
  --card-gap: 2rem;
}

.card-gap--most {
  --card-gap: 3rem;
}

.site-web.support.uids {
  --asterisk-color: #bd472a;
}
.site-web.support.uids button.toggle-button {
  font-family: monospace;
  margin: 0 0 0.25rem 0;
  padding: 0.25rem 1rem;
  display: inline-block;
  border-radius: 1.5rem;
}
.site-web.support.uids button.toggle-button[aria-pressed=false] i.pressed {
  display: none;
}
.site-web.support.uids button.toggle-button[aria-pressed=false] i.not-pressed {
  display: inline;
}
.site-web.support.uids button.toggle-button[aria-pressed=true] {
  background-color: #ffeb99;
}
.site-web.support.uids button.toggle-button[aria-pressed=true] i.pressed {
  display: inline;
}
.site-web.support.uids button.toggle-button[aria-pressed=true] i.not-pressed {
  display: none;
}
.site-web.support.uids .reset-selections {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
}
.site-web.support.uids [role=tooltip] {
  display: inline-block;
  margin-left: 0.5rem;
}
.site-web.support.uids i.fa-asterisk {
  color: var(--asterisk-color);
}

.accordion .accordion__heading {
  margin: 0;
  margin-top: 1rem;
  font-size: 1.9rem;
  font-weight: 700;
  padding-bottom: 0;
}
.accordion .accordion__heading:not(:first-child) {
  margin-top: 1rem;
}
.accordion .accordion__heading button {
  all: inherit;
  width: calc(100% - 2.5rem);
  background-color: #edeceb;
  padding: 1.25rem;
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
  transition-property: background-color;
  cursor: pointer;
}
.accordion .accordion__heading button:focus {
  outline: 5px auto -webkit-focus-ring-color;
}
.accordion .accordion__heading button i,
.accordion .accordion__heading button img {
  color: currentColor;
  transform: rotate(-180deg) translate(0px, -0.1em);
  transition: all 0.3s ease-in-out;
  transition-property: transform, background-color;
  background-color: transparent;
  width: 30px !important;
  height: 30px !important;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -0.3rem 0;
  border-radius: 50%;
  opacity: 0.8;
}
.accordion .accordion__heading button:hover img, .accordion .accordion__heading button:focus img {
  transform: rotate(-180deg) translate(0px, -0.2em);
}
.accordion .accordion__heading button:hover[aria-expanded=true] i, .accordion .accordion__heading button:hover[aria-expanded=true] img, .accordion .accordion__heading button:focus[aria-expanded=true] i, .accordion .accordion__heading button:focus[aria-expanded=true] img {
  transform: rotate(0deg) translate(0px, 0em);
}
.accordion .accordion__heading button[aria-expanded=true] i, .accordion .accordion__heading button[aria-expanded=true] img {
  transform: rotate(0deg) translate(0px, 0.1em);
  background-color: #fafafa;
}
.accordion .accordion__content {
  padding: 0 1.25rem;
  padding-bottom: 0;
  border: 1px solid #edeceb;
}
.accordion .accordion__content p {
  font-size: inherit;
}

dl.uids-list {
  padding: 1rem;
  background-color: #ccc;
}
dl.uids-list dd {
  margin-left: 0;
}

.uids-cta {
  margin-bottom: 0;
  padding: 10rem 0;
  background-repeat: repeat;
}
.uids-cta .cta-inner {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: 0 auto;
  max-width: 1150px;
}
.uids-cta .cta-title {
  flex: 1;
  margin: 0;
  font-family: Antonio, sans-serif;
  text-transform: uppercase;
  font-size: 40px;
  font-size: 4rem;
  line-height: 1.5;
  text-align: center;
}
.uids-cta .cta-title--font-accent {
  font-family: "Zilla Slab", serif;
  text-transform: none;
}
.uids-cta .cta-title--font-workhorse {
  font-family: "Roboto", sans-serif;
  text-transform: none;
}
.uids-cta .cta-details {
  flex: 1;
  margin: 0;
  text-align: center;
}
.uids-cta .cta-button {
  flex: 1;
  margin: 0;
  text-align: center;
}
.uids-cta--left .cta-inner {
  align-items: flex-start;
}
.uids-cta.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
.uids-cta.bg--community-gray, .uids-cta.bg--community-gray-on-gold, .uids-cta.bg--community-gray-on-black, .uids-cta.bg--community-gray-on-white {
  background-image: url("//www.lib.uiowa.edu/includes/uids/images/pattern-community-gray.png");
  background-size: 75%;
  background-color: #f2f3f3;
}
.uids-cta.bg--community-gray-on-white {
  background-color: white;
}
.uids-cta.bg--community-gray-on-black {
  background-color: #151515;
}
.uids-cta.bg--community-gray-on-gold {
  background-color: #ffcd00;
}
.uids-cta.bg--community-white, .uids-cta.bg--community-white-on-gold, .uids-cta.bg--community-white-on-black {
  background-image: url("//www.lib.uiowa.edu/includes/uids/images/pattern-community-white.png");
  background-size: 75%;
  background-color: #f2f3f3;
}
.uids-cta.bg--community-white-on-black {
  background-color: #151515;
}
.uids-cta.bg--community-white-on-gold {
  background-color: #ffcd00;
}
.uids-cta.bg--community-gold, .uids-cta.bg--community-gold-on-white, .uids-cta.bg--community-gold-on-black {
  background-image: url("//www.lib.uiowa.edu/includes/uids/images/pattern-community-gold.png");
  background-size: 75%;
  background-color: #fff5cc;
}
.uids-cta.bg--community-gold-on-black {
  background-color: #151515;
}
.uids-cta.bg--community-gold-on-white {
  background-color: white;
}
.uids-cta.bg--community-black, .uids-cta.bg--community-black-on-gold, .uids-cta.bg--community-black-on-white {
  background-image: url("//www.lib.uiowa.edu/includes/uids/images/pattern-community-black.png");
  background-size: 75%;
  background-color: #f2f3f3;
}
.uids-cta.bg--community-black-on-white {
  background-color: white;
}
.uids-cta.bg--community-black-on-gold {
  background-color: #ffcd00;
}
.uids-cta.bg--brain-gray {
  background-image: url("//www.lib.uiowa.edu/includes/uids/images/pattern-brain-gray.png");
  background-size: 25%;
  background-color: #f2f3f3;
}
.uids-cta.bg--brain-gold {
  background-image: url("https://www.lib.uiowa.edu/images/branding/community-pattern--gray.png");
  background-color: #f3f3f3;
}
.uids-cta.bg--brain-black {
  background-image: url("https://www.lib.uiowa.edu/images/branding/community-pattern--gray.png");
  background-color: #f3f3f3;
}
.uids-cta.bg--particles-white {
  background-image: url("https://www.lib.uiowa.edu/images/branding/particles-pattern--gray.png");
  background-color: #f3f3f3;
}
.uids-cta.bg--particles-gold {
  background-image: url("https://www.lib.uiowa.edu/images/branding/particles-pattern--gray.png");
  background-color: #f3f3f3;
}
.uids-cta.bg--particles-black {
  background-image: url("https://www.lib.uiowa.edu/images/branding/particles-pattern--gray.png");
  background-color: #f3f3f3;
}
@media (min-width: 855px) {
  .uids-cta--inline .cta-inner {
    flex-flow: row nowrap;
  }
}

a.skip-to-main,
a.skip-to-subnav {
  background: black;
  color: white !important;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
  height: 2em;
  left: 40%;
  padding: 8px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.1s;
}
a.skip-to-main:focus,
a.skip-to-subnav:focus {
  transform: translateY(0%);
}

.element-invisible {
  position: absolute;
  left: -10000px;
  top: auto;
  height: 1px;
  overflow: hidden;
  width: 1px;
}

.iowa-bar {
  position: relative;
  z-index: 2;
  display: block;
  min-height: auto;
  background: #151515;
}
.iowa-bar:after {
  content: "";
  display: table;
  clear: both;
}
@media (min-width: 855px) {
  .iowa-bar {
    background: #ffcd00;
  }
}
.iowa-bar .logo--tab {
  margin-right: 20px;
  padding: 25px 20px;
  background-color: #151515;
}
.iowa-bar .logo--tab .logo-icon {
  height: 20px;
  fill: #ffcd00;
  transition: 0.1s;
}
.iowa-bar .logo--tab .logo-icon:hover {
  opacity: 0.7;
}
@media (min-width: 855px) {
  .iowa-bar .logo--tab {
    padding: 27px 20px 20px;
  }
  .iowa-bar .logo--tab .logo-icon {
    height: 30px;
  }
}
@media (min-width: 1150px) {
  .iowa-bar .logo--tab {
    margin-left: -12px;
  }
  .iowa-bar .logo--tab .logo-icon {
    height: 43px;
  }
}
.iowa-bar .site-name {
  padding: 20px;
  width: 100%;
  background-color: #ffcd00;
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.5;
}
.iowa-bar .site-name a {
  text-decoration: none;
  color: #151515;
  font-weight: 300;
}
.iowa-bar .site-name__subsite-name {
  position: absolute;
  left: -10000px;
  top: auto;
  height: 1px;
  overflow: hidden;
  width: 1px;
}
@media (min-width: 855px) {
  .iowa-bar .site-name {
    padding-left: 0;
    width: auto;
    background-color: transparent;
  }
}
@media (min-width: 1150px) {
  .iowa-bar .site-name {
    margin: 5px 0 0 0;
  }
}
.iowa-bar__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  max-width: 1150px;
}
@media (min-width: 855px) {
  .iowa-bar__container {
    justify-content: flex-start;
    display: flex;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 1150px) {
  .iowa-bar--narrow.horizontal .iowa-bar__container, .iowa-bar--narrow.mega .iowa-bar__container {
    position: relative;
  }
}
@media (min-width: 855px) {
  .iowa-bar--full {
    height: 80px;
    background: #ffcd00;
  }
}
@media (min-width: 855px) {
  .iowa-bar--narrow {
    min-height: 50px;
    position: relative;
  }
}
.iowa-bar--narrow .logo--tab {
  margin-right: 10px;
}
@media (min-width: 855px) {
  .iowa-bar--narrow .logo--tab {
    height: 56px;
    width: 119px;
    padding: 15px;
  }
}
@media (min-width: 1150px) {
  .iowa-bar--narrow .logo--tab {
    margin-bottom: -6px;
  }
}
.search-is-open .iowa-bar--narrow .logo--tab {
  visibility: none;
  opacity: 0;
}
@media (min-width: 855px) {
  .search-is-open .iowa-bar--narrow .logo--tab {
    display: flex;
    opacity: 1;
    visibility: visible;
  }
}
.iowa-bar--narrow .logo-icon {
  height: 20px;
}
@media (min-width: 855px) {
  .iowa-bar--narrow .logo-icon {
    height: 26px;
  }
}
@media (min-width: 1150px) {
  .iowa-bar--narrow .parent-site-name {
    padding: 6px 0 0 0;
  }
}
.iowa-bar--narrow .site-name {
  margin: 0;
  padding: 1.25rem;
  font-size: 1rem;
  background: #ffcd00;
}
@media (min-width: 855px) {
  .iowa-bar--narrow .site-name {
    padding: 0;
    background: transparent;
    font-size: 1rem;
  }
}
@media (min-width: 1150px) {
  .iowa-bar--narrow .site-name {
    padding: 6px 0 0 0;
  }
}
.iowa-bar--below {
  z-index: 2;
  border-bottom: 1px solid #e6e5e5;
  background: #fff;
}
.iowa-bar--below .iowa-bar__container {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 0;
  margin-left: 0;
}
@media (min-width: 855px) {
  .iowa-bar--below .iowa-bar__container {
    display: flex;
    position: unset;
    min-height: 90px;
    justify-content: unset;
    margin-right: auto;
    padding-left: 1.25rem;
  }
}
@media (min-width: 1150px) {
  .iowa-bar--below .iowa-bar__container {
    margin-left: auto;
    padding-left: 0;
  }
}
.iowa-bar--below .site-name {
  margin: 0;
  text-align: left;
  font-size: 1.8rem;
}
@media (min-width: 855px) {
  .iowa-bar--below .site-name {
    width: 75%;
    padding: 25px 20px 25px 0;
    background: transparent;
    font-size: 2rem;
  }
}
@media (min-width: 1150px) {
  .iowa-bar--below .site-name {
    font-size: 2.2rem;
  }
}
@media (min-width: 855px) {
  .search-is-open .iowa-bar--below .site-name {
    display: none;
  }
}
.search-site-wrapper {
  margin-left: auto;
  padding: 0 20px 20px 20px;
  width: 100%;
  background-color: #ffcd00;
}
.search-site-wrapper form.uids-search {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  border-bottom: 1px solid #151515;
}
.search-site-wrapper form.uids-search:after {
  content: "";
  display: table;
  clear: both;
}
.search-site-wrapper form.uids-search #edit-search-terms {
  padding: 0.5rem 0;
  text-align: left;
  color: #3d3d3d;
  border: none;
  background: none;
  box-shadow: none !important;
  font-size: 1em;
  flex-grow: 999;
  outline: none;
}
.search-site-wrapper form.uids-search #edit-search-terms::placeholder {
  color: rgba(0, 0, 0, 0.8);
}
.search-site-wrapper form.uids-search #edit-search-terms:focus,
.search-site-wrapper form.uids-search #edit-search-terms:focus-within {
  border: 2px solid #00558c;
}
.search-site-wrapper form.uids-search #edit-search-terms:focus::placeholder,
.search-site-wrapper form.uids-search #edit-search-terms:focus-within::placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.search-site-wrapper form.uids-search button {
  appearance: none;
  border: none;
  display: inline-block;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  text-align: right;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1.4;
  font-size: 1.25rem;
  padding: 0.5rem 1rem;
  background-color: transparent;
}
.search-site-wrapper form.uids-search button span {
  display: none;
}
.search-site-wrapper form.uids-search button:before {
  content: "\f002";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: black;
  transition: 0.1s;
  font-size: 1.5em;
}
.search-site-wrapper form.uids-search button:hover:before {
  color: #333;
}
@media (min-width: 855px) {
  .search-site-wrapper {
    width: 45%;
    padding: 20px;
  }
}
@media (min-width: 1150px) {
  .search-site-wrapper {
    padding: 0;
  }
}

.footer__links .menu,
.footer__links .menu ul li,
.footer__links--icon ul,
.footer__links--icon li,
.socket__menu,
.footer-cta__container .menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer__links .menu > li,
.footer__links .menu ul li > li,
.footer__links--icon ul > li,
.footer__links--icon li > li,
.socket__menu > li,
.footer-cta__container .menu > li {
  display: inline-block;
  list-style-type: none;
  margin: 0;
}

.footer__container,
.socket__container,
.footer-cta__container {
  margin: 0 1.25rem;
  position: relative;
}

@media (min-width: 84.375em) {
  .footer__container,
  .socket__container,
  .footer-cta__container {
    max-width: 81.875em;
    margin: 0 auto;
  }
}
.footer {
  position: relative;
  background: #151515;
  padding-top: 2.5rem;
  padding-right: 0;
  padding-bottom: 2.5rem;
  padding-left: 0;
}

@media (min-width: 855px) {
  .footer {
    padding-top: 2.5rem;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
}
.footer p {
  color: #ccc;
  line-height: 1.5;
  font-weight: 100;
  margin-top: 0.9rem;
}

.footer p a {
  color: #FFCD00;
  text-decoration: none;
  font-weight: 400;
}

.footer p a:hover,
.footer p a:focus {
  text-decoration: underline;
}

.footer p a i,
.footer p a svg,
.footer p a span {
  margin-right: 0.625rem;
  font-size: 0.8rem;
  color: #999;
}

.footer .site-name {
  color: #fff;
  font-size: 1.7rem;
  margin-top: 1.05rem;
  font-weight: 900;
  margin-bottom: 0.325rem;
}

.footer .site-name a {
  color: #fff;
  text-decoration: none;
}

.footer .site-name a:hover,
.footer .site-name a:focus {
  text-decoration: underline;
}

.footer .parent-site-name {
  color: #ccc;
  font-size: 1.4rem;
  margin-top: 0;
  font-weight: 300;
  margin-bottom: 0.625rem;
  display: block;
  background: #151515;
  padding: 0;
}

.footer .parent-site-name a {
  color: #ccc;
  text-decoration: none;
}

.footer .parent-site-name a:hover,
.footer .parent-site-name a:focus {
  text-decoration: underline;
}

@media (min-width: 84.375em) {
  .footer__container {
    padding: 0;
  }
}
@media (min-width: 855px) {
  .footer__container--flex {
    display: flex;
    flex-wrap: wrap;
  }
}
.footer__links {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 1.875rem;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.footer__links .menu {
  margin: 0;
}

.footer__links .menu li {
  /* * %no-ul-list sets display: inline-block; for li, * which doesn't work for this menu. */
  display: block;
}

.footer__links .menu a {
  font-weight: 300;
  text-decoration: none;
  color: white;
  font-size: 1.1rem;
}

.footer__links .menu a:hover,
.footer__links .menu a:focus {
  text-decoration: underline;
}

.footer__links .menu ul {
  margin: 0;
}

.footer__links--nav {
  margin-bottom: 2.5rem;
}

@media (min-width: 855px) {
  .footer__links--nav {
    display: flex;
  }
}
@media (min-width: 855px) {
  .footer__links--contact {
    flex-grow: 2;
  }
}
.footer__links--icon {
  /* * Display icon menu as flex since the icons sit * side-by-side. */
}

.footer__links--icon .menu {
  flex-wrap: wrap;
  display: flex;
  line-height: 2;
}

@media (min-width: 855px) {
  .footer__links--icon .menu {
    line-height: 1;
  }
}
.footer__links--icon a {
  text-decoration: none;
  color: #fff;
}

.footer__links--icon i {
  color: #fff;
  font-size: 1.6rem;
  margin-right: 1.05rem;
}

.footer__links--icon .link-text {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

@media (min-width: 84.375em) {
  .socket__container {
    padding: 0;
  }
}
.socket__menu {
  display: flex;
  flex-wrap: wrap;
}

.socket__menu li {
  color: white;
  font-size: 0.8rem;
  border-right: 1px solid #5d5656;
  margin-bottom: 0.325rem;
  padding-top: 0;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.socket__menu li:first-child {
  flex-basis: 100%;
  border-right: none;
}

.socket__menu li:nth-child(2) {
  padding-left: 0;
}

.socket__menu li:last-child {
  border-right: none;
  padding-right: 0;
}

.socket__menu li:first-child {
  padding-left: 0;
}

.socket__menu li a {
  text-decoration: none;
  color: #FFCD00;
}

.socket__menu li a:hover,
.socket__menu li a:focus {
  text-decoration: underline;
}

.footer-cta__wrapper {
  background: #FFCD00;
  padding: 1.875rem 0;
  margin: 0;
}

@media (min-width: 768px) {
  .footer-cta__wrapper .menu {
    display: flex;
  }
}
.footer-cta__wrapper .menu li {
  flex: 1;
  display: block;
}

.footer-cta__wrapper .bttn--outline {
  border: 1px solid #151515;
  display: block;
}

@media (min-width: 768px) {
  .footer-cta__wrapper .bttn--outline {
    flex: 1;
    display: block;
  }
}
.footer-cta__container {
  justify-content: space-between;
}

.footer-cta__container div:first-of-type,
.footer-cta__container nav:first-of-type {
  flex-basis: 100%;
}

@media (min-width: 768px) {
  .footer-cta__container {
    display: flex;
  }
}
@media (min-width: 84.375em) {
  .footer-cta__container {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .footer-cta__container .menu li:first-child a {
    margin: 0 1.05rem 0 0;
  }
  .footer-cta__container .menu li:last-child a {
    margin: 0 0 0 1.05rem;
  }
}
.footer-cta__container .menu a {
  margin: 1.05rem 0;
  padding-top: 0.325rem;
}

@media (min-width: 768px) {
  .footer-cta__container .menu a {
    margin: 0 1.05rem;
  }
}
.footer-cta__container .menu a:before {
  content: " ";
  background-repeat: no-repeat;
  background-size: 90%;
  width: 35px;
  height: 35px;
  display: inline-block;
  margin-bottom: -5px;
  margin-right: 5px;
}

.footer-cta__container .menu a.bttn--apply:before {
  background-image: url("../../assets/images/apply-01.svg");
}

.footer-cta__container .menu a.bttn--visit:before {
  background-image: url("../../assets/images/location-01.svg");
}

.footer-cta__container .menu a.bttn--request:before {
  background-image: url("../../assets/images/request-01.svg");
}

.footer-cta__container > a:first-child {
  margin-left: 0;
}

.footer-cta__container > a:last-child {
  margin-right: 0;
}

.logo--footer {
  padding: 0 40px 0 0;
  width: 128px;
}

@media (min-width: 855px) {
  .logo--footer {
    width: 195px;
  }
}
@media (min-width: 84.375em) {
  .logo--footer {
    width: 176px;
  }
}
footer.footer {
  padding: 3rem 2rem;
  width: calc(100% - 4rem);
}
footer.footer .footer__container {
  margin: 0 auto;
  max-width: 1150px;
}
footer.footer .logo-icon {
  fill: #ffcd00;
  transition: 0.1s;
}
footer.footer .logo-icon:hover {
  opacity: 0.7;
}
footer.footer .site-name-link {
  text-decoration: none;
}
footer.footer .site-name-link h2 {
  font-size: 27px;
  font-size: 2.7rem;
  line-height: 1.5;
  font-weight: 900;
}
footer.footer .site-name-link h2:hover {
  text-decoration: underline;
}
footer.footer .region-footer-first {
  font-size: 19px;
  font-size: 1.9rem;
  line-height: 1.5;
  font-weight: 100;
}
footer.footer .region-footer-first a {
  font-weight: 400;
}
footer.footer .region-footer-first i {
  display: inline-block;
  margin-right: 1rem;
  font-size: 19px;
  font-size: 1.9rem;
  line-height: 1.5;
}
footer.footer .socket {
  margin-top: 4rem;
}
footer.footer .socket ul.socket__menu li,
footer.footer .socket ul.socket__menu a {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.5;
  line-height: 2.1rem;
  font-weight: 400;
}
footer.footer .footer__links ul li a {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
  line-height: 3rem;
}
footer.footer .footer__links ul li.uilib-other-links svg {
  margin-top: 1rem;
  height: 2.4rem;
}
footer.footer .footer__links ul li.uilib-other-links svg a:focus {
  fill: #ffcd00;
}
footer.footer .footer__links ul li.uilib-social-media {
  margin: 2rem 0;
  min-width: 200px;
  max-width: 300px;
}
footer.footer .footer__links ul li.uilib-social-media a:focus {
  fill: #ffcd00;
}
footer.footer .region-footer-first ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
footer.footer .region-footer-first ul li {
  color: #ccc;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
}
footer.footer .region-footer-first ul li i {
  width: 2rem;
  color: #ccc;
}
footer.footer .region-footer-first .uilib-social-media {
  margin-top: 2rem;
  width: 340px;
}
footer.footer a {
  color: #ffcd00;
  font-weight: 400;
  text-decoration: none;
}
footer.footer a:hover {
  text-decoration: underline;
}

.nav--vertical {
  background-color: #eee;
}
.nav--vertical ul {
  list-style-type: none;
}
.nav--vertical ul.menu {
  margin: 0;
  margin-top: 0;
  padding: 10px 20px;
}
#nav-narrow-toggle {
  margin: 0;
  padding: 1rem;
  width: 100%;
  border: none;
  background-color: #aaa;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}
#nav-narrow-toggle .toggle-icon {
  display: inline-block;
  width: 1.25em;
}
#nav-narrow-toggle .toggle-text {
  display: block;
  margin: 0;
  padding: 0;
  color: black;
  text-transform: uppercase;
  text-align: left;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
}
#nav-narrow-toggle[aria-expanded=false] .icon-close {
  display: none;
}
#nav-narrow-toggle[aria-expanded=false] .icon-menu {
  display: inline-block;
}
#nav-narrow-toggle[aria-expanded=true] .icon-close {
  display: inline-block;
  opacity: 0.5;
}
#nav-narrow-toggle[aria-expanded=true] .icon-menu {
  display: none;
}

ul#nav-narrow-menu.hidden {
  display: none;
}
ul#nav-narrow-menu li.sf-depth-1 > a {
  display: block;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
}
ul#nav-narrow-menu ul.sub-menu {
  padding: 0 0 0 1rem;
}
ul#nav-narrow-menu ul.sub-menu > li {
  padding-left: 2rem;
  text-indent: -2rem;
}
ul#nav-narrow-menu ul.sub-menu > li a {
  display: block;
  padding: 0.25rem 0;
}

.nav--horizontal {
  margin-top: 10px;
  clear: both;
  border-bottom: 1px solid #e6e5e5;
}
.nav--horizontal:after {
  content: "";
  display: table;
  clear: both;
}
.nav--horizontal .nav-inner {
  margin: 0 auto;
  max-width: 1150px;
}

.block-superfish.block-superfishmain {
  width: 100%;
  /**
    * Arrows.
    */
  /**
    * Shadow.
    */
  /**
    * Multi-column sub-menus.
    * Supersubs.js can adjust column widths too and this is a fallback.
    */
  /**
    * "Vertical" type.
    */
  /**
    * "Navbar" type.
    */
  /**
    * sfSmallscreen.
    */
  /**
    * RTL support.
    */
}
.block-superfish.block-superfishmain .sf-hidden {
  background: transparent !important;
  border: 0 none !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px !important;
  margin: !important;
  overflow: hidden !important;
  padding: 0 !important;
  width: 1px !important;
}
.block-superfish.block-superfishmain ul.sf-menu:after {
  content: "";
  display: table;
  clear: both;
}
.block-superfish.block-superfishmain ul.sf-menu,
.block-superfish.block-superfishmain ul.sf-menu li {
  list-style: none;
}
.block-superfish.block-superfishmain ul.sf-menu,
.block-superfish.block-superfishmain ul.sf-menu ul,
.block-superfish.block-superfishmain ul.sf-menu ol,
.block-superfish.block-superfishmain ul.sf-menu li,
.block-superfish.block-superfishmain ul.sf-menu a,
.block-superfish.block-superfishmain ul.sf-menu span,
.block-superfish.block-superfishmain ul.sf-menu div {
  margin: 0;
  padding: 0;
}
.block-superfish.block-superfishmain ul.sf-menu {
  line-height: 1;
  z-index: 497;
}
.block-superfish.block-superfishmain ul.sf-menu ul {
  left: 0;
  position: absolute;
  top: -9999px;
  width: 20em;
  background-color: white;
}
.block-superfish.block-superfishmain ul.sf-menu ul li {
  width: 100%;
}
.block-superfish.block-superfishmain ul.sf-menu li {
  float: left;
  position: relative;
  z-index: 498;
}
.block-superfish.block-superfishmain ul.sf-menu a,
.block-superfish.block-superfishmain ul.sf-menu span.nolink {
  display: block;
  padding: 0.75em 1.5em;
  position: relative;
  text-decoration: none;
  color: #151515;
}
.block-superfish.block-superfishmain ul.sf-menu > li > a,
.block-superfish.block-superfishmain ul.sf-menu > li > span.nolink {
  padding: 1em 1.5em;
  font-weight: 600;
}
.block-superfish.block-superfishmain ul.sf-menu > li > a:after,
.block-superfish.block-superfishmain ul.sf-menu > li > span.nolink:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 5px;
  content: "";
  transition: 0.3s;
  background-color: #ffcd00;
}
.block-superfish.block-superfishmain ul.sf-menu > li:hover > a:after,
.block-superfish.block-superfishmain ul.sf-menu > li:focus-within > a:after,
.block-superfish.block-superfishmain ul.sf-menu > li:hover > span.nolink:after {
  width: 100%;
}
.block-superfish.block-superfishmain ul.sf-menu > li ul {
  background-color: rgb(241, 241, 241);
}
.block-superfish.block-superfishmain ul.sf-menu > li li {
  padding: 1em 1.5em;
  transition: 0.1s;
}
.block-superfish.block-superfishmain ul.sf-menu > li li:hover, .block-superfish.block-superfishmain ul.sf-menu > li li:focus-within {
  background-color: #ddd;
}
.block-superfish.block-superfishmain ul.sf-menu li:hover,
.block-superfish.block-superfishmain ul.sf-menu li.sfHover,
.block-superfish.block-superfishmain ul.sf-menu li:hover ul,
.block-superfish.block-superfishmain ul.sf-menu li.sfHover ul {
  z-index: 499;
}
.block-superfish.block-superfishmain ul.sf-menu li:hover > ul,
.block-superfish.block-superfishmain ul.sf-menu li.sfHover > ul {
  left: 0;
  top: 3em;
}
.block-superfish.block-superfishmain ul.sf-menu li li:hover > ul,
.block-superfish.block-superfishmain ul.sf-menu li li.sfHover > ul {
  left: 12em;
  top: 0;
}
.block-superfish.block-superfishmain ul.sf-menu.menu,
.block-superfish.block-superfishmain ul.sf-menu.menu ul,
.block-superfish.block-superfishmain ul.sf-menu.menu li {
  margin: 0;
  padding: 0;
}
.block-superfish.block-superfishmain ul.sf-menu .sf-sub-indicator {
  height: 12px;
  line-height: 9999px;
  opacity: 0.75;
  overflow: hidden;
  position: absolute;
  right: 0.75em;
  speak: none;
  text-indent: -9999px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 12px;
}
@media (min-width: 1150px) {
  .block-superfish.block-superfishmain ul.sf-menu > li.sf-depth-1.menuparent:first-of-type > a {
    padding-left: 0;
  }
}
.block-superfish.block-superfishmain ul.sf-menu > li.sf-depth-1.menuparent:before {
  position: absolute;
  top: 2em;
  right: 0.25em;
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 1rem;
  line-height: 1;
  text-indent: 0;
  color: #999;
  transition: 0.2s;
}
.block-superfish.block-superfishmain ul.sf-menu > li.sf-depth-1.menuparent:hover:before, .block-superfish.block-superfishmain ul.sf-menu > li.sf-depth-1.menuparent:focus:before, .block-superfish.block-superfishmain ul.sf-menu > li.sf-depth-1.menuparent:focus-within:before {
  top: 2.2em;
}
.block-superfish.block-superfishmain ul.sf-menu a:focus > .sf-sub-indicator,
.block-superfish.block-superfishmain ul.sf-menu a:hover > .sf-sub-indicator,
.block-superfish.block-superfishmain ul.sf-menu a:active > .sf-sub-indicator,
.block-superfish.block-superfishmain ul.sf-menu li:hover > a > .sf-sub-indicator,
.block-superfish.block-superfishmain ul.sf-menu li.sfHover > a > .sf-sub-indicator,
.block-superfish.block-superfishmain ul.sf-menu span.nolink:hover > .sf-sub-indicator,
.block-superfish.block-superfishmain ul.sf-menu li:hover > span.nolink > .sf-sub-indicator,
.block-superfish.block-superfishmain ul.sf-menu li.sfHover > span.nolink > .sf-sub-indicator {
  opacity: 1;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column {
  display: inline;
  float: left;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-parent-children-1 ul.sf-multicolumn {
  width: 12em;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-parent-children-2 ul.sf-multicolumn {
  width: 24em;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-parent-children-3 ul.sf-multicolumn {
  width: 36em;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-parent-children-4 ul.sf-multicolumn {
  width: 48em;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-parent-children-5 ul.sf-multicolumn {
  width: 60em;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-parent-children-6 ul.sf-multicolumn {
  width: 72em;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-parent-children-7 ul.sf-multicolumn {
  width: 84em;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-parent-children-8 ul.sf-multicolumn {
  width: 96em;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-parent-children-9 ul.sf-multicolumn {
  width: 108em;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-parent-children-10 ul.sf-multicolumn {
  width: 120em;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(2),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(1) {
  width: 50%;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(3),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(2),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(1) {
  width: 32.333%;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(4),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(3),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(2),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(1) {
  width: 25%;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(5),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(4),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(3),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(2),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(5):nth-last-child(1) {
  width: 20%;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(6),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(5),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(4),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(3),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(5):nth-last-child(2),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(6):nth-last-child(1) {
  width: 16.665%;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(7),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(6),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(5),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(4),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(5):nth-last-child(3),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(6):nth-last-child(2),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(7):nth-last-child(1) {
  width: 14.2855%;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(8),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(7),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(6),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(5),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(5):nth-last-child(4),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(6):nth-last-child(3),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(7):nth-last-child(2),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(8):nth-last-child(1) {
  width: 14.2855%;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(9),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(8),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(7),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(6),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(5):nth-last-child(5),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(6):nth-last-child(4),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(7):nth-last-child(3),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(8):nth-last-child(2),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(9):nth-last-child(1) {
  width: 11.111%;
}
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(10),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(9),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(8),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(7),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(5):nth-last-child(6),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(6):nth-last-child(5),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(7):nth-last-child(4),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(8):nth-last-child(3),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(9):nth-last-child(2),
.block-superfish.block-superfishmain ul.sf-menu li.sf-multicolumn-column:nth-child(10):nth-last-child(1) {
  width: 10%;
}
.block-superfish.block-superfishmain ul.sf-menu.sf-vertical {
  width: 12em;
}
.block-superfish.block-superfishmain ul.sf-menu.sf-vertical li {
  width: 100%;
}
.block-superfish.block-superfishmain ul.sf-menu.sf-vertical li:hover > ul,
.block-superfish.block-superfishmain ul.sf-menu.sf-vertical li.sfHover > ul {
  left: 12em;
  top: 0;
}
.block-superfish.block-superfishmain ul.sf-menu.sf-vertical .sf-sub-indicator:after {
  content: "►";
}
.block-superfish.block-superfishmain ul.sf-menu.sf-navbar {
  position: relative;
}
.block-superfish.block-superfishmain ul.sf-menu.sf-navbar li {
  position: static;
}
.block-superfish.block-superfishmain ul.sf-menu.sf-navbar li li {
  position: relative;
}
.block-superfish.block-superfishmain ul.sf-menu.sf-navbar li ul,
.block-superfish.block-superfishmain ul.sf-menu.sf-navbar li li li {
  width: 100%;
}
.block-superfish.block-superfishmain ul.sf-menu.sf-navbar li li {
  width: auto;
  float: left;
}
.block-superfish.block-superfishmain ul.sf-menu.sf-navbar li li:hover > ul,
.block-superfish.block-superfishmain ul.sf-menu.sf-navbar li li.sfHover > ul,
.block-superfish.block-superfishmain ul.sf-menu.sf-navbar > li.active-trail > ul {
  left: 0;
  top: 2.5em;
}
.block-superfish.block-superfishmain ul.sf-menu.sf-navbar li li li:hover > ul,
.block-superfish.block-superfishmain ul.sf-menu.sf-navbar li li li.sfHover > ul {
  left: 12em;
  top: 0;
}
.block-superfish.block-superfishmain ul.sf-menu.sf-navbar ul .sf-sub-indicator:after {
  content: "▼";
}
.block-superfish.block-superfishmain ul.sf-menu.sf-navbar ul ul .sf-sub-indicator:after {
  content: "►";
}
.block-superfish.block-superfishmain ul.sf-menu.sf-accordion {
  display: none;
  position: absolute;
}
.block-superfish.block-superfishmain ul.sf-menu.sf-accordion,
.block-superfish.block-superfishmain ul.sf-menu.sf-accordion ul,
.block-superfish.block-superfishmain ul.sf-menu.sf-accordion li {
  float: left;
  width: 100%;
}
.block-superfish.block-superfishmain ul.sf-menu.sf-accordion ul {
  margin: 0;
  padding: 0;
}
.block-superfish.block-superfishmain ul.sf-menu.sf-accordion.sf-expanded,
.block-superfish.block-superfishmain ul.sf-menu.sf-accordion li.sf-expanded > ul {
  left: auto !important;
  position: relative;
  top: auto !important;
}
.block-superfish.block-superfishmain ul.sf-menu.sf-accordion .sf-sub-indicator:after {
  content: "▼";
}
.block-superfish.block-superfishmain ul.sf-menu.sf-accordion.sf-accordion-with-buttons .sf-accordion-button:after {
  content: "▼";
  margin: 0 0 0 1em;
}
.block-superfish.block-superfishmain ul.sf-menu.sf-accordion.sf-accordion-with-buttons li.sf-expanded > .sf-accordion-button:after {
  content: "▲";
}
.block-superfish.block-superfishmain ul.sf-menu.rtl,
.block-superfish.block-superfishmain ul.sf-menu.rtl li {
  float: right;
}
.block-superfish.block-superfishmain ul.sf-menu.rtl li:hover > ul,
.block-superfish.block-superfishmain ul.sf-menu.rtl li.sfHover > ul {
  left: auto;
  right: 0;
}
.block-superfish.block-superfishmain ul.sf-menu.rtl li li:hover > ul,
.block-superfish.block-superfishmain ul.sf-menu.rtl li li.sfHover > ul {
  left: auto;
  right: 12em;
}
.block-superfish.block-superfishmain ul.sf-menu.rtl ul {
  left: auto;
  right: 0;
}
.block-superfish.block-superfishmain ul.sf-menu.rtl .sf-sub-indicator {
  left: 0.75em;
  right: auto;
}
.block-superfish.block-superfishmain ul.sf-menu.rtl.sf-horizontal ul .sf-sub-indicator:after {
  content: "◄";
}
.block-superfish.block-superfishmain ul.sf-menu.rtl.sf-horizontal.sf-shadow ul,
.block-superfish.block-superfishmain ul.sf-menu.rtl.sf-vertical.sf-shadow ul,
.block-superfish.block-superfishmain ul.sf-menu.rtl.sf-navbar.sf-shadow ul ul {
  -webkit-box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.5);
  box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.5);
}
.block-superfish.block-superfishmain ul.sf-menu.rtl li.sf-multicolumn-column {
  float: right;
}
.block-superfish.block-superfishmain ul.sf-menu.rtl.sf-vertical li:hover > ul,
.block-superfish.block-superfishmain ul.sf-menu.rtl.sf-vertical li.sfHover > ul {
  left: auto;
  right: 12em;
}
.block-superfish.block-superfishmain ul.sf-menu.rtl.sf-vertical .sf-sub-indicator:after {
  content: "◄";
}
.block-superfish.block-superfishmain ul.sf-menu.rtl.sf-navbar li li {
  float: right;
}
.block-superfish.block-superfishmain ul.sf-menu.rtl.sf-navbar ul ul .sf-sub-indicator:after {
  content: "◄";
}
.block-superfish.block-superfishmain ul.sf-menu.rtl.sf-navbar li li:hover > ul,
.block-superfish.block-superfishmain ul.sf-menu.rtl.sf-navbar li li.sfHover > ul {
  left: auto;
  right: 0;
}
.block-superfish.block-superfishmain ul.sf-menu.rtl.sf-navbar li li li:hover > ul,
.block-superfish.block-superfishmain ul.sf-menu.rtl.sf-navbar li li li.sfHover > ul {
  left: auto;
  right: 12em;
}
.block-superfish.block-superfishmain ul.sf-menu.rtl.sf-accordion.sf-expanded,
.block-superfish.block-superfishmain ul.sf-menu.rtl.sf-accordion li.sf-expanded > ul {
  right: auto !important;
}

.nav--horizontal {
  display: none;
}

.nav--vertical {
  display: block;
}

@media (min-width: 855px) {
  .nav--horizontal {
    display: block;
  }
  .nav--vertical {
    display: none;
  }
}
section.alert {
  padding: 2rem;
}
section.alert .alert-inner {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 auto;
  max-width: calc(1150px - 4rem);
}
section.alert .alert-inner:before {
  flex: 0 0 1.5em;
  font-size: 2em;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f061";
}
section.alert .alert-inner .alert-body {
  flex: 0 0 90%;
}
section.alert .alert-inner .alert-body h2, section.alert .alert-inner .alert-body h3 {
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 1.5;
}
section.alert .alert-inner .alert-body p:first-of-type {
  margin-top: 0;
}
section.alert .alert-inner .alert-body p:last-of-type {
  margin-bottom: 0;
}
section.alert--type-question, section.alert--question {
  background-color: #e8f6ff;
  border: 1px solid rgba(0, 85, 140, 0.5);
}
section.alert--type-question .alert-inner:before, section.alert--question .alert-inner:before {
  content: "\f059";
  color: #00558c;
}
section.alert--type-success {
  background-color: #e6fff9;
  border: 1px solid rgba(0, 102, 79, 0.5);
}
section.alert--type-success .alert-inner:before {
  content: "\f058";
  color: #00664f;
}
section.alert--type-info, section.alert--info {
  background-color: #e8f6ff;
  border: 1px solid rgba(0, 85, 140, 0.5);
}
section.alert--type-info .alert-inner:before, section.alert--info .alert-inner:before {
  content: "\f05a";
  color: #00558c;
}
section.alert--type-warning, section.alert--warning {
  background-color: #fcf2f0;
  border: 1px solid rgba(189, 71, 42, 0.5);
}
section.alert--type-warning .alert-inner:before, section.alert--warning .alert-inner:before {
  content: "\f071";
  color: #bd472a;
}
section.alert--type-danger, section.alert--danger {
  background-color: #fcf2f0;
  border: 1px solid rgba(189, 71, 42, 0.5);
}
section.alert--type-danger .alert-inner:before, section.alert--danger .alert-inner:before {
  content: "\f06a";
  color: #bd472a;
}
section.alert--hawk .alert-inner {
  padding: 2rem;
  background-color: #fcf2f0;
  border: 1px solid rgba(189, 71, 42, 0.4);
}
section.alert--hawk .alert-inner .alert-body p {
  margin: 0.5em 0;
}
section.alert--hawk .alert-inner .alert-body .hawkalert-title {
  font-family: "Zilla Slab", serif;
}
section.alert--hawk .alert-inner .alert-body .hawkalert-timestamp {
  font-style: italic;
}
section.alert--hawk .alert-inner:before {
  content: "\f06a";
  color: #bd472a;
}
section.alert--status-39 {
  display: block;
}
section.alert--status-40 {
  display: none;
}
body.logged-in section.alert--status-40 {
  display: block;
}
section.alert.icon-virus .alert-inner:before {
  font-family: "uilibraries";
  content: "\e90d";
  font-size: 2.4em;
}
section.alert.icon-snowflake .alert-inner:before {
  content: "\f2dc";
}
section.alert.icon-wrench .alert-inner:before {
  content: "\f0ad";
}
section.alert.icon-sun .alert-inner:before {
  content: "\f185" !important;
}
section.alert.color-orange {
  background-color: #fcf2f0;
  border: 1px solid rgba(189, 71, 42, 0.5);
}
section.alert.color-orange .alert-inner:before {
  color: #bd472a;
}
section.alert.color-yellow {
  background-color: #fff5cc;
  border: 1px solid rgba(255, 205, 0, 0.5);
}
section.alert.color-yellow .alert-inner:before {
  color: #ffcd00;
}
section.alert.color-blue {
  background-color: #e8f6ff;
  border: 1px solid rgba(0, 85, 140, 0.5);
}
section.alert.color-blue .alert-inner:before {
  color: #00558c;
}
section.alert.color-green {
  background-color: #e6fff9;
  border: 1px solid rgba(0, 102, 79, 0.5);
}
section.alert.color-green .alert-inner:before {
  color: #00664f;
}
section.alert.icon-color-black .alert-inner:before {
  color: black;
}

.libraries-alerts-feed .alert {
  display: none;
}

body .libraries-alerts-feed .alert--syndication-10,
body.home .libraries-alerts-feed .alert--syndication-11 {
  display: block;
}

body.site-annex .libraries-alerts-feed .alert--syndication-22,
body.site-annex.home .libraries-alerts-feed .alert--syndication-16 {
  display: block;
}

body.site-art .libraries-alerts-feed .alert--syndication-20,
body.site-art.home .libraries-alerts-feed .alert--syndication-15 {
  display: block;
}

body.site-biz .libraries-alerts-feed .alert--syndication-24,
body.site-biz.home .libraries-alerts-feed .alert--syndication-18 {
  display: block;
}

body.site-eng .libraries-alerts-feed .alert--syndication-23,
body.site-eng.home .libraries-alerts-feed .alert--syndication-17 {
  display: block;
}

body.site-hardin .libraries-alerts-feed .alert--syndication-21,
body.site-hardin.home .libraries-alerts-feed .alert--syndication-12 {
  display: block;
}

body.site-music .libraries-alerts-feed .alert--syndication-25,
body.site-music.home .libraries-alerts-feed .alert--syndication-13 {
  display: block;
}

body.site-sciences .libraries-alerts-feed .alert--syndication-26,
body.site-sciences.home .libraries-alerts-feed .alert--syndication-14 {
  display: block;
}

.callout.callout-alert.callout-alert-virus .callout-inner h2 {
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.5;
}
.callout.callout-alert.callout-alert-virus .callout-inner p:first-of-type {
  margin-top: 0;
}

.alert--id-1671638406 {
  display: none;
}

.hawkalerts-feed-TESTING .alert,
.hawk-alerts-feed .alert {
  margin: 1rem 0 2rem 0;
  padding: 0;
}
.hawkalerts-feed-TESTING .alert .alert-inner,
.hawk-alerts-feed .alert .alert-inner {
  padding: 2rem;
}
.hawkalerts-feed-TESTING .alert .alert-inner:before,
.hawk-alerts-feed .alert .alert-inner:before {
  margin-bottom: 2rem;
  font-size: 2.6em;
}
.hawkalerts-feed-TESTING .alert .alert-inner .alert-body .hawkalert-title,
.hawk-alerts-feed .alert .alert-inner .alert-body .hawkalert-title {
  margin-bottom: 0.5rem;
  padding-bottom: 0;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.5;
  font-family: "Roboto", "Roboto Condensed", sans-serif;
  font-weight: 500;
}
.hawkalerts-feed-TESTING .alert .alert-inner .alert-body .hawkalert-timestamp,
.hawk-alerts-feed .alert .alert-inner .alert-body .hawkalert-timestamp {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
  font-style: italic;
}
.hawkalerts-feed-TESTING .alert.status-COMPLETE,
.hawk-alerts-feed .alert.status-COMPLETE {
  display: none;
}

.page__container {
  display: grid;
  column-gap: 0;
  row-gap: 0;
  grid-template-columns: [full-start] minmax(0, auto) [content-start] repeat(12, [col-start] minmax(0, 95.8333333333px)) [content-end] minmax(0, auto) [full-end];
  grid-auto-rows: minmax(0, min-content);
}
.page__container .page-region .page-region-inner {
  padding: 0 20px;
}
.page__container .page-region__banner {
  grid-column: full-start/full-end;
  grid-row: 1/2;
}
.page__container .page-region__nav-secondary {
  grid-column: col-start 1/span 12;
  grid-row: 2/3;
}
.page__container .page-region__content-primary {
  grid-column: col-start 1/span 12;
  grid-row: 3/4;
}
.page__container .page-region__content-secondary {
  grid-column: col-start 1/span 12;
  grid-row: 4/5;
}
.page__container .page-region__footer-local {
  grid-column: full-start/full-end;
  grid-row: 5/6;
}
.page__container .page-region__footer-local .page-region-inner {
  padding: 20px;
}
@media (min-width: 1150px) {
  .page__container .page-region .page-region-inner {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 855px) {
  .content-layout-sidebar-right-narrow .page__container .page-region__nav-secondary {
    grid-column: col-start 10/span 3;
    grid-row: 2/3;
  }
  .content-layout-sidebar-right-narrow .page__container .page-region__nav-secondary .page-region-inner {
    padding-left: 0;
  }
  .content-layout-sidebar-right-narrow .page__container .page-region__content-primary {
    grid-column: col-start 1/span 9;
    grid-row: 2/5;
  }
  .content-layout-sidebar-right-narrow .page__container .page-region__content-primary .page-region-inner {
    padding-right: 30px;
  }
  .content-layout-sidebar-right-narrow .page__container .page-region__content-secondary {
    grid-column: col-start 10/span 3;
    grid-row: 3/4;
  }
  .content-layout-sidebar-right-narrow .page__container .page-region__content-secondary .page-region-inner {
    padding-left: 0;
  }
}
@media (min-width: 855px) {
  .content-layout-sidebar-left-narrow .page__container .page-region {
    padding: 0;
  }
  .content-layout-sidebar-left-narrow .page__container .page-region__nav-secondary {
    grid-column: col-start 1/span 3;
    grid-row: 2/3;
  }
  .content-layout-sidebar-left-narrow .page__container .page-region__nav-secondary .page-region-inner {
    padding-right: 0;
  }
  .content-layout-sidebar-left-narrow .page__container .page-region__content-primary {
    grid-column: col-start 4/span 9;
    grid-row: 2/5;
  }
  .content-layout-sidebar-left-narrow .page__container .page-region__content-primary .page-region-inner {
    padding-left: 40px;
  }
  .content-layout-sidebar-left-narrow .page__container .page-region__content-secondary {
    grid-column: col-start 1/span 3;
    grid-row: 3/4;
  }
  .content-layout-sidebar-left-narrow .page__container .page-region__content-secondary .page-region-inner {
    padding-right: 0;
  }
}
@media (min-width: 855px) {
  .content-layout-sidebar-right-wide .page__container .page-region__nav-secondary {
    grid-column: col-start 9/span 4;
    grid-row: 2/3;
  }
  .content-layout-sidebar-right-wide .page__container .page-region__nav-secondary .page-region-inner {
    padding-left: 0;
  }
  .content-layout-sidebar-right-wide .page__container .page-region__content-primary {
    grid-column: col-start 1/span 8;
    grid-row: 2/5;
  }
  .content-layout-sidebar-right-wide .page__container .page-region__content-primary .page-region-inner {
    padding-right: 40px;
  }
  .content-layout-sidebar-right-wide .page__container .page-region__content-secondary {
    grid-column: col-start 9/span 4;
    grid-row: 3/4;
  }
  .content-layout-sidebar-right-wide .page__container .page-region__content-secondary .page-region-inner {
    padding-left: 0;
  }
}
@media (min-width: 855px) {
  .content-layout-sidebar-left-wide .page__container .page-region__nav-secondary {
    grid-column: col-start 1/span 4;
    grid-row: 2/3;
  }
  .content-layout-sidebar-left-wide .page__container .page-region__nav-secondary .page-region-inner {
    padding-right: 0;
  }
  .content-layout-sidebar-left-wide .page__container .page-region__content-primary {
    grid-column: col-start 5/span 8;
    grid-row: 2/5;
  }
  .content-layout-sidebar-left-wide .page__container .page-region__content-primary .page-region-inner {
    padding-left: 40px;
  }
  .content-layout-sidebar-left-wide .page__container .page-region__content-secondary {
    grid-column: col-start 1/span 4;
    grid-row: 3/4;
  }
  .content-layout-sidebar-left-wide .page__container .page-region__content-secondary .page-region-inner {
    padding-right: 0;
  }
}
@media (min-width: 855px) {
  .content-layout-two-sidebars-nav-right .page__container .page-region__nav-secondary {
    grid-column: col-start 10/span 3;
    grid-row: 2/3;
  }
  .content-layout-two-sidebars-nav-right .page__container .page-region__nav-secondary .page-region-inner {
    padding-left: 0;
  }
  .content-layout-two-sidebars-nav-right .page__container .page-region__content-primary {
    grid-column: col-start 1/span 9;
    grid-row: 2/4;
  }
  .content-layout-two-sidebars-nav-right .page__container .page-region__content-primary .page-region-inner {
    padding-right: 40px;
  }
  .content-layout-two-sidebars-nav-right .page__container .page-region__content-secondary {
    grid-column: col-start 1/span 9;
    grid-row: 4/5;
  }
  .content-layout-two-sidebars-nav-right .page__container .page-region__content-secondary .page-region-inner {
    padding-right: 40px;
  }
}
@media (min-width: 1150px) {
  .content-layout-two-sidebars-nav-right .page__container .page-region__nav-secondary {
    grid-column: col-start 10/span 3;
    grid-row: 2/3;
  }
  .content-layout-two-sidebars-nav-right .page__container .page-region__nav-secondary .page-region-inner {
    padding-left: 0;
  }
  .content-layout-two-sidebars-nav-right .page__container .page-region__content-primary {
    grid-column: col-start 4/span 6;
    grid-row: 2/4;
  }
  .content-layout-two-sidebars-nav-right .page__container .page-region__content-primary .page-region-inner {
    padding-right: 40px;
    padding-left: 40px;
  }
  .content-layout-two-sidebars-nav-right .page__container .page-region__content-secondary {
    grid-column: col-start 1/span 3;
    grid-row: 2/3;
  }
  .content-layout-two-sidebars-nav-right .page__container .page-region__content-secondary .page-region-inner {
    padding-right: 0;
  }
}

@media (min-width: 855px) {
  .content-layout-two-sidebars-nav-left .page__container .page-region__nav-secondary {
    grid-column: col-start 1/span 3;
    grid-row: 2/3;
  }
  .content-layout-two-sidebars-nav-left .page__container .page-region__nav-secondary .page-region-inner {
    padding-right: 0;
  }
  .content-layout-two-sidebars-nav-left .page__container .page-region__content-primary {
    grid-column: col-start 4/span 9;
    grid-row: 2/4;
  }
  .content-layout-two-sidebars-nav-left .page__container .page-region__content-primary .page-region-inner {
    padding-left: 40px;
  }
  .content-layout-two-sidebars-nav-left .page__container .page-region__content-secondary {
    grid-column: col-start 4/span 9;
    grid-row: 4/5;
  }
  .content-layout-two-sidebars-nav-left .page__container .page-region__content-secondary .page-region-inner {
    padding-left: 40px;
  }
}
@media (min-width: 1150px) {
  .content-layout-two-sidebars-nav-left .page__container .page-region__nav-secondary {
    grid-column: col-start 1/span 3;
    grid-row: 2/3;
  }
  .content-layout-two-sidebars-nav-left .page__container .page-region__nav-secondary .page-region-inner {
    padding-right: 0;
  }
  .content-layout-two-sidebars-nav-left .page__container .page-region__content-primary {
    grid-column: col-start 4/span 6;
    grid-row: 2/4;
  }
  .content-layout-two-sidebars-nav-left .page__container .page-region__content-primary .page-region-inner {
    padding-right: 40px;
    padding-left: 40px;
  }
  .content-layout-two-sidebars-nav-left .page__container .page-region__content-secondary {
    grid-column: col-start 10/span 3;
    grid-row: 2/3;
  }
  .content-layout-two-sidebars-nav-left .page__container .page-region__content-secondary .page-region-inner {
    padding-left: 0;
  }
}

.region-columns {
  clear: both;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  gap: 1rem;
}
.region-columns section {
  flex: 1;
  flex-basis: 100%;
}
@media (min-width: 855px) {
  .region-columns--two > section {
    max-width: 49%;
  }
  .region-columns--two--6-6 > section:nth-of-type(1),
  .region-columns--two--6-6 > section:nth-of-type(2) {
    max-width: 49%;
  }
  .region-columns--two--7-5 > section:nth-of-type(1) {
    max-width: 58%;
  }
  .region-columns--two--7-5 > section:nth-of-type(2) {
    max-width: 40%;
  }
  .region-columns--two--7-5-flipped {
    flex-direction: row-reverse;
  }
  .region-columns--two--7-5-flipped > section:nth-of-type(1) {
    max-width: 40%;
  }
  .region-columns--two--7-5-flipped > section:nth-of-type(2) {
    max-width: 58%;
  }
  .region-columns--two--5-7 > section:nth-of-type(1) {
    max-width: 40%;
  }
  .region-columns--two--5-7 > section:nth-of-type(2) {
    max-width: 58%;
  }
  .region-columns--two--5-7-flipped {
    flex-direction: row-reverse;
  }
  .region-columns--two--5-7-flipped > section:nth-of-type(1) {
    max-width: 58%;
  }
  .region-columns--two--5-7-flipped > section:nth-of-type(2) {
    max-width: 40%;
  }
  .region-columns--two--8-4 > section:nth-of-type(1) {
    max-width: 65%;
  }
  .region-columns--two--8-4 > section:nth-of-type(2) {
    max-width: 32%;
  }
  .region-columns--two--8-4-flipped {
    flex-direction: row-reverse;
  }
  .region-columns--two--8-4-flipped > section:nth-of-type(1) {
    max-width: 32%;
  }
  .region-columns--two--8-4-flipped > section:nth-of-type(2) {
    max-width: 66%;
  }
  .region-columns--two--4-8 > section:nth-of-type(1) {
    max-width: 32%;
  }
  .region-columns--two--4-8 > section:nth-of-type(2) {
    max-width: 66%;
  }
  .region-columns--two--4-8-flipped {
    flex-direction: row-reverse;
  }
  .region-columns--two--4-8-flipped > section:nth-of-type(1) {
    max-width: 66%;
  }
  .region-columns--two--4-8-flipped > section:nth-of-type(2) {
    max-width: 32%;
  }
  .region-columns--two--9-3 > section:nth-of-type(1) {
    max-width: 74%;
  }
  .region-columns--two--9-3 > section:nth-of-type(2) {
    max-width: 24%;
  }
  .region-columns--two--9-3-flipped {
    flex-direction: row-reverse;
  }
  .region-columns--two--9-3-flipped > section:nth-of-type(1) {
    max-width: 24%;
  }
  .region-columns--two--9-3-flipped > section:nth-of-type(2) {
    max-width: 74%;
  }
  .region-columns--two--3-9 > section:nth-of-type(1) {
    max-width: 24%;
  }
  .region-columns--two--3-9 > section:nth-of-type(2) {
    max-width: 74%;
  }
  .region-columns--two--3-9-flipped {
    flex-direction: row-reverse;
  }
  .region-columns--two--3-9-flipped > section:nth-of-type(1) {
    max-width: 74%;
  }
  .region-columns--two--3-9-flipped > section:nth-of-type(2) {
    max-width: 24%;
  }
}
@media (min-width: 1150px) {
  .region-columns--three > section {
    max-width: 32%;
  }
  .region-columns--four > section {
    max-width: 24%;
  }
}

.region-blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
}
.region-blocks > div, .region-blocks > li, .region-blocks .simple-button {
  flex: 1;
  flex-basis: 100%;
  margin: 0 0 6px 0;
  padding: 0;
}
@media (min-width: 855px) {
  .region-blocks--1-2 > div, .region-blocks--1-2 > li, .region-blocks--1-2 .simple-button {
    flex-basis: 49%;
    max-width: calc(50% - 6px);
  }
  .region-blocks--1-2 > div:not(:nth-of-type(2n)), .region-blocks--1-2 > li:not(:nth-of-type(2n)), .region-blocks--1-2 .simple-button:not(:nth-of-type(2n)) {
    margin-right: 6px;
  }
  .region-blocks--1-3-3 > div, .region-blocks--1-3-3 > li, .region-blocks--1-3-3 .simple-button {
    flex-basis: 33%;
    max-width: calc(33.3333333333% - 6px);
  }
  .region-blocks--1-3-3 > div:not(:nth-of-type(3n)), .region-blocks--1-3-3 > li:not(:nth-of-type(3n)), .region-blocks--1-3-3 .simple-button:not(:nth-of-type(3n)) {
    margin-right: 6px;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .site-annex.content-layout-sidebar-right-narrow .page__container,
  .site-conservation .page__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .site-annex.content-layout-sidebar-right-narrow .page__container .page-region,
  .site-conservation .page__container .page-region {
    width: 100%;
  }
  .site-annex.content-layout-sidebar-right-narrow .page__container .page-region__banner,
  .site-conservation .page__container .page-region__banner {
    width: 100%;
  }
}
.banner__container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.banner__container.banner-image-exists {
  height: 25vw;
  max-height: 400px;
  min-height: 200px;
  background-color: black;
}

.banner__image {
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.banner__image.bottom-align {
  background-position: bottom right;
}
.banner__image.bottom-align-20 {
  background-position: bottom 20% right;
}
.banner__image.bottom-align-40 {
  background-position: bottom 40% right;
}
.banner__image.middle-align {
  background-position: middle right;
}
.banner__image.top-align {
  background-position: top right;
}
.banner__image.top-align-20 {
  background-position: top 20% right;
}
.banner__image.top-align-40 {
  background-position: top 40% right;
}
.banner__image.overlay-blur {
  overflow: hidden;
  filter: blur(1px);
}
.banner__image-overlay {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 85%);
  background-size: auto;
  background-repeat: repeat;
  width: 100%;
  height: 100%;
}
.overlay-brain-white .banner__image-overlay {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url("//www.lib.uiowa.edu/includes/uids/images/brain-overlay-white-trans050.png");
  background-size: auto auto;
  background-repeat: repeat repeat;
}
.overlay-brain-black .banner__image-overlay {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url("//www.lib.uiowa.edu/includes/uids/images/brain-overlay-black-trans025.png");
  background-size: auto auto;
  background-repeat: repeat repeat;
}
.overlay-brain-gold .banner__image-overlay {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url("//www.lib.uiowa.edu/includes/uids/images/brain-overlay-gold-trans025.png");
  background-size: auto auto;
  background-repeat: repeat repeat;
}
.overlay-triangles .banner__image-overlay {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url("//www.lib.uiowa.edu/includes/uids/images/cover-pattern-triangles.png");
  background-size: auto auto;
  background-repeat: repeat repeat;
}
.overlay-tiles .banner__image-overlay {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 80%), url("//www.lib.uiowa.edu/includes/uids/images/cover-pattern-tiles.png");
  background-size: auto auto;
  background-repeat: repeat repeat;
}
.overlay-tiles-light .banner__image-overlay {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url("//www.lib.uiowa.edu/includes/uids/images/cover-pattern-tiles-light.png");
  background-size: auto auto;
  background-repeat: repeat repeat;
}
.overlay-tiles-small .banner__image-overlay {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url("//www.lib.uiowa.edu/includes/uids/images/cover-pattern-tiles-small.png");
  background-size: auto auto;
  background-repeat: repeat repeat;
}
.overlay-particles .banner__image-overlay {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url("//www.lib.uiowa.edu/includes/uids/images/cover-pattern-particles-2.png");
  background-size: auto auto;
  background-repeat: repeat repeat;
  background-position: top left, -20px -20px;
}
.overlay-particles-2 .banner__image-overlay {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url("//www.lib.uiowa.edu/includes/uids/images/cover-pattern-particles-1.png");
  background-size: auto auto;
  background-repeat: repeat repeat;
  background-position: top left, -20px -20px;
}
.overlay-screendoor .banner__image-overlay {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url("//www.lib.uiowa.edu/includes/uids/images/cover-pattern-screendoor.png");
  background-size: auto auto;
  background-repeat: repeat repeat;
}
.overlay-gridpaper .banner__image-overlay {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url("//www.lib.uiowa.edu/includes/uids/images/cover-pattern-gridpaper.png");
  background-size: auto auto;
  background-repeat: repeat repeat;
}
.banner.half-height {
  max-height: 200px;
}

.banner__content {
  z-index: 10;
  margin: 20px auto;
  padding: 0 0 0 0;
  width: calc(100% - 40px);
}
.banner__content .breadcrumbs ol {
  margin: 0;
  padding: 0;
}
.banner__content .breadcrumbs ol li {
  display: inline-block;
  text-shadow: none;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
}
.banner__content .breadcrumbs ol li:not(:first-child):before {
  display: inline-block;
  margin: 0 1rem;
  content: " /";
  color: #ffcd00;
}
.banner-image-exists .banner__content .breadcrumbs ol li:not(:first-child):before {
  text-shadow: 0 1px 1px #000;
}
.banner__content .breadcrumbs ol li a {
  color: #151515;
  text-decoration: none;
  text-shadow: none;
}
.banner-image-exists .banner__content .breadcrumbs ol li a {
  color: white;
  text-shadow: 0 1px 1px #000;
}
.banner__content .breadcrumbs ol li a:hover {
  text-decoration: underline;
}
.banner__content .entry-title {
  margin: 0;
  font-family: "Zilla Slab", "Roboto", serif;
  font-weight: 600;
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.5;
  color: black;
  text-shadow: none;
}
.banner-image-exists .banner__content .entry-title {
  text-shadow: 2px 2px 3px #000;
  color: white;
}
.banner__content .entry-title--long, .banner__content .entry-title--super-long {
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 1.5;
}
@media (min-width: 855px) {
  .banner__content .entry-title {
    font-size: 48px;
    font-size: 4.8rem;
    line-height: 1.5;
  }
  .banner__content .entry-title--long {
    font-size: 36px;
    font-size: 3.6rem;
    line-height: 1.5;
  }
  .banner__content .entry-title--super-long {
    font-size: 30px;
    font-size: 3rem;
    line-height: 1.5;
  }
}
@media (min-width: 1150px) {
  .banner__content {
    max-width: 1150px;
  }
  .banner__content .entry-title {
    font-size: 54px;
    font-size: 5.4rem;
    line-height: 1.5;
  }
  .banner__content .entry-title--long {
    font-size: 40px;
    font-size: 4rem;
    line-height: 1.5;
  }
  .banner__content .entry-title--super-long {
    font-size: 32px;
    font-size: 3.2rem;
    line-height: 1.5;
  }
}

.page-region__nav-secondary .libcal-todays-hours ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.page-region__nav-secondary .libcal-todays-hours ul li {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
}
.page-region__nav-secondary ul.menu.subnav-sidebar {
  margin: 0 -4px 20px;
}

.page-region__footer-local {
  margin-top: 40px;
  background-color: #ccc;
}
.page-region__footer-local .page-region-inner {
  margin: 0 auto;
  padding: 20px;
  max-width: 1150px;
}
.page-region__footer-local .page-region-inner p:first-of-type {
  margin-top: 0;
}
.page-region__footer-local .page-region-inner p:last-of-type {
  margin-bottom: 0;
}
.page-region__footer-local--empty {
  margin-top: 20px;
  background-color: transparent;
}
body {
  position: relative;
}
body .scrolltop-wrap {
  display: none;
  position: absolute;
  bottom: 0;
  right: 2rem;
  height: calc(100% - 100vh);
  z-index: 9999;
}
body .scrolltop-wrap:before {
  content: "";
  display: block;
  height: 100vh;
  pointer-events: none;
}
body .scrolltop-wrap a {
  position: sticky;
  top: 90vh;
  cursor: pointer;
  font-size: 20px;
}
body .scrolltop-wrap a svg {
  transform: scale(0.9);
  transition: 0.2s;
  filter: drop-shadow(#666 2px 2px 2px);
}
body .scrolltop-wrap a:hover {
  cursor: pointer;
}
body .scrolltop-wrap a:hover svg {
  transform: scale(1);
}
body .scrolltop-wrap a:hover svg #scrolltop-bg {
  fill: #e6b900;
}

.site-root.home .page__container .page-region .page-region-inner {
  padding-left: 0;
  padding-right: 0;
}

.site-home.page-id-4312 .page-region__banner {
  margin-bottom: 0;
  height: 40vw;
  max-height: 600px !important;
}
.site-home.page-id-4312 .page-region__banner .breadcrumbs {
  display: none;
}
.site-home.page-id-4312 .page-region__banner .entry-title {
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.5;
}
.site-home.page-id-4312 .section-top {
  grid-column: full;
}
.site-home.page-id-4312 .section-top .big-feature-item {
  min-height: 520px;
}
.site-home.page-id-4312 .page-region__banner .banner__image {
  background-position: top 40% center;
}
.site-home.page-id-4312 .page-region__banner .banner__content {
  padding-bottom: 0;
}
.site-home.page-id-4312 .page-region__banner .banner__content h2 em {
  display: block;
  font-size: 66%;
}
@media (min-width: 855px) {
  .site-home.page-id-4312 .page-region__banner .banner__content {
    padding-bottom: 240px;
  }
  .site-home.page-id-4312 .page-region__banner .entry-title {
    font-size: 48px;
    font-size: 4.8rem;
    line-height: 1.5;
  }
}
@media (min-width: 1150px) {
  .site-home.page-id-4312 .page-region__banner .page-region__banner {
    height: 60vw;
  }
  .site-home.page-id-4312 .page-region__banner .entry-title {
    font-size: 70px;
    font-size: 7rem;
    line-height: 1.5;
  }
}

.search-widget {
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
  z-index: 33;
  grid-column: col-start 1/span 12;
}
.search-widget .search-widget-inner {
  margin: 0;
  min-height: auto;
}
.search-widget .title-widget-search {
  color: black;
}
.search-widget .title-widget-search span {
  position: absolute;
  left: -10000px;
  top: auto;
  height: 1px;
  overflow: hidden;
  width: 1px;
}
.search-widget .title-widget-search i {
  font-size: 0.6em;
  transition: 0.1s;
}
.search-widget .title-widget-search i:before {
  display: block;
  position: relative;
  top: -4px;
  left: 1px;
  transition: 0.2s linear all;
}
.search-widget.focus-ON .title-widget-search i {
  color: #666;
}
.search-widget.focus-ON .title-widget-search i:before {
  transform: scale(0.9) rotate(-90deg);
}
.search-widget form#simple2 {
  height: auto;
  padding: 20px;
  margin: 0;
  background-color: #ccc;
}
.search-widget form#simple2 .core {
  display: flex;
  gap: 0;
  height: 48px;
}
.search-widget form#simple2 .core label {
  flex: 5;
  max-height: 48px;
}
.search-widget form#simple2 .core label input#ihplus_search {
  padding: 0 1rem;
  height: 48px;
  width: calc(100% - 2rem);
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
}
.search-widget form#simple2 .core input#submit {
  flex: 1;
  height: 52px;
  background-size: 20%;
}
.search-widget form#simple2 ul.search-options {
  clear: both;
  display: block;
  margin: 4px 0 0 0;
  padding: 0;
}
.search-widget form#simple2 ul.search-options li {
  display: inline-block;
  margin: 2px 3rem 0 0;
  color: #111;
  width: auto !important;
  height: 34px;
}
.search-widget form#simple2 ul.search-options li a, .search-widget form#simple2 ul.search-options li span {
  color: #111;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  line-height: 34px;
  text-decoration: none;
}
.search-widget form#simple2 ul.search-options li a:hover, .search-widget form#simple2 ul.search-options li span:hover {
  color: #666 !important;
}
.search-widget form#simple2 ul.search-options li.focus-toggle {
  /* Hide default HTML checkbox */
  /* The slider */
  /* Rounded sliders */
}
.search-widget form#simple2 ul.search-options li.focus-toggle input {
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden;
}
.search-widget form#simple2 ul.search-options li.focus-toggle .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.search-widget form#simple2 ul.search-options li.focus-toggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.search-widget form#simple2 ul.search-options li.focus-toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 60px;
  height: 34px;
  background-color: #999;
  transition: 0.4s;
}
.search-widget form#simple2 ul.search-options li.focus-toggle .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}
.search-widget form#simple2 ul.search-options li.focus-toggle input:checked + .slider {
  background-color: #ffcd00;
}
.search-widget form#simple2 ul.search-options li.focus-toggle input:focus + .slider {
  box-shadow: 0 0 1px #ffcd00;
}
.search-widget form#simple2 ul.search-options li.focus-toggle input:checked + .slider:before {
  transform: translateX(26px);
}
.search-widget form#simple2 ul.search-options li.focus-toggle [aria-checked=true] {
  background-color: #ffcd00;
}
.search-widget form#simple2 ul.search-options li.focus-toggle [aria-checked=true]:before {
  transform: translateX(26px);
}
.search-widget form#simple2 ul.search-options li.focus-toggle [aria-checked=true] + label .focus-off {
  display: none;
}
.search-widget form#simple2 ul.search-options li.focus-toggle + label .focus-off {
  display: none;
}
.search-widget form#simple2 ul.search-options li.focus-toggle [aria-checked=false] + label .focus-on {
  display: none;
}
.search-widget form#simple2 ul.search-options li.focus-toggle .slider.round {
  border-radius: 34px;
}
.search-widget form#simple2 ul.search-options li.focus-toggle .slider.round:before {
  border-radius: 50%;
}
.search-widget form#simple2 ul.search-options li.focus-toggle #focus-label {
  display: inline-block;
}
.search-widget form#simple2 ul.search-options li.focus-toggle #focus-label .focus-on,
.search-widget form#simple2 ul.search-options li.focus-toggle #focus-label .focus-off {
  margin-left: 70px;
}
.search-widget form#simple2 ul.search-options li.focus-toggle .focus-help {
  font-size: 11px;
  font-size: 1.1rem;
  line-height: 1.5;
}
.search-widget form#simple2 ul.search-options li.focus-toggle .focus-help a {
  text-decoration: none;
}
.search-widget form#simple2 ul.search-options li.advanced-search {
  margin-inline-start: 20px;
}
.search-widget form#simple2 ul.search-options li.ihplus-help {
  margin-inline-end: 0;
}
@media (min-width: 855px) {
  .site-home.page-id-4312 .search-widget {
    margin-top: -220px;
  }
  .site-home.page-id-4312 .search-widget .title-widget-search {
    color: white;
  }
  .site-home.page-id-4312 .search-widget form#simple2 {
    box-shadow: 0px -48px 96px rgba(0, 0, 0, 0.9);
  }
}
@media (min-width: 1150px) {
  .search-widget {
    margin-right: 0;
    margin-left: 0;
  }
}

.site-home.page-id-4312 .section-contact {
  margin: 20px 0 40px 0;
}
.site-home.page-id-4312 .section-resources {
  margin-bottom: 30px;
}

.site-home.page-id-4312 .section-locations {
  margin-bottom: 30px;
}
.site-home.page-id-4312 .section-locations .locations-here {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.site-home.page-id-4312 .section-locations .locations-here .liblock__location {
  flex: 1;
  flex-basis: 100%;
  margin: 0 0 6px 0;
  padding: 0;
  background-color: white;
}
.site-home.page-id-4312 .section-locations .locations-here .liblock__location .liblock-inner {
  background-color: #e5e6e7;
}
.site-home.page-id-4312 .section-locations .locations-here .liblock__location--title {
  margin: 0;
  padding: 1rem 1rem 0.5rem 0;
  width: 100%;
}
.site-home.page-id-4312 .section-locations .locations-here .liblock__location--email-link, .site-home.page-id-4312 .section-locations .locations-here .liblock__location--phone, .site-home.page-id-4312 .section-locations .locations-here .liblock__location--contact-more, .site-home.page-id-4312 .section-locations .locations-here .liblock__location--contact-link, .site-home.page-id-4312 .section-locations .locations-here .liblock__location--people-link, .site-home.page-id-4312 .section-locations .locations-here .liblock__location--mailing-address, .site-home.page-id-4312 .section-locations .locations-here .liblock__location--hours--primary, .site-home.page-id-4312 .section-locations .locations-here .liblock__location--campus-address {
  display: none;
}
.site-home.page-id-4312 .section-locations .locations-here .liblock__location--home-link, .site-home.page-id-4312 .section-locations .locations-here .liblock__location--infopage-link {
  display: inline-block;
  margin-bottom: 1rem;
  width: 100%;
}
.site-home.page-id-4312 .section-locations .locations-here .liblock__location--home-link a, .site-home.page-id-4312 .section-locations .locations-here .liblock__location--infopage-link a {
  display: block;
  padding: 1rem;
  width: calc(100% - 2rem);
  text-align: start;
}
.site-home.page-id-4312 .section-locations .locations-here .liblock__location--home-link a i:before, .site-home.page-id-4312 .section-locations .locations-here .liblock__location--infopage-link a i:before {
  display: inline-block;
  transition: 0.2s;
}
.site-home.page-id-4312 .liblock-inner {
  display: flex;
  margin: 0;
}
.site-home.page-id-4312 .liblock-inner .location-image {
  flex: 1;
}
.site-home.page-id-4312 .liblock-inner .location-image a, .site-home.page-id-4312 .liblock-inner .location-image img {
  display: block;
  margin: 0;
  padding: 0;
  width: 180px;
}
.site-home.page-id-4312 .liblock-inner .location-info {
  flex: 4;
  padding: 1rem;
}
.site-home.page-id-4312 .liblock-inner .liblock__hours--contact {
  display: none;
}
.site-home.page-id-4312 .liblock-inner .liblock__hours--list {
  margin-left: 0;
  width: 100%;
}
.site-home.page-id-4312 .liblock-inner .liblock__hours--footer {
  display: none;
}
.site-home.page-id-4312 .hours-id-14197 .liblock-inner .liblock__hours--contact {
  display: block;
  background-color: transparent;
  width: 100%;
}
.site-home.page-id-4312 .hours-id-14197 .liblock-inner .liblock__hours--contact + ul.liblock__hours--list {
  display: none;
}
@media (min-width: 855px) {
  .site-home.page-id-4312 .section-locations .locations-here .liblock__location--home-link {
    margin-right: 2%;
    width: 49%;
  }
  .site-home.page-id-4312 .section-locations .locations-here .liblock__location--infopage-link {
    width: 49%;
  }
}
@media (min-width: 1150px) {
  .site-home.page-id-4312 .section-locations .locations-here .liblock__location {
    flex-basis: 49%;
  }
  .site-home.page-id-4312 .section-locations .locations-here .liblock__location:not(:nth-of-type(2n)) {
    margin-right: 6px;
  }
}

.site-home.page-id-4312 .section-features {
  margin-bottom: 30px;
}

.site-home.page-id-4312 .section-news-events {
  margin-bottom: 30px;
}
.site-home.page-id-4312 .section-news-events > section {
  margin-bottom: 3rem;
}
.site-home.page-id-4312 .section-news-events a.more {
  float: right;
  margin-top: 3rem;
}

body.site-home .pagination {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 0;
}
body.site-home .pagination a {
  width: calc(100% - 8rem);
}
@media (min-width: 480px) {
  body.site-home .pagination {
    flex-direction: row;
  }
  body.site-home .pagination a {
    width: auto;
  }
}
body.site-home.news span.title a {
  font-weight: bold;
}
body.site-home.events .pagination.offset-0 {
  flex-direction: row-reverse;
}
body.site-home.events .event-title a {
  font-weight: bold;
}

.site-home.page-id-4312 .section-fdlp {
  grid-column: full;
  margin: 0;
  padding: 20px 0;
  background-color: #e1e1e1;
  color: black;
}
.site-home.page-id-4312 .section-fdlp:after {
  content: "";
  display: table;
  clear: both;
}
.site-home.page-id-4312 .section-fdlp .section-inner {
  margin: 0 20px;
  max-width: 1150px;
}
.site-home.page-id-4312 .section-fdlp .section-inner .fdlp-emblem {
  float: right;
  margin: 0 0 0 20px;
}
.site-home.page-id-4312 .section-fdlp .section-inner .fdlp-emblem svg {
  max-width: 100px;
  max-height: 88.8px;
}
.site-home.page-id-4312 .section-fdlp .section-inner h3 {
  display: inline-block;
}
.site-home.page-id-4312 .section-fdlp .section-inner p {
  margin: 0;
}
.site-home.page-id-4312 .section-fdlp .section-inner:hover .fdlp-emblem svg .shield-bg {
  fill: #1c3f95;
}
.site-home.page-id-4312 .section-fdlp .section-inner:hover .fdlp-emblem svg .pages-inside,
.site-home.page-id-4312 .section-fdlp .section-inner:hover .fdlp-emblem svg .cover-inside {
  fill: #e51937;
}
@media (min-width: 1150px) {
  .site-home.page-id-4312 .section-fdlp .section-inner {
    margin: 0 auto;
  }
}

.site-home.page-id-4312 .section-give {
  grid-column: full;
  clear: both;
  margin: 0;
  padding: 0;
  background-image: url("//www.lib.uiowa.edu/wp-content/uploads/2021/04/mildred-books-banner-bg.jpg");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-color: #ffcd00;
  min-height: 400px;
  width: 100%;
  color: black;
}
.site-home.page-id-4312 .section-give:after {
  content: "";
  display: table;
  clear: both;
}
.site-home.page-id-4312 .section-give .section-overlay {
  background-color: rgba(0, 0, 0, 0.7);
}
.site-home.page-id-4312 .section-give .section-inner {
  margin: 0 20px;
  padding: 40px 0;
  max-width: 1150px;
  min-height: 400px;
}
.site-home.page-id-4312 .section-give .section-inner h3 {
  color: white;
}
.site-home.page-id-4312 .section-give .section-inner h4:first-of-type {
  text-align: center;
  font-size: 60px;
  font-size: 6rem;
  line-height: 1.5;
  color: white;
  font-family: "Lora", serif;
  font-style: italic;
  text-shadow: 2px 2px #000;
}
.site-home.page-id-4312 .section-give .section-inner h4:last-of-type {
  margin: 4rem 0 2rem 0;
  margin-bottom: 3rem;
  text-align: center;
}
@media (min-width: 1150px) {
  .site-home.page-id-4312 .section-give .section-inner {
    margin: 0 auto;
  }
}

.events-list-contenthub .event.main-gallery-exhibit {
  display: none;
}
.events-list-contenthub .event.main-gallery-exhibit.mlg-exhibit-0 {
  display: block;
}

.site-home .breadcrumbs ol li:nth-of-type(2) {
  display: none;
}

.site-web.single-post.support dl.def-list-classes {
  padding-left: 2em;
}

/* ----- BEGIN Shame ----- */
.gform_body .chosen-container .chosen-single span {
  overflow: visible;
}

/* ----- END Shame ----- */