.site-home.page-id-4312 {

  // Technically, the "Contact a Librarian" button is not in resources, but here we are
  .section-contact {
    margin: 20px 0 40px 0;
  }
  
  .section-resources { 
    margin-bottom: $section-bottom-gap;
  }
}