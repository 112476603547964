// —————————————————————————————————————————————————————————————————————————————————
// Card component VARIANTS
// ---------------------------------------------------------------------------------


// card-media ----------------------------------------------------------------------
.card-list.card-media--square .uids-card,
.uids-card.card-media--square {
	--card-media-aspect: 1 / 1;
}
.card-list.card-media--wide .uids-card,
.uids-card.card-media--wide {
	--card-media-aspect: 1.618 / 1;
}
.card-list.card-media--circle .uids-card,
.uids-card.card-media--circle {
	--card-media-aspect: 1 / 1;

	.card-media img {
		border-radius: 50%;
	}
}

.card-list.card-media--align-top .uids-card,
.uids-card.card-media--align-top {
	--card-media-position: top;
}
.card-list.card-media--align-bottom .uids-card,
.uids-card.card-media--align-bottom {
	--card-media-position: bottom;
}


// card-button ---------------------------------------------------------------------
.card-list.card-button--no-valign .uids-card,
.uids-card.card-button--no-valign {
	--card-button-margin-top: 0;
}

.card-list.card-button--full-width .uids-card,
.uids-card.card-button--full-width {
	--card-button-margin-right: 0;
	--card-button-margin-left: 0;
}

.card-list.card-button--hide .uids-card,
.uids-card.card-button--hide {

	.card-body {

		.card-button {
			display: none;
		}
	}
}


// card-title ----------------------------------------------------------------------
.card-list.card-title--serif .uids-card,
.uids-card.card-title--serif {
	--card-title-font: #{$font-header-serif};
	--card-title-size: 2.2rem;
	--card-title-weight: 700;
}

.card-list.card-title--hide .uids-card,
.uids-card.card-title--hide {
	--card-body-padding-top: 1rem;

	.card-title {
		@include hide-offscreen;
	}
}

.card-list.card-title--size-smaller .uids-card,
.uids-card.card-title--size-smaller {
	--card-title-size: 1.8rem;
}

.card-list.card-title--size-smallest .uids-card,
.uids-card.card-title--size-smallest {
	--card-title-size: 1.6rem;
}


// card-desc -----------------------------------------------------------------------
.card-list.card-desc--none .uids-card,
.uids-card.card-desc--none {
	// --card-title-margin-bottom: 2rem;

	.card-body {

		.card-desc {
			display: none;
		}
	}
}


// card-border ---------------------------------------------------------------------
.card-list.card-border--none .uids-card,
.uids-card.card-border--none,
.card-list.card-border--alt-odd .uids-card:nth-child(even),
.card-list.card-border--alt-even .uids-card:nth-child(odd) {
	--card-border-color: rgba(0, 0, 0, 0);
	--card-border-width: 0;

	--card-body-padding-right: 0;
	--card-body-padding-left: 0;
}


// card-background ------------------------------------------------------------------
.card-list.card-bg--black .uids-card,
.uids-card.card-bg--black,
.card-list.card-bg--black-odd .uids-card:nth-child(odd),
.card-list.card-bg--black-even .uids-card:nth-child(even) {
	--card-bg-color: black;
	--card-border-color: black;

	--card-body-padding-right: 1rem;
	--card-body-padding-left: 1rem;
	--card-body-text-color: white;

	--card-title-color: white;

	--card-desc-link-color: white;
	--card-desc-link-visited-color: #white;
	--card-desc-link-hover-color: #ccc;
	--card-desc-link-active-color: white;
	--card-desc-link-focus-color: white;

	.card-body {

		.card-button {
			color: white !important;
			border-color: white !important;

			i:before {
				color: $hawk-gold;
			}

			&.no-link-text {

				i:before {
					color: white;
				}
			}
		}
	}
}

.card-list.card-bg--gold .uids-card,
.uids-card.card-bg--gold,
.card-list.card-bg--gold-odd .uids-card:nth-child(odd),
.card-list.card-bg--gold-even .uids-card:nth-child(even) {	
	--card-bg-color: #{$hawk-gold};

	--card-body-padding-right: 1rem;
	--card-body-padding-left: 1rem;
	--card-body-text-color: black;

	.card-body{
		
		.card-button {
			color: black !important;
			border: 1px solid black !important;

			&:after {
				background-color: white !important;
			}

			i:before {
				color: white;
			}

			&.no-link-text {

				i:before {
					color: black;
				}

				&:hover {
					outline: 4px solid white;
				}
			}
		}
	}
}

.card-list.card-bg--grey .uids-card,
.uids-card.card-bg--grey,
.card-list.card-bg--grey-odd .uids-card:nth-child(odd),
.card-list.card-bg--grey-even .uids-card:nth-child(even) {	
	--card-bg-color: #{$secondary-grey-lighter};

	--card-body-padding-right: 1rem;
	--card-body-padding-left: 1rem;

	.card-body {

		.card-button {
			color: black !important;
			border: 1px solid $hawk-gold !important;
		}
	}
}


// card-button ---------------------------------------------------------------------
.card-list.card-button--full-width .uids-card,
.uids-card.card-button--full-width {

	.card-body {
		
		.card-button {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
	}
}


// card-body -----------------------------------------------------------------------
.card-list.card-body--centered .uids-card,
.uids-card.card-body--centered {
	--card-body-text-align: center;

	.card-body {

		.card-button {
			margin-left: auto;
			margin-right: auto;
		}
	}
}


// card-orientation -----------------------------------------------------------------------
.card-list.card-orientation--left .uids-card,
.uids-card.card-orientation--left {
	--card-media-margin: 0 0 0 0;

	--card-body-padding-top: 1rem;
	--card-body-padding-right: 2rem;
	--card-body-padding-left: 2rem;
	--card-body-padding-bottom: 2rem;

	flex-direction: row;
	justify-content: flex-start;
	flex-basis: 100%;

	.card-media {
		min-width: 33%;
		width: 33%;

		img {
			height: 100%;
		}
	}
}

.card-list.card-orientation--right .uids-card,
.uids-card.card-orientation--right {
	--card-media-margin: 0 0 0 0;

	--card-body-padding-top: 1rem;
	--card-body-padding-right: 2rem;
	--card-body-padding-left: 2rem;
	--card-body-padding-bottom: 2rem;

	flex-direction: row-reverse;
	justify-content: flex-end;
	flex-basis: 100%;

	.card-media {
		min-width: 33%;
		width: 33%;

		img {
			height: 100%;
		}
	}
}


// card-padding --------------------------------------------------------------------
.card-list.card-padding--some .uids-card,
.uids-card.card-padding--some {
	--card-padding: 1rem;

	--card-body-padding-right: 0 !important;
	--card-body-padding-bottom: 0 !important;
	--card-body-padding-left: 0 !important;
}

.card-list.card-padding--more .uids-card,
.uids-card.card-padding--more {
	--card-padding: 2rem;

	--card-body-padding-right: 0 !important;
	--card-body-padding-bottom: 0 !important;
	--card-body-padding-left: 0 !important;
}

.card-list.card-padding--most .uids-card,
.uids-card.card-padding--most {
	--card-padding: 3rem;

	--card-body-padding-right: 0 !important;
	--card-body-padding-bottom: 0 !important;
	--card-body-padding-left: 0 !important;
}
