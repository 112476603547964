#content .single-post {
	margin: 1em 0;
	padding-bottom: 1em;
	// border-bottom: 1px solid $gray-lightest;

	.entry-title {

	}

	.entry-info {
		color: $gray-dark;
		@include font-size(1.2);

		a {
			color: $gray-dark;
		}

		abbr[title] {
			text-decoration: none;
		}
	}

	.entry-content {
		
		img {
			padding: 0;
			border: 0;
			background-color: transparent;
			
			&.alignright {
				float: right;
				margin: 1em 0 1em 1.5em;
			}

			&.alignleft {
				float: left;
				margin: 1em 1.5em 1em 0;
			}
		}

		ul {
			overflow: hidden;

			li {
				margin-left: 0.5em;
			}
		}
	}
}

