.banner {

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 20px;
  
    &.banner-image-exists {
      height: 25vw;
      max-height: 400px;
      min-height: 200px;
      background-color: black;
    }
  }
}