// Used for grids of buttons and block displays with tight gutters.
// Notation:  1-2-4 denotes the number of block columns at smal, medium and fixed width, respectively
.region-blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;

  >div, >li, .simple-button {
    flex: 1;
    flex-basis: 100%; // ALL blocks end up stacked into a single column by default
    margin: 0 0 6px 0;
    padding: 0;
  }

  @include breakpoint($medium-width) {
      
    &--1-2 {

      >div, >li, .simple-button {
        flex-basis: 49%;
        max-width: calc(50% - 6px);
          
        &:not(:nth-of-type(2n)) {
          margin-right: 6px;
        }
      }
    }

    &--1-3-3 {

      >div, >li, .simple-button {
        flex-basis: 33%;
        max-width: calc(100% / 3 - 6px);
          
        &:not(:nth-of-type(3n)) {
          margin-right: 6px;
        }
      }
    }
  }
  @include breakpoint($fixed-width) {
    
    
  }
}