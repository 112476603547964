
a[href*="sharepoint.uiowa.edu"] {
    &:after { 
		content:" (Authentication required) "; 
	}
}

a[href$=".pdf"].iconify { 
	@include simple-icon('file-pdf');
}

a[href$=".mp3"].iconify { 
	@include simple-icon('file-audio');
}