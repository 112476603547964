$section-fdlp-bg: 		#e1e1e1;
$fdlp-emblem-width:			100px;
$fdlp-emblem-height:		$fdlp-emblem-width * 0.888;

.site-home.page-id-4312 {

	.section-fdlp {
		grid-column: full;
		@include clearfix;
		margin: 0;
		padding: 20px 0;
		background-color: $section-fdlp-bg;
		color: color-contrast($section-fdlp-bg);

		.section-inner {
			margin: 0 20px;
			max-width: $fixed-width;
			

			.fdlp-emblem {
				float: right;
				margin: 0 0 0 20px;
				
				svg {
					max-width: $fdlp-emblem-width;
					max-height: $fdlp-emblem-height;
				}
			}

			h3 {
				display: inline-block;
			}

			p {
				margin: 0;
			}

			&:hover {

				.fdlp-emblem svg {

					.shield-bg {
						fill: #1c3f95;
					}
					.pages-inside,
					.cover-inside {
						fill: #e51937;
					}
				}
			}
		}

		// --- BREAKPOINTS ------------------------
		@include breakpoint($fixed-width) {

			.section-inner {
				margin: 0 auto;
			}
		}
		// ---------------------------------------
		
	}
}