// —————————————————————————————————————————————————————————————————————————————————
// DEFAULT Card component layouts using a card-list container
// ---------------------------------------------------------------------------------

.card-list {
  	--card-gap: 1rem;

  	display: flex;
	flex-flow: column wrap;
	justify-content: flex-start;
	gap: var(--card-gap);

  margin: 0;
	padding: 0;

	.uids-card {
		flex-basis: 100%; // DEFAULT for narrowest viewports
	}
}

.card-list--2 {
	@extend .card-list;

	@include breakpoint($medium-width) {
		flex-flow: row wrap;

		.uids-card {
			flex-basis: theFlexBasis(2, var(--card-gap));
		}
	}
}

.card-list--3 {
	@extend .card-list;
	
	@include breakpoint($medium-width) {
		flex-flow: row wrap;

		.uids-card {
			flex-basis: theFlexBasis(2, var(--card-gap));
		}
	}

	@include breakpoint($fixed-width) {

		.uids-card {
			flex-basis: theFlexBasis(3, var(--card-gap));
		}
	}
}

.card-list--4 {
	@extend .card-list;

	@include breakpoint($smallish-width) {
		flex-flow: row wrap;

		.uids-card {
			flex-basis: theFlexBasis(2, var(--card-gap));
		}
	}
	
	@include breakpoint($medium-width) {

		.uids-card {
			flex-basis: theFlexBasis(3, var(--card-gap));
		}
	}

	@include breakpoint($fixed-width) {

		.uids-card {
			flex-basis: theFlexBasis(4, var(--card-gap));
		}
	}
}

.card-list--5 {
	@extend .card-list;

	@include breakpoint($smallish-width) {
		flex-flow: row wrap;

		.uids-card {
			flex-basis: theFlexBasis(2, var(--card-gap));
		}
	}
	
	@include breakpoint($medium-width) {

		.uids-card {
			flex-basis: theFlexBasis(3, var(--card-gap));
		}
	}

	@include breakpoint($fixed-width) {

		.uids-card {
			flex-basis: theFlexBasis(5, var(--card-gap));
		}
	}
}

.card-list--6 {
	@extend .card-list;

	@include breakpoint($smallish-width) {
		flex-flow: row wrap;

		.uids-card {
			flex-basis: theFlexBasis(2, var(--card-gap));
		}
	}
	
	@include breakpoint($medium-width) {

		.uids-card {
			flex-basis: theFlexBasis(3, var(--card-gap));
		}
	}

	@include breakpoint($fixed-width) {

		.uids-card {
			flex-basis: theFlexBasis(6, var(--card-gap));
		}
	}
}

.card-gap--more {
	--card-gap: 2rem;
}

.card-gap--most {
	--card-gap: 3rem;
}