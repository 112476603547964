// TEMP - CLEAN UP

// ------ sidebar boxes
.widgetcontainer { 
	padding: 10px; 
	margin-bottom: 10px; 
	@include font-size(1.4);
	border: 1px solid #ccc;

	ul {
		list-style-type: none; 
		margin: 0; 
		padding: 0;

		li {
			margin: 0;
			// text-indent: -1em;

			a {
				display: inline-block;
				margin: 0.25em 0 0.25em 1em;
				text-indent: -1em;
				line-height: 2.4rem;
			}
		}
	}

	.excerpt {
		font-size: 11px; 
		margin: 5px 0;
	}

	input[type=text], 
	input[type=password] { 
		width: 80%; 
	}

	.widgettitle { 
		color: #333; 
		margin: -10px -10px 10px; 
		padding: 5px 10px; 
		background-color: #ccc;
		border-bottom: 1px solid #ccc; 

		a {
			color: #333; 
		}
	}
}