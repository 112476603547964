a.badlink-scua {
	background-color: #E2B858; // yellow
	color: black;
}
a.badlink-scua-bai {
	background-color: #90dbff; // blue
	color: black;
}
a.badlink-sdrc {
	background-color: #fca87b; // orange
	color: black;
}
a.badlink-sdrc-dada {
	background-color: transparent;
	color: $link-standard;
}