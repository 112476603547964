dl.uids-list {
    padding: 1rem;
    background-color: #ccc;

    dt {

    }

    dd {
        margin-left: 0;
    }
}