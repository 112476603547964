
// body {

  // ======================================================
  // --- BEGIN Single column (all content stacked) (DEFAULT)
  .page__container {
    display: grid;
    column-gap: 0;
    row-gap: 0;
    grid-template-columns: [full-start] minmax(0, auto) [content-start] repeat(12, [col-start] minmax(0, calc($fixed-width/12))) [content-end] minmax(0, auto) [full-end];
    grid-auto-rows: minmax(0, min-content);
    
    .page-region {
      
      .page-region-inner {
        padding: 0 $gap;
      }
      
      &__banner {
        grid-column: full-start / full-end;
        grid-row: 1 / 2;
      }
      &__nav-secondary {
        grid-column: col-start 1 / span 12;
        grid-row: 2 / 3;
      }
      &__content-primary {
        grid-column: col-start 1 / span 12;
        grid-row: 3 / 4;
      }
      &__content-secondary {
        grid-column: col-start 1 / span 12;
        grid-row: 4 / 5;
      }      
      &__footer-local {
        grid-column: full-start / full-end;
        grid-row: 5 / 6;

        .page-region-inner {
          padding: $gap;
        }
      }

      @include breakpoint($medium-width) {
        
      }
      @include breakpoint($fixed-width) {

        .page-region-inner {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
  // ======================================================

  

  // ------------------------------------------------------
  // --- BEGIN Narrow sidebar on the right
  .content-layout-sidebar-right-narrow .page__container {

    @include breakpoint($medium-width) {

      .page-region {
  
        // &__banner {
        //   grid-column: full-start / full-end;
        //   grid-row: 1 / 2;
        // }
        &__nav-secondary {
          grid-column: col-start 10 / span 3;
          grid-row: 2 / 3;

          .page-region-inner {
            padding-left: 0;
          }
        }
        &__content-primary {
          grid-column: col-start 1 / span 9;
          grid-row: 2 / 5;
          
          .page-region-inner {
            padding-right: $gap*1.5;
          }
        }
        &__content-secondary {
          grid-column: col-start 10 / span 3;
          grid-row: 3 / 4;

          .page-region-inner {
            padding-left: 0;
          }
        }
        // &__footer-local {
        //   grid-column: full-start / full-end;
        //   grid-row: 5 / 6;
        // }
      }
    }
    @include breakpoint($fixed-width) {
      
    }
  }
  // ------------------------------------------------------

  // ------------------------------------------------------
  // --- BEGIN Narrow sidebar on the left
  .content-layout-sidebar-left-narrow .page__container {

    @include breakpoint($medium-width) {

      .page-region {
        padding: 0;
  
        // &__banner {
        //   grid-column: full-start / full-end;
        //   grid-row: 1 / 2;
        // }
        &__nav-secondary {
          grid-column: col-start 1 / span 3;
          grid-row: 2 / 3;
          
          .page-region-inner {
            padding-right: 0;
          }
        }
        &__content-primary {
          grid-column: col-start 4 / span 9;
          grid-row: 2 / 5;
          
          .page-region-inner {
            padding-left: $gap*2;
          }
        }
        &__content-secondary {
          grid-column: col-start 1 / span 3;
          grid-row: 3 / 4;
          
          .page-region-inner {
            padding-right: 0;
          }
        }
        // &__footer-local {
        //   grid-column: full-start / full-end;
        //   grid-row: 5 / 6;
        // }
      }
    }
    @include breakpoint($fixed-width) {
      
    }
  }
  // ------------------------------------------------------

  // ------------------------------------------------------
  // --- BEGIN Wide sidebar on the right
  .content-layout-sidebar-right-wide .page__container {

    @include breakpoint($medium-width) {

      .page-region {
  
        // &__banner {
        //   grid-column: full-start / full-end;
        //   grid-row: 1 / 2;
        // }
        &__nav-secondary {
          grid-column: col-start 9 / span 4;
          grid-row: 2 / 3;
          
          .page-region-inner {
            padding-left: 0;
          }
        }
        &__content-primary {
          grid-column: col-start 1 / span 8;
          grid-row: 2 / 5;
          
          .page-region-inner {
            padding-right: $gap*2;
          }
        }
        &__content-secondary {
          grid-column: col-start 9 / span 4;
          grid-row: 3 / 4;

          .page-region-inner {
            padding-left: 0;
          }
        }
        // &__footer-local {
        //   grid-column: full-start / full-end;
        //   grid-row: 5 / 6;
        // }
      }
    }
    @include breakpoint($fixed-width) {
      
    }
  }
  // ------------------------------------------------------

  // ------------------------------------------------------
  // --- BEGIN Wide sidebar on the left
  .content-layout-sidebar-left-wide .page__container {

    @include breakpoint($medium-width) {

      .page-region {
  
        // &__banner {
        //   grid-column: full-start / full-end;
        //   grid-row: 1 / 2;
        // }
        &__nav-secondary {
          grid-column: col-start 1 / span 4;
          grid-row: 2 / 3;
          
          .page-region-inner {
            padding-right: 0;
          }
        }
        &__content-primary {
          grid-column: col-start 5 / span 8;
          grid-row: 2 / 5;
          
          .page-region-inner {
            padding-left: $gap*2;
          }
        }
        &__content-secondary {
          grid-column: col-start 1 / span 4;
          grid-row: 3 / 4;
          
          .page-region-inner {
            padding-right: 0;
          }
        }
        // &__footer-local {
        //   grid-column: full-start / full-end;
        //   grid-row: 5 / 6;
        // }
      }
    }
    @include breakpoint($fixed-width) {
      
    }
  }
  // ------------------------------------------------------
  
  // ------------------------------------------------------
  // --- BEGIN Two sidebars, nav on the right
  .content-layout-two-sidebars-nav-right .page__container {

    @include breakpoint($medium-width) {

      .page-region {
  
        // &__banner {
        //   grid-column: full-start / full-end;
        //   grid-row: 1 / 2;
        // }
        &__nav-secondary {
          grid-column: col-start 10 / span 3;
          grid-row: 2 / 3;

          .page-region-inner {
            padding-left: 0;
          }
        }
        &__content-primary {
          grid-column: col-start 1 / span 9;
          grid-row: 2 / 4;

          .page-region-inner {
            padding-right: $gap*2;
          }
        }
        &__content-secondary {
          grid-column: col-start 1 / span 9;
          grid-row: 4 / 5;

          .page-region-inner {
            padding-right: $gap*2;
          }
        }
        // &__footer-local {
        //   grid-column: full-start / full-end;
        //   grid-row: 5 / 6;
        // }
      }
    }
    @include breakpoint($fixed-width) {
      
      .page-region {
  
        &__nav-secondary {
          grid-column: col-start 10 / span 3;
          grid-row: 2 / 3;

          .page-region-inner {
            padding-left: 0;
          }
        }
        &__content-primary {
          grid-column: col-start 4 / span 6;
          grid-row: 2 / 4;
          
          .page-region-inner {
            padding-right: $gap*2;
            padding-left: $gap*2;
          }
        }
        &__content-secondary {
          grid-column: col-start 1 / span 3;
          grid-row: 2 / 3;

          .page-region-inner {
            padding-right: 0;
          }
        }
      }
    }
  }
  // ------------------------------------------------------

  // ------------------------------------------------------
  // --- BEGIN Two sidebars, nav on the left
  .content-layout-two-sidebars-nav-left .page__container {

    @include breakpoint($medium-width) {

      .page-region {
  
        // &__banner {
        //   grid-column: full-start / full-end;
        //   grid-row: 1 / 2;
        // }
        &__nav-secondary {
          grid-column: col-start 1 / span 3;
          grid-row: 2 / 3;

          .page-region-inner {
            padding-right: 0;
          }
        }
        &__content-primary {
          grid-column: col-start 4 / span 9;
          grid-row: 2 / 4;

          .page-region-inner {
            padding-left: $gap*2;
          }
        }
        &__content-secondary {
          grid-column: col-start 4 / span 9;
          grid-row: 4 / 5;

          .page-region-inner {
            padding-left: $gap*2;
          }
        }
        // &__footer-local {
        //   grid-column: full-start / full-end;
        //   grid-row: 5 / 6;
        // }
      }
    }
    @include breakpoint($fixed-width) {
      
      .page-region {
  
        &__nav-secondary {
          grid-column: col-start 1 / span 3;
          grid-row: 2 / 3;

          .page-region-inner {
            padding-right: 0;
          }
        }
        &__content-primary {
          grid-column: col-start 4 / span 6;
          grid-row: 2 / 4;
          
          .page-region-inner {
            padding-right: $gap*2;
            padding-left: $gap*2;
          }
        }
        &__content-secondary {
          grid-column: col-start 10 / span 3;
          grid-row: 2 / 3;

          .page-region-inner {
            padding-left: 0;
          }
        }
      }
    }
  }
  // ------------------------------------------------------
// }