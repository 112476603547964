dl.def-list-classes {
	margin-bottom: 2em;

	dt {
		margin-bottom: 0.5em;
		font-weight: bold;
	}

	dd {
		margin-bottom: 1em;
	}
}