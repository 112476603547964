.resources-list {

  .resource-item {

    a {
      @include clearfix;
      display: block;
      padding: 1em;      
      width: 100%;
      text-decoration: none;

      background-color: #666; // default. should always be overridden.

      .resource-region-1 {
        float: left;
        padding-right: 1em;
        width: 40%;

        .resource-area {
          padding-right: 1em;
          font-family: $font-stack-cursive;
          @include font-size(2);
          // font-weight: lighter;
        }

        .resource-person {

          .person-image {
            float: left;
            padding: 0.5em;
            max-height: 120px;
            border-radius: 50%;
          }
          .person-title {
            clear: both;
            padding-top: 0px;
            @include font-size(1.1);
            // font-weight: lighter;
          }
        }
      }

      .resource-region-2 {
        float: left;
        width: 59%;

        .resource-title {
          margin-top: 0;
          @include font-size(2.2);
          text-transform: uppercase;
          font-weight: lighter;
        }

        .resource-description p {
          margin: 0;
          font-family: $font-stack-body-2 !important;
          font-style: italic;
          font-weight: lighter;
        }
      }
    }
  }

  @include breakpoint($medium-width) {

    .resource-item a {

      .resource-region-1 {

        .resource-area {
          float: right;
          @include font-size(3);
        }

        .resource-person {

          .person-image {
            max-height: 120px;
          }
          .person-title {
            clear: none;
            padding-top: 90px;
          }
        }
      }
    }
  }
}