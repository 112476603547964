

// TODO:  Conflate this class into one or the other.
.element-invisible {
  @include hide-offscreen;
}

.iowa-bar { 
  position: relative; 
  z-index: 2; 
  display: block; 
  min-height: auto; 
  background: $hawk-black; 
  // margin-bottom: 10px;
  @include clearfix;

  @include breakpoint($medium-width) { 
    background: $hawk-gold; 
  }

  .logo--tab {
    margin-right: 20px; 
    padding: 25px 20px;
    background-color: $hawk-black;

    .logo-icon {
      height: 20px;
      fill: $hawk-gold;
      transition: 0.1s;
    
      &:hover {
        opacity: 0.7;
      }
    }
    @include breakpoint($medium-width) { 
      padding: 27px 20px 20px; 

      .logo-icon {
        height: 30px;
      }
    } 
    @include breakpoint($fixed-width) { 
      margin-left: -12px; 
  
      .logo-icon {
        height: 43px;
      }
    } 
  } // END .iowa-bar .logo--tab

  .site-name {
    padding: 20px;
    width: 100%;
    background-color: $hawk-gold;
    @include font-size(3.2);

    a {
      text-decoration: none;
      color: $hawk-black;
      font-weight: 300;
    }

    &__subsite-name {
      @include hide-offscreen;
    }

    @include breakpoint($medium-width) { 
      padding-left: 0;
      width: auto; 
      background-color: transparent; 
      
    } 
    @include breakpoint($fixed-width) { 
      margin: 5px 0 0 0; 
    }
  } // END .iowa-bar .site-name

  &__container { 
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto; 
    max-width: $fixed-width;

    @include breakpoint($medium-width) { 
      justify-content: flex-start; 
      display: flex;
      margin-right: auto; 
      margin-left: auto;
    } 
    // TODO: In what context(s) are we going to use the narrow header? Keep it in for now.
    @include breakpoint($fixed-width) { 
      .iowa-bar--narrow.horizontal &, 
      .iowa-bar--narrow.mega & { 
        position: relative; 
      } 
    } 
  } // END .iowa-bar--container

  &--full {

    // .logo--tab {
    //   padding: 24px 20px 20px 20px;
    //   background-color: $hawk-black;
    
    //   .logo-icon {
    //     fill: $hawk-gold;
    //     transition: 0.1s;
    
    //     &:hover {
    //       opacity: 0.7;
    //     }
    //   }
    // }

    @include breakpoint($medium-width) {  
      height: 80px; 
      background: $hawk-gold; 
    } 
  } // END .iowa-bar--full

  &--narrow {

    @include breakpoint($medium-width) { 
      min-height: 50px; 
      position: relative; 
    }

    // Overrides the logo tab styles when using the narrow version of the IOWA bar.
    .logo--tab {
      margin-right: 10px;

      @include breakpoint($medium-width) {
        height: 56px;
        width: 119px;
        padding: 15px;
      }

      @include breakpoint($fixed-width) {
        margin-bottom: -6px;
      }

      // @todo Move this to search or search-toggle?
      // TODO:  I love it that someone else uses todos in their comments.
      .search-is-open & {
        visibility: none;
        opacity: 0;

        @include breakpoint($medium-width) {
          display: flex;
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .logo-icon { 
      height: 20px;

      @include breakpoint($medium-width) { 
        height: 26px;
      } 
    } 

    // TEMP:  WTF is this?
    .parent-site-name {
      @include breakpoint($fixed-width) {
        padding: 6px 0 0 0;
      }
    }

    .site-name { 
      margin: 0; 
      padding: 1.25rem; 
      font-size: 1rem;
      background: $hawk-gold; 

      @include breakpoint($medium-width) {
        padding: 0;
        background: transparent; 
        font-size: 1rem; 
      }

      @include breakpoint($fixed-width) {
        padding: 6px 0 0 0; 
      }
    } 





  } // END .iowa-bar--narrow

  // Styles for container underneath
  // TODO:  Figure out what this is. Alerts?
  &--below {
    z-index: 2;
    border-bottom: 1px solid #e6e5e5;
    background: #fff;

    & .iowa-bar__container {
      flex-wrap: wrap;
      justify-content: space-between;
      margin-right: 0;
      margin-left: 0;

      @include breakpoint($medium-width) {
        display: flex;
        position: unset;
        min-height: 90px;
        justify-content: unset;
        margin-right: auto;
        padding-left: 1.25rem;
      }
      @include breakpoint($fixed-width) {
        margin-left: auto;
        padding-left: 0;
      }
    }

    // Override .site-name styles when
    // displayed underneath the iowa-bar.
    .site-name {
      margin: 0;
      text-align: left;
      font-size: 1.8rem;

      @include breakpoint($medium-width) {
        width: 75%;
        padding: 25px 20px 25px 0;
        background: transparent;
        font-size: 2rem;
      }

      @include breakpoint($fixed-width) {
        font-size: 2.2rem;
      }

      // @todo Move to search or search-toggle?
      .search-is-open & {
        @include breakpoint($medium-width) {
          display: none;
        }
      }
    }
  } // END .iowa-bar--below


  // ———————————————————————————————————————————————————————————
  // --- BREAKPOINTS -------------------------------------------

  @include breakpoint($medium-width) { 
    
  }

  @include breakpoint($fixed-width) { 
    
  }

  // -----------------------------------------------------------
  // ———————————————————————————————————————————————————————————

}  // END .iowa-bar
