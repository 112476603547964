html {
  font-size: 62.5%;  // Sets up the Base 10 stuff
}

body, p, ul {
	font-family: $font-stack-body;
	@include font-size(1.6);

	.small-type,
	&.small-type {
		@include font-size(1.2);
	}
}
.entry-content p {
	@include font-size(1.6);
	line-height: 1.4em;
}
.entry-content li {
	@include font-size(1.6);
}

// ------ Headings
h1, h2, h3, h4 {
	font-family: $font-stack-headers;
	font-weight: bold;
	// clear: both; // This clear: both was too heavy-handed. 
}

h1 {
	// @include hide-offscreen;  // by default
	margin: 0;
	padding-bottom: 0.5em;
	@include font-size(2.6);
}
.header-wrapper-inner h1 {
	@include hide-offscreen;  // TEMP: Eliminate this when UIDS is implemented
}
h2 {
	margin: 0;
	padding-bottom: 0.5em;
	@include font-size(2.2);

	&.site-name {
		font-weight: lighter;
	}

	&.entry-title {
		margin: 0.75em 0;
		padding: 0;
		@include font-size(2.6);
		font-weight: 300;
	}
}
h3 {
	margin: 0;
	padding-bottom: 0.5em;
	@include font-size(2.0);
}
h4 {
	margin: 1.33em 0 0 0;
	padding-bottom: 0.25em;
	@include font-size(1.8);
}
h5 {
	margin: 1.33em 0 0 0;
	padding-bottom: 0.25em;
	@include font-size(1.6);

	+ p {
		margin-top: 0.25em;
	}
}

code {
	font-size: 100%;
}
// ------------

// @import "typography-google-fonts";
