html, body {
	max-width: 100%;
	overflow-x: hidden;
}

body #content .entry-content {
	padding: 0 8px;

	// === BREAKPOINTS ==============================================================================
	@include breakpoint($fixed-width) {
		padding: 0;
	}
	// ==============================================================================================
}

#main {
	margin: 0 auto;
	min-height: 400px;
	width: 100%;
}