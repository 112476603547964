// TEMP
// TODO:  WTF is this? Endeavor to delete with prejudice.
#top-menu {

	ul#menu-top {
		display: inline-block;
		float: right;

		li {

			a {
				display: block;
				padding: 2px 8px;
			}
		}
	}
}