div.gallery {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  clear: both;

  br {
    display: none;
  }

  dl.gallery-item {
    margin-right: 1.5rem;
    flex-basis: 150px;
    
    dt.gallery-icon {

    }
    
    img {
      max-width: 150px;
    }
    dd.wp-caption-text {
      margin: 0;
      @include font-size(1.2);
    }
  }
}

#slb_viewer_wrap {

  .slb_theme_slb_default .slb_data_content {

    .slb_data_title {
      font-family: $font-stack-body;
      @include font-size(1.4);
      font-weight: 300;
    }
  
    .slb_group_status {
      float: right;
      font-family: $font-stack-body;
      @include font-size(1.4);
      font-weight: 300;
    }
  }
}