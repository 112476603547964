#nav {

  .sf-menu {
    float: left;
    line-height: 1.2;

    a {
      display: block; 
      margin: 0;
      padding: 8px 8px 12px 8px;
      text-decoration: none; 
      color: #e1e1e1;
      transition: background 0.1s linear, color 0.1s linear;
    }

    ul {

      li {
        border-bottom: 1px solid #444; 
        margin-top: 0; 
        background-color: #373331; 

        &:last-of-type {
          border-bottom: none;
        }
      }
    }

    >li:hover >a {
      background-color: $hawk-gold;
      color: #111;
    }

    // >li >a:hover,
    // li ul.sub-menu li a:hover {
    //   background-color: $hawk-gold-hover;
    //   color: #111;
    // }
    a:hover, 
    li:hover , 
    li.sfHover { 
      background-color: $hawk-gold-hover;
      color: #111;
    }
  }
}

// ------------------------------------------------------------------------------------------
// --- Stuff below this line needs to be audited 
// ------------------------------------------------------------------------------------------
// #nav .sf-menu a:hover, 
// #nav .sf-menu li:hover, 
// #nav .sf-menu li.sfHover, 
// #nav .sf-menu ul li { 
//   background: #373331; 
//   // background-color: $hawk-gold-hover;
//   color: #FFF; 
//   // color: #111;
// }

#nav .sf-menu ul a:hover, 
#nav .sf-menu ul li.sfHover, 
#nav .sf-menu ul li:hover { 
  color: #373331; 
  background: #e1e1e1; 
}
#nav .sf-menu ul li.sfHover a.sf-with-ul { 
  color: #373331; 
}
#nav .sf-menu li li a { 
  padding: 0.75em; 
  // line-height: 1; 
  height: auto; 
  @include font-size(1.2); 
}
// #nav .sf-menu .sf-with-ul { padding-right: 15px; }
#nav .sf-sub-indicator { 
  background: url(/images/dropdown.png) no-repeat; 
  width: 5px; 
  height: 5px; 
  text-indent: -9999px; 
  display: inline-block; 
  position: relative; 
  left: 7px; 
  top: 0; 
  vertical-align: middle; 
}
#nav .sf-menu ul .sf-sub-indicator { 
  display: none; 
}
#nav .quick-nav li { 
  padding: 5px 0 0 8px; 
}

///* Menus */
.sf-menu, 
.sf-menu * { 
  list-style: none; 
  margin: 0; 
  padding: 0; 
}
.sf-menu { 
  line-height: 1; 
}
.sf-menu ul { 
  position: absolute; 
  left: -999em; 
  width: 11em; 
  z-index: 100; 
}
.sf-menu ul li { 
  width: 100%; 
}
.sf-menu li:hover { 
  visibility: inherit; 
}
.sf-menu li { 
  float: left; 
  position: relative; 
}
.sf-menu a { 
  display: block; 
  position: relative; 
}
.sf-menu li:hover ul, 
.sf-menu li.sfHover ul { 
	left: 0; 
	top: 2.2em; 
	z-index: 99; 
}
ul.sf-menu li:hover li ul, 
ul.sf-menu li.sfHover li ul { 
  top: -999em; 
}
ul.sf-menu li li:hover ul, 
ul.sf-menu li li.sfHover ul { 
  left: 11em; 
  top: 0; 
}
ul.sf-menu li li:hover li ul, 
ul.sf-menu li li.sfHover li ul { 
  top: -999em; 
}
ul.sf-menu li li li:hover ul, 
ul.sf-menu li li li.sfHover ul { 
  left: 11em; top: 0; 
}
.sf-shadow ul { 
  background: url(/images/shadow.png) no-repeat bottom right; 
  padding: 0 8px 9px 0; 
  border-top-right-radius: 17px;
  border-bottom-left-radius: 17px;
  // -moz-border-radius-bottomleft: 17px; 
  // -moz-border-radius-topright: 17px; 
  // -webkit-border-top-right-radius: 17px; 
  // -webkit-border-bottom-left-radius: 17px; 
}
.sf-shadow ul.sf-shadow-off { 
  background: transparent; 
}
.quick-nav { 
  float: right; 
  list-style: none; 
  margin: 0 10px 0; 
  padding: 0; 
}
.quick-nav li { 
  float: left; 
  display: inline; 
}
#menu-mobile { 
  display: none; 
}
#mobileonly { 
  display: none; 
}
