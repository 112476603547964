@import "home-shiny-base";
@import "home-shiny-banner";
@import "home-shiny-search";
@import "home-shiny-resources";
@import "home-shiny-locations";
@import "home-shiny-featured";
@import "home-shiny-news-events";
@import "home-shiny-fdlp";
@import "home-shiny-give";

// TEMP: testing events logic
.events-list-contenthub {

  .event {

    &.main-gallery-exhibit {
      display: none;

      &.mlg-exhibit-0 {
        display: block;
      }
    }
  }
}



.site-home {

  // This is the root site site and its link is already built into the breadcrumbs.
  .breadcrumbs ol li:nth-of-type(2) {
    display: none; 
  }
}