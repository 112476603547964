body.theme-ranganathan #nav {
	display: none;
	padding-top: 12px;
	background: none;
	background-color: $header-bg;

	#nav-content.new-format {
		display: block;
	}

	// === BREAKPOINTS =================================================================================
	@include breakpoint($medium-width) {
		position: relative; 
		display: block;
		z-index: 101;

		#nav-content.new-format {
			width: $medium-width;
		}

		a {
			@include font-size(1.4);
		}
	}

	@include breakpoint($fixed-width) {
			
		#nav-content.new-format {
			margin: 0 auto;
			width: $fixed-width;

			ul#menu-main {
				display: flex;
				width: 100%;
	
				// all levels
				li {
	
					a {
	
						span {
							font-size: 80%;
						}
					}
				}
	
				// top-level items
				>li {
					flex: 1;
	
					>a {
						text-transform: uppercase;
						text-align: center;
					}
	
					&.sfHover:last-of-type {
	
						>ul.sub-menu {
							left: auto;
							right: 0;
						}
					}
				}
			}
		}
		









		#nav-content {
			margin: 0 auto;
			width: $fixed-width;
		}










		
	}
	// =================================================================================================
}

// TEMP
body.theme-ranganathan #mobnav {
	display: block;

	@include breakpoint($medium-width) {
		display: none;
	}
}