.pod-region-slide-outer {
	margin: 0 0 1em 0;

	.pod-region-slide-inner {
		margin: 0 auto;
		max-width: $large-width;  // default width of a slide

		.slide-item {
			position: relative;
			@include clearfix;

			>a {
				display: block;
				color: white;
				text-decoration: none;

				&:hover {

					.slide-info {
						background-color: rgba(black, 1.0);
					}
				}
			}

			.slide-image {
				
				img {
					margin: 0;
					padding: 0;
					width: 100%;
					height: auto;
				}
			}

			.slide-cover {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}

			.slide-info {

				.slide-title {
					clear: both;
					margin: 0 0 0.5em 0;
					padding: 0.5em;
					background-color: $default__slide-text-bg;
					color: $default__slide-text-color;
					@include font-size(1.6);
					max-width: 100%;
					font-weight: lighter;

					em {
						display: block;
						margin-top: 8px;
						font-size: 80%;
					}
				}

				.slide-title-image {
					position: absolute;
					top: 20px;
					// width: 80%;
					height: auto;
				}

				.slide-description {
					position: absolute;
					bottom: 0;
					margin: 0;
					padding: 2px 4px;
					background-color: rgba(black, 0.8);
					
					p {
						margin: 0;
						padding: 0;
						@include font-size(1.2);
						color: white;
					}

					a {
						color: white;
					}
				}
			}
		}
	}

	// -----------------------------------------------------------------------------
	// ------ BREAKPOINTS ----------------------------------------------------------
	// -----------------------------------------------------------------------------

	@include breakpoint($fixed-width) {

		.pod-region-slide-inner {

			.slide-item {

				.slide-info {

					.slide-title {
						position: absolute;
						top: 32px;
						left: 0;
						margin: 0;
						// padding: 0.5em 2em;
						background-color: $default__slide-text-bg;
						color: $default__slide-text-color;
						@include font-size($default__slide-title-font-size);
						max-width: 90%;

						em {
							display: block;
							margin-top: 8px;
							font-size: 80%;
						}
					}
				}
			}
		}
	}

	// -----------------------------------------------------------------------------
}
.jumbotron {

	.pod-region-slide-outer {

		.pod-region-slide-inner {
			max-width: 100%;
		}
	}
}