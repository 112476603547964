
.banner-region {
	margin: 0;
	max-width: 100%;
	background-color: $banner-bg;
	background-image: linear-gradient($header-bg, $banner-bg);
	

	.banner-region-inner {
		display: block;
		margin: 0 auto;
		max-width: $fixed-width;
	}

	@include breakpoint($smallish-width) {

		.banner-region-inner {
			display: flex;
			flex-flow: row nowrap;
		}

		&.banner-region-flipped {

			.banner-region-inner {
				flex-flow: row-reverse nowrap;
			}
		}
	}
}