// —————————————————————————————————————————————————————————————————————————————————
// DEFAULT Card component
// Libraries' support:  https://www.lib.uiowa.edu/web/support/uids-card/
// UIDS reference:  https://uids.brand.uiowa.edu/?path=/docs/components-card--docs
// ---------------------------------------------------------------------------------

.uids-card {
	--card-bg-color: white;
	--card-border-color: rgba(0, 0, 0, 0.125);
	--card-border-width: 1px;
	--card-padding: 0;

	--card-media-margin: 0 0 1rem 0;
	--card-media-width: 100%;
	--card-media-aspect: auto;
	--card-media-position: auto;

	--card-body-padding-top: 0;
	--card-body-padding-right: 1rem;
	--card-body-padding-bottom: 1rem;
	--card-body-padding-left: 1rem;
	--card-body-text-align: start;
	--card-body-text-color: black;

	--card-title-font: #{$font-header-sans-serif};
	--card-title-size: 2rem;
	--card-title-weight: bold;
	--card-title-color: black;
	--card-title-margin-top: 0;
	--card-title-margin-bottom: 0;

	--card-desc-link-color: #{$link-standard};
	--card-desc-link-visited-color: #{$link-visited};
	--card-desc-link-hover-color: #{$link-hover};
	--card-desc-link-active-color: #{$link-active};
	--card-desc-link-focus-color: #{$link-active};
	--card-desc-margin: 0 0 0 0;

	--card-button-margin-top: auto;
	--card-button-margin-right: auto;
	--card-button-margin-bottom: 0;
	--card-button-margin-left: 0;

	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;

	flex-basis: 100%;

	margin: 0;
	padding: var(--card-padding);
	border: var(--card-border-width) solid var(--card-border-color) !important;
	box-sizing: border-box;
	background-color: var(--card-bg-color) !important;

	.card-media {
		order: 0;
		margin: var(--card-media-margin);
		padding: 0;
		width: var(--card-media-width);

		img {
			display: block;
			width: 100%;
			object-fit: cover;
			object-position: var(--card-media-position);
			aspect-ratio: var(--card-media-aspect);

		}
	}

	.card-body {
		flex: 1;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;

		order: 1;

		padding: var(--card-body-padding-top) var(--card-body-padding-right) var(--card-body-padding-bottom) var(--card-body-padding-left);
		text-align: var(--card-body-text-align);
		color: var(--card-body-text-color);

		.card-title {
			margin-top: var(--card-title-margin-top);
			margin-bottom: var(--card-title-margin-bottom);
			font-family: var(--card-title-font);
			font-size: var(--card-title-size);
			font-weight: var(--card-title-weight);

			a {
				color: var(--card-title-color);
				text-decoration: none;
	
				&:hover {
					text-decoration: underline;
				}
			}
		}

		.card-desc {
			margin: var(--card-desc-margin);

			>p, >ul {
				margin-top: 0;
			}

			a {
		
				color: var(--card-desc-link-color);

				&:visited {
					color: var(--card-desc-link-visited-color);
				}
				&:hover {
					color: var(--card-desc-link-hover-color);
				}
				&:active {
					color: var(--card-desc-link-active-color);
				}
				&:focus {
					color: var(--card-desc-link-focus-color);
				}
			}
		}

		.card-button {
			@extend .uids-button--card-cta;

			display: inline-block;
			margin: var(--card-button-margin-top) var(--card-button-margin-right) var(--card-button-margin-bottom) var(--card-button-margin-left);
			padding: 1.5rem;
			background-color: transparent;
			transition: 0.1s;
	
			&.no-link-text {
				display: inline-block;
				width: 2rem;
				border-radius: 4rem;
	
				i {
					margin-left: 0;
	
					&:before {
						color: black;
						transform: scale(1.1);
					}
				}
	
				&:hover {
					outline: 4px solid $hawk-gold;
	
					&:after {
						display: none;
					}
				}
				.card-bg--gold &:hover {
					outline: 4px solid $white;
				}
			}
	
			&.no-link-url {
				display: none;
			}
		}
		a.card-button,
		a.card-button.no-link-text {
			display: none;
		}
	}
}
