@import "global-narrow";
@import "testing";

.nav--horizontal {
  @include clearfix;
  margin-top: 10px;
  clear: both;

  border-bottom: 1px solid #e6e5e5;

  .nav-inner {
    margin: 0 auto;
    max-width: $fixed-width;
  }
}

.nav--vertical {
  // nothing, yet
}

.block-superfish.block-superfishmain {
  width: 100%;

  .sf-hidden {
    background: transparent !important;
    border: 0 none !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px !important;
    margin:  !important;
    overflow: hidden !important;
    padding: 0 !important;
    width: 1px !important;
  }

  ul.sf-menu {
    @include clearfix;
  }
  ul.sf-menu,
  ul.sf-menu li {
    list-style: none;
  }

  ul.sf-menu,
  ul.sf-menu ul,
  ul.sf-menu ol,
  ul.sf-menu li,
  ul.sf-menu a,
  ul.sf-menu span,
  ul.sf-menu div {
    margin: 0;
    padding: 0;
  }
  ul.sf-menu {
    line-height: 1.0;
    z-index: 497;
  }
  ul.sf-menu ul {
    left: 0;
    position: absolute;
    top: -9999px;
    width: 20em;
    background-color: white;
  }
  ul.sf-menu ul li {
    width: 100%;
  }
  ul.sf-menu li {
    float: left;
    position: relative;
    z-index: 498;
  }
  ul.sf-menu a,
  ul.sf-menu span.nolink {
    display: block;
    padding: 0.75em 1.5em;
    position: relative;
    text-decoration: none;
    color: #151515;
  }

  // ul.sf-menu > li {

  // 	&:after {
  // 		content: "";
  // 		position: absolute;
  // 		left: 0;
  // 		bottom: 0;
  // 		width: 100%;
  // 		height: 5px;
  // 		background-color: $hawk-gold;
  // 		// transform-origin: center;
  // 		transform: translate(-50%, 0) scaleX(0);
  // 		transition: transform 0.3s ease-in-out;
  // 	}
  // 	&:hover:after {
  // 		transform: translate(-50%, 0) scaleX(1);
  // 	}
  // }

  // top-level menu behavior
  ul.sf-menu {

    > li > a,
    > li > span.nolink {
      padding: 1em 1.5em;
      font-weight: 600;

      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 5px;
        content: "";
        transition: .3s;
        background-color: $hawk-gold;
      }
    }

    > li:hover > a,
    > li:focus-within > a,
    > li:hover > span.nolink {

      &:after {
        width: 100%;
      }
    }
  }

  ul.sf-menu > li {

    ul {
      background-color: rgb(241, 241, 241);
    }

    li {
      padding: 1em 1.5em;
      transition: 0.1s;

      &:hover,
      &:focus-within {
        background-color: #ddd;
      }
    }
  }





  ul.sf-menu li:hover,
  ul.sf-menu li.sfHover,
  ul.sf-menu li:hover ul,
  ul.sf-menu li.sfHover ul {
    z-index: 499;

    

  }
  ul.sf-menu li:hover > ul,
  ul.sf-menu li.sfHover > ul {
    left: 0;
    top: 3em;
  }
  ul.sf-menu li li:hover > ul,
  ul.sf-menu li li.sfHover > ul {
    left: 12em;
    top: 0;
  }
  ul.sf-menu.menu,
  ul.sf-menu.menu ul,
  ul.sf-menu.menu li {
    margin: 0;
    padding: 0;
  }
  /**
    * Arrows.
    */
  // ul.sf-menu .sf-with-ul {
  // 	padding-right: 3em;
  // }
  ul.sf-menu .sf-sub-indicator {
    height: 12px;
    line-height: 9999px;
    opacity: 0.75;
    overflow: hidden;
    position: absolute;
    right: 0.75em;
    speak: none;
    text-indent: -9999px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 12px;
  }

  // top-level menu down chevron behavior
  ul.sf-menu > li.sf-depth-1.menuparent {

    &:first-of-type > a {


      @include breakpoint($fixed-width) {
        padding-left: 0;
      }
    }

    &:before {
      position: absolute;
      top: 2em;
      right: 0.25em;
      content: "\f078";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 1rem;
      line-height: 1;
      text-indent: 0;
      color: #999;
      transition: 0.2s;
    }

    &:hover:before,
    &:focus:before,
    &:focus-within:before {
      top: 2.2em;
    }
  }


  // ul.sf-menu ul .sf-sub-indicator:after {
  // 	content: "►";
  // }
  ul.sf-menu a:focus > .sf-sub-indicator,
  ul.sf-menu a:hover > .sf-sub-indicator,
  ul.sf-menu a:active > .sf-sub-indicator,
  ul.sf-menu li:hover > a > .sf-sub-indicator,
  ul.sf-menu li.sfHover > a > .sf-sub-indicator,
  ul.sf-menu span.nolink:hover > .sf-sub-indicator,
  ul.sf-menu li:hover > span.nolink > .sf-sub-indicator,
  ul.sf-menu li.sfHover > span.nolink > .sf-sub-indicator {
    opacity: 1;
  }
  /**
    * Shadow.
    */
  // ul.sf-menu.sf-horizontal.sf-shadow ul,
  // ul.sf-menu.sf-vertical.sf-shadow ul,
  // ul.sf-menu.sf-navbar.sf-shadow ul ul {
  // 	-webkit-box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
  // 	-moz-box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
  // 	box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
  // }
  // ul.sf-menu.sf-shadow ul.sf-shadow-off {
  // 	-webkit-box-shadow: none;
  // 	-moz-box-shadow: none;
  // 	box-shadow: none;
  // }
  /**
    * Multi-column sub-menus.
    * Supersubs.js can adjust column widths too and this is a fallback.
    */
  ul.sf-menu li.sf-multicolumn-column {
    display: inline;
    float: left;
  }
  ul.sf-menu li.sf-parent-children-1 ul.sf-multicolumn {
    width: 12em;
  }
  ul.sf-menu li.sf-parent-children-2 ul.sf-multicolumn {
    width: 24em;
  }
  ul.sf-menu li.sf-parent-children-3 ul.sf-multicolumn {
    width: 36em;
  }
  ul.sf-menu li.sf-parent-children-4 ul.sf-multicolumn {
    width: 48em;
  }
  ul.sf-menu li.sf-parent-children-5 ul.sf-multicolumn {
    width: 60em;
  }
  ul.sf-menu li.sf-parent-children-6 ul.sf-multicolumn {
    width: 72em;
  }
  ul.sf-menu li.sf-parent-children-7 ul.sf-multicolumn {
    width: 84em;
  }
  ul.sf-menu li.sf-parent-children-8 ul.sf-multicolumn {
    width: 96em;
  }
  ul.sf-menu li.sf-parent-children-9 ul.sf-multicolumn {
    width: 108em;
  }
  ul.sf-menu li.sf-parent-children-10 ul.sf-multicolumn {
    width: 120em;
  }
  ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(2),
  ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(1) {
    width: 50%;
  }
  ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(3),
  ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(2),
  ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(1) {
    width: 32.333%;
  }
  ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(4),
  ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(3),
  ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(2),
  ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(1) {
    width: 25%;
  }
  ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(5),
  ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(4),
  ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(3),
  ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(2),
  ul.sf-menu li.sf-multicolumn-column:nth-child(5):nth-last-child(1) {
    width: 20%;
  }
  ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(6),
  ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(5),
  ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(4),
  ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(3),
  ul.sf-menu li.sf-multicolumn-column:nth-child(5):nth-last-child(2),
  ul.sf-menu li.sf-multicolumn-column:nth-child(6):nth-last-child(1) {
    width: 16.665%;
  }
  ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(7),
  ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(6),
  ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(5),
  ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(4),
  ul.sf-menu li.sf-multicolumn-column:nth-child(5):nth-last-child(3),
  ul.sf-menu li.sf-multicolumn-column:nth-child(6):nth-last-child(2),
  ul.sf-menu li.sf-multicolumn-column:nth-child(7):nth-last-child(1) {
    width: 14.2855%;
  }
  ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(8),
  ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(7),
  ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(6),
  ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(5),
  ul.sf-menu li.sf-multicolumn-column:nth-child(5):nth-last-child(4),
  ul.sf-menu li.sf-multicolumn-column:nth-child(6):nth-last-child(3),
  ul.sf-menu li.sf-multicolumn-column:nth-child(7):nth-last-child(2),
  ul.sf-menu li.sf-multicolumn-column:nth-child(8):nth-last-child(1) {
    width: 14.2855%;
  }
  ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(9),
  ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(8),
  ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(7),
  ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(6),
  ul.sf-menu li.sf-multicolumn-column:nth-child(5):nth-last-child(5),
  ul.sf-menu li.sf-multicolumn-column:nth-child(6):nth-last-child(4),
  ul.sf-menu li.sf-multicolumn-column:nth-child(7):nth-last-child(3),
  ul.sf-menu li.sf-multicolumn-column:nth-child(8):nth-last-child(2),
  ul.sf-menu li.sf-multicolumn-column:nth-child(9):nth-last-child(1) {
    width: 11.111%;
  }
  ul.sf-menu li.sf-multicolumn-column:nth-child(1):nth-last-child(10),
  ul.sf-menu li.sf-multicolumn-column:nth-child(2):nth-last-child(9),
  ul.sf-menu li.sf-multicolumn-column:nth-child(3):nth-last-child(8),
  ul.sf-menu li.sf-multicolumn-column:nth-child(4):nth-last-child(7),
  ul.sf-menu li.sf-multicolumn-column:nth-child(5):nth-last-child(6),
  ul.sf-menu li.sf-multicolumn-column:nth-child(6):nth-last-child(5),
  ul.sf-menu li.sf-multicolumn-column:nth-child(7):nth-last-child(4),
  ul.sf-menu li.sf-multicolumn-column:nth-child(8):nth-last-child(3),
  ul.sf-menu li.sf-multicolumn-column:nth-child(9):nth-last-child(2),
  ul.sf-menu li.sf-multicolumn-column:nth-child(10):nth-last-child(1) {
    width: 10%;
  }
  /**
    * "Vertical" type.
    */
  ul.sf-menu.sf-vertical {
    width: 12em;
  }
  ul.sf-menu.sf-vertical li {
    width: 100%;
  }
  ul.sf-menu.sf-vertical li:hover > ul,
  ul.sf-menu.sf-vertical li.sfHover > ul {
    left: 12em;
    top: 0;
  }
  ul.sf-menu.sf-vertical .sf-sub-indicator:after {
    content: "►";
  }
  /**
    * "Navbar" type.
    */
  ul.sf-menu.sf-navbar {
    position: relative;
  }
  ul.sf-menu.sf-navbar li {
    position: static;
  }
  ul.sf-menu.sf-navbar li li {
    position: relative;
  }
  ul.sf-menu.sf-navbar li ul,
  ul.sf-menu.sf-navbar li li li {
    width: 100%;
  }
  ul.sf-menu.sf-navbar li li {
    width: auto;
    float: left;
  }
  ul.sf-menu.sf-navbar li li:hover > ul,
  ul.sf-menu.sf-navbar li li.sfHover > ul,
  ul.sf-menu.sf-navbar > li.active-trail > ul {
    left: 0;
    top: 2.5em;
  }
  ul.sf-menu.sf-navbar li li li:hover > ul,
  ul.sf-menu.sf-navbar li li li.sfHover > ul {
    left: 12em;
    top: 0;
  }
  ul.sf-menu.sf-navbar ul .sf-sub-indicator:after {
    content: "▼";
  }
  ul.sf-menu.sf-navbar ul ul .sf-sub-indicator:after {
    content: "►";
  }
  /**
    * sfSmallscreen.
    */
  ul.sf-menu.sf-accordion {
    display: none;
    position: absolute;
  }
  ul.sf-menu.sf-accordion,
  ul.sf-menu.sf-accordion ul,
  ul.sf-menu.sf-accordion li {
    float: left;
    width: 100%;
  }
  ul.sf-menu.sf-accordion ul {
    margin: 0;
    padding: 0;
  }
  ul.sf-menu.sf-accordion.sf-expanded,
  ul.sf-menu.sf-accordion li.sf-expanded > ul {
    left: auto !important;
    position: relative;
    top: auto !important;
  }
  ul.sf-menu.sf-accordion .sf-sub-indicator:after {
    content: "▼";
  }
  ul.sf-menu.sf-accordion.sf-accordion-with-buttons .sf-accordion-button:after {
    content: "▼";
    margin: 0 0 0 1em;
  }
  ul.sf-menu.sf-accordion.sf-accordion-with-buttons li.sf-expanded > .sf-accordion-button:after {
    content: "▲";
  }
  /**
    * RTL support.
    */
  ul.sf-menu.rtl,
  ul.sf-menu.rtl li {
    float: right;
  }
  ul.sf-menu.rtl li:hover > ul,
  ul.sf-menu.rtl li.sfHover > ul {
    left: auto;
    right: 0;
  }
  ul.sf-menu.rtl li li:hover > ul,
  ul.sf-menu.rtl li li.sfHover > ul {
    left: auto;
    right: 12em;
  }
  ul.sf-menu.rtl ul {
    left: auto;
    right: 0;
  }
  ul.sf-menu.rtl .sf-sub-indicator {
    left: 0.75em;
    right: auto;
  }
  ul.sf-menu.rtl.sf-horizontal ul .sf-sub-indicator:after {
    content: "◄";
  }
  ul.sf-menu.rtl.sf-horizontal.sf-shadow ul,
  ul.sf-menu.rtl.sf-vertical.sf-shadow ul,
  ul.sf-menu.rtl.sf-navbar.sf-shadow ul ul {
    -webkit-box-shadow: -2px 2px 10px rgba(0,0,0,0.5);
    -moz-box-shadow: -2px 2px 10px rgba(0,0,0,0.5);
    box-shadow: -2px 2px 10px rgba(0,0,0,0.5);
  }
  ul.sf-menu.rtl li.sf-multicolumn-column {
    float: right;
  }
  ul.sf-menu.rtl.sf-vertical li:hover > ul,
  ul.sf-menu.rtl.sf-vertical li.sfHover > ul {
    left: auto;
    right: 12em;
  }
  ul.sf-menu.rtl.sf-vertical .sf-sub-indicator:after {
    content: "◄";
  }
  ul.sf-menu.rtl.sf-navbar li li {
    float: right;
  }
  ul.sf-menu.rtl.sf-navbar ul ul .sf-sub-indicator:after {
    content: "◄";
  }
  ul.sf-menu.rtl.sf-navbar li li:hover > ul,
  ul.sf-menu.rtl.sf-navbar li li.sfHover > ul {
    left: auto;
    right: 0;
  }
  ul.sf-menu.rtl.sf-navbar li li li:hover > ul,
  ul.sf-menu.rtl.sf-navbar li li li.sfHover > ul {
    left: auto;
    right: 12em;
  }
  ul.sf-menu.rtl.sf-accordion.sf-expanded,
  ul.sf-menu.rtl.sf-accordion li.sf-expanded > ul {
    right: auto !important;
  }
}

// DEFAULT
.nav--horizontal {
  display: none;
}
.nav--vertical {
  display: block;
}
@include breakpoint($medium-width) {

  .nav--horizontal {
    display: block;
  }
  .nav--vertical {
    display: none;
  }
}