table {

  // TODO:  Add styles for the inclusion of <td> tags within the <thead>. This is valid, but not required.
  
  &.simple-table {
    margin-bottom: 3em;
    width: 100%;

    > tbody > tr {
      @include zebra-stripe;
  
      > td {
        padding: 1em;
        @include font-size(1.4);
      }
  
      > th {
        padding: 1em;
        @include font-size(1.4);
        background-color: #333;
        color: white;
        font-weight: lighter;
      }
    }
    > thead {

      th {
        padding: 1em;
        @include font-size(1.4);
        background-color: #333;
        color: white;
        font-weight: bolder;
      }
    }
  }

  &.zebra-stripe {
     
    tr {
      @include zebra-stripe;
      
      td {
        padding: 1rem;
      }
    }
  }
}