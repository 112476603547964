// Replacement style for the WP wigdet legacy xoxo.
ul.links-widget {
	margin: 0;
	padding: 0;
	
	// h3.widgettitle {
	// 	@include font-size(1.6);
	// }

	ul {
		margin: 0;
		padding: 0;
	}

	li a {
		text-decoration: none;
	}
}