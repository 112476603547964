// .page-region {

  .region-columns {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    gap: 1rem;

    section {
      flex: 1;
      flex-basis: 100%;
    }

    @include breakpoint($medium-width) {
      
      &--two {
  
        >section {
          max-width: 49%;
        }

        &--6-6 {

          >section:nth-of-type(1),
          >section:nth-of-type(2) {
            max-width: 49%;
          }
        }
        &--7-5 {

          >section:nth-of-type(1) {
            max-width: 58%;
          }
          >section:nth-of-type(2) {
            max-width: 40%;
          }

          &-flipped {
            flex-direction: row-reverse;

            >section:nth-of-type(1) {
              max-width: 40%;
            }
            >section:nth-of-type(2) {
              max-width: 58%;
            }
          }
        }
        &--5-7 {

          >section:nth-of-type(1) {
            max-width: 40%;
          }
          >section:nth-of-type(2) {
            max-width: 58%;
          }

          &-flipped {
            flex-direction: row-reverse;

            >section:nth-of-type(1) {
              max-width: 58%;
            }
            >section:nth-of-type(2) {
              max-width: 40%;
            }
          }
        }
        &--8-4 {

          >section:nth-of-type(1) {
            max-width: 65%;
          }
          >section:nth-of-type(2) {
            max-width: 32%;
          }

          &-flipped {
            flex-direction: row-reverse;

            >section:nth-of-type(1) {
              max-width: 32%;
            }
            >section:nth-of-type(2) {
              max-width: 66%;
            }
          }
        }
        &--4-8 {

          >section:nth-of-type(1) {
            max-width: 32%;
          }
          >section:nth-of-type(2) {
            max-width: 66%;
          }

          &-flipped {
            flex-direction: row-reverse;

            >section:nth-of-type(1) {
              max-width: 66%;
            }
            >section:nth-of-type(2) {
              max-width: 32%;
            }
          }
        }
        &--9-3 {

          >section:nth-of-type(1) {
            max-width: 74%;
          }
          >section:nth-of-type(2) {
            max-width: 24%;
          }

          &-flipped {
            flex-direction: row-reverse;

            >section:nth-of-type(1) {
              max-width: 24%;
            }
            >section:nth-of-type(2) {
              max-width: 74%;
            }
          }
        }
        &--3-9 {

          >section:nth-of-type(1) {
            max-width: 24%;
          }
          >section:nth-of-type(2) {
            max-width: 74%;
          }

          &-flipped {
            flex-direction: row-reverse;

            >section:nth-of-type(1) {
              max-width: 74%;
            }
            >section:nth-of-type(2) {
              max-width: 24%;
            }
          }
        }
      }
    }
    @include breakpoint($fixed-width) {
      &--three > section {
        max-width: 32%;
      }
      &--four > section {
        max-width: 24%;
      }
    }
  }
// }