/* ----- Links ----- */
a:link {
	color: $link-standard;
}

a:visited {
	color: $link-visited;
}

a:hover {
	color: $link-hover;
	text-decoration: underline;
}

a:active {
	color: $link-active;
	text-decoration: underline;
}

a:focus {
	color: $link-active;
	text-decoration: underline;
}