.page-region__footer-local {
  margin-top: $gap*2;
  background-color: #ccc;

  .page-region-inner {
    margin: 0 auto;
    padding: $gap;
    max-width: $fixed-width;

    p:first-of-type {
      margin-top: 0;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  }

  &--empty {
    margin-top: $gap;
    background-color: transparent;
  }

  @include breakpoint($medium-width) {

  }
  @include breakpoint($fixed-width) {

  }
}