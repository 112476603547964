@import "../variables/colors";

.search-widget {
  display: block;
  margin: 0;
  padding: 1rem;
  
  .search-widget-inner {
    margin: 0;
    min-height: 18rem;

    h2 {
      margin: 0;
      text-align: center;
      color: white;
      font-family: $font-stack-headers-2;
      @include font-size(4);
      text-transform: uppercase;
      font-weight: lighter;
    }
  }
  
  .ihp-branding {
    margin-top: 0;

    svg {
      display: inline-block;
      fill: $grey-rest;
      height: 20px;
      width: 140px;
      transition: all 0.2s ease;

      .plus {
        opacity: 1;
        transition: all 0.2s ease;
      }

      &:hover,
      &:hover .plus {
        cursor: pointer;
        fill: #eee;
        opacity: 1 !important;
      }
    }
  }

  &.toggled-ON .ihp-branding  {
    
    svg .plus {
      opacity: 0.333;
    }
  }

  form#simple2,
  form.form-search-standard {
    margin: 0;
    padding: 0;
    height: 36px;

    .core {
      height: 36px; 

      input#ihplus_search,
      input.input-primary {
        float: left;
        margin: 0;
        padding: 0 0.5em;
        width: 85%;
        height: 36px;
        background-color: white;
        border: 2px solid $hawk-gold;
        border-right: 0;
        border-radius: 0;
        @include font-size(1.4);
        font-family: $font-stack-body;

        &::placeholder {
          color: #999;
          font-weight: lighter;
        }
      }
      input#submit,
      input.submit-primary {
        float: left;
        margin: 0;
        padding: 0;
        height: 36px;
        width: 15%;
        background-color: $hawk-gold;
        background-image: url(/includes/icons/svg/black/search.svg);
        background-size: 50%;
        background-position: center center;
        background-repeat: no-repeat;
        border: 0;
        transition: 0.25s;
        border-radius: 0;
        z-index: 50;
        color: transparent;
        font-size: 1px;

        &:hover,
        &:focus {
          background-color: $hawk-gold-hover;
          cursor: pointer;
        }
      }
    }

    .search-options {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      @include clearfix;

      >div {

        span, a {
          font-size: 14px !important;
        }
      }

      .focus-toggle {
        position: relative;
        // display: block;
        width: 40%;
        text-align: left;
        margin: 0.6em 0 0 0;
        max-height: 24px;
        color: $grey-rest;
        @include font-size(1.4);

        .label-cbx {
          user-select: none;
          cursor: pointer;

          // checkbox DEFAULTS
          input + .checkbox {
            position: relative;
            float: left;
            width: 20px;
            height: 20px;
            transition: all 0.2s ease;
            
            svg {
              position: absolute;
              top: 0;
              left: 0;
              transition: all 0.2s ease;
              max-height: 20px; // Someday IE will die and SVGs
              max-width: 20px;  //  can be cool with impunity

              &.checked-square {
                fill: #999;
              }
            }

            ~ span {
              display: inline-block;
              margin-top: 0;
              transition: all 0.2s ease;
              min-width: 2em;
            }
            + span {
              padding-left: 4px;
            }
          }
          
          // checkbox checked = FALSE
          input:not(:checked) + .checkbox {
            
            svg {
        
              &.empty-square {
                opacity: 1;
                fill: $grey-rest;
              }
        
              &.checked-square  {
                opacity: 0;
              }
            }
        
            ~ span.on {
              display: none;
            }
            ~ span.off {
              display: inline-block;
            }
          }
        
          // checkbox checked = TRUE
          input:checked + .checkbox {
        
            svg {

              &.empty-square {
                opacity: 0;
              }
              
              &.checked-square {
                opacity: 1;
                fill: $hawk-gold;
              }
            }
            
            ~ span.on {
              display: inline-block;
            }
            ~ span.off {
              display: none;
            }
          }

          // hovering the label
          &:hover {

            input:not(:checked) + .checkbox {

              svg.empty-square {
                fill: $hawk-gold-hover;
                opacity: 1;
              }
            }

            input:checked + .checkbox {

              svg.checked-square {
                fill: $hawk-gold-hover;
              }
            }

            input + .checkbox ~ span {
              color: #eee;
            }
          }
        }

        .focus-help {
          display: inline-block;

          a {
            display: block;
            margin: 0;
            padding: 0 0 0 4px;

            svg {
              margin-top: 2px;
              height: 16px; // Someday IE will die and SVGs
              width: 16px;   //  can be cool with impunity
              fill: $grey-rest;
              transition: all 0.2s ease;
            }

            &:hover {

              svg {
                fill: #eee;
              }
            }
          }
        }
      }

      .invisible {
        position: absolute;
        z-index: -1;
        width: 0;
        height: 0;
        opacity: 0;
      }

      // .advanced-search {
      //   margin-top: 0.6em;
      //   width: 40%;
      //   text-align: center;

      //   a {
      //     margin: 0;
      //     padding: 2px 0 0 0;
      //     @include font-size(1.4);
      //     text-decoration: none;
      //     color: $grey-rest;
      //     transition: 0.2s;

      //     &:hover {
      //       color: white;
      //     }
      //   }
      // }

      // .ihplus-help {
      //   margin-top: 0.6em;
      //   width: 20%;
      //   text-align: right;

      //   a {
      //     margin: 0;
      //     padding: 2px 0 0 0;
      //     @include font-size(1.4);
      //     text-decoration: none;
      //     color: $grey-rest;
      //     transition: 0.2s;

      //     &:hover {
      //       color: white;
      //     }
      //   }
      // }
    }
  }
}

// --- BEGIN breakpoints ------------------------------------------
@include breakpoint($smallish-width) {

  .search-widget {
    // flex: 1;
  
    .search-widget-inner {
      margin-left: 2rem;

      h2 {
        margin: 5rem 0;
        
      }
    }
  }
}

@include breakpoint($fixed-width) {

  .search-widget {
    margin: 0;
  }
}
// --- END breakpoints --------------------------------------------

