// DEV stylings --------------------------------------------------------------------
.site-web.support.uids {
	--asterisk-color: #{$secondary-orange};

	button.toggle-button {
		font-family: monospace;
		margin: 0 0 0.25rem 0;
		padding: 0.25rem 1rem;
		display: inline-block;
		border-radius: 1.5rem;

		&[aria-pressed="false"] {
			
			i.pressed { display: none; }
			i.not-pressed { display: inline; }
		}

		&[aria-pressed="true"] {
			background-color: lighten($hawk-gold, 30%);
			
			i.pressed { display: inline; }
			i.not-pressed { display: none; }
		}
	}

	.reset-selections {
		@include font-size(1.4);
	}

	[role=tooltip] {
		display: inline-block;
		margin-left: 0.5rem;
	}
	i.fa-asterisk {
		color: var(--asterisk-color);
	}
}
