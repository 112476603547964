.liblock__location {
  background-color: $secondary-grey-light;

  p {
    margin: 0;
    padding: 1rem;
  }
  
  span.label {
    display: block;
    @include font-size(1.2);
    color: #666;
    line-height: 1.2rem;
  }

  &--title {
    clear: both;
    display: block;
  }

  &--image {

  }

  &--campus-address {
    // @include simple-icon("map-marker-alt");
  }

  &--mailing-address {
    // @include simple-icon("envelope");

    span {
      display: block;
    }
  }

  &--email-link {
    // @include simple-icon("at");

    span.label {
      @include hide-offscreen;
    }
  }

  &--phone {
    // @include simple-icon("mobile-alt");

    span.label {
      @include hide-offscreen;
    }
  }

  &--hours {

    span.label {
      @include hide-offscreen;
    }
  }

  &--home-link,
  &--contact-link,
  &--hours-link,
  &--people-link {
    display: none;
  }



  // --- utility classes
  &.hide-title {

    .liblock__location--title {
      @include hide-offscreen;
    }
  }

  // --- breakpoints
  @include breakpoint($medium-large-width) {
    
    &--image {
      // float: left;
      margin-right: 1rem;
    }
  }
}
