.liblock__gallery {
  display: flex;
  flex-flow: row wrap;

  >div {
    width: 100% !important;
    padding: 1rem;

    img {
      width: 100% !important;
    }
  }

  @include breakpoint($fixed-width) {

    >div {
      width: 19.5% !important;

      // img {
      // 	width: inherit;
      // }
    }
  }
}
