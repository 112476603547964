// Somehow, this should all be moot if there is a way to set font-size based upon container size
// ideas:  svg site-names, js percentage adjustments based upon width estimates

body.theme-ranganathan {

	// the default
	.header-wrapper .header-wrapper-inner .site-name a {
		color: #ccc;
		// font-weight: 100;
		@include font-size(2.0);
		text-decoration: none;
		transition: 0.2s;

		&:hover {
			color: white;
		}

		@include breakpoint($small-width) {
			@include font-size(3);
		}

		@include breakpoint($fixed-width) {
			@include font-size(4.2);
		}
	}

	// the long site names
	&.site-studio,
	&.site-hardin,
	&.site-eng,
	&.site-biz {

		.header-wrapper .header-wrapper-inner .site-name a {
			@include font-size(1.8);

			@include breakpoint($small-width) {
				@include font-size(2.2);
			}
			@include breakpoint($medium-width) {
				@include font-size(2.8);
			}
			@include breakpoint($fixed-width) {
				@include font-size(3.2);
			}
		}
	}
}