.site-home.page-id-4312 {

  .section-locations {
    margin-bottom: $section-bottom-gap;

    .locations-here {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      
      .liblock__location {
        flex: 1;
        flex-basis: 100%;
        margin: 0 0 6px 0;
        padding: 0;
        background-color: white;

        .liblock-inner {
          background-color: #e5e6e7;
        }

        &--title {
          margin: 0;
          padding: 1rem 1rem 0.5rem 0;
          width: 100%;
        }

        &--email-link,
        &--phone,
        &--contact-more,
        &--contact-link,
        &--people-link,
        &--mailing-address,
        &--hours--primary,
        &--campus-address {
          display: none;
        }

        &--home-link,
        &--infopage-link {
          display: inline-block;
          margin-bottom: 1rem;
          width: 100%;

          a { 
            display: block;
            padding: 1rem;
            width: calc(100% - 2rem);
            text-align: start;

            i:before {
              display: inline-block;
              // min-width: 1.33em;
              transition: 0.2s;
            }
            // &:hover i:before {
            //   transform: scale(1.1);
            // }
          }
        }
        // &--home-link {
        //   margin-right: 2%;
        // }
      }
    }
  }

  .liblock-inner {
    display: flex;
    margin: 0;

    .location-image {
      flex: 1;

      a, img {
        display: block;
        margin: 0;
        padding: 0;
        width: 180px;
      }
    }

    .location-info {
      flex: 4;
      padding: 1rem;
    }

    .liblock__hours--contact {
      display: none;
    }
    .liblock__hours--list {
      margin-left: 0;
      width: 100%;
    }
    .liblock__hours--footer {
      display: none;
    }
  }


  // .open-hours {

  //   i:before {
  //     display: inline-block;
  //   }
  // }




  .hours-id-14197 .liblock-inner .liblock__hours--contact {
    display: block;
    background-color: transparent;
    width: 100%;

    + ul.liblock__hours--list {
      display: none;
    }
  }

  @include breakpoint($medium-width) {
    
    .section-locations .locations-here .liblock__location {

      &--home-link {
        margin-right: 2%;
        width: 49%;
      }
      &--infopage-link {
        width: 49%;
      }
    }
  }
  @include breakpoint($fixed-width) {
  
    .section-locations .locations-here .liblock__location {
      flex-basis: 49%;
      
      &:not(:nth-of-type(2n)) {
        margin-right: 6px;
      }
    }
  }
}