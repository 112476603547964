body.theme-ranganathan {

	// ======= MOVE ======
	#main {
		background-color: white;
	}

	// TEMP
	#menu-mobile {
		display: none;
	}
}