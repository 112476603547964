// These are the prints styles in progress and will be broken out when the time comes.

@media not print {

	.print-only {
		display: none;
	}
}

@media print {

	.print-only {
		display: block;
	}

	a.show-url-on-print,
	.show-url-on-print a {

		&:after { 
			content:" [" attr(href) "] "; 
		}
	}

	// Hide a bunch of stuff outright.
  .iowa-bar--full,
  .nav--horizontal, 
  .breadcrumbs,
  .page-region__nav-secondary,
  .footer {
    display: none !important;
  }

	.print-header {
		margin: 1rem 0 2rem 0;

		.print-header-inner {
			display: flex;
			margin-bottom: 2rem;
			justify-content: flex-start;
			align-items: flex-end;
		}
	
		.print--block-iowa {
			flex: 0 0 auto;
			max-width: 160px;
		}
		.print--org-title {
			flex: 0 0 auto;
			margin: 0 0 0 2rem;
			font-size: 2rem;
		}
		.print--site-title {
			flex: 0 0 auto;
			margin: 0;
			font-size: 2rem;
		}
	}

	// Simplify banner and page title for printing context.
  .page-region__banner {
    margin-bottom: 0 !important;

		.entry-title {
			font-size: 3.6rem !important;
			color: black !important;
			text-shadow: none !important;
		}

		&.banner-image-exists {
			height: auto !important;
			min-height: 0 !important;
			background-color: white !important;

			.banner__image {
				display: none;
			}
		}
  }

  .print-footer {
		font-size: 1.2rem;
		
		.print--block-iowa {
			float: right;
			margin-top: 1.5rem;
			padding-left: 4rem;
			height: 4.5rem;
		}
		.print--footer-content {
			margin-top: 2rem;
		}
	}
}


@media print {


	
	


	


}