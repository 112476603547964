// .site-home.page-id-4312 {


.search-widget {
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
  z-index: 33;

  grid-column: col-start 1 / span 12;

  .search-widget-inner {
    margin: 0;
    min-height: auto;
  }

  .title-widget-search {
    color: black;
    span {
      @include hide-offscreen;
    }
    i {
      // line-height: 30px;
      font-size: 0.6em;
      transition: 0.1s;

      &:before {
        display: block;
        position: relative;
        top: -4px;
        left: 1px;
        transition: 0.2s linear all;
      }
    }
  }
  &.focus-ON .title-widget-search {

    i {
      color: #666;

      &:before {
        transform: scale(0.9) rotate(-90deg);
      }
    }
  }

  form#simple2 {
    height: auto;
    padding: 20px;
    margin: 0;
    background-color: #ccc;

    .core {
      display: flex;
      gap: 0;
      height: 48px;

      label {
        flex: 5;
        max-height: 48px;

        input#ihplus_search {
          padding: 0 1rem;
          height: 48px;
          width: calc(100% - 2rem);
          @include font-size(1.6);
        }
      }

      input#submit {
        flex: 1;
        height: 52px;
        background-size: 20%;
      }
    }

    ul.search-options {  
      clear: both;
      display: block;
      margin: 4px 0 0 0;
      padding: 0;

      li {
        display: inline-block;
        margin: 2px 3rem 0 0;
        color: #111;
        width: auto !important;  // TODO revoke important when migration complete
        height: 34px;

        a, span {
          color: #111;
          @include font-size(1.4);
          line-height: 34px;
          text-decoration: none;

          &:hover {
            color: #666 !important;
          }
        }
      
        // &.focus-toggle {
        //   margin-inline-end: 20px;

        //   .focus-help {}
        // }
        &.focus-toggle {

          input {
            visibility: hidden;
            width: 0;
            height: 0;
            overflow: hidden;
          }
        
          .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
          }
        
          /* Hide default HTML checkbox */
          .switch input {
            opacity: 0;
            width: 0;
            height: 0;
          }
        
          /* The slider */
          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0;
            padding: 0;
            width: 60px;
            height: 34px;
            background-color: #999;
            transition: .4s;
          }
        
          .slider:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            transition: .4s;
          }
        
          input:checked + .slider {
            background-color: $hawk-gold;
          }
        
          input:focus + .slider {
            box-shadow: 0 0 1px $hawk-gold;
          }
        
          input:checked + .slider:before {
            transform: translateX(26px);
          }
        
          [aria-checked="true"] {
            background-color: $hawk-gold;
        
            &:before {
              transform: translateX(26px);
            }
        
            +label .focus-off {
              display: none;
            }
          }
          
          +label .focus-off {
            display: none;
          }
          [aria-checked="false"] + label .focus-on {
            display: none;
          }
        
          /* Rounded sliders */
          .slider.round {
            border-radius: 34px;
          }
        
          .slider.round:before {
            border-radius: 50%;
          }
        
          #focus-label {
            display: inline-block;
            // min-width: 160px;
        
            .focus-on,
            .focus-off {
              margin-left: 70px;
            }
          }
        
          .focus-help {
            @include font-size(1.1);
            a {
              text-decoration: none;
            }
          }
        }
        &.advanced-search {
          margin-inline-start: 20px;
        }
        &.ihplus-help {
          margin-inline-end: 0;
        }
      }
    }
  }

  // --- BREAKPOINTS ------------------------
          
  @include breakpoint($medium-width) {
    
    // Keeps the special full width styles for the main homepage in their place
    .site-home.page-id-4312 & { 
      margin-top: -220px;

      .title-widget-search {
        color: white;
      }
      form#simple2 {
        box-shadow: 0px -48px 96px rgba(0, 0, 0, 0.9);
      }
    }

  }

  @include breakpoint($fixed-width) {
    margin-right: 0;
    margin-left: 0;
    
  }
  // ---------------------------------------
}

// }