// TEMP: hide a single Hawk Alert because #reasons 
.alert--id-1671638406 {
  display: none;
}

// .hawk-alerts-feed {
//   // margin-bottom: 20px;

//   .alert {

//     .alert-inner {
//       padding: 2rem;

//       .alert-title {
//         padding-bottom: 0;
//         font-family: "Roboto", "Roboto Condensed", sans-serif;
//         font-weight: 600;
//       }

//       .hawkalert-timestamp {
//         @include font-size(1.4);
//       }
//     }
//   }
// }

.hawkalerts-feed-TESTING,
.hawk-alerts-feed {

  .alert {
    margin: 1rem 0 2rem 0;
    padding: 0;

    .alert-inner {
      padding: 2rem;

      &:before {
        margin-bottom: 2rem;
        font-size: 2.6em;
      }

      .alert-body {

        .hawkalert-title {
          margin-bottom: 0.5rem;
          padding-bottom: 0;
          @include font-size(2.4);
          font-family: $font-workhorse-1;
          font-weight: 500;
        }

        .hawkalert-timestamp {
          @include font-size(1.8);
          font-style: italic;
        }
      }
    }
    &.status-COMPLETE {
      display: none;
    }
  }
}