section.alert {
  padding: 2rem;

  .alert-inner {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto;
    max-width: calc($fixed-width - 4rem);

    &:before {
      flex: 0 0 1.5em;
      font-size: 2em;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f061";
    }

    .alert-body {
      // flex: 1;
      flex: 0 0 90%;

      h2, h3 {
        @include font-size(2.5);
      }

      p:first-of-type {
        margin-top: 0;
      }
  
      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }


  // ___ Alert TYPES ___
  &--type-question,
  &--question {
    background-color: $secondary-blue-lighter;
    border: 1px solid fade-out($secondary-blue, 0.5);

    .alert-inner:before {
      content: "\f059";
      color: $secondary-blue;
    }
  }

  &--type-success {
    background-color: $secondary-green-lighter;
    border: 1px solid fade-out($secondary-green, 0.5);

    .alert-inner:before {
      content: "\f058";
      color: $secondary-green;
    }
  }

  &--type-info,
  &--info {
    background-color: $secondary-blue-lighter;
    border: 1px solid fade-out($secondary-blue, 0.5);

    .alert-inner:before {
      content: "\f05a";
      color: $secondary-blue;
    }
  }

  &--type-warning,
  &--warning {
    background-color: $secondary-orange-lighter;
    border: 1px solid fade-out($secondary-orange, 0.5);

    .alert-inner:before {
      content: "\f071";
      color: $secondary-orange;
    }
  }

  &--type-danger,
  &--danger {
    background-color: $secondary-orange-lighter;
    border: 1px solid fade-out($secondary-orange, 0.5);

    .alert-inner:before {
      content: "\f06a";
      color: $secondary-orange;
    }
  }

  &--hawk {
    
    .alert-inner {
      padding: 2rem; // TODO: REMOVE WITH NEW FEED IN AUGUST 2024 ???
      background-color: $secondary-orange-lighter;
      border: 1px solid fade-out($secondary-orange, 0.6);

      .alert-body {

        p {
          margin: 0.5em 0;
        }
        .hawkalert-title {
          font-family: $font-accent;
        }
        .hawkalert-timestamp {
          font-style: italic;
        }
      }

      &:before {
        content: "\f06a";
        color: $secondary-orange;
      }
    }
  }


  // ___ Alert STATUSES ___
  &--status-39 {  // ENABLED
    display: block;
  }
  &--status-40 {  // TESTING
    display: none;

    body.logged-in & {
      display: block;
    }
  }
  

  // ___ Alert SYNDICATIONS ___
  // Nothing, yet.


  // ___ Alert TAGS ___
  // Nothing, yet.

  // ___ Alert custom classes ___
  &.icon-virus {

    .alert-inner:before {
      font-family: "uilibraries";
      content: "\e90d";
      font-size: 2.4em;
    }
  }
  &.icon-snowflake {

    .alert-inner:before {
      content: "\f2dc";
    }
  }
  &.icon-wrench {

    .alert-inner:before {
      content: "\f0ad";
    }
  }
  &.icon-sun {

    .alert-inner:before {
      content: "\f185" !important;
    }
  }

  &.color-orange {
    background-color: $secondary-orange-lighter;
    border: 1px solid fade-out($secondary-orange, 0.5);

    .alert-inner:before {
      color: $secondary-orange;
    }
  }
  &.color-yellow {
    background-color: $secondary-yellow-lighter;
    border: 1px solid fade-out($secondary-yellow, 0.5);

    .alert-inner:before {
      color: $secondary-yellow;
    }
  }
  &.color-blue {
    background-color: $secondary-blue-lighter;
    border: 1px solid fade-out($secondary-blue, 0.5);

    .alert-inner:before {
      color: $secondary-blue;
    }
  }
  &.color-green {
    background-color: $secondary-green-lighter;
    border: 1px solid fade-out($secondary-green, 0.5);

    .alert-inner:before {
      color: $secondary-green;
    }
  }

  &.icon-color-black {

    .alert-inner:before {
      color: black;
    }
  }

}



// ———————————————————————————————————————————————————————————————————————————————————————————————————
// Site-wide alerts initiated from https://www.lib.uiowa.edu/alerts/wp-admin/edit.php?post_type=alert
.libraries-alerts-feed .alert {
  display: none;  // By default, hide alerts...
}
// ...and display them as syndicated...
// ...on ALL Libraries' org units...
body .libraries-alerts-feed .alert--syndication-10,
body.home .libraries-alerts-feed .alert--syndication-11 { 
  display: block;
}
// ...or individual Libraries' org units
body.site-annex .libraries-alerts-feed .alert--syndication-22,
body.site-annex.home .libraries-alerts-feed .alert--syndication-16 {
  display: block;
}
body.site-art .libraries-alerts-feed .alert--syndication-20,
body.site-art.home .libraries-alerts-feed .alert--syndication-15 {
  display: block;
}
body.site-biz .libraries-alerts-feed .alert--syndication-24,
body.site-biz.home .libraries-alerts-feed .alert--syndication-18 {
  display: block;
}
body.site-eng .libraries-alerts-feed .alert--syndication-23,
body.site-eng.home .libraries-alerts-feed .alert--syndication-17 {
  display: block;
}
body.site-hardin .libraries-alerts-feed .alert--syndication-21,
body.site-hardin.home .libraries-alerts-feed .alert--syndication-12 {
  display: block;
}
body.site-music .libraries-alerts-feed .alert--syndication-25,
body.site-music.home .libraries-alerts-feed .alert--syndication-13 {
  display: block;
}
body.site-sciences .libraries-alerts-feed .alert--syndication-26,
body.site-sciences.home .libraries-alerts-feed .alert--syndication-14 {
  display: block;
}
