// $color-hover:		#eee;
// $color-warm:		#aaa;
// $color-rest:		lighten($hawk-gold, 10%);
$color-rest:		#ccc;
$color-hover:		$hawk-gold-hover;
$color-selected:	#aaa;
// $color-test:		#900;

$submenu-base-indent:	12px;
$submenu-item-indent:	8px;

// .sub-navigation {
	// margin-bottom: 2em;

	// ALL levels
	ul.menu.subnav-sidebar,
	ul.menu.subnav-sidebar ul.sub-menu {
		margin: 0;
		padding: 0;
		list-style-type: none;
		background-color: $color-rest;
		
		li {
			margin: 0;

			// &:hover {
			
			// 	&:before {
			// 		position: absolute;
			// 		display: block;
			// 		content: "";
			// 		width: 10px;
			// 		height: 29px;
			// 		background-position: -3px center;
			// 		background-size: contain;
			// 		background-image: url('/includes/icons/svg/black/caret-right.svg');
			// 		background-repeat: no-repeat;
			// 		opacity: 0.6;
			// 	}
			// }

			&.current-menu-item {
				background-color: $color-selected;

				&:before {
					position: absolute;
					display: block;
					content: "";
					width: 10px;
					height: 29px;
					background-position: -1px center;
					background-size: contain;
					background-image: url('/includes/icons/svg/black/caret-right.svg');
					background-repeat: no-repeat;
				}
			}
		}

		a {
			display: block;
			padding: 6px 6px 6px $submenu-base-indent;
			text-decoration: none;
			line-height: 1.25em;
			color: color-contrast($color-rest);
			@include font-size(1.4);
			transition: 0.2s;

			&:hover {
				background-color: $color-hover;
				color: color-contrast($color-hover);
			}
		}

		button {

			&:hover {
				background-color: $color-hover;
				cursor: pointer;
			}
		}
	}



	// Sub level augmentations
	// --- First level
	ul.menu.subnav-sidebar {
		position: relative;
		margin-bottom: 2rem;
		padding: 4px;
		background-color: rgba(white, 1);

		// "Home" item (i.e., the first item in the menu, usually)
		> li:first-of-type {
			position: relative;
			background-color: $color-rest;

			> a {
				@include simple-icon('home');
				padding-left: 2em;
				background-position-x: 0.666em;
				background-position-y: 0.5em;

				border-bottom: 2px solid white;
				
				
				&:hover + button {
					background-color: $color-hover;
				}
			}

			> button {
				position: absolute;
				top: 0;
				right: 0;
				margin: 0;
				padding: 0;
				border: none;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 12px;
				background-color: transparent;
				height: 29px;
				width: 32px;
				transition: 0.2s;

				&.menu-expanded {
					background-image: url('/includes/icons/svg/black/caret-up.svg');
				}
				&:not(.menu-expanded) {
					background-image: url('/includes/icons/svg/black/caret-down.svg');
				}

				&:hover {
					background-color: $color-hover;
				}
			}

			&.current-menu-item {
				background-color: $color-selected;
			}
		}

		// other menu icons
		> li {

			&.menu-icon {
				> a {
					@include simple-icon('circle');
					padding-left: 2em !important;  // TODO: Eliminate this !important
					background-position-x: 0.666em;
					background-position-y: 0.5em;
				}
			}

			&.resources {
	
				> a {
					@include bg-icon('link');
				}
			}
			&.services {
	
				> a {
					@include bg-icon('link');
				}
			}
			&.events {
	
				> a {
					@include bg-icon('calendar-alt');
				}
			}
			&.news {
	
				> a {
					@include bg-icon('rss');
				}
			}
			&.contact {
	
				> a {
					// @include bg-icon('question-circle');
					@include bg-icon('user-friends');
				}
			}
			&.contact-info {
	
				> a {
					@include bg-icon('info-circle');
				}
			}
			&.about {
	
				> a {
					@include bg-icon('info-circle');
				}
			}
			&.past {
	
				> a {
					@include bg-icon('custom_calendar-rewind');
				}
			}
			&.recent {
	
				> a {
					@include bg-icon('step-backward');
				}
			}
			&.next {
	
				> a {
					@include bg-icon('step-forward');
				}
			}
			&.future {
	
				> a {
					@include bg-icon('custom_calendar-forward');
				}
			}
			&.featured {
	
				> a {
					@include bg-icon('star');
				}
			}
			&.hourz {
	
				> a {
					@include bg-icon('clock');
				}
			}
			&.ui-presidents {
	
				> a {
					@include bg-icon('user-graduate');
				}
			}
			&.add {
	
				> a {
					@include bg-icon('plus-square');
				}
			}
			&.write {
	
				> a {
					@include bg-icon('pen-alt');
				}
			}
			&.timeline {
	
				> a {
					@include bg-icon('history');
				}
			}
			&.move-to-parent {
	
				> a {
					@include bg-icon('arrow-circle-left');
				}
			}
			&.user {
	
				> a {
					@include bg-icon('user');
				}
			}
			&.sitemap {
	
				> a {
					@include bg-icon('sitemap');
				}
			}
			&.award {
	
				> a {
					@include bg-icon('award');
				}
			}
			&.parking {
	
				> a {
					@include bg-icon('car');
				}
			}
			&.current {
	
				> a {
					@include bg-icon('star');
				}
			}
			&.upcoming {
	
				> a {
					@include bg-icon('angle-double-right');
				}
			}
			&.training {
	
				> a {
					@include bg-icon('chalkboard-teacher');
				}
			}
			&.facebook {
	
				> a {
					@include bg-icon('facebook');
				}
			}
			&.youtube {
	
				> a {
					@include bg-icon('youtube');
				}
			}

			&.spacer {
				margin-top: 0.5rem;
			}
		}
	}

	// --- First level, continued (the home page is, technically, at the same level)
	ul.menu.subnav-sidebar {

		> li:not(:first-of-type) {
			position: relative;
			background-color: #ccc;
			border-bottom: 2px solid white;
			
			> a {
				padding-left: $submenu-base-indent;

				&:hover + button {
					background-color: $color-hover;
				}
			}

			> button {
				position: absolute;
				top: 0;
				right: 0;
				margin: 0;
				padding: 0;
				border: none;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 12px;
				background-color: transparent;
				height: 29px;
				width: 32px;
				transition: 0.2s;

				&:hover {
					background-color: $color-hover;
				}

				&.menu-expanded {
					background-image: url('/includes/icons/svg/black/caret-up.svg');
				}
				&:not(.menu-expanded) {
					background-image: url('/includes/icons/svg/black/caret-down.svg');
				}
			}

			&.current-page-ancestor,
			&.current-menu-parent {
				// background-color: $hawk-gold;
				background-color: $color-selected;

				ul.sub-menu {
					// background-color: lighten($hawk-gold, 10%);
					background-color: $color-rest;
				}
			}

			&.current-menu-item {
				// background-color: $hawk-gold;
				background-color: $color-selected;

				ul.sub-menu {
					// background-color: lighten($hawk-gold, 10%);
					background-color: $color-rest;
				}
			}

			&:last-of-type {
				border-bottom: 1rem solid white;
			}
		}
	}

	// --- Secondary level
	ul.menu.subnav-sidebar > li > ul.sub-menu {

		> li {
			position: relative;

			> a {
				padding-left: $submenu-base-indent + $submenu-item-indent;
			}
		}
	}

	// --- Tertiary level (currently affects all levels down, but lets not let those happen)
	ul.menu.subnav-sidebar > li > ul.sub-menu ul.sub-menu {

		> li {
			position: relative;

			> a {
				padding-left: $submenu-base-indent + $submenu-item-indent*2;
				font-size: 80%;
			}
		}
	}
// }

body {

	ul.menu.subnav-sidebar {
	
		li.dev-hidden,
		li.dev-hidden-webcomm {
			display: none;
		}
	}
	
	
	// Anyone who is logged in to WP can see dev-hidden items
	&.logged-in ul.menu.subnav-sidebar li.dev-hidden {
		display: block;
	}

	// Only  can see dev-hidden-webcomm items
	&.logged-in ul.menu.subnav-sidebar li.dev-hidden-webcomm {
		display: block;
	}
}

// ==================================================================


    #subnav-menu {
        margin: 0 0 1rem 0;
    }

    #subnav-menu-toggle {
        margin: 0;
        padding: 1rem;
        width: 100%;
        border: none;
        color: black;
        background-color: #aaa;
        text-align: left;
        font-weight: bold;
        @include font-size(1.8);
        border-bottom: 2px solid white;
        font-family: $font-workhorse;

        .toggle-icon {
            width: 1.25em;
        }

        &[aria-expanded="true"] {

            i.icon-menu {
                display: none;
            }
            i.icon-close {
                display: inline-block;
                opacity: 0.5;
            }

            +#subnav-menu-container {
                display: block;
            }
        }
        &[aria-expanded="false"] {

            i.icon-menu {
                display: inline-block;
            }
            i.icon-close {
                display: none;
            }

            +#subnav-menu-container {
                display: none;
            }
        }
    }

    #subnav-menu-container {

        &.hidden-when-narrow {
            display: none;
        }

        .menu.subnav {
            margin: 0;
            padding: 0;
        }
    }

    // ——— BREAKPOINTS ————————————————————————————————————————
    @include breakpoint($medium-width) {

        #subnav-menu-toggle {  // on wider viewports, hide the subnav menu toggle...
            display: none;

            &[aria-expanded="false"] {  

                +#subnav-menu-container {  // ... but show the subnav menu container
                    display: block;
                }
            }
        }
    }
    // ————————————————————————————————————————————————————————
