$section-give-bg: $hawk-gold;

.site-home.page-id-4312 {

  .section-give {
		grid-column: full;
		clear: both;
		@include clearfix;
		margin: 0;
		padding: 0;
		// background-image: url('//www.lib.uiowa.edu/wp-content/uploads/2017/12/homepage-section-giving-bg-small.jpg');
		background-image: url('//www.lib.uiowa.edu/wp-content/uploads/2021/04/mildred-books-banner-bg.jpg');
		background-size: cover;
		background-position: top;
		background-repeat: no-repeat;
		background-color: $section-give-bg;
		min-height: 400px;
		width: 100%;
		color: color-contrast($section-give-bg);

		.section-overlay {
			background-color: transparentize(black, .3); 
		}

		.section-inner {
			margin: 0 20px;
			padding: 40px 0;
			max-width: $fixed-width;
			min-height: 400px;

			h3 {
				color: white;
			}
			h4:first-of-type {
				text-align: center;
				@include font-size(6);
				color: white;
				font-family: $font-formal-1;
				font-style: italic;
				text-shadow: 2px 2px #000;
			}
			h4:last-of-type {
				margin: 4rem 0 2rem 0;
				margin-bottom: 3rem;
				text-align: center;


			}
		}

		// --- BREAKPOINTS ------------------------
		@include breakpoint($fixed-width) {

			.section-inner {
				margin: 0 auto;
			}
		}
		// ---------------------------------------
  }
}