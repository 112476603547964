.site-home.page-id-4312 { // Libraries home page ID

  .page-region__banner {
    margin-bottom: 0;
    height: 40vw;
    max-height: 600px !important;
  
    .breadcrumbs {
      display: none;
    }

    .entry-title {
      @include font-size(3);
    }
  }

  .section-top {
    grid-column: full;
  
    .big-feature-item {
      min-height: 520px;
    }
  }

  .page-region__banner {
  
    .banner__image {
      background-position: top 40% center;
    }
    .banner__content {
      padding-bottom: 0;
      
      h2 em {
        display: block;
        font-size: 66%;
      }
    }

    // --- BREAKPOINTS ------------------------

    @include breakpoint($medium-width) {
      
      .banner__content {
        padding-bottom: 240px;
      }

      .entry-title {
        @include font-size(4.8);
      }
    }

    @include breakpoint($fixed-width) {

      .page-region__banner {
        height: 60vw;
      }
    
      .entry-title {
        @include font-size(7);
      }
    }

    // ---------------------------------------
  }
}