img {
	border: none; 
	height: auto;
	max-width: 100%;

	&.aligncenter {
		display: block;
		margin: 0 auto !important;
	}
	&.alignright {
		float: right;
		margin: 0 0 1em 1.5em;
	}
	&.alignleft { 
		float: left;
		margin: 0 1.5em 1em 0;
	}
	
	&.floatleft {
		float: left; 
	}
	&.floatright {
		float: right; 
	}

	&.textcenter {
		text-align: center;
	}
	&.textright {
		text-align: right;
	}
	&.textleft {
		text-align: left;
	}

	// assumed to be full width image at top of a page
	&.top-banner {
		width: 100%;
	}

	&.border {

		&--dark {
			border: 1px solid #333;
		}
		&--light {
			border: 1px solid #bbb;
		}

	}
}
.wp-caption {
	// width: 100% !important;

	&.aligncenter {
		display: block;
		margin: 0 auto !important;
	}
	&.alignright {
		float: right;
		margin: 0 0 1em 1.5em;
	}
	&.alignleft { 
		float: left;
		margin: 0 1.5em 1em 0;
	}

	img {
		margin: 0;
		padding: 0;
	}

	.wp-caption-text {
		margin-top: 0.25em;
		text-align: left;
		@include font-size(1.2);
	}

	
}

