h2.entry-title {
	@include font-size(2);
}

@include breakpoint($small-width) {

	h2.entry-title {
		@include font-size(2.6);
	}
}

@include breakpoint($medium-width) {

	h2.entry-title {
		@include font-size(2.6);
	}
}
