// OLD branding defaults
.uilibraries-branding {
	
	// DEFAULTS
	.ui-branding,
	.uilibraries-wordmark {
		transition: 0.25s;
		stroke: transparent;
		stroke-width: 12;
	}
	.ui-branding {
		fill: $hawk-gold;

		&:hover {
			fill: $hawk-gold-hover;
			cursor: pointer;
		}
	}
	.uilibraries-wordmark {
		fill: #ccc;

		&:hover {
			fill: white;
			cursor: pointer;
		}
	}
}


// NEW branding defaults
.uilib-branding { 
	fill: #ccc; 

	&.uilib-iowa {

		.block-letter {
			fill:  #ffcd00;
			transition: 0.1s; 

			&.block-letter-i { transition-delay: 0.00s; }
			&.block-letter-o { transition-delay: 0.03s; }
			&.block-letter-w { transition-delay: 0.06s; }
			&.block-letter-a { transition-delay: 0.09s; }
		}

		&:hover .block-letter.block-letter-i { fill: #ffe680; }
		&:hover .block-letter.block-letter-o { fill: #ffe680; }
		&:hover .block-letter.block-letter-w { fill: #ffe680; }
		&:hover .block-letter.block-letter-a { fill: #ffe680; }
	}
	
	&.uilib-libraries {
		transition: 0.2s;

		&:hover {
			fill: #eee; 
		}
	}
}

// Global HEADER specific styles
.header-wrapper .uilib-branding {
	margin-bottom: 10px;

	svg {
		max-width: 480px !important;
	}
}


// Global FOOTER specific styles
#global-footer .uilib-branding {
	margin-bottom: 10px;
	max-width: 480px;
}


