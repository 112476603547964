/* -- Owl Carousel style overrides -- */
.owl-theme .owl-controls.clickable {  // .clickable makes it specific enough to select for some reason
    margin-top: 0;
}
.owl-wrapper-outer {
	
}
.owl-carousel-item-text {
	position: relative;
}
.owl-caption {  // .owl-caption added by jQuery inline on homepage (for now)
	position: absolute;
	bottom: 1px;
	left: 1px;
	width: 100%;
	text-align: center;
	padding: 6px;
	font-size: small;
	color: white;
	background: rgba(0,0,0,0.6);
}

.owl-item img {
	padding: 0;
}