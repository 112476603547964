// Primarily, this is only a replacement of grid with flexbox for the dying IE
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

  .site-annex.content-layout-sidebar-right-narrow,
  .site-conservation {

    .page__container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .page-region {
        width: 100%;

        &__banner {
          width: 100%;
        }
        &__content-primary {
          
        }
        &__content-secondary {
          
        }
        &__sidebar-primary {
          
        }
        &__sidebar-secondary {
          
        }
        &__footer-local {
          
        }
      }
    }  
  }
}