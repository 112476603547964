
.search-site-wrapper {
  margin-left: auto;
  padding: 0 20px 20px 20px;
  width: 100%;
  background-color: $hawk-gold;
  
  form.uids-search {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    border-bottom: 1px solid #151515;
    @include clearfix;
  
    #edit-search-terms {
      padding: 0.5rem 0;
      text-align: left;
      color: #3d3d3d;
      border: none;
      background: none;
      box-shadow: none!important;
      font-size: 1em;
      flex-grow: 999;
      outline: none;
      
      &::placeholder {
        color: rgba(black, 0.8);
      }
    }
    #edit-search-terms:focus,
    #edit-search-terms:focus-within {
      border: 2px solid $link-focus;

      &::placeholder {
        color: rgba(black, 0.4);
      }
    }

    button {
      appearance: none;
      border: none;
      display: inline-block;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
      text-align: right;
      text-decoration: none;
      text-transform: uppercase;
      line-height: 1.4;
      font-size: 1.25rem;
      padding: 0.5rem 1rem;

      background-color: transparent;

      span {
        display: none;
      }

      &:before {
        // TODO:  It may be better to inline an SVG of the magnifying glass for portability
        content: "\f002";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: black;
        transition: 0.1s;
        font-size: 1.5em;
      }
      &:hover:before {
        color: #333;
      }
    }
  }

  // ———————————————————————————————————————————————————————————
  // --- BREAKPOINTS -------------------------------------------

  @include breakpoint($medium-width) { 
    width: 45%;
    padding: 20px;
  }

  @include breakpoint($fixed-width) { 
    padding: 0;
  }

  // -----------------------------------------------------------
  // ———————————————————————————————————————————————————————————

}