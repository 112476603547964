// Table of contents created for ????

.table-of-contents {
	float: right;
	margin: 0 0 8px 12px;
	padding: 4px 8px;
	background-color: #ddd;
	width: 30%;
	font-size: small;
	border-radius: 4px;
}

.table-of-contents ul {
	padding: 0;
}

.table-of-contents li {
	line-height: 1.25em;
}

.table-of-contents a {
	text-decoration: none !important;
}

.table-of-contents a:hover {
	color: #666;
}

.table-of-contents > h2 {
	border-bottom: none;
}