// ------------------------------------------------------------
// --- button-grid --------------------------------------------
// A button-grid is a small grid of UIDS buttons. It is used in 
// in the Libraries enough to justify generalizing it here and
// extending the uids-button defined here.
// 
// The brand typeface, Antonio, is great for small amounts of
// test in buttons - both when in uppercase and normal case.
// However, another brand font, Roboto Condensed, is much more
// readable and so, for button-grids, this font is used.

// function: Calculate the flex-basis of the buttons
@function theFlexBasis($numColumns, $gapSize) {
@return calc((100% - ($gapSize * ($numColumns - 1))) / $numColumns);
}

$button-gap: 1rem;

ul.button-grid,
ul.button-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    gap: $button-gap;

    li {
        flex-basis: 100%; // default is one column of buttons (e.g., mobile)

        a {
            @extend .uids-button;
      display: block; // grid buttons are always full width
      font-family: 'Antonio', sans-serif;
      font-variation-settings: "wght" 500;
      @include font-size(1.8);
      text-transform: none; // default to normal case for ease of reading
      transition: 0.2s;

      i:before {
        color: black;
      }
    }
  }
}

// Where button grid is 2 buttons wide max
ul.button-grid--2,
ul.button-list--2 {
    @extend .button-grid;

    @include breakpoint($medium-width) {
        flex-flow: row wrap;

        li {
            flex-basis: theFlexBasis(2, $button-gap);
        }
    }
}

// Where button grid is 3 buttons wide max
ul.button-grid--3,
ul.button-list--3 {
    @extend .button-grid;

    @include breakpoint($medium-width) {
        flex-flow: row wrap;

        li {
            flex-basis: theFlexBasis(2, $button-gap);
        }
    }

    @include breakpoint($fixed-width) {

        li {
            flex-basis: theFlexBasis(3, $button-gap);
        }
    }
}