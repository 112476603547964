.banner {

  &__content {
    z-index: 10;
    margin: $gap auto;
    padding: 0 0 0 0;
    width: calc(100% - 40px);

    .breadcrumbs {

      ol {
        margin: 0;
        padding: 0;

        li {
          display: inline-block;
          text-shadow: none;
          @include font-size(1.4);

          &:not(:first-child):before {
            display: inline-block;
            margin: 0 1rem;
            content: " /";
            color: $hawk-gold;

            .banner-image-exists & {
              text-shadow: 0 1px 1px #000;
            }
          }
          // &:last-of-type {
          //   font-weight: bold;
          // }

          a {
            color: $hawk-black;
            text-decoration: none;
            text-shadow: none;
            
            .banner-image-exists & {
              color: white;
              text-shadow: 0 1px 1px #000;
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .entry-title {
      margin: 0;
      font-family: $font-accent-1;
      font-weight: 600;
      @include font-size(3);
      color: black;
      text-shadow: none;

      .banner-image-exists & {
        text-shadow: 2px 2px 3px #000;
        color: white;
      }

      &--long,
      &--super-long {
        @include font-size(2.6);
      }
    }

    @include breakpoint($medium-width) {
      .entry-title {
        @include font-size(4.8);

        &--long {
          @include font-size(3.6);
        }
        &--super-long {
          @include font-size(3.0);
        }
      }
    }
    @include breakpoint($fixed-width) {
      max-width: $fixed-width;

      .entry-title {
        @include font-size(5.4);

        &--long {
          @include font-size(4.0);
        }
        &--super-long {
          @include font-size(3.2);
        }
      }
    }
  }
}