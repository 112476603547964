.page-region__nav-secondary {

  .libcal-todays-hours {

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    
      li {
        @include font-size(1.4);
      }
    }
  }

  @include breakpoint($medium-width) {

  }
  @include breakpoint($fixed-width) {
    
  }

  // TODO: Reconcile this, eventually
  ul.menu.subnav-sidebar {
    margin: 0 -4px $gap;
  }
}