
#nav-narrow-toggle {
    margin: 0;
    padding: 1rem;
    width: 100%;
    border: none;
    background-color: #aaa;
    font-weight: bold;

    font-family: $font-workhorse;

    .toggle-icon {
        display: inline-block;
        width: 1.25em;
    }

    .toggle-text {
        display: block;
        margin: 0;
        padding: 0;
        color: black;
        text-transform: uppercase;
        text-align: left;
        @include font-size(1.8);
    }

    &[aria-expanded="false"] {

        .icon-close {
            display: none;
        }
        .icon-menu {
            display: inline-block;
        }
    }
    &[aria-expanded="true"] {
        // background-color: #ccc;
        
        .icon-close {
            display: inline-block;
            opacity: 0.5;
        }
        .icon-menu {
            display: none;
        }
    }
}

ul#nav-narrow-menu {

    &.hidden {
        display: none;
    }

    li.sf-depth-1 {
        
        >a {
            display: block;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
            font-weight: bold;
            @include font-size(2);
        }
    }
    ul.sub-menu {
        padding: 0 0 0 1rem;

        >li {
            padding-left: 2rem;
            text-indent: -2rem;
            
            a {
                display: block;
                padding: 0.25rem 0;
            }
        }
    }
}
