// This addresses browsers that do not handle inline-SVG (e.g., IE8 and below, Android 2.3 and below, etc.).
// Relies upon classes placed by modernizr. 
.svg-fallback {
	display: none;
}
.no-inlinesvg .svg-fallback {
	display: block;
}
.svg-fallback-test {
	display: inline-block;
	border: none;
}

// OMG, IE! Get your ducks in a row. This filters for IE10 and above.
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	// svg {
	// 	display: none;
	// }
	// .svg-fallback {
	// 	display: inline-block;
	// 	opacity: 0.9;

	// 	&:hover {
	// 		opacity: 1.0;
	// 	}
	// }

	// specific hacks to get inline SVG to display acceptably in IE
	#global-footer.footer-wrapper .uilibraries-branding svg {
		width: 40%;
	}
	body.theme-ranganathan .header-wrapper .uilibraries-branding svg {
		max-height: 48px;
	}
}