.featured-items.owl-carousel {
	margin-bottom: 0;

	.owl-controls {
		display: none;
	}
}

.featured-items.owl-carousel {
	margin-bottom: 24px;

	.feature-image {
		display: none; // Hide images until the Owl Carousel js is in play so that images do not flash
	}

	.owl-item {
		position: relative;

		.feature-item {
			transition: 0.2s;
		}

		.feature-image {
			display: block;
		}

		.feature-info {
			position: absolute;
			bottom: -1px;
			right: 0;
			// min-height: 8rem;

			padding: 0.5rem;
			width: calc(100% - 1rem);
			opacity: 1.0;
			color: white;
			text-shadow: none;
			background-color: rgba(black, 0.8);
			transition: 0.2s;

			.feature-title {
				margin: 0;
				padding: 4px 0;
				@include font-size(1.6);
				font-weight: 200;
				text-align: center;
			}
			.feature-description {
				margin: 0;
				padding: 0 0 6px 0;
				
				font-weight: 200;
				text-align: center;

				p {
					margin: 0;
					padding: 0;
					@include font-size(1.3);
					line-height: 1.35rem;
				}
			}

			a {
				color: white;
				text-decoration: none;
			}
		}

		&:hover {

			// .feature-item {
				// box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
				// transform: scale(1.02);
			// }

			.feature-info {
				background-color: rgba(black, 1.0);
			}
		}

		// custom classes to be generalized later
		.feature-info {

			&.info-top {
				bottom: auto;
				top: 0;
			}
		}
	}
}