.banner {

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;

    // --- Special style classes affecting the image
    &.bottom-align {
      background-position: bottom right;
    }
    &.bottom-align-20 {
      background-position: bottom 20% right;
    }
    &.bottom-align-40 {
      background-position: bottom 40% right;
    }
    &.middle-align {
      background-position: middle right;
    }
    &.top-align {
      background-position: top right;
    }
    &.top-align-20 {
      background-position: top 20% right;
    }
    &.top-align-40 {
      background-position: top 40% right;
    }
    // TODO:  overlay-blur is not as good as it should be. See to that.
    &.overlay-blur {
      overflow: hidden;
      filter: blur(1px);
    }
  }
  &__image-overlay {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 85%);
    background-size: auto;
    background-repeat: repeat;
    width: 100%;
    height: 100%;

    // -- For higher contrast when more banner text is present...
    // .darktide-higher {
    //   background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%);
    // }

    // --- Special style classes affecting the transparent overlay image options...
    .overlay-brain-white & {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url('//www.lib.uiowa.edu/includes/uids/images/brain-overlay-white-trans050.png');
      background-size: auto auto;
      background-repeat: repeat repeat;
    }
    .overlay-brain-black & {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url('//www.lib.uiowa.edu/includes/uids/images/brain-overlay-black-trans025.png');
      background-size: auto auto;
      background-repeat: repeat repeat;
    }
    .overlay-brain-gold & {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url('//www.lib.uiowa.edu/includes/uids/images/brain-overlay-gold-trans025.png');
      background-size: auto auto;
      background-repeat: repeat repeat;
    }
    .overlay-triangles & {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url('//www.lib.uiowa.edu/includes/uids/images/cover-pattern-triangles.png');
      background-size: auto auto;
      background-repeat: repeat repeat;
    }
    .overlay-tiles & {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 80%), url('//www.lib.uiowa.edu/includes/uids/images/cover-pattern-tiles.png');
      background-size: auto auto;
      background-repeat: repeat repeat;
    }
    .overlay-tiles-light & {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url('//www.lib.uiowa.edu/includes/uids/images/cover-pattern-tiles-light.png');
      background-size: auto auto;
      background-repeat: repeat repeat;
    }
    .overlay-tiles-small & {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url('//www.lib.uiowa.edu/includes/uids/images/cover-pattern-tiles-small.png');
      background-size: auto auto;
      background-repeat: repeat repeat;
    }
    .overlay-particles & {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url('//www.lib.uiowa.edu/includes/uids/images/cover-pattern-particles-2.png');
      background-size: auto auto;
      background-repeat: repeat repeat;
      background-position: top left, -20px -20px;
    }
    .overlay-particles-2 & {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url('//www.lib.uiowa.edu/includes/uids/images/cover-pattern-particles-1.png');
      background-size: auto auto;
      background-repeat: repeat repeat;
      background-position: top left, -20px -20px;
    }
    .overlay-screendoor & {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url('//www.lib.uiowa.edu/includes/uids/images/cover-pattern-screendoor.png');
      background-size: auto auto;
      background-repeat: repeat repeat;
    }
    .overlay-gridpaper & {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 70%), url('//www.lib.uiowa.edu/includes/uids/images/cover-pattern-gridpaper.png');
      background-size: auto auto;
      background-repeat: repeat repeat;
    }
  }
  // TODO: implement once the template places the banner classes on the outer element
  &.half-height {
    max-height: 200px;
  }
}