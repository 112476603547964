.featured-items-outer {  // TODO: class should signify that this is a pod to be consistent with other styles

    .feature-item {

        &.title-uppercase {

            .feature-title {
                text-transform: uppercase;
            }
        }
    }
}