.page__container {

  .page-region__content-primary h2 {
    margin-bottom: 1rem;
    padding: 0;
    font-family: $font-accent-1;
    font-weight: 600;
    @include font-size(2.75);

    +p, +ul, +ol {
      margin-top: 0;
    }

    strong,b {
      font-weight: 600;
    }

    @include breakpoint($medium-width) {
      @include font-size(3.25);
    }
    @include breakpoint($fixed-width) {
      @include font-size(3.75);
    }
  }

  h3 {
    margin-bottom: 1rem;
    padding: 0;
    font-family: $font-accent-1;
    font-weight: 600;
    @include font-size(2.5);

    +p, +ul, +ol {
      margin-top: 0;
    }

    strong,b {
      font-weight: 600;
    }

    @include breakpoint($medium-width) {
      @include font-size(2.75);
    }
    @include breakpoint($fixed-width) {
      @include font-size(3);
    }
  }

  h4 {
    margin-bottom: 1rem;
    padding: 0;
    font-family: $font-workhorse-1;
    font-weight: 600;
    @include font-size(1.6);

    +p, +ul, +ol {
      margin-top: 0;
    }

    strong,b {
      font-weight: 600;
    }

    @include breakpoint($medium-width) {
      @include font-size(1.8);
    }
    @include breakpoint($fixed-width) {
      @include font-size(2.0);
    }
  }

  h5 {
  
  }

  h6 {
  
  }

}