body {
  position: relative;
  
  .scrolltop-wrap {
    // display: block;

    display: none;


    position: absolute;
    bottom: 0;
    right: 2rem;
    height: calc(100% - 100vh);
    z-index: 9999;

    &:before {
      content: '';
      display: block;
      height: 100vh;
      pointer-events: none;
    }

    a {
      position: sticky;
      top: 90vh;
      cursor: pointer;
      font-size: 20px;
      
      svg {
        transform: scale(0.9);
        transition: 0.2s;
        filter: drop-shadow(#666 2px 2px 2px);
      }

      &:hover {
        cursor: pointer;

        svg {
          transform: scale(1);

          #scrolltop-bg {
            fill: darken($hawk-gold, 5%);
          }
        }
      }
    }
  }
}