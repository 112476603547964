@mixin breakpoint($point) {

    @media (min-width: $point) { 
        @content; 
    }
}

// Set rem font sizing.
@mixin font-size($sizeValue: 1.6) {
    font-size: ($sizeValue * 10) + px;
    font-size: $sizeValue + rem;
    line-height: 1.5;
}

// Clearfix. Use @extend for this instead of @include and it will not bloat. < WTF? Did I write this?
@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

// Hides tag offscreen; still accessible
@mixin hide-offscreen {
    position: absolute;
    left: -10000px;
    top: auto;
    height: 1px;
    overflow: hidden;
    width: 1px;
}

// Negates a hide-offscreen, specifically. it is not really good for anything else.
// NOT FOUND IN USE IN /sass - 20181130
@mixin show-onscreen {
    position: inherit;
    // left: -10000px;
    // top: auto;
    height: auto;
    overflow: inherit;
    width: auto;
}

// Creates a standard button from any clickable element (e.g., a or button)
@mixin simple-button($bg-color: $hawk-gold, $text_color: black) {
    $bg-color-hover: lighten($bg-color, 10%);
    $bg-color-active: saturate($bg-color, 15%);
    display: inline-block;
    padding: .7em 2em .55em;
    outline: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none !important;
    font-family: inherit;
    transition: 0.1s;
    background-color: $bg-color;
    color: color-contrast($bg-color) !important; // needed to override link colors
    border: none;
    line-height: 1.5em;

    &:hover {
        text-decoration: none;
        background-color: $bg-color-hover;
        color: color-contrast($bg-color-hover);
    }

    &:active {
        position: relative;
        top: 1px;
        background-color: $bg-color-active;
        color: color-contrast($bg-color-active);
    }

    &:focus {
        outline: 2px solid $link-standard;
    }

    i {
        margin-right: 0.333em;
    }
}


















@mixin simple-button-temp($bg-color: $hawk-gold, $text_color: black) {
    $bg-color-hover: lighten($bg-color, 10%);
    $bg-color-active: saturate($bg-color, 15%);
    display: inline-block;
    padding: .7em 2em .55em;
    outline: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none !important;
    font-family: inherit;
    // border-radius: 0.33em;
    transition: 0.1s;
    font-weight: normal;
    background-color: $bg-color !important;
    color: color-contrast($bg-color) !important; // needed to override link colors
    border: none;
    line-height: 1.5em;

    &:hover {
        text-decoration: none;
        background-color: $bg-color-hover !important;
        color: color-contrast($bg-color-hover);
    }

    &:active {
        position: relative;
        top: 1px;
        background-color: $bg-color-active;
        color: color-contrast($bg-color-active);
    }

    &:focus {
        outline: 2px solid $link-standard;
    }

    i {
        margin-right: 0.333em;
    }
}




// Extends simple-button to include an icon
//  $icon-name   - name (without suffix) of the icon file
//  $bg-color    - button color
//  $position    - does the icon appear on the left or right of the button
//  $icon-color  - force an icon color to override contrast rules (TODO: make gold an override option in future edits)
@mixin simple-button-with-icon($icon-name: 'arrow-circle-right', $bg-color: $hawk-gold, $position: left, $icon-color: null) {
    @include simple-button($bg-color);
    text-align: left;
    padding-#{$position}: 2.5em;
    @if $icon-color {
        // do nothing
    } @else {
        $icon-color: color-contrast($bg-color);
    }
    $icon-directory: if(($icon-color == #000), 'black', 'white');
    @if starts-with($icon-name, 'custom_') {
        $icon-name: str-replace($icon-name, 'custom_');
        background-image: url(/includes/icons/custom/svg/#{$icon-directory}/#{$icon-name}.svg);
    } @else {
        background-image: url(/includes/icons/svg/#{$icon-directory}/#{$icon-name}.svg);
    }
    background-repeat: no-repeat;
    background-size: 1.25em;
    @if $position == 'right' {
        background-position: calc(100% - 0.5em) center;
        padding-left: 1.25em;
    } @else {
        background-position: 0.75em center;
        padding-right: 1.25em;
    }
}

// Returns JUST a white or black svg icon depending upon highest contrast against bg-color
@mixin bg-icon($icon-name, $bg-color: white) {
    $icon-color: color-contrast($bg-color);
    $icon-directory: if(($icon-color == #000), 'black', 'white');
    @if starts-with($icon-name, 'custom_') {
        $icon-name: str-replace($icon-name, 'custom_');
        background-image: url(/includes/icons/custom/svg/#{$icon-directory}/#{$icon-name}.svg);
    } @else {
        background-image: url(/includes/icons/svg/#{$icon-directory}/#{$icon-name}.svg);
    }
    background-repeat: no-repeat;
}

// Places an icon to the right or left of an element
// @mixin simple-icon($icon-name: 'circle', $bg-color: white, $alignment: left, $bg-x: 0, $bg-y: top, $bg-size: 1em) {
//     text-align: left;
//     padding-#{$alignment}: $bg-size * 1.5;
//     $icon-directory: if((color-contrast($bg-color) == #000), 'black', 'white');
//     @if starts-with($icon-name, 'custom_') {
//         $icon-name: str-replace($icon-name, 'custom_');
//         background-image: url(/includes/icons/custom/svg/#{$icon-directory}/#{$icon-name}.svg);
//     } @else {
//         background-image: url(/includes/icons/svg/#{$icon-directory}/#{$icon-name}.svg);
//     }
//     background-repeat: no-repeat;
//     background-size: $bg-size;
//     @if $alignment == 'right' {
//         background-position: calc(98% - $bg-x) $bg-y;
//     } @else {
//         background-position: $bg-x $bg-y;
//     }
// }
// Alternative version under development --------------------------------------------------------------
@mixin simple-icon($icon-name, $bg-color: white, $position: left, $icon-size: 1em, $icon-padding: 0em) {
    text-align: left;
    padding-#{$position}: calc(#{$icon-size} * 1.333 + #{$icon-padding});
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    line-height: 1.5em;
    color: color-contrast($bg-color);
    $icon-directory: if((color-contrast($bg-color) == #000), 'black', 'white');
    @if starts-with($icon-name, 'custom_') {
        $icon-name: str-replace($icon-name, 'custom_');
        background-image: url(/includes/icons/custom/svg/#{$icon-directory}/#{$icon-name}.svg);
    } @else {
        background-image: url(/includes/icons/svg/#{$icon-directory}/#{$icon-name}.svg);
    }
    background-repeat: no-repeat;
    background-size: $icon-size;
    background-position: #{$position} #{$icon-padding} top 0.35em; // NOTE: 0.35em is a kludge based upon a line-height of 1.5em
}





// Notification boxen
@mixin simple-notification($icon-name, $bg-color-1: white, $bg-color-2: lighten($bg-color-1, 25%), $custom: false) {
    display: flex;
    background-color: $bg-color-2;
    color: color-contrast($bg-color-2);
    overflow: hidden;

    &:before {
        content: '';
        min-width: 3em;
        @include bg-icon($icon-name, $bg-color-1);
        background-color: $bg-color-1;
        background-repeat: no-repeat;
        background-size: 2em;
        background-position: center 1em;
    }

    // Below is the minimum for a simple notification. Other stylings affecting notification content are added more locally.
    .notification-content {
        flex: 1;
        // padding: 8px;
    }
}

// Notification boxen
// NOT FOUND IN USE IN /sass - 20181130
@mixin simple-notification-test($icon-name, $bg-color-1: white, $bg-color-2: lighten($bg-color-1, 25%)) {
    display: flex;
    background-color: $bg-color-2;
    color: color-contrast($bg-color-2);
    overflow: hidden;

    &:before {
        content: '';
        min-width: 3em;
        // $icon-color: color-contrast($bg-color-1);
        $icon-color: white; // For now, let's just make these icons white. They are not semantically essential.
        // background: url(/includes/icons/png/#{$icon-color}/128/#{$icon-name}.png);
        background: url(/includes/icons/svg/custom/#{$icon-name}-#{$icon-color}.svg), linear-gradient(transparent, transparent); // see 'PNG fallback trick' below
        background-color: $bg-color-1;
        background-repeat: no-repeat;
        background-size: 2em;
        background-position: center 1em;
    }

    // Below is the minimum for a simple notification. Other stylings affecting notification content are added more locally.
    .notification-content {
        flex: 1;
        padding: 8px;
    }
}


// Places an CUSTOM icon to the right or left of an element
// NOTE:  It seems like there should be a better way to do this via SVG. Get on this, Ken.
// NOT FOUND IN USE IN /sass - 20181130
@mixin simple-custom-icon($icon-path, $position: left, $indent: 0) {
    text-align: left;
    padding-#{$position}: 1.5em;
    background-image: url(#{$icon-path});
    background-repeat: no-repeat;
    background-size: 1em;
    @if $position == 'right' {
        background-position: calc(100% - $indent) center;
    } @else {
        background-position: $indent center;
    }
}


// Applies zebra striping to the repeating element within which it is included.
@mixin zebra-stripe($odd-bg-color: $zebra-odd-bg-default, $even-bg-color: $zebra-even-bg-default) {
    &:nth-of-type(odd) {
        background-color: $odd-bg-color;
    }
    &:nth-of-type(even) {
        background-color: $even-bg-color;
    }
}

@mixin seperator-line($line-color: $zebra-odd-bg-default, $line-weight: 1px) {
    border-bottom: $line-weight solid $line-color;

    &:last-of-type {
        border-bottom: 0;
    }
}

// Accepts image and makes it contained in the bg. This was created for the Studio site.
@mixin contain-bg-img($path) { 
    background-image: image-url($path); 
    background-size: contain; 
    background-repeat: no-repeat; 
    width: 100%; 
    height: 0; 
    // padding-top: percentage(image-height($path) / image-width($path)); 
    padding-top: 120px; 
}
// Same as above, take #2. This is a total hack that works. This was created for the Studio site.
// NOT FOUND IN USE IN /sass - 20181130
@mixin fit-div-to-bg-img($path) { 
    background-image: url($path); 
    background-size: contain; 
    background-repeat: no-repeat; 
    width: 100%; 
    height: 0; 
    padding-top: percentage(image-height($path) / image-width($path)); 
}

// Accepts image and makes it the cover bg.
// @mixin cover-bg-img($path) { 
//     background-image: image-url($path); 
//     background-size: cover; 
//     background-repeat: no-repeat; 
//     width: 100%; 
//     height: auto;
// }

// Positions block in center of its encompassing pos:rel. It's frikkin magic!
@mixin centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// Sets bg color. Sets text and links to black or white whichever is most contrasty to bg color.
// This is not foolproof because it still allows for contrast fails. 
// Something else is in the works, but it will adjust the bg color to create a contrast ration that is acceptable.
@mixin simple-colors($bg-color) {
    background-color: $bg-color;
    color: color-contrast($bg-color);
        
    a,a:link,a:visited {
        color: color-contrast($bg-color);
        text-decoration: underline;
        transition: 0.2s;

        &:hover {
            opacity: 0.8;
            text-decoration: none;
        }
    }
}

// ----- ELIMINATE or ENHANCE -----------------------------------------------------------
// /Volumes/Quiet-www/sass/elements/_boxes-TEST.scss:
//     1  .simple-box {
//     2:  @include simple-box;
//     3  }

// /Volumes/Quiet-www/sass/elements/_boxes.scss:
//     1  .simple-box {
//     2:  @include simple-box;
//     3  }

// /Volumes/Quiet-www/sass/shame/_index.scss:
//    25     // padding: 8px;
//    26     // width: 660px;
//    27:    // @include simple-box;
//    28     border: none;
//    29  

// /Volumes/Quiet-www/sass/sites/hardin/_hardin-workshops.scss:
//    64   // dynamically created list of all sessions on this page
//    65   .workshop-list {
//    66:    @include simple-box;
//    67     //margin-bottom: 24px;
//    68     float: right;

@mixin simple-box {
    margin: 0 0 18px 0;
    padding: 8px;
    border: 1px solid #ccc;

    .box-title {
        display: block;
        margin: -8px -8px 8px -8px;
        padding: 4px 8px;
        background-color: #ccc;
        color: black;
        @include font-size(1.8);

        a:link {
            @include font-size(1.8);
        }

        em {
            @include font-size(1.4);
        }
    }

    ul {
        padding-bottom: 0.5em;
    }
}
// ------------------------------------------------------------------------------------


// ------ FOOTNOTES ----------------------------------------------------------
//
// *** PNG fallback trick
// The linear-gradient targets the same browsers that can't do SVG and allows the bg PNG to play through. 
// Credit to Amelia Bellamy-Royds (https://css-tricks.com/a-complete-guide-to-svg-fallbacks/).
// HOWEVER, since I have moved to using flexbox to render the notifications box, this clever fallback is moot.
// I keep it because it is preprocessed and I like it.
//
// ***
// ---------------------------------------------------------------------------


@mixin simple-dev-bg($base-color: $hawk-gold) {
    background: repeating-linear-gradient(
		-45deg,
		lighten($base-color, 20%),
		lighten($base-color, 20%) 10px,
		lighten($base-color, 10%) 10px,
		lighten($base-color, 10%) 20px
	);
}

@mixin simple-dev-message($message-text: "THIS PAGE IS UNDER DEVELOPMENT", $message-bg: $hawk-gold, $message-height: 40px) {
    position: relative;

    .header-wrapper {
        margin-top: $message-height;
    }

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border-bottom: 6px solid $header-bg;
        content: $message-text;
        width: 100%;
        height: $message-height;
        @include simple-dev-bg($message-bg);
        color: color-contrast(lighten($message-bg, 20%));
        text-align: center;
        font-weight: bold;
        line-height: $message-height;
    }
}

@mixin simple-callout-box {
	margin: 0 2rem 3rem 0;
	padding: 2rem 3rem;
	
	$icon-size: 120px;
	background-image: url('/includes/icons/svg/white/info-circle.svg'), linear-gradient(rgba(#b0eafc, 1), rgba(#b0eafc, 0.5));
	background-size: $icon-size, auto;
	background-repeat: no-repeat,  repeat;
	background-position: top $icon-size*-0.15 right $icon-size*-0.15, top left;

	&:before {
		float: right;
		display: block;
		margin: 0 1rem 0 1rem;
		content: "";
		width: $icon-size*0.85;
		height: $icon-size*0.85;
	}

	// &.callout-alert  {
	// 	background-image: url('/includes/icons/svg/white/exclamation-circle.svg'), linear-gradient(rgba($m-orange, 0.45), rgba($m-orange, 0.4));
	// }

	// &__construction  {
	// 	background-image: url('/includes/icons/svg/white/hard-hat.svg'), linear-gradient(rgba($m-orange, 1), rgba($m-orange, 1));
	// }
}

@mixin simple-full-width-banner($img-url, $img-h: 20vh, $img-x: left, $img-y: bottom, $title-bg-color: rgba(black, 0.8), $bottom-border: false) {

    .entry-title-outer {
      position: relative;
      margin-bottom: 2rem;
      background-image: url($img-url);
      background-size: cover;
      background-position-x: $img-x;
      background-position-y: $img-y;
      height: $img-h;
      @if $bottom-border == false {
        border-bottom: none;
      } @else if $bottom-border == true {
        border-bottom: 2px solid $hawk-gold;
      } @else {
        border-bottom: $bottom-border;
      }
      

      >.entry-title {
        position: absolute;
        left: 0;
        bottom: 0;
        margin: 0;
        padding: 2rem 3rem !important;
        @include font-size(2.4);
        font-family: $font-stack-headers;
        font-weight: 400;
        background-color: $title-bg-color;
        color: color-contrast($title-bg-color);
        min-width: 35%;
        height: auto;
      }
    }

    @include breakpoint($fixed-width) {

    .entry-title-outer {
      height: $img-h * 1.25;

      h2.entry-title {
        @include font-size(3.6);
      }
    }
  }
}