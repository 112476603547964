.gfield_required_asterisk {
    font-size: 1em !important;
    font-weight: bolder;
}

.ginput_container_textarea textarea {
    font-family: $font-workhorse;
}

.gravity-theme.ui-datepicker {

    .ui-datepicker-header .ui-datepicker-title {

        .ui-datepicker-month,
        .ui-datepicker-year {
            font-size: 1.6rem !important;
        }
    }

    .ui-datepicker-calendar {

        th span, td {
            font-size: 1.6rem !important;
        }
    }
}
