// NOTE: These styles are not connected to a Pods template. 
//       They are connected to PHP/JSON in Wordpress templates.
//       They are global to afford inclusion in any site.

$person-image-dimension:		100px;  // size of the person image square

.org-unit {
	margin-bottom: 24px;
}

.dir-list {
	margin-bottom: 2rem;

	.dir-list-item {
		@include clearfix;
		margin: 0;
		padding: 8px;
		@include font-size(1.2);
		@include zebra-stripe;

		.dir-list-item-inner {
			@include clearfix;

			h3.heading {
				@include font-size(1.4);
			}
			
			>div {
				display: inline-block;
				vertical-align: top;

				&.photo {
					margin-right: 1em;
					margin-left: -8px;
					margin-top: -8px;
					margin-bottom: -11px;
					width: $person-image-dimension;
					min-height: $person-image-dimension;
					background-image: url('//www.lib.uiowa.edu/people/files/2017/04/generic-person-square.jpg');
					background-size: contain;
					background-repeat: no-repeat;

					img {
						width: 100%;
						height: auto;
					}
				}

				&.person-info {
					width: calc(100% - 100px - 1em);
					columns: 2 300px;
					

					.name-title-link {
						break-inside: avoid-column;
						
						.name {
							@include font-size(1.6);
							// font-weight: 400;
						}

						.edit {
							@include font-size(1.2);
						}

						.pronouns {
							@include font-size(1.2);
							font-style: italic;
						}
					}

					.subject-specialties {
						display: none;
						clear: both;
						@include font-size(1.2);
	
						ul {
							display: inline;
							margin: 0;
							padding: 0;
							list-style-type: none;
	
							li {
								display: inline;
								@include font-size(1.2);
	
								&:after {
									content: "; ";
								}
								&:last-of-type:after {
									content: "";
								}
							}
						}
					}
				}
			}
		}
	}

	// --- BREAKPOINTS ----------------------------------------
	@include breakpoint($small-width) {

		h3.heading {
			@include font-size(1.8);
		}

		.dir-list-item {
			@include font-size(1.4);
		}
		
	}
	// --------------------------------------------------------
}







// Special lists of directories by org unit or by subject specialty
.org-unit,
.subject-area {

	h3,h4 {
		position: relative;
		margin: 0 !important;
		padding: 8px !important;
		background-color: #333;
		color: white;
		font-weight: lighter !important;

		.back-to-top {
			position: absolute;
			top: 8px;
			right: 8px;
			transition: 0.25s;
			opacity: 0.666;

			img {
				height: 1.5em;
				width: 1.5em;
			}

			&:hover {
				opacity: 1;
			}
		}
	}
}