@import "reset";
@import "colors";
@import "links";
@import "headings";
@import "typography";
@import "images";
@import "lists";
@import "layout";
@import "tables";
@import "misc";
@import "hide-show";

// TODO:  temporary placement
body.libchat-ONLINE {
	.chat-online {
		display: block;
		padding: 2rem 0 3rem 0;

		// .chat-link {
		// 	@include simple-button-with-icon("comments", $hawk-gold);
		// 	font-weight: bold;
		// 	@include font-size(2.8);
		// }
	}
	.chat-offline {
		display: none;
	}
}
body.libchat-OFFLINE {
	.chat-online {
		display: none;
	}
	.chat-offline {
    display: block;
    padding: 1rem 0 3rem 0;
    @include font-size(2);
    font-weight: bold;
	}
}

body.user-clinkenb {

	.all-homes-alert {
		display: block !important
	}
}