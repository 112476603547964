.subjects-listing {

  .subject-item {
    margin-bottom: 1rem;

    h3 {
      margin-bottom: 0;
      padding: 6px;
      background-color: #333;
      color: white;
      @include font-size(2.2);
      font-weight: 300;
    }

    .subject-primary-link {
      padding: 6px;
      @include font-size(1.4);
      background-color: #ccc;

      a i:before {
        color: #333;
      }
    }

    .subject-expert {
      padding: 6px;
      background-color: #e1e1e1;
      @include font-size(1.4);
    }
  }
}