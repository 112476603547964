.pod-region-slide-inner {

	.owl-nav {
		
		button {
			position: absolute;
			margin: 0;
			padding: 0;
			bottom: 40px;
			height: 48px;
			width: 48px;
			background-color: rgba($link-standard, 0.8) !important;
			transition: 0.25s;
			
			span {
				display: block;
				height: 100%;
				width: 100%;
				background: none;
				background-size: contain;
				background-position: center center;
				background-repeat: no-repeat;
				color: transparent;
			}

			&.owl-next {
				right: 0;

				span {
					background-image: url('/includes/icons/png/white/128/caret-right.png');
				}
				
			}
			&.owl-prev {
				right: 52px;

				span {
					background-image: url('/includes/icons/png/white/128/caret-left.png');
				}
			}

			&:hover {
				background-color: rgba($link-standard, 1.0) !important;
			}
		}
		
		.disabled {
			display: none;
		}
	}
}