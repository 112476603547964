.nav--vertical {
  background-color: #eee;

  ul {
    list-style-type: none;

    &.menu {
      margin: 0;
      margin-top: -0;
      padding: 10px 20px;
    }

    li.sf-depth-1 ul {
      // display: none;
    }
  }
}