.site-home.page-id-4312 {

  .section-news-events {
    margin-bottom: $section-bottom-gap;
    
    >section {
      margin-bottom: 3rem;
    }

    a.more {
      float: right;
      margin-top: 3rem;
    }
  }
}

// Paginated pages at https://www.lib.uiowa.edu/news/ and https://www.lib.uiowa.edu/events/
body.site-home {

	.pagination {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 2rem;
    margin: 0;

		a {
			width: calc(100% - 8rem);
		}

    @include breakpoint($small-width) {
      flex-direction: row;

      a {
        width: auto;
      }
    }
	}

  &.news {

    span.title a {
      font-weight: bold;
    }
  }
  &.events {

    .pagination.offset-0 {
      // hold next link to right align when no prev link exists
      flex-direction: row-reverse;  
    }

    .event-title a {
      font-weight: bold;
    }
  }
}