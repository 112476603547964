.liblock__gform {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #eee;

  // --- overrides

  .gform_wrapper {

    .gform_body .gform_fields {

      .gfield {
        margin-top: 1em;
      }

      .gfield_label {
        font-size: 85%;
        font-weight: lighter;
      }
    }

    .gform_footer {

      input[type=submit] {
        @include simple-button($hawk-gold);
      }
    }
  }
}