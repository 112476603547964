.gfield--type-select {

    .ginput_container_select {

        a.chosen-default > span {
            color: #555; // a11y correction for contrast (was #999 on white)
        }
    }
}

// An a11y hack to override, primarily, the overflow: hidden on selection dropdown content. This appeases Siteimprove.
.gform_wrapper.gravity-theme .chosen-container,
.gform_wrapper.gravity-theme .chosen-single {
    overflow-x: visible !important; 
    overflow-y: visible !important;
    white-space: normal !important; 
    word-wrap: break-word;
}
// ================================