aside.callout {
	margin-bottom: 3rem;
	padding: 1.5rem 2rem;

	.callout-inner {
		margin: 0 auto;
		max-width: $fixed-width;
	}

	h2 {
		@include font-size(1.8);

		// @include breakpoint($medium-width) {
		// 	@include font-size(1.8);
		// }
	}

	p {
		margin-bottom: 0.5rem;
		margin-top: 1rem;
		// @include font-size(1.4);
	}
	ul {
		margin: 0.5rem 0;

		li {
			// @include font-size(1.4);
		}
	}
	
	$icon-size: 100px;
	background-image: url('/includes/icons/svg/white/info-circle.svg'), linear-gradient(rgba($secondary-blue, 0.35), rgba($secondary-blue, 0.2));
	background-size: $icon-size, auto;
	background-repeat: no-repeat,  repeat;
	background-position: top $icon-size*-0.15 right $icon-size*-0.15, top left;

	&:before {
		float: right;
		display: block;
		margin: 0 1rem 0 1rem;
		content: "";
		width: $icon-size*0.85;
		height: $icon-size*0.85;
	}

	&.callout-alert  {
		background-color: rgba($secondary-orange, 0.25);
		background-image: none;
		border-bottom: 2px solid $secondary-orange;

		// TEMP:  Remove when all alerts are updated
		&:before {
			display: none;
		}

		h2 {
			@include font-size(2);

			i {
				color: $secondary-orange;
			}
		}

		&-info {
			background-color: rgba($secondary-blue, 0.15);
			background-image: none;
			border-bottom: 2px solid $secondary-blue;

			h2 {
				@include font-size(2);

				i {
					color: $secondary-blue;
				}
			}
			i {
					color: $secondary-blue;
				}
		}

		&-red {
			background: url('/includes/icons/svg/white/exclamation-circle.svg'), linear-gradient(rgba($secondary-orange, 0.45), rgba($secondary-orange, 0.33));
		}

		&-hawkalert {
			background-color: rgba($secondary-orange, 0.25);
			background-image: none;
			border-bottom: 2px solid $secondary-orange;

			// TEMP:  Remove when all alerts are updated
			&:before {
				display: none;
			}

			h2 {
				@include font-size(3);

				i {
					color: $secondary-orange;
				}
			}
			p.hawkalert-timestamp {
				margin-top: 0;
			}
		}

		&-virus {
			background-color: rgba($secondary-blue, 0.15);
			// background-image: url('/includes/icons/custom/svg/black/exclamation-virus.svg');
			background-image: none;
			background-size: $icon-size*0.8;
			background-repeat: no-repeat;
			background-position: top $icon-size*0.1 right $icon-size*0.1;
			border-bottom: 2px solid $secondary-blue;

			// TEMP:  Remove when all alerts are updated
			&:before {
				display: none;
			}

			h2 {
				@include font-size(2.6);

				i {
					color: $secondary-blue;
				}
			}
		}


		// &-anti-racism {
			// background-image: url('/includes/icons/custom/svg/black/users-group.svg'), repeating-linear-gradient(
			// 	-45deg,
			// 	lighten($secondary-blue, 60%),
			// 	lighten($secondary-blue, 60%) 120px, 
			// 	lighten($secondary-blue, 55%) 120px,
			// 	lighten($secondary-blue, 55%) 240px
			// );
			// background-size: $icon-size*0.6, auto;
			// background-repeat: no-repeat,  repeat;
			// background-position: top $icon-size*0.2 right $icon-size*0.25, top left;

			// background-color: lighten($secondary-blue, 60%);
			// background-image: url('/includes/icons/custom/svg/black/users-group.svg');
			// background-size: $icon-size*.666;
			// background-repeat: no-repeat;
			// background-position: top $icon-size*0.2 right $icon-size*0.25;
		// }
	}
}

.all-pages-alert aside.callout,
.all-homes-alert aside.callout {

	h2 {
		padding-bottom: 0;
	}
}