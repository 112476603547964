/* -- Columns style overrides -- */

.column-group { float: left; width: 100%; clear: both; }
.column { float: left; margin-right: 5%; width: 100%; }
.column-group > br, .column-group > p { display: none; }
.column.last { margin-right: 0; }
.columns-2 .column { width: 47.5%; } /* ( 100 - $margin * ( $num_cols - 1 ) ) / $num_cols */
.columns-3 .column { width: 30%; }
.columns-4 .column { width: 21.25%; }
.columns-3 .column-span-2 { width: 65%; } /* $width * $span + $margin * ( $span - 1 ) */
.columns-4 .column-span-2 { width: 47.5%; }
.columns-4 .column-span-3 { width: 73.75%; }

.column-group {
	margin: 10px 0;

	p {
		//margin: 0;
	}

	a {
		//text-decoration: none;
	}

	h2, h3 {
		margin-bottom: 4px;
	}
}