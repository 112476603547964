.liblock__hours {
  margin: 0 auto 0.5rem auto;
  max-width: $fixed-width;

  &--title {
    margin-top: 2rem;
    padding: 0;
  }

  &--contact {
    margin-bottom: 4px;
    padding: 0;
    @include font-size(1.4);
    color: #000;

    .contact-options {
      display: inline-block;
      margin: 0;
      padding: 8px;
      list-style-type: none;

      li {
        display: inline-block;

        a {
          display: inline;
        }

        &:nth-last-child(2):after {
          content: "";  // Sorry, Oxford comma
        }
        
        &.virtual-hours {
          display: block;

          &:after {
            content: "";
          }

          .temp-alert {
            font-weight: bold;
            font-style: normal;
          }
        }
                
        &.option-more .options-below {
          display: none; // Each contact page with these listed will have a style that toggles the display of this self-referential link
        }
      }

      // TEMP - New styles adapting to open buildings and virtual hours before everything closes again. - Ken, 19 Aug 2020
      // TODO:  Generalize these for the future.

      &.virtual-hours {

      }

      &.contact-options {

        li {

        }

        .option-email {

        }
        .contact-link {

        }

      }

      &.open-building-hours {

      }

      &.post-virus-normal {

        li {
          display: block;

          &:nth-child(2):before {
            content: none;
          }
        }
      }

      .contact-message {
        padding: 8px;
      }
    }
    // -------------------------------------------------------------------------------------------------------
  }

  &--list {
    margin: 0 !important;
    padding: 0 !important;
    list-style-type: none;

    &-item {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: baseline;
      padding: 8px;
      @include zebra-stripe;
      font-family: $font-stack-buttons;

      @include font-size(1.8);

      &:first-of-type:before {
        display: none;
      }
      

      &:has(>button) {
        padding: 0;
      }

      >span {
        padding: 2px;
      }
      .date {
        min-width: 7em;
        // font-variation-settings: "wght" 500;
        font-weight: bold;
        color: black;
      }
      .status {
        display: block;
        padding: 0 2rem 0 0;
        color: black;

        // &+.status:before {
        //   content: ", ";
        // }
      }
      .note {
        display: block;
        padding: 2px 0;
        padding-right: 2rem;
        // color: black;
      }
      // .added-note {
      //   margin-left: 1em;
      //   &:before {
      //     content: " (";
      //   }
      //   &:after {
      //     content: ")";
      //   }
      // }

      &.status-closed {
        color: #333;

        .liblock__hours--list-toggle {
          background-color: #bbb;

          &:hover {
            background-color: #ccc;
          }
        }

        .date, .status, .added-note, .note {
          color: #333;
        }
      }

      &.tense-past {
        color: #666;
      }

      &.tense-today {
        padding: 0;

        // @include simple-icon('custom_clock-calendar');
      }

      &.spring-break {

        &:nth-child(odd) {
          background-color: rgba($secondary-orange, 0.3);
        }
        &:nth-child(even) {
          background-color: rgba($secondary-orange, 0.2);
        }
      }

      // &.range-override-covid19 {

      //   .date, .status, .added-note {
      //     color: #444;
      //   }
      // }

      

      &.final-exams {

        &:nth-child(odd) {
          background-color: rgba($secondary-yellow,0.3);
        }
        &:nth-child(even) {
          background-color: rgba($secondary-yellow,0.2);
        }
      }

      // &.reduced-covid19 {

      //   &:nth-child(odd) {
      //     background-color: rgba($m-orange,0.4);
      //   }
      //   &:nth-child(even) {
      //     background-color: rgba($m-orange,0.3);
      //   }
      // }

      .added-note {
        display: block;
        // @include font-size(1.2);
        font-style: italic;

        a {
          text-decoration: none;
        }
      }
      .note {
        font-style: italic;
        color: #444;
      }
    }

    &-toggle {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: baseline;
      margin: 0;
      padding: 1rem 2em 1rem 1rem;
      width: 100%;
      text-align: left;
      background-color: $hawk-gold;
      border: 0;
      font-size: 1.6rem;
      cursor: pointer;
      transition: 0.2s;
      @include bg-icon("angle-down");
      background-position: right 18px center;
      background-size: 1.4rem;
      font-family: $font-stack-buttons;
      @include font-size(1.8);

      &:hover {
        background-color: $hawk-gold-hover;
      }

      &:not(.collapsed) {
        @include bg-icon("angle-up");
      }
    }
  }

  &--more-link {
    display: block;
    padding: 0;
    text-align: right;
    background-color: transparent !important;

    a {
      display: block;
      padding: 8px 12px;
      @include font-size(1.4);

      // TODO: generalize these button styles
      background-color: $hawk-gold;
      color: black;
      transition: 0.2s;
      text-decoration: none;

      &:hover {
        background-color: $hawk-gold-hover;
      }
    }
    text-align: right;
    font-size: 85%;
  }

  &--footer p {
    margin: 0;
    padding: 4px 8px;
    font-size: 85%;

    // &:nth-of-type(1) {
    //   font-size: 100%;
    // }
  }



  // --- utility classes 
  &.hide-title {

    .liblock__hours--title {
      // @include hide-offscreen;
      display: none;
    }
  }

  &.contact-info-only {

    .liblock__hours--list {
      display: none;
    }
  }

  &.hide-contact-info {

    .liblock__hours--contact {
      display: none;
    }
  }

  &.hide-more-link {

    .liblock__hours--more-link {
      @include hide-offscreen;
    }
  }
  &.show-all {

    .liblock__hours--more-link {
      display: none !important;
    }
  }

  &.hide-footnote {

    .liblock__hours--footer {
      @include hide-offscreen;
    }
  }

  .notabene {
    color: #900;
    font-weight: bold;
  }


  // -----------------------


  // --- breakpoints
  @include breakpoint($medium-width) {

    &--contact p {

      strong {
        display: inline-block;

        &:after {
          content: " – ";
        }
      }

      .virtual-hours {
        display: inline-block;

        &:after {
          content: " – " !important;
        }
      }
      .contact-options {
        display: inline-block;
        margin: 0;
        padding: 0;
        list-style-type: none;
  
        li {
          display: inline-block;
  
          &:after {
            content: ", ";
          }
          &:last-of-type:after {
            content: ".";
          }
          &.virtual-hours:after {
            content: " – " !important;
          }
          &.option-more .options-below {
            display: none; // Each contact page with these listed will have a style that toggles the display of this self-referential link
          }
        }
      }
    }
    
    a {
      display: inline-block;
    }

  }
}

// body.home .liblock__hours--contact {
//   @include simple-icon
// }