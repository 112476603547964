a.skip-to-main,
a.skip-to-subnav {
  background: black;
  color: white !important;
  @include font-size(2);
  height: 2em;
  left: 40%;
  padding: 8px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.1s;

  &:focus {
    transform: translateY(0%);
  }
}