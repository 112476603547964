.uids-cta {
    margin-bottom: 0; // TODO: Is this prudent here?
    padding: 10rem 0;
    background-repeat: repeat;
    
    .cta-inner {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        gap: 2rem;

        margin: 0 auto;
        max-width: $fixed-width;
        
    }

    .cta-title {
        flex: 1;
        margin: 0;
        font-family: $font-display;
        text-transform: uppercase;
        @include font-size(4);
        text-align: center;

        &--font-display {
            // DEFAULT
        }
        &--font-accent {
            font-family: $font-accent;
            text-transform: none;
        }
        &--font-workhorse {
            font-family: $font-workhorse;
            text-transform: none;
        }
    }

    .cta-details {
        flex: 1;
        margin: 0;
        text-align: center;
    }

    .cta-button {
        flex: 1;
        margin: 0;
        text-align: center;
    }

    &--inline {

    }

    &--centered {

    }

    &--left {

        .cta-inner {
            align-items: flex-start;
        }
    }

    &.full-width { // full width of the viewport - beyond fixed-width
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
    }

    &.bg--community-gray {
        background-image: url("//www.lib.uiowa.edu/includes/uids/images/pattern-community-gray.png");
        background-size: 75%;
        background-color: $secondary-grey-lighter;

        &-on-gray {
            // DEFAULT is lighter gray
        }
        &-on-white {
            @extend .bg--community-gray;
            background-color: white;
        }
        &-on-black {
            @extend .bg--community-gray;
            background-color: $hawk-black;
        }
        &-on-gold {
            @extend .bg--community-gray;
            background-color: $hawk-gold;
        }
    }
    &.bg--community-white {
        background-image: url("//www.lib.uiowa.edu/includes/uids/images/pattern-community-white.png");
        background-size: 75%;
        background-color: $secondary-grey-lighter;

        &-on-gray {
            // DEFAULT is lighter gray
        }
        &-on-black {
            @extend .bg--community-white;
            background-color: $hawk-black;
        }
        &-on-gold {
            @extend .bg--community-white;
            background-color: $hawk-gold;
        }
    }
    &.bg--community-gold {
        background-image: url("//www.lib.uiowa.edu/includes/uids/images/pattern-community-gold.png");
        background-size: 75%;
        background-color: $secondary-yellow-lighter;

        &-on-gold {
            // DEFAULT is light yellow (gold)
        }
        &-on-black {
            @extend .bg--community-gold;
            background-color: $hawk-black;
        }
        &-on-white {
            @extend .bg--community-gold;
            background-color: white;
        }
    }
    &.bg--community-black {
        background-image: url("//www.lib.uiowa.edu/includes/uids/images/pattern-community-black.png");
        background-size: 75%;
        background-color: $secondary-grey-lighter;

        &-on-gray {
            // DEFAULT is lighter gray
        }
        &-on-white {
            @extend .bg--community-black;
            background-color: white;
        }
        &-on-gold {
            @extend .bg--community-black;
            background-color: $hawk-gold;
        }
    }

    &.bg--brain-gray {
        background-image: url("//www.lib.uiowa.edu/includes/uids/images/pattern-brain-gray.png");
        background-size: 25%;
        background-color: $secondary-grey-lighter;
    }
    &.bg--brain-gold {
        background-image: url("https://www.lib.uiowa.edu/images/branding/community-pattern--gray.png");
        background-color: #f3f3f3;
    }
    &.bg--brain-black {
        background-image: url("https://www.lib.uiowa.edu/images/branding/community-pattern--gray.png");
        background-color: #f3f3f3;
    }

    &.bg--particles-white {
        background-image: url("https://www.lib.uiowa.edu/images/branding/particles-pattern--gray.png");
        background-color: #f3f3f3;
    }
    &.bg--particles-gold {
        background-image: url("https://www.lib.uiowa.edu/images/branding/particles-pattern--gray.png");
        background-color: #f3f3f3;
    }
    &.bg--particles-black {
        background-image: url("https://www.lib.uiowa.edu/images/branding/particles-pattern--gray.png");
        background-color: #f3f3f3;
    }

    // ——— BREAKPOINTS ————————————————————————————————————————
    @include breakpoint($medium-width) {
        
        &--inline {

            .cta-inner {
                flex-flow: row nowrap;
            }
        }
        
    }
    // ————————————————————————————————————————————————————————
}