$image-scale-on-hover:  						1.05; // how much the image grows on hover
$image-text-gutter: 								8px;
$image-size-default:								60px;
$image-size-small-width:						80px;
$image-size-fixed-width:						100px;
$image-size-default-single:					100%;


.events-region {
	margin-bottom: 2rem;
}

.events-list-contenthub {
	margin-right: 1rem;

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;

		.event {
			@include clearfix;
			padding: 1rem 0;
			
			.event-image {
				float: left;
				margin-left: 3px;
				width: calc($image-size-default / $image-scale-on-hover);
				height: calc($image-size-default / $image-scale-on-hover);
				transition: 0.2s;
				
				a img {
					border: 1px solid #666;
					background-color: #fff;
					transition: 0.2s;
				}
      }

			&:hover {

				.event-image {
					transform: scale($image-scale-on-hover, $image-scale-on-hover);

					a img {
						box-shadow: 2px 2px 2px rgba(#666, 0.5);
					}
				}
			}

			.event-info {
				padding-left: $image-text-gutter;
				overflow: hidden;

				a {
					overflow-wrap: anywhere;
				}
			}

			.event-title {
				padding-bottom: 0.5rem;
				font-weight: bold;
				@include font-size(1.8);
				font-family: "Roboto", sans-serif;  // TODO: change to variable when UIDS styles are ensconced.

				a {
					text-decoration: none;
				}
			}

			.event-description {
				padding-bottom: 0.5rem;
				@include font-size(1.4);

				.readmore a {
					text-decoration: none;
				}
			}
			
			.event-time {
				@include font-size(1.4);
				@include simple-icon('calendar-alt-regular');

				i {
					text-transform: uppercase;
					font-size: 85%;
					font-style: normal;
				}
				em {
					display: block;
					font-size: 85%;
					line-height: 85%;
				}
			}

			.event-virtual-location {
				@include font-size(1.4);
				@include simple-icon('globe');
			}

			.event-location {
				@include font-size(1.4);
				@include simple-icon('map-marker-alt');
				background-size: 0.9em; // map-marker-alt is oddly shaped and looks bigger
			}

			&.EVENT-CANCELED {
				opacity: 0.8;

				.event-time,
				.event-location {
					text-decoration: line-through;
				}
			}

			&.SCOPE-PRIVATE {
				display: none;
			}
		}
  }
  
  // ------ BREAKPOINTS ----------------------------------------------------------------------
  @include breakpoint($small-width) {

    ul li.event {

      .event-image {
        width: calc($image-size-small-width / $image-scale-on-hover);
			height: calc($image-size-small-width / $image-scale-on-hover);
      }
    }
  }
  
  @include breakpoint($fixed-width) {

    ul li.event {

      .event-image {
        width: calc($image-size-fixed-width / $image-scale-on-hover);
				height: calc($image-size-fixed-width / $image-scale-on-hover);
      }
    }
  }
  // -----------------------------------------------------------------------------------------
}

.event-single-contenthub {

	.event {
		@include clearfix;
		// padding: 0 1rem;
		
		.event-image {
			margin-bottom: 1rem;
			max-width: $image-size-default-single;
			
			a img {
				border: 1px solid #666;
				max-width: 100%;
			}
		}

		.event-info {
			padding-top: 2rem;
		}

		.event-title {
			font-weight: 400;
			@include font-size(2.6);

			a {
				text-decoration: none;
			}
		}
		
		.event-quick {
			padding-bottom: 1rem;
			@include font-size(1.6);

			&-time {
				@include simple-icon('calendar-alt-regular');

				i {
					text-transform: uppercase;
					font-size: 85%;
					font-style: normal;
				}
				em {
					display: block;
					font-size: 85%;
				}
			}

			&.event-virtual-location {
				@include simple-icon('globe');
				background-size: 0.9em; // map-marker-alt is oddly shaped and looks bigger
			}

			&-location {
				@include simple-icon('map-marker-alt');
				background-size: 0.9em; // map-marker-alt is oddly shaped and looks bigger
			}

			&-contact {
				@include simple-icon('address-card');
			}

			&-url {
				@include simple-icon('link');
			}

			&-signup-url {
				@include simple-icon('user-plus');
			}
		}
		
		.event-description {
			margin: 2rem 0;
			padding-bottom: 6rem;
			@include font-size(1.4);

			.readmore a {
				text-decoration: none;
			}
		}

		.event-taxonomy {
			padding-bottom: 1rem;
			@include font-size(1.4);
			@include simple-icon('tags');

			strong {
				font-weight: 400;

				&:after {
					content: ": ";
				}
			}
		}

		.events-site-url {
			padding-bottom: 1rem;
			@include font-size(1.4);
			@include simple-icon('external-link-alt');

			strong {
				font-weight: 400;
			}
		}

		.accessibility-statement {
			margin-top: 2rem;
			padding: 2rem 0;
			border-top: 1px solid #ccc;
			font-style: italic;
			color: #666;
			@include font-size(1.4);
		}
	}

  
  // ------ BREAKPOINTS ------------------------------------------------------------------------------
  @include breakpoint($medium-width) {

    .event {

      .event-image {
				float: right;
				margin-left: 2rem;
				max-width: 300px;
			}
    }
  }
  
  @include breakpoint($fixed-width) {

    .event {
			padding: 0;

      // .event-image {
			// 	max-width: 300px;
			// }
    }
  }
  // --------------------------------------------------------------------------------------------------
}