.callout.callout-alert.callout-alert-virus {

  .callout-inner {

    h2 {
      @include font-size(2.2);
    }
    p:first-of-type {
      margin-top: 0;
    }
  }
}