.footer__links .menu,
.footer__links .menu ul li,
.footer__links--icon ul,
.footer__links--icon li,
.socket__menu,
.footer-cta__container .menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer__links .menu>li,
.footer__links .menu ul li>li,
.footer__links--icon ul>li,
.footer__links--icon li>li,
.socket__menu>li,
.footer-cta__container .menu>li {
  display: inline-block;
  list-style-type: none;
  margin: 0;
}

.footer__container,
.socket__container,
.footer-cta__container {
  margin: 0 1.25rem;
  position: relative;
}

@media (min-width: 84.375em) {

  .footer__container,
  .socket__container,
  .footer-cta__container {
    max-width: 81.875em;
    margin: 0 auto;
  }
}

.footer {
  position: relative;
  background: #151515;
  padding-top: 2.5rem;
  padding-right: 0;
  padding-bottom: 2.5rem;
  padding-left: 0;
}

@media (min-width: 855px) {
  .footer {
    padding-top: 2.5rem;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
}

.footer p {
  color: #ccc;
  line-height: 1.5;
  font-weight: 100;
  margin-top: 0.9rem;
}

.footer p a {
  color: #FFCD00;
  text-decoration: none;
  font-weight: 400;
}

.footer p a:hover,
.footer p a:focus {
  text-decoration: underline;
}

.footer p a i,
.footer p a svg,
.footer p a span {
  margin-right: 0.625rem;
  font-size: 0.8rem;
  color: #999;
}

.footer .site-name {
  color: #fff;
  font-size: 1.7rem;
  margin-top: 1.05rem;
  font-weight: 900;
  margin-bottom: 0.325rem;
}

.footer .site-name a {
  color: #fff;
  text-decoration: none;
}

.footer .site-name a:hover,
.footer .site-name a:focus {
  text-decoration: underline;
}

.footer .parent-site-name {
  color: #ccc;
  font-size: 1.4rem;
  margin-top: 0;
  font-weight: 300;
  margin-bottom: .625rem;
  display: block;
  background: #151515;
  padding: 0;
}

.footer .parent-site-name a {
  color: #ccc;
  text-decoration: none;
}

.footer .parent-site-name a:hover,
.footer .parent-site-name a:focus {
  text-decoration: underline;
}

@media (min-width: 84.375em) {
  .footer__container {
    padding: 0;
  }
}

@media (min-width: 855px) {
  .footer__container--flex {
    display: flex;
    flex-wrap: wrap;
  }
}

.footer__links {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 1.875rem;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.footer__links .menu {
  margin: 0;
}

.footer__links .menu li {
  /* * %no-ul-list sets display: inline-block; for li, * which doesn't work for this menu. */
  display: block;
}

.footer__links .menu a {
  font-weight: 300;
  text-decoration: none;
  color: white;
  font-size: 1.1rem;
}

.footer__links .menu a:hover,
.footer__links .menu a:focus {
  text-decoration: underline;
}

.footer__links .menu ul {
  margin: 0;
}

.footer__links--nav {
  margin-bottom: 2.5rem;
}

@media (min-width: 855px) {
  .footer__links--nav {
    display: flex;
  }
}

@media (min-width: 855px) {
  .footer__links--contact {
    flex-grow: 2;
  }
}

.footer__links--icon {
  /* * Display icon menu as flex since the icons sit * side-by-side. */
}

.footer__links--icon .menu {
  flex-wrap: wrap;
  display: flex;
  line-height: 2;
}

@media (min-width: 855px) {
  .footer__links--icon .menu {
    line-height: 1;
  }
}

.footer__links--icon a {
  text-decoration: none;
  color: #fff;
}

.footer__links--icon i {
  color: #fff;
  font-size: 1.6rem;
  margin-right: 1.05rem;
}

.footer__links--icon .link-text {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

@media (min-width: 84.375em) {
  .socket__container {
    padding: 0;
  }
}

.socket__menu {
  display: flex;
  flex-wrap: wrap;
}

.socket__menu li {
  color: white;
  font-size: 0.8rem;
  border-right: 1px solid #5d5656;
  margin-bottom: 0.325rem;
  padding-top: 0;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.socket__menu li:first-child {
  flex-basis: 100%;
  border-right: none;
}

.socket__menu li:nth-child(2) {
  padding-left: 0;
}

.socket__menu li:last-child {
  border-right: none;
  padding-right: 0;
}

.socket__menu li:first-child {
  padding-left: 0;
}

.socket__menu li a {
  text-decoration: none;
  color: #FFCD00;
}

.socket__menu li a:hover,
.socket__menu li a:focus {
  text-decoration: underline;
}

.footer-cta__wrapper {
  background: #FFCD00;
  padding: 1.875rem 0;
  margin: 0;
}

@media (min-width: 768px) {
  .footer-cta__wrapper .menu {
    display: flex;
  }
}

.footer-cta__wrapper .menu li {
  flex: 1;
  display: block;
}

.footer-cta__wrapper .bttn--outline {
  border: 1px solid #151515;
  display: block;
}

@media (min-width: 768px) {
  .footer-cta__wrapper .bttn--outline {
    flex: 1;
    display: block;
  }
}

.footer-cta__container {
  justify-content: space-between;
}

.footer-cta__container div:first-of-type,
.footer-cta__container nav:first-of-type {
  flex-basis: 100%;
}

@media (min-width: 768px) {
  .footer-cta__container {
    display: flex;
  }
}

@media (min-width: 84.375em) {
  .footer-cta__container {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .footer-cta__container .menu li:first-child a {
    margin: 0 1.05rem 0 0;
  }

  .footer-cta__container .menu li:last-child a {
    margin: 0 0 0 1.05rem;
  }
}

.footer-cta__container .menu a {
  margin: 1.05rem 0;
  padding-top: 0.325rem;
}

@media (min-width: 768px) {
  .footer-cta__container .menu a {
    margin: 0 1.05rem;
  }
}

.footer-cta__container .menu a:before {
  content: ' ';
  background-repeat: no-repeat;
  background-size: 90%;
  width: 35px;
  height: 35px;
  display: inline-block;
  margin-bottom: -5px;
  margin-right: 5px;
}

.footer-cta__container .menu a.bttn--apply:before {
  background-image: url("../../assets/images/apply-01.svg");
}

.footer-cta__container .menu a.bttn--visit:before {
  background-image: url("../../assets/images/location-01.svg");
}

.footer-cta__container .menu a.bttn--request:before {
  background-image: url("../../assets/images/request-01.svg");
}

.footer-cta__container>a:first-child {
  margin-left: 0;
}

.footer-cta__container>a:last-child {
  margin-right: 0;
}

.logo--footer {
  padding: 0 40px 0 0;
  width: 128px;
}

@media (min-width: 855px) {
  .logo--footer {
    width: 195px;
  }
}

@media (min-width: 84.375em) {
  .logo--footer {
    width: 176px;
  }
}






























// ================================================================================================

footer.footer {
  padding: 3rem 2rem;
  width: calc(100% - 4rem);

  .footer__container {
    margin: 0 auto;
    max-width: $fixed-width;
  }

  // .logo--footer {

  //   svg {
  //     fill: $hawk-gold;
  //     transition: 0.1s;
  //   }
  // }

  .logo-icon {
    fill: $hawk-gold;
    transition: 0.1s;

    &:hover {
      opacity: 0.7;
    }
  }

  .site-name-link {
    text-decoration: none;

    h2 {
      @include font-size(2.7);
      font-weight: 900;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .region-footer-first {
    @include font-size(1.9);
    font-weight: 100;

    a {
      font-weight: 400;
    }

    i {
      display: inline-block;
      margin-right: 1rem;
      @include font-size(1.9);
      // padding-right: 0px;
    }
  }

  .socket {
    margin-top: 4rem;

    ul.socket__menu {

      li,
      a {
        @include font-size(1.3);
        line-height: 2.1rem;
        font-weight: 400;
      }
    }
  }




  .footer__links {
    ul {

      li {

        a {
          @include font-size(1.8);
          line-height: 3rem;
        }

        &.uilib-other-links svg {
          margin-top: 1rem;
          height: 2.4rem;

          a:focus {
            fill: $hawk-gold;
          }
        }

        &.uilib-social-media {
          margin: 2rem 0;
          min-width: 200px;
          max-width: 300px;

          a:focus {
            fill: $hawk-gold;
          }
        }
      }
    }
  }

  .region-footer-first {

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        color: #ccc;
        @include font-size(1.6);

        i {
          width: 2rem;
          color: #ccc;
        }
      }
    }

    .uilib-social-media {
      margin-top: 2rem;
      width: 340px;
    }
  }

  a {
    color: $hawk-gold;
    font-weight: 400;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

}