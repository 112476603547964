// NOTE:  There are many !importants here required to override the Feedzy plugin's built-in styles.
// TODO:  Remove Feedzy's CSS - https://docs.themeisle.com/article/545-how-to-remove-plugin-css

.feedzy-rss {
	$image-scale-on-hover:  			1.05; // how much the image grows on hover
	$image-text-gutter: 					8px;
	$image-size-default:					60px;
	$image-size-small-width:			80px;
	$image-size-fixed-width:			100px;

	ul {
		margin: 0 !important;
		padding: 0;

		li.rss_item {
			margin: 0 0 0 3px;
			padding: 1rem 1rem 1rem 0 !important;  // STUPID use of ids vs. classes should be stopped
			border-bottom: none;

			.rss_image {
				margin-top: 0;
				margin-right: 1rem;
				transition: 0.2s;
				border: 1px solid #666;
				
				a {

					span {
						
						transition: 0.2s;

						&.default {
							display: none; // Hides the Feedzy logo from flashing prior to featured image load
						}
					}
				}

				+ p {  // Sometimes, a <p> is rendered surrounding the span.title. WTF?
					margin: 0;
				}

				// HACK:  This kludge hides the link text added by javascript to avoid the Section508.L.NoContentAnchor error.
				span.link-text {
					font-size: 2px;
					visibility: hidden;
				}
			}

			.title {
				display: block;
				font-weight: bold;

				a {
					@include font-size(1.8);
					font-weight: bold;
					text-decoration: none;
				}
			}
			

			.rss_content p {
				margin-top: 0.5rem;
				margin-bottom: 0.5rem;
				@include font-size(1.4);
				// font-weight: lighter;
			}

			&:hover {

				.rss_image {
					transform: scale($image-scale-on-hover, $image-scale-on-hover);
					box-shadow: 2px 2px 2px rgba(#666, 0.5);
				}
			}
		}
	}

	// === BREAKPOINTS ======================================================================================
	@include breakpoint($no-width) {
		// The no-width breakpoint is required to take care of the inline styles used in the FeedzyRSS plugin

		ul li.rss_item {

		.rss_image {
			width: calc($image-size-default / $image-scale-on-hover) !important;
					height: calc($image-size-default / $image-scale-on-hover) !important;

					a {
						width: calc($image-size-default / $image-scale-on-hover) !important;
						height: calc($image-size-default / $image-scale-on-hover) !important;
					}

					span {
						height: calc($image-size-default / $image-scale-on-hover) !important;
						width: calc($image-size-default / $image-scale-on-hover) !important;
					}
				}
				
				.title {
					padding-left: calc($image-size-default / $image-scale-on-hover) + $image-text-gutter;
				}

				.rss_content {
					padding-left: calc($image-size-default / $image-scale-on-hover) + $image-text-gutter;
				}
		}
	}
	
	@include breakpoint($small-width) {

    ul li.rss_item {

      .rss_image {
        width: calc($image-size-small-width / $image-scale-on-hover) !important;
				height: calc($image-size-small-width / $image-scale-on-hover) !important;

				a {
					width: calc($image-size-small-width / $image-scale-on-hover) !important;
					height: calc($image-size-small-width / $image-scale-on-hover) !important;

					span {
						height: calc($image-size-small-width / $image-scale-on-hover) !important;
						width: calc($image-size-small-width / $image-scale-on-hover) !important;
					}
				}
			}
			
			.title {
				padding-left: calc($image-size-small-width / $image-scale-on-hover) + $image-text-gutter;
			}

			.rss_content {
				padding-left: calc($image-size-small-width / $image-scale-on-hover) + $image-text-gutter;
			}
    }
	}
	
	@include breakpoint($fixed-width) {

    ul li.rss_item {

      .rss_image {
        width: calc($image-size-fixed-width / $image-scale-on-hover) !important;
				height: calc($image-size-fixed-width / $image-scale-on-hover) !important;

				a {
					width: calc($image-size-fixed-width / $image-scale-on-hover) !important;
					height: calc($image-size-fixed-width / $image-scale-on-hover) !important;

					span {
						height: calc($image-size-fixed-width / $image-scale-on-hover) !important;
						width: calc($image-size-fixed-width / $image-scale-on-hover) !important;
					}
				}
			}
			
			.title {
				padding-left: calc($image-size-fixed-width / $image-scale-on-hover) + $image-text-gutter;
				line-height: 1.5;
			}

			.rss_content {
				padding-left: calc($image-size-fixed-width / $image-scale-on-hover) + $image-text-gutter;
			}
    }
	}
	// ======================================================================================================
}

.feedzy-rss + .more-link {
	float: right;

	a {
		font-weight: bold;
		text-decoration: none;
		@include font-size(1.2);
	}
}